<template>
<div id="wrapper" :key="valuekey">
        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <h4 class="page-title">{{$t('string.msg.list.user.label.title')}}</h4>

                            </div>
                        </div>
                    </div>
                    <!-- Form row -->
                    <div class="row" >
                        <div class="col-md-12">
                            <div class="card-box">
                                <p class="sub-header">
                                    {{$t('string.msg.list.user.label.description')}}
                                </p>
                                <form>

                                    <div v-if="data._id" class="form-row">
                                        <div class="form-group col-md-3">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code> {{$t('string.msg.product.brand.label.id')}}
                                            </label>
                                            <input type="text" readonly v-model="data._id" class="form-control">
                                        </div>
                                        <div class="form-group col-md-2">
                                            <br/>
                                            <br/>
                                            <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.user.label.name')}}
                                            </label>
                                            <input type="text" v-model="data.firstName" class="form-control" readonly>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.user.label.lastname')}}
                                            </label>
                                            <input type="text" v-model="data.lastName" class="form-control" readonly>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.user.label.email')}}
                                            </label>
                                            <input type="text" v-model="data.email" class="form-control" readonly>
                                        </div>

                                        <div v-if="data.companyUser && data.companyUser.subtype" class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.user.label.type')}}
                                            </label>
                                            <input v-if="data.companyUser.subtype == 'ADMIN'" :placeholder="camelize($t('string.msg.user.type.company.admin'))"  type="text" class="form-control" readonly>
                                            <input v-if="data.companyUser.subtype == 'ADMIN_MASTER'" :placeholder="camelize($t('string.msg.user.type.company.admin.master'))"  type="text" class="form-control" readonly>
                                            <input v-else-if="data.companyUser.subtype == 'SIMPLE'"  :placeholder="camelize($t('string.msg.user.type.company.simple'))" type="text" class="form-control" readonly>
                                        </div>
                                    </div>

                                    <div v-if="data.companyUser.subtype== 'ADMIN'" class="form-row" >
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.company.user.rg')}}
                                            </label>
                                             <input  type="text" v-model="data.companyUser.documentRg"  :placeholder="$t('str.company.user.rg')" class="form-control">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('str.company.user.cpf')}}
                                            </label>
                                            <input  type="text" v-model="data.companyUser.documentCpf" v-mask="['###.###.###-##']" :placeholder="$t('str.company.user.cpf')" class="form-control">
                                        </div>
                                    </div> 
                                    
                                    <div class="form-row" v-if="data.companyUser">
                                        <div class="form-group col-md-12">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.user.label.company')}}
                                            </label>
                                            <input type="text" v-model="data.companyUser.company.fantasyName" class="form-control" readonly>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.user.platforms.with.access.label')}}
                                                </label>
                                                <div class="d-flex flex-wrap">
                                                    <div class="container-switch" v-bind:key="item.id" v-for="item in services">
                                                        <label class="switch">
                                                            <input 
                                                                v-model="data.companyUser.userPlatforms" 
                                                                type="checkbox"
                                                                :id="item._id" 
                                                                :value="item._id"
                                                                :true-value="item._id"
                                                                :false-value="null"
                                                                >
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <span class="label-swith">{{camelize(item.name)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    <div class="form-row text-center">
                                        <div class="form-group col-md-6">
                                            <button @click="clearForm" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <button @click="save" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                        </div>
                                    </div>

                                </form>

                                </div>
                        </div>
                    </div>
                    <List v-on:load-item="selectItem"/>
                </div>
            </div>
        </div>
        
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
    </div>
</template>

<script>
import Controller from './CrtUser';
import List from './ListUser';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from '../../../components/layout/Confirmation';

export default {
  name: 'User',
  components: {
    Spinner,
    Toast,
    List,
    Confirmation
  },
  data() {
      return {
        errors: [],
        addresses: [],
        valuekey: 0,
        newPassword: null,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        company:null,
        services: [],
        searchBy: null,
        data: {
            status: "ACTIVE",
            firstName: null,
            lastName: null,
            email: null,
            isAcceptGeneralTerms: true,
            type: "USER-COMPANY",
            companyUser:{
                company: null,
                status: "ACTIVE",
                photoURL: "https://",
                userPlatforms:[],
                subtype: null
            }
        },
        
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
