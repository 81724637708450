var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.additional.informations.label.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("form", [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("h6", { staticClass: "text-right" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("string.msg.ratinng.label.status")) +
                              ":"
                          )
                        ]),
                        _vm.data.status == "ACTIVE"
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                        "
                              )
                            ])
                          : _vm._e(),
                        _vm.data.status == "ARCHIVED"
                          ? _c("span", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                        "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.company.label.instagram")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.instagramURL,
                              expression: "data.instagramURL"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.instagram.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.instagramURL },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "instagramURL",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.company.label.facebook")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.facebookURL,
                              expression: "data.facebookURL"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.facebook.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.facebookURL },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "facebookURL",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.company.label.googlebusiness"
                                  )
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.googleBusinessURL,
                              expression: "data.googleBusinessURL"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.googlebusiness.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.googleBusinessURL },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "googleBusinessURL",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.company.label.youtube")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.youtubeURL,
                              expression: "data.youtubeURL"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.youtube.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.youtubeURL },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "youtubeURL",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.company.label.whatsapp")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.whatsApp,
                              expression: "data.whatsApp"
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) ####-####", "(##) #####-####"],
                              expression:
                                "['(##) ####-####', '(##) #####-####']"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.whatsapp.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.whatsApp },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "whatsApp",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.company.label.telegram")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.telegram,
                              expression: "data.telegram"
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) ####-####", "(##) #####-####"],
                              expression:
                                "['(##) ####-####', '(##) #####-####']"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.company.label.telegram.placeholder"
                            )
                          },
                          domProps: { value: _vm.data.telegram },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "telegram",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mb-2" }),
                    _c("div", { staticClass: "form-row text-center" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger w-50",
                            on: { click: _vm.save }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("FlashMessage"),
      _c("ModalAddress")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }