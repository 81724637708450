<script>
    import axios from 'axios';

    axios.defaults.withCredentials = true

    axios.interceptors.response.use(
        response => {
            return response
        },
        error => {
            if (!error.response) {
                console.log("Please check your internet connection.");
            }

            return Promise.reject(error)
        }
    );
    
    export default {
        getDefaultHeader: (vue) => { 
            let header = { 'Content-Type': 'application/json' };
            
            if (localStorage.getItem('correlationId')){
                header['x-correlation-id'] = localStorage.getItem('correlationId');
            }
            return header;
        },
        do: async function (vue, method, headers, params, endpoint, callbackSuccess, callbackError) {
            try {
                let res = await axios({
                    method:     method,
                    url:        endpoint,
                    headers:    headers,
                    data:       params
                });
                
                if (callbackSuccess) return callbackSuccess.call(vue, res.data, res);
                return res
            } catch(err) {
                if (callbackError) return callbackError.call(vue, err);
                return err
            }
        }
    }
</script>

