<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card-box">

                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.list.user.label.search')}}
                            </label>
                            <input type="text" v-model="filters.name" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.list.companies.label.search')}}
                            </label>
                            <multiselect v-model="company" :options="companies" @input="searchUserByCompany(company)" 
                                :close-on-select="true" track-by="_id" :custom-label="customLabelCompany" :searchable="true"
                                placeholder="Selecione" > 
                            </multiselect>  
                        </div>
                    </div>

                    <br/>
                    <div class="d-flex justify-content-between bd-highlight mb-3">
                        <button @click="filter" class="btn btn-success w-20">{{$t('string.msg.form.btn.search')}}</button>
                    </div>
                </form>

                  <div class="table-responsive">
                    <table class="table table-centered table-hover mb-0" id="datatable">
                        <thead class="thead-dark">
                            <tr>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.name')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.email')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.level')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.company')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.status')}}</th>
                            </tr>
                        </thead>
                        <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer" >
                              <tr v-if="item.status == 'ACTIVE'" >
                                <td>
                                    <span v-if="item.firstName && item.lastName">
                                        {{camelize(item.firstName) + ' ' + camelize(item.lastName) }}
                                    </span>
                                </td>
                                <td>
                                  <span v-if="item.email">
                                        {{item.email}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.companyUser.subtype == 'ADMIN'">
                                        {{$t('string.msg.user.type.company.admin')}}
                                    </span>
                                    <span v-else-if="item.companyUser.subtype == 'SIMPLE'">
                                        {{$t('string.msg.user.type.company.simple')}}
                                    </span>
                                    <span v-else-if="item.companyUser.subtype == 'ADMIN_MASTER'">
                                        {{$t('string.msg.user.type.company.admin.master')}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.companyUser && item.companyUser.company && item.companyUser.company.fantasyName">
                                        {{ camelize(item.companyUser.company.fantasyName)}}
                                    </span>
                                    <span v-else style="color:red">
                                        {{'X'}}
                                    </span>
                                </td>
                                <td>
                                  <span v-if="item.status">
                                        {{camelize($t(item.status))}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="errors.length" class="alert alert-danger">
                    <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                    <ul>
                        <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                </div>

            </div>
        </div>
    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    </div>
</template>

<script>
import Controller from './CrtListUser';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';

export default {
  name: 'ListUser',
  props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
  components: {
    Spinner,
    Toast,
    
  },
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        company: null,
        items: [],
        companies: [],
        userType: null,
        filters: {
            name: "",
        }
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
.multiselect__option--highlight {
    background: #1DA1F2;
    outline: none;
    color: #fff;
}
.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #1DA1F2;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #1DA1F2;
}
</style>
