<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card-box">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                      
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        methods:{
        },
    data() {
      return {
    }
    }
}
</script>

