<template>

  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
    
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <h4 class="page-title">{{$t('string.msg.company.registration.label.title')}}</h4>
                    </div>
                </div>
            </div>
            <!-- Form row -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card-box">
                        <form>

                            <div class="card-box">
                                <ul class="nav nav-tabs nav-bordered nav-justified">
                                    <li class="nav-item">
                                        <a href="#company" data-toggle="tab" aria-expanded="true" class="nav-link active">
                                            {{$t('string.msg.company.register.label')}}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#plans" data-toggle="tab" aria-expanded="false" class="nav-link">
                                            {{$t('string.msg.plans.label')}}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#sponsors" data-toggle="tab" aria-expanded="false" class="nav-link">
                                            {{$t('string.msg.sponsors.label')}}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#platforms" data-toggle="tab" aria-expanded="false" class="nav-link">
                                            {{$t('string.msg.technical.data')}}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#additionalUsers" data-toggle="tab" aria-expanded="false" class="nav-link">
                                            {{$t('string.msg.additional.ser')}}
                                        </a>
                                    </li>
                          
                                </ul>   

                                <!-- DADOS DA EMPRESA -->
                                <div class="tab-content">
                                    <div class="tab-pane active" id="company">
                                        <h4 class="page-title"><strong>{{$t('string.msg.data.company.label')}}</strong></h4>
                                        <div v-if="data._id" class="form-group col-md-12">
                                            <h6 class="text-right">   
                                                <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                                <span v-if="data.status == 'ACTIVE'" style="color: green">
                                                    {{$t(data.status)}} 
                                                </span>
                                                <span v-if="data.status == 'ARCHIVED'" style="color: blue">
                                                    {{$t(data.status)}} 
                                                </span>
                                            </h6>
                                        </div>

                                        <div v-if="data._id && data.status != 'ARCHIVED' && isAdminMaster()" class="form-row">
                                            <div class="form-group col-md-2">
                                                <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.fantasyName')}}
                                                </label>
                                                <input type="text" v-model="data.fantasyName" class="form-control" :placeholder="$t('string.msg.company.placeholder.fantasyName')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.socialName')}}
                                                </label>
                                                <input type="text" v-model="data.socialName" class="form-control" :placeholder="$t('string.msg.company.placeholder.socialName')">
                                            </div>
                                
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.document')}}
                                                </label>
                                                <input type="tel" v-mask="['###.###.###-##', '##.###.###/####-##']"   v-model="data.document" class="form-control" :placeholder="$t('string.msg.company.placeholder.document')">
                                            </div>
                                                <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code v-if="!data._id" class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                                </label>
                                                <input v-model="data.primaryPhone" type="text" class="form-control" :placeholder='$t("string.msg.company.placeholder.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                            </div>
                                        </div>  

                                        <div class="form-row">

                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.table.label.type')}}
                                                </label> 
                                                <select id="typeService" v-model="data.typeService[0]" class="form-control">
                                                    <option :value="[]" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                    <option :value="item._id" v-bind:key="item" v-for="item in companyTypes">{{camelize($t(item.name))}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <label>{{$t('str.msg.configuration.for.adm.label.title')}}</label>
                                        <div class="mt-1">
                                            <div class="custom-control custom-checkbox">
                                                <input  v-model="data.authWithoutLogo" disabled type="checkbox" class="custom-control-input" id="customCheck1">
                                                <label class="custom-control-label" for="customCheck1">{{$t('str.msg.company.have.logo.question.label.descrition.two')}}</label>
                                            </div>
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="data.buyLogo" type="checkbox" disabled class="custom-control-input" id="customCheck2">
                                                <label class="custom-control-label" for="customCheck2">{{$t('str.msg.company.have.logo.question.label.descrition.one')}}</label>
                                            </div>
                                            <div class="custom-control custom-checkbox">
                                                <input  v-model="data.haveApp" disabled type="checkbox" class="custom-control-input" id="customCheck3">
                                                <label class="custom-control-label" for="customCheck3">{{$t('str.company.have.app.label')}}</label>
                                            </div>
                                            <div class="custom-control custom-checkbox">
                                                <input  v-model="data.appInAppleStore" disabled type="checkbox" class="custom-control-input" id="customCheck4">
                                                <label class="custom-control-label" for="customCheck4">{{$t('str.app.in.apple.store.label')}}</label>
                                            </div>
                                            <div v-if="data.appName">
                                                <label>{{$t('str.app.name.label') + ":"}}</label>
                                                <span v-if="data.appName.length > 0" disabled style="color:gray">{{' '+ data.appName}}</span>
                                                <label v-else>{{$t('str.name.app.not.informed')}}</label>
                                            </div>
                                            
                                        </div>
                                        <br/>
                                        <label>{{$t('string.msg.company.list.domain.label.title')}}</label>
                                        <div class="mb-1 mt-1 table-responsive" v-if="data.domains && data.domains.length > 0">
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th class="border-top-0">{{$t('string.msg.domain.label.name')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody :key="item" v-bind="item" v-for="item in data.domains">
                                                    <tr  >
                                                        <td>
                                                            <span>
                                                                {{item}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else>
                                            <label style="color:gray">{{ ' - ' + $t('str.company.no.domain')}}</label>
                                        </div>

                                        <div v-if="data.linkDomain && data.linkDomain.length > 0">
                                            <label>{{$t('string.msg.menu.company.domains.link.buy.admin.label')}}</label><br>
                                            <label style="color:gray">{{' - '+ data.linkDomain}}</label>
                                        </div><br/>

                                        <div v-if="data.emailAccess">
                                            <label>{{$t('string.msg.credentials.platforms.label')}}</label><br>
                                            <label>{{$t('str.company.email.access') + ': '}}</label>
                                            <label style="color:gray">{{data.emailAccess}}</label><br>
                                            <label>{{$t('str.company.password.access') + ': '}}</label>
                                            <label style="color:gray">{{data.passwordAccess}}</label>
                                        </div> -->

                                        <label for="inputAddress" class="col-form-label">
                                            {{$t('string.msg.company.address')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-2">
                                                <label>
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.cep')}}
                                                </label>
                                                <input @blur="loadInfosByCEP" v-mask="'#####-###'" v-model="data.address.cep" type="tel" class="form-control" :placeholder="$t('string.msg.company.label.cep')" />
                                            </div>
                                        </div>
                                        <div v-if="data.address.cep && data.address.cep.length == 9" class="form-row ">
                                            <div class="form-group col-md-4">
                                                <label>
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.state')}}    
                                                </label>
                                                <select v-model="data.address.state" class="form-control select2">
                                                    <option :value="-1" selected>{{$t('string.msg.element.select.selectitem')}}</option>
                                                    <option value="AC">AC</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AP">AP</option>
                                                    <option value="AM">AM</option>
                                                    <option value="BA">BA</option>
                                                    <option value="CE">CE</option>
                                                    <option value="DF">DF</option>
                                                    <option value="ES">ES</option>
                                                    <option value="GO">GO</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MT">MT</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MG">MG</option>
                                                    <option value="PA">PA</option> 
                                                    <option value="PB">PB</option>
                                                    <option value="PR">PR</option>
                                                    <option value="PE">PE</option>
                                                    <option value="PI">PI</option>
                                                    <option value="RJ">RJ</option>
                                                    <option value="RN">RN</option>
                                                    <option value="RS">RS</option>
                                                    <option value="RO">RO</option>
                                                    <option value="RR">RR</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SP">SP</option>
                                                    <option value="SE">SE</option>
                                                    <option value="TO">TO</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.city')}}
                                                </label>
                                                <input v-model="data.address.city" type="text" class="form-control" :placeholder="$t('string.msg.company.label.city')" />
                                            </div>
                                            <div class="form-group col-sm-4">
                                                <label>
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.street')}}
                                                </label>
                                                <input v-model="data.address.address" type="text" class="form-control" :placeholder="$t('string.msg.company.label.street')" />
                                            </div>
                                        </div>
                                        <div v-if="data.address.cep && data.address.cep.length == 9" class="form-row">

                                            <div class="form-group col-md-4">
                                                <label>
                                                <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.number')}}
                                                </label>
                                                <input v-model="data.address.number" type="text" class="form-control" :placeholder="$t('string.msg.company.label.number')" />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.neighborhood')}}
                                                </label>
                                            <input v-model="data.address.neighborhood" type="text" class="form-control" :placeholder="$t('string.msg.company.label.neighborhood')" />
                                            </div>
                                            <div class="form-group col-md-4">
                                                    <label>
                                                    {{$t('string.msg.company.label.complement')}}
                                                </label>
                                                <input v-model="data.address.complement" type="text" class="form-control" :placeholder="$t('string.msg.company.label.complement')" />
                                            </div>
                                        </div>
                                        
                                        <!-- USUARIO REPRESENTANTE -->
                                        <label for="inputAddress" class="mt-2 col-form-label">
                                            {{$t('string.msg.company.representative.user')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.name')}}
                                                </label>
                                                <input type="text" v-model="data.representative.name" class="form-control" :placeholder="$t('string.msg.user.label.name')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.email')}}
                                                </label>
                                                <input type="text" v-model="data.representative.email" class="form-control" :placeholder="$t('string.msg.user.label.email')">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.company.user.cpf')}}
                                                </label>
                                                <input type="text" v-model="data.representative.cpf"   v-mask="['###.###.###-##']" class="form-control" :placeholder="$t('str.company.user.cpf')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                                </label>
                                                <input type="text" v-model="data.representative.primaryPhone"  :placeholder='$t("string.msg.company.placeholder.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']" class="form-control">
                                            </div>
                                        </div>

                                         <!-- USUARIO TESTEMUNHA -->
                                        <label for="inputAddress" class="mt-2 col-form-label">
                                            {{$t('string.msg.company.witness.user')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.name')}}
                                                </label>
                                                <input type="text" v-model="data.witness.name" class="form-control" :placeholder="$t('string.msg.user.label.name')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.email')}}
                                                </label>
                                                <input type="text" v-model="data.witness.email" class="form-control" :placeholder="$t('string.msg.user.label.email')">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.company.user.cpf')}}
                                                </label>
                                                <input type="text" v-model="data.witness.cpf"   v-mask="['###.###.###-##']" class="form-control" :placeholder="$t('str.company.user.cpf')">
                                            </div>
                                        </div>

                                         <!-- USUARIO TESTEMUNHA -->
                                        <label for="inputAddress" class="mt-2 col-form-label">
                                            {{$t('string.msg.company.financial.user')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.name')}}
                                                </label>
                                                <input type="text" v-model="data.financialContact.name" class="form-control" :placeholder="$t('string.msg.user.label.name')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.user.label.email')}}
                                                </label>
                                                <input type="text" v-model="data.financialContact.email" class="form-control" :placeholder="$t('string.msg.user.label.email')">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                                </label>
                                                <input type="text" v-model="data.financialContact.primaryPhone"  :placeholder='$t("string.msg.company.placeholder.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']" class="form-control">
                                            </div>
                                        </div>

                                        <div class="form-row text-center mt-2">
                                            <div class="form-group col-md-6">
                                                <button @click="clearForm" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PLANOS -->
                                    <div class="tab-pane" id="plans">
                                        <h4 class="page-title"><strong>{{$t('string.msg.data.company.plans.label')}}</strong></h4>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.plans.label')}}
                                                </label>
                                                <select id="plans" v-model="data.plans[0]" class="form-control" @change="onChangePlans()">
                                                    <option :value="[]" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                    <option :value="item" v-bind:key="item" v-for="item in plans">{{ camelize(item.name) }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="table-responsive" v-if="data.plans && data.plans.length > 0">
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead>
                                                    <tr>
                                                        <th class="border-top-0">{{$t('string.msg.plans.label.name')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.plans.setup.price')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.plans.monthy.price')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-bind:key="item._id" v-for="item in data.plans" class="cursor-pointer">
                                                    <tr>
                                                        <td>
                                                            <span v-if="item.name">
                                                                {{camelize(item.name)}}
                                                            </span>
                                                        </td>
                                                        <td >
                                                            <span v-if="item.setupPrice">
                                                                {{'R$ ' + item.setupPrice.toFixed(2)}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.monthlyPrice">
                                                                {{'R$ ' + item.monthlyPrice.toFixed(2)}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="form-row mt-4">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.additional.resources.label')}}
                                                </label>
                                                <multiselect v-model="data.additionalResources" :options="AdditionalResources" placeholder="Selecione" 
                                                            @input="onChangePlans()"  deselectLabel="Pressione para remover" 
                                                            :close-on-select="true" selectLabel="Pressione para selecionar" 
                                                            selectedLabel="Selecionado" :multiple="true" track-by="name"  
                                                            :custom-label="customLabelAdditionalResources" :show-labels="false" >
                                                </multiselect>
                                            </div> 
                                        </div>

                                        <div class="table-responsive" v-if="data.additionalResources && data.additionalResources.length > 0">
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead>
                                                    <tr>
                                                        <th class="border-top-0">{{$t('string.msg.additionalResources.label.name')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.additionalResources.label.platform')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.additionalResources.label.features')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.additionalResources.label.price')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-bind:key="item._id" v-for="item in data.additionalResources" class="cursor-pointer">
                                                    <tr>
                                                        <td>
                                                            <span v-if="item.name">
                                                                {{camelize(item.name)}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.platform">
                                                                {{camelize(item.platform.name)}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.features">
                                                                {{item.features}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.price">
                                                                {{'R$ ' + item.price.toFixed(2) }}
                                                            </span>
                                                        </td>
                                                    
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="form-row mt-4">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.additional.users.label')}}
                                                </label>
                                                <multiselect v-model="data.additionalUsers" :options="userTypes" placeholder="Selecione" 
                                                             @input="onChangePlans()" deselectLabel="Pressione para remover" 
                                                             :close-on-select="true" selectLabel="Pressione para selecionar"
                                                              selectedLabel="Selecionado" :multiple="true" track-by="name" 
                                                            :custom-label="customLabelUserTypes" :show-labels="false" >
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="form-row" v-if="data.additionalUsers && data.additionalUsers.length > 0">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-hover mb-0" id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th class="border-top-0">{{$t('string.msg.additional.label.name')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.additional.users.price')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-bind:key="item._id" v-for="item in data.additionalUsers" class="cursor-pointer">
                                                        <tr>
                                                            <td>
                                                                <span v-if="item.name">
                                                                    {{camelize(item.name)}}
                                                                </span>
                                                            </td>
                                                            <td v-if="item.price">
                                                                {{'R$ ' + item.price.toFixed(2) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="form-row mt-4">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.billing.data')}}
                                            </label>
                                        </div>

                                         <div v-if="otherPeriod == null || otherPeriod ==0" class="form-row  mt-4">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.contract.period')}}
                                                </label>
                                                <select id="periodContract" v-model="data.contractPeriod" class="form-control">
                                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                    <option :value="12">{{$t('str.msg.month.12')}}</option>
                                                    <option :value="24">{{$t('str.msg.month.24')}}</option>
                                                    <option :value="36">{{$t('str.msg.month.36')}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div v-if="data.contractPeriod != 12 && data.contractPeriod != 24 && data.contractPeriod != 36" class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.contract.period.other')}}
                                                </label>
                                                <input v-model="otherPeriod" type="text" class="form-control" :placeholder='$t("string.msg.company.contract.period")' v-mask="['##']"/>
                                            </div>
                                        </div>

                                        <!-- MENSALIDADE -->
                                        <div class="form-row mt-4">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.msg.menthly')}}
                                            </label>
                                        </div>
                                        
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.amount')}}
                                                </label>
                                                <input v-money="money" id="amount" disabled :value="data.amount" type="text" class="form-control" :placeholder="$t('string.msg.company.amount')" />
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.discount')}}
                                                </label>
                                                 <input v-money="money" id="discount" v-model="data.discount" type="text" class="form-control" :placeholder="$t('string.msg.company.discount')" />
                                            </div>
                                            <div class="form-group col-md-6 mt-4">
                                                 <button @click="discountAmount(data.discount)" class="text-dark-gray btn btn-warning w-20">{{$t('string.msg.discount.btn')}}</button>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.totalAmount')}}
                                                </label>
                                                 <input  v-money="money" id="totalAmount" disabled :value="data.totalAmount" type="text" class="form-control" :placeholder="$t('string.msg.company.totalAmount')" />
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.dueDate')}}
                                                </label>
                                                <select v-model="data.dueDate" class="form-control">
                                                    <option :value="null" selected> {{$t('string.msg.element.select.selectitem')}}</option>
                                                    <option :value="index + 1" v-bind:key="item" v-for="(item, index) in 31" > {{ index + 1 }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.paymentType')}}
                                            </label>
                                            <select id="paymentType" v-model="data.paymentType" class="form-control">
                                                <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                <option :value="item._id" v-bind:key="item" v-for="item in paymentTypes">{{$t(item._id)}}</option>
                                            </select>
                                        </div>

                                        <!-- SETUP -->
                                        <div class="form-row mt-4">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.msg.setup')}}
                                            </label>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.amount')}}
                                                </label>
                                                <input  v-money="money" id="amountSetup" disabled :value="data.amountSetup" type="text" class="form-control" :placeholder="$t('string.msg.company.amount')" />
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.discount')}}
                                                </label>
                                                 <input v-money="money" id="discountSetup" v-model="data.discountSetup" type="text" class="form-control" :placeholder="$t('string.msg.company.discount')" />
                                            </div>
                                            <div class="form-group col-md-6 mt-4">
                                                 <button @click="discountAmountSetup(data.discountSetup)" class="text-dark-gray btn btn-warning w-20">{{$t('string.msg.discount.btn')}}</button>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.totalAmount')}}
                                                </label>
                                                 <input  v-money="money" id="totalAmountSetup" disabled :value="data.totalAmountSetup" type="text" class="form-control" :placeholder="$t('string.msg.company.totalAmount')" />
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.dueDate')}}
                                                </label>
                                                 <select v-model="data.dueDateSetup" class="form-control">
                                                    <option :value="null" selected> {{$t('string.msg.element.select.selectitem')}}</option>
                                                    <option :value="index + 1" v-bind:key="item" v-for="(item, index) in 31" > {{ index + 1 }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.paymentType')}}
                                            </label>
                                            <select id="paymentTypeSetup" v-model="data.paymentTypeSetup" class="form-control">
                                                <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                <option :value="item._id" v-bind:key="item" v-for="item in paymentTypes">{{$t(item._id)}}</option>
                                            </select>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.input.value')}}
                                                </label>
                                                <input  v-money="money" id="totalAmountSetup" :value="data.inputPriceSetup" type="text" class="form-control" :placeholder="$t('string.msg.company.totalAmount')" />
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.installments')}}
                                            </label>
                                            <select id="numberInstallments" v-model="data.numberInstallments" class="form-control" @change="installmentsOfSetup(data.numberInstallments)">
                                                <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                <option :value="1">{{$t('string.msg.installments.1')}}</option>
                                                <option :value="2">{{$t('string.msg.installments.2')}}</option>
                                                <option :value="3">{{$t('string.msg.installments.3')}}</option>
                                                <option :value="4">{{$t('string.msg.installments.4')}}</option>
                                                <option :value="5">{{$t('string.msg.installments.5')}}</option>
                                                <option :value="6">{{$t('string.msg.installments.6')}}</option>
                                                <option :value="7">{{$t('string.msg.installments.7')}}</option>
                                                <option :value="8">{{$t('string.msg.installments.8')}}</option>
                                                <option :value="9">{{$t('string.msg.installments.9')}}</option>
                                                <option :value="10">{{$t('string.msg.installments.10')}}</option>
                                                <option :value="11">{{$t('string.msg.installments.11')}}</option>
                                                <option :value="12">{{$t('string.msg.installments.12')}}</option>
                                            </select>
                                        </div>

                                        <!-- TABELA DAS PARCELAS DO SETUP -->
                                        <div class="form-row" v-if="data.numberInstallments && data.numberInstallments > 1">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-hover mb-0" id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th class="border-top-0">{{$t('string.msg.installments.table')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.additional.users.price')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-bind:key="item" v-for="(item, index) in data.valueInstallments" class="cursor-pointer">
                                                        <tr>
                                                            <td>
                                                                <span v-if="item.number">
                                                                    {{ item.number + 'º' }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.value">
                                                                    <input v-bind:id="index" v-money="money" disabled :value="item.value"/>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>  
                

                                        <div class="form-row text-center mt-2">
                                            <div class="form-group col-md-12">
                                                <button @click="checkForm" class="btn btn-danger w-30">{{$t('string.msg.form.btn.save')}}</button>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- PATROCINADORES -->
                                    <div class="tab-pane" id="sponsors">
                                        <h4 class="page-title"><strong>{{$t('string.msg.list.sponsors.label')}}</strong></h4>
                                        <form class="mt-2">
                                            <div v-if="sponsor._id" class="form-group col-md-12">
                                                <h6 class="text-right">   
                                                    <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                                    <span v-if="sponsor.status == 'ACTIVE'" style="color: green">
                                                        {{$t(sponsor.status)}} 
                                                    </span>
                                                    <span v-if="sponsor.status == 'ARCHIVED'" style="color: blue">
                                                        {{$t(sponsor.status)}} 
                                                    </span>
                                                </h6>
                                            </div>
                                            <div v-if="sponsor._id && sponsor.status != 'ARCHIVED' && isAdminMaster()" class="form-row">
                                                <div class="form-group col-md-2">
                                                    <button v-on:click="confirmArchiveSponsor" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.fantasyName')}}
                                                    </label>
                                                    <input type="text" v-model="sponsor.fantasyName" class="form-control" :placeholder="$t('string.msg.company.placeholder.fantasyName')">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        {{$t('string.msg.company.label.socialName')}}
                                                    </label>
                                                    <input type="text" v-model="sponsor.socialName" class="form-control" :placeholder="$t('string.msg.company.placeholder.socialName')">
                                                </div>
                                    
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        {{$t('string.msg.company.label.document')}}
                                                    </label>
                                                    <input type="tel" v-mask="['###.###.###-##', '##.###.###/####-##']"   v-model="sponsor.document" class="form-control" :placeholder="$t('string.msg.company.placeholder.document')">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        <code v-if="!sponsor._id" class="highlighter-rouge">*</code>
                                                        {{$t('str.msg.primaryPhone')}}
                                                    </label>
                                                    <input v-model="sponsor.primaryPhone" type="text" class="form-control" :placeholder='$t("str.msg.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                                </div>
                                            </div>  

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.responsible')}}
                                                    </label>
                                                    <input type="text" v-model="sponsor.responsible" class="form-control" :placeholder="$t('string.msg.company.label.responsible')">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress" class="col-form-label">
                                                        <code v-if="!sponsor._id" class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                                    </label>
                                                    <input v-model="sponsor.secundaryPhone" type="text" class="form-control" :placeholder='$t("string.msg.company.label.primaryphone.whatsapp")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                                </div>
                                            </div>  

                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.address')}}
                                            </label>
                                            <div class="form-row">
                                                <div class="form-group col-md-2">
                                                    <label>
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.cep')}}
                                                    </label>
                                                    <input @blur="loadInfosByCEPSponsor" v-mask="'#####-###'" v-model="sponsor.address.cep" type="tel" class="form-control" :placeholder="$t('string.msg.company.label.cep')" />
                                                </div>
                                            </div>
                                            <div v-if="sponsor.address.cep && sponsor.address.cep.length == 9" class="form-row ">
                                                <div class="form-group col-md-4">
                                                    <label>
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.state')}}    
                                                    </label>
                                                    <select v-model="sponsor.address.state" class="form-control select2">
                                                        <option :value="-1" selected>{{$t('string.msg.element.select.selectitem')}}</option>
                                                        <option value="AC">AC</option>
                                                        <option value="AL">AL</option>
                                                        <option value="AP">AP</option>
                                                        <option value="AM">AM</option>
                                                        <option value="BA">BA</option>
                                                        <option value="CE">CE</option>
                                                        <option value="DF">DF</option>
                                                        <option value="ES">ES</option>
                                                        <option value="GO">GO</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MT">MT</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MG">MG</option>
                                                        <option value="PA">PA</option> 
                                                        <option value="PB">PB</option>
                                                        <option value="PR">PR</option>
                                                        <option value="PE">PE</option>
                                                        <option value="PI">PI</option>
                                                        <option value="RJ">RJ</option>
                                                        <option value="RN">RN</option>
                                                        <option value="RS">RS</option>
                                                        <option value="RO">RO</option>
                                                        <option value="RR">RR</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SP">SP</option>
                                                        <option value="SE">SE</option>
                                                        <option value="TO">TO</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label>
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.city')}}
                                                    </label>
                                                    <input v-model="sponsor.address.city" type="text" class="form-control" :placeholder="$t('string.msg.company.label.city')" />
                                                </div>
                                                <div class="form-group col-sm-4">
                                                    <label>
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.street')}}
                                                    </label>
                                                    <input v-model="sponsor.address.address" type="text" class="form-control" :placeholder="$t('string.msg.company.label.street')" />
                                                </div>
                                            </div>
                                            <div v-if="sponsor.address.cep && sponsor.address.cep.length == 9" class="form-row">

                                                <div class="form-group col-md-4">
                                                    <label>
                                                    <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.number')}}
                                                    </label>
                                                    <input v-model="sponsor.address.number" type="text" class="form-control" :placeholder="$t('string.msg.company.label.number')" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label>
                                                        <code class="highlighter-rouge">*</code>
                                                        {{$t('string.msg.company.label.neighborhood')}}
                                                    </label>
                                                <input v-model="sponsor.address.neighborhood" type="text" class="form-control" :placeholder="$t('string.msg.company.label.neighborhood')" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                        <label>
                                                        {{$t('string.msg.company.label.complement')}}
                                                    </label>
                                                    <input v-model="sponsor.address.complement" type="text" class="form-control" :placeholder="$t('string.msg.company.label.complement')" />
                                                </div>
                                            </div>


                                            <div class="form-row text-center mt-2">
                                                <div class="form-group col-md-6">
                                                    <button @click="clearSponsor" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <button @click="checkFormSponsor" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                                </div>
                                            </div>

                                            <div class="table-responsive mt-4" v-if="sponsors && sponsors.length > 0">
                                                <p class="sub-header">
                                                    {{$t('string.msg.list.sponsors')}}
                                                </p>
                                                <table class="table table-centered table-hover mb-0"> 
                                                    <thead class="thead-dark" >
                                                        <tr>
                                                            <th scope="col">{{$t('string.msg.table.sponsor.name')}}</th>
                                                            <th scope="col">{{$t('string.msg.table.sponsor.primaryPhone')}}</th>
                                                            <th scope="col">{{$t('string.msg.table.sponsor.responsible')}}</th>
                                                            <th scope="col">{{$t('string.msg.table.sponsor.secundaryPhone')}}</th>
                                                            <th scope="col">{{$t('string.msg.table.sponsor.status')}}</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody v-bind:key="item._id" v-for="item in sponsors" class="cursor-pointer">
                                                            <tr v-if="item.status == 'ACTIVE'" @click="selectItemSponsor(item)">
                                                                <td>
                                                                    <span v-if="item.fantasyName">
                                                                        {{camelize(item.fantasyName)}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="item.primaryPhone">
                                                                        {{item.primaryPhone}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="item.responsible">
                                                                        {{camelize(item.responsible)}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="item.secundaryPhone">
                                                                        {{item.secundaryPhone}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span v-if="item.status">
                                                                        {{ $t(item.status) }}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                    
                                        </form>
                                    </div>

                                    <!-- PLATAFORMAS --->
                                    <div class="tab-pane" id="platforms">
                                        <h4 class="page-title"><strong>{{$t('string.msg.list.techinical.data.label')}}</strong></h4>
                                        <!-- IDENTIDADE VISUAL -->
                                        <div class="form-row mt-2">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.visual.identity.data')}}
                                            </label>
                                        </div>
                                         <div class="form-row">
                                            <div  class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.brand/coat')}}
                                                </label>
                                                <input type="file" id="logo" ref="file" v-on:change="handleFileUpload()" class="form-control">
                                            </div> 
                                            <div  class="form-group col-md-6 text-center">
                                                <img v-if="addInfo.logoURL" v-bind:src="`${domain}${addInfo.logoURL}`" alt="image" height="120" width="150">
                                            </div>

                                            <form action="#">
                                                <div class="form-group mb-0">
                                                    <label for="inputAddress" class="col-form-label">
                                                        {{$t('str.primary.color')}}
                                                     </label>
                                                    <input v-model="addInfo.colorPaletteOne" type="text" class="form-control" @input="changePaletteOne(addInfo.colorPaletteOne)"  v-mask="['XXXXXX']"/>
                                                    <div ref="box1" class="box"></div>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label for="inputAddress" class="col-form-label">
                                                        {{$t('str.secondary.color')}}
                                                     </label>
                                                    <input v-model="addInfo.colorPaletteTwo" type="text" class="form-control" @input="changePaletteTwo(addInfo.colorPaletteTwo)" v-mask="['XXXXXX']"/>
                                                    <div ref="box2" class="box"></div>
                                                </div>
                                                
                                                <div class="form-group mb-4">
                                                    <label for="inputAddress" class="col-form-label">
                                                        {{$t('str.terciaria.color')}}
                                                     </label>
                                                    <input v-model="addInfo.colorPaletteThree" type="text" class="form-control" @input="changePaletteThree(addInfo.colorPaletteThree)" v-mask="['XXXXXX']"/>
                                                    <div ref="box3" class="box"></div>
                                                </div>
                                            </form>
                                        </div>
                                         <!-- REDES SOCIAIS -->
                                        <div class="form-row mt-2">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.msg.company.social.media')}}
                                            </label>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.instagram')}}
                                                </label>
                                                <input type="text" v-model="addInfo.instagramURL" class="form-control" :placeholder="$t('string.msg.company.label.instagram.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.label.facebook')}}
                                                </label>
                                                <input type="text" v-model="addInfo.facebookURL" class="form-control" :placeholder="$t('string.msg.company.label.facebook.placeholder')">
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.twitter')}}
                                                </label>
                                                <input type="text" v-model="addInfo.twitterURL" class="form-control" :placeholder="$t('string.msg.company.label.twitter.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.youtube')}}
                                                </label>
                                                <input type="text" v-model="addInfo.youtubeURL" class="form-control" :placeholder="$t('string.msg.company.label.youtube.placeholder')">
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.googlebusiness')}}
                                                </label>
                                                <input type="text" v-model="addInfo.googleBusinessURL" class="form-control" :placeholder="$t('string.msg.company.label.googlebusiness.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.whatsapp')}}
                                                </label>
                                                <input v-model="addInfo.whatsApp" type="text" class="form-control" :placeholder='$t("string.msg.company.label.whatsapp.placeholder")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.label.telegram')}}
                                                </label>
                                                <input v-model="addInfo.telegram" type="text" class="form-control" :placeholder='$t("string.msg.company.label.telegram.placeholder")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                            </div>
                                        </div>


                                        
                                        <!--INTEGRAÇÕES EXTERNA-->
                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('string.msg.company.external.integration.data')}}
                                        </label>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.facebookAds')}}
                                                </label>
                                                <input type="text" v-model="addInfo.facebookAds.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.facebookAds.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.googleAds')}}
                                                </label>
                                                <input type="text" v-model="addInfo.googleAds.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.googleAds.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.googleAnalytics')}}
                                                </label>
                                                <input type="text" v-model="addInfo.googleAnalytics.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.googleAnalytics.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.RDStation')}}
                                                </label>
                                                <input type="text" v-model="addInfo.RDStation.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.RDStation.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.streamingRadio')}}
                                                </label>
                                                <input type="text" v-model="addInfo.streamingRadio.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.streamingRadio.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.crud.podcast')}}
                                                </label>
                                                <input type="text" v-model="addInfo.podcast.user" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                                <input type="text" v-model="addInfo.podcast.password" class="form-control" :placeholder="$t('string.msg.company.label.password.placeholder')">
                                            </div>
                                        </div>
                                        <!-- DADOS PARA O SITE -->
                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('string.msg.company.site.data')}}
                                        </label>

                                        <div class="form-row mt-0">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.template.label')}}
                                                </label>
                                                <multiselect v-model="addInfo.templateSite" :options="templatesSite" placeholder="Selecione" 
                                                           deselectLabel="Pressione para remover" :close-on-select="true" selectLabel="Pressione para selecionar" 
                                                            selectedLabel="Selecionado"  track-by="_id" :custom-label="customLabelTemplates" :show-labels="false" >
                                                </multiselect>
                                                 <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.config.adjustment.template.site')}}
                                                </label>
                                                <textarea style="height:120px;" type="text" v-model="addInfo.obsTemplateSite" class="form-control"></textarea>
                                            </div> 
                                        </div>


                                        <div class="form-row mt-4">
                                    
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.url.domain.label')}}
                                                </label>
                                                <input type="text" v-model="domainData.url" class="form-control" :placeholder="$t('str.msg.url.domain.label')"/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.register.local.label')}}
                                                </label>
                                                <input type="text" v-model="domainData.registerLocal" class="form-control" :placeholder="$t('str.msg.register.local.label')"/>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                    
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.user.label')}}
                                                </label>
                                                <input type="text" v-model="domainData.user" class="form-control" :placeholder="$t('str.msg.user.label')"/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.password.label')}}
                                                </label>
                                                <input type="text" v-model="domainData.password" class="form-control" :placeholder="$t('str.msg.password.label')"/>
                                            </div>
                                        </div>
                                        


                                        <div class="form-group col-md-12 text-center">
                                            <button @click="addDomain(domainData)" class="text-dark-gray btn btn-warning w-30 mt-2">{{$t('string.msg.add.item.btn')}}</button>
                                        </div>

                                        <div v-if="addInfo.domains" class="table-responsive">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.list.domain.label.title')}}
                                            </label>
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th class="border-top-0">{{$t('str.msg.url.domain.label')}}</th>
                                                        <th class="border-top-0">{{$t('str.msg.register.local.label')}}</th>
                                                        <th class="border-top-0">{{$t('str.msg.user.label')}}</th>
                                                        <th class="border-top-0">{{$t('str.msg.password.label')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.domain.table.label.remove')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-bind:key="item" v-for="(item, index) in addInfo.domains" class="cursor-pointer">
                                                    <tr v-if="item">
                                                        <td>
                                                            <span v-if="item.url">
                                                                {{item.url}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.registerLocal">
                                                                {{item.registerLocal }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.user">
                                                                {{item.user }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.password">
                                                                {{item.password }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <button @click="removeDomain(index)" class="text-dark-gray btn btn-warning w-3">{{$t('string.msg.x')}}</button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('str.msg.site.migration')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.current.host.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.siteMigration.hostURL" class="form-control" :placeholder="$t('str.msg.current.host.label')"/>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.user.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.siteMigration.user" class="form-control" :placeholder="$t('str.msg.user.label')"/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.password.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.siteMigration.password" class="form-control" :placeholder="$t('str.msg.password.label')"/>
                                            </div>
                                        </div>

                                        <!-- DADOS PARA O APP -->
                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('string.msg.company.app.data')}}
                                        </label>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.company.template.label')}}
                                                </label>
                                                <multiselect v-model="addInfo.templateApp" :options="templatesApp" placeholder="Selecione" 
                                                           deselectLabel="Pressione para remover" :close-on-select="true" selectLabel="Pressione para selecionar" 
                                                            selectedLabel="Selecionado"  track-by="_id" :custom-label="customLabelTemplates" :show-labels="false" >
                                                </multiselect>
                                                 <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.config.adjustment.template.site')}}
                                                </label>
                                                <textarea style="height:120px;" type="text" v-model="addInfo.obsTemplateApp" class="form-control"></textarea>
                                            </div> 
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.app.name.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.appName" class="form-control" :placeholder="$t('str.app.name.label')"/>
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.config.app.description.app')}}
                                                </label>
                                                <textarea style="height:120px;" type="text" v-model="addInfo.appDescription" class="form-control"></textarea>
                                            </div>
                                        </div>
                                        <label for="inputAddress" class="col-form-label mt-2">
                                            {{$t('str.msg.apple.store.access')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.user.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.appleStoreUser" class="form-control" :placeholder="$t('str.msg.user.label')"/>
                                            </div> 
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.password.label')}}
                                                </label>
                                                <input type="text" v-model="addInfo.appleStorePassword" class="form-control" :placeholder="$t('str.msg.password.label')"/>
                                            </div>
                                        </div>

                                        <!-- EMAIL MARKETING -->
                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('str.msg.email.marketing.label')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                   {{$t('string.msg.company.crud.email.marketing')}}
                                                </label>
                                                <input type="text" v-model="addInfo.emailMarketing" class="form-control mb-1" :placeholder="$t('str.email.access')">
                                            </div>
                                        </div>
                                                
 
                                         <!-- WEBMAIL -->
                                        <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('string.msg.company.webmail.data')}}
                                        </label>
                                    
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.email')}}
                                                </label>
                                                <input type="text" v-model="webmail" class="form-control mb-1" :placeholder="$t('string.msg.company.label.user.placeholder')">
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12 text-center">
                                            <button @click="addEmail(webmail)" class="text-dark-gray btn btn-warning w-30 mt-2">{{$t('string.msg.add.item.btn')}}</button>
                                        </div>

                                        <div v-if="addInfo.webmail" class="table-responsive">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.msg.account.emails')}}
                                            </label>
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th class="border-top-0">{{$t('string.msg.email')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.domain.table.label.remove')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-bind:key="item" v-for="(item, index) in addInfo.webmail" class="cursor-pointer">
                                                    <tr v-if="item">
                                                        <td>
                                                            <span>
                                                                {{item}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <button @click="removeEmail(index)" class="text-dark-gray btn btn-warning w-3">{{$t('string.msg.x')}}</button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                         <label for="inputAddress" class="col-form-label mt-4">
                                            {{$t('str.msg.webmail.migration')}}
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.current.host.label')}}
                                                </label>
                                                <input type="text" v-model="webmailMigration.currentHost" class="form-control" :placeholder="$t('str.msg.current.host.label')"/>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.user.label')}}
                                                </label>
                                                <input type="text" v-model="webmailMigration.currentEmail" class="form-control" :placeholder="$t('str.msg.user.label')"/>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('str.msg.password.label')}}
                                                </label>
                                                <input type="text" v-model="webmailMigration.currentPassword" class="form-control" :placeholder="$t('str.msg.password.label')"/>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group col-md-12 text-center">
                                            <button @click="addWebmailMigration(webmailMigration)" class="text-dark-gray btn btn-warning w-30 mt-2">{{$t('string.msg.add.item.btn')}}</button>
                                        </div>
                                        <div v-if="addInfo.webmailMigration" class="table-responsive">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.msg.account.to.migrate')}}
                                            </label>
                                            <table class="table table-centered table-hover mb-0" id="datatable">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th class="border-top-0">{{$t('str.msg.current.host.label')}}</th>
                                                        <th class="border-top-0">{{$t('str.msg.user.label')}}</th>
                                                        <th class="border-top-0">{{$t('str.msg.password.label')}}</th>
                                                        <th class="border-top-0">{{$t('string.msg.domain.table.label.remove')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-bind:key="item" v-for="(item, index) in addInfo.webmailMigration" class="cursor-pointer">
                                                    <tr v-if="item">
                                                        <td>
                                                            <span v-if="item.currentHost">
                                                                {{item.currentHost}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.currentEmail">
                                                                {{item.currentEmail }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.currentPassword">
                                                                {{item.currentPassword }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <button @click="removeWebmailMigration(index)" class="text-dark-gray btn btn-warning w-3">{{$t('string.msg.x')}}</button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                        <div class="form-row text-center mt-4 mb-2">
                                            <div class="form-group col-md-12">
                                                <button @click="saveAddInfo" class="btn btn-danger w-30">{{$t('string.msg.form.btn.save')}}</button>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <!-- USUARIOS ADICIONAIS -->
                                    <div class="tab-pane" id="additionalUsers">
                                        <h4 class="page-title"><strong>{{$t('string.msg.user.data.platforms.label')}}</strong></h4>

                                        <div v-if="newData._id" class="form-group col-md-12">
                                            <h6 class="text-right">   
                                                <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                                <span v-if="newData.status == 'ACTIVE'" style="color: green">
                                                    {{$t(newData.status)}} 
                                                </span>
                                                <span v-if="newData.status == 'ARCHIVED'" style="color: blue">
                                                    {{$t(newData.status)}} 
                                                </span>
                                            </h6>
                                        </div>
                                        <div v-if="newData._id && newData.status != 'ARCHIVED'" class="form-row">
                                            <div class="form-group col-md-2">
                                                <button v-on:click="confirmUserArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.list.user.label.type')}}
                                                </label>
                                                <select id="userType" v-model="newData.companyUser.userType" class="form-control">
                                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                    <option v-bind:key="item._id" v-for="item in userTypes" :value="item._id">{{camelize(item.name)}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-row" >
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                        <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.form.crud.user.label.name')}}
                                                </label>
                                                <input type="text" v-model="newData.firstName" :placeholder="$t('string.msg.form.crud.user.placeholder.name')" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                        <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.form.crud.user.label.lastname')}}
                                                </label>
                                                <input type="text" v-model="newData.lastName" :placeholder="$t('string.msg.form.crud.user.placeholder.lastname')" class="form-control">
                                            </div>
                                        </div>
                                        <div class="form-row" >
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.form.crud.user.label.email')}}
                                                </label>
                                                <input type="text" v-model="newData.email" :placeholder="$t('string.msg.form.crud.user.placeholder.email')" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                        <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.form.crud.user.label.password')}}
                                                </label>
                                                <input type="password" v-model="newData.password" :placeholder="$t('string.msg.form.crud.user.placeholder.password')" class="form-control">
                                            </div>
                                        </div> 

                                        <div class="form-row text-center mt-4">
                                            <div class="form-group col-md-6">
                                                <button @click="clearUserForm" class="btn btn-secondary w-30">{{$t('string.msg.form.btn.clear')}}</button>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <button @click="checkUserForm" class="text-dark-gray btn btn-warning w-30">{{$t('string.msg.save')}}</button>
                                            </div>
                                        </div>

                                        <h4 class="sub-header mt-4">
                                            {{$t('string.msg.list.user.register.credential.label')}}
                                        </h4>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.company.table.label.platform')}}
                                                </label>
                                                <select id="credentialType" v-model="credential.type" class="form-control">
                                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                    <option :value="item.whiteLabelSystem" v-bind:key="item" v-for="item in services">{{camelize(item.whiteLabelSystem ? item.whiteLabelSystem : item.name)}}</option>
                                                </select>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    {{$t('string.msg.platforms.label.accessURL')}}
                                                </label>
                                                <input type="text" v-model="credential.accessURL" class="form-control">
                                            </div>
                                        </div>                            

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.menu.users.credential.label')}}
                                                </label>
                                                <input type="text" v-model="credential.user" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputAddress" class="col-form-label">
                                                    <code class="highlighter-rouge">*</code>
                                                    {{$t('string.msg.platforms.label.pass')}}
                                                </label>
                                                <input type="text" v-model="credential.pass" class="form-control">
                                            </div>
                                        </div>


                                        <div class="form-row text-center mt-4">
                                            <!-- <div class="form-group col-md-6">
                                                <button @click="clearFormCredentials" class="btn btn-secondary w-30">{{$t('string.msg.form.btn.clear')}}</button>
                                            </div> -->
                                            <div class="form-group col-md-12 text-center">
                                                <button @click="checkFormCredential" class="text-dark-gray btn btn-warning w-30">
                                                {{ 
                                                    credential._id 
                                                    ? ('string.msg.add.credential.platform.btn.update') 
                                                    : $t('string.msg.add.credential.platform.btn.add')
                                                }}
                                                </button>
                                            </div>
                                        </div>

                                        <div v-if="newData._id && credentials && credentials.length > 0" class="mt-4">
                                            <h4 class="sub-header">
                                                {{$t('string.msg.list.platforms.user.label')}}
                                            </h4>
                                            <div class="table-responsive mt-2">
                                                <table class="table table-centered table-hover mb-0" id="datatable">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th class="border-top-0">{{$t('string.msg.table.list.credential.label.type')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.table.list.credential.label.url')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.table.list.user.label.user')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.table.list.user.label.password')}}</th>
                                                            <th class="border-top-0">{{$t('string.msg.table.list.user.label.status')}}</th>
                                                            <th v-if="isAdminMaster()" class="border-top-0"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-bind:key="item._id" v-for="(item, index) in credentials" class="cursor-pointer" @click="selectCredentialItem(item, index)">
                                                        <tr>
                                                            <td>
                                                                <span v-if="item.type">
                                                                    {{camelize($t(item.type))}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.accessURL">
                                                                    {{item.accessURL}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.user">
                                                                    {{item.user}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.pass">
                                                                    {{item.pass}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.status">
                                                                    {{camelize($t(item.status))}}
                                                                </span>
                                                            </td>
                                                            <td v-if="isAdminMaster()">
                                                                <span>
                                                                    <button @click="confirmRemoveCredential" class="btn btn-secondary w-30">{{$t('string.msg.table.list.user.platforms.label.delete')}}</button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    


                                        <div class="table-responsive mt-4" v-if="users && users.length > 0">
                                            <h4 class="sub-header">
                                                {{$t('string.msg.list.company.user.label.users')}}
                                            </h4>
                                            <table class="table table-centered table-hover mb-0"> 
                                                <thead class="thead-dark" >
                                                    <tr>
                                                        <th scope="col">{{$t('string.msg.menu.crud.user.table.label.name')}}</th>
                                                        <th scope="col">{{$t('string.msg.menu.crud.user.table.label.email')}}</th>
                                                        <th scope="col">{{$t('string.msg.user.user.type.label')}}</th>
                                                    </tr>
                                                </thead>
                                                    <tbody v-bind:key="item._id" v-for="(item, index) in users" class="cursor-pointer">
                                                        <tr v-if="item.status == 'ACTIVE'" @click="selectUserItem(index, item)">
                                                            <td>
                                                                <span v-if="item.firstName">
                                                                    {{ toUpperCase(item.firstName) + ' ' }}
                                                                </span>
                                                                <span v-if="item.lastName">
                                                                    {{ toUpperCase(item.lastName) }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.email">
                                                                    {{ item.email }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span v-if="item.companyUser && item.companyUser.userType && item.companyUser.userType.name">
                                                                    {{ camelize(item.companyUser.userType.name) }}
                                                                </span>
                                                            </td>
                                                        
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                            
                                    </div>
                            </div> <!-- end card-box--> 

                             <div v-if="errors.length" class="alert alert-danger">
                                <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                <ul>
                                    <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                            
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
    <ConfirmationCredential :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="removeCredential"/>
    <ConfirmationUser :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archiveUser"/>
    <ConfirmationSponsor :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archiveSponsor"/>
    <ModalAddress/>
</div>
</template>
<script>
import Controller from './CrtCompany';
import Spinner from '../../../components/layout/Spinner';
import ModalAddress from '../Modal/ModalAddress';
import Toast from '../../../components/layout/Toast';
import Confirmation from '../../../components/layout/Confirmation';
import ConfirmationCredential from '../Modal/ConfirmationCredential';
import ConfirmationUser from '../Modal/Confirmation';
import ConfirmationSponsor from '../Modal/ConfirmationSponsor';
import Endpoints from '../../../components/common/Endpoints';
import Vue from 'vue';
Vue.prototype.$eventHub = new Vue();
export default {
name: 'Companies',
components: {
    Spinner,
    Toast,
    ModalAddress,
    Confirmation,
    ConfirmationCredential,
    ConfirmationUser,
    ConfirmationSponsor
},
data() {
    return {        
    errors: [],
    addresses:[],
    services: [],
    plans: [],
    users:[],
    credentials: [],
    companyTypes: [],
    companyId: null,
    userTypes: [],
    sponsors: [],
    otherPeriod: null,
    installmentsSetup: 0,
    AdditionalResources: [],
    templatesSite: [],
    templatesApp: [],
    formCrud: null,
    items: null,
    indexUser: null,
    valuekey: 0,
    titleToast: null,
    messageToast: null,
    messageIdToast: null,
    file: null,
    setupPrice: null,
    indexCredential: null,
    domainURL: null,
    domain: Endpoints.domain,
    data: {
        status: "ACTIVE",
        fantasyName: null,
        socialName: null,
        email: null,
        primaryPhone: null,
        document: null,
        buyLogo: null,
        authWithoutLogo: null,
        buyDomain: false,
        haveApp: null,
        appName: null,
        appInAppleStore: false,
        passwordAccess: null,
        emailAccess: null,
        contractPeriod: null,
        address: {
            state: -1,
            cep: null,
            city: null,
            address: null,
            neighborhood: null,
            number: null
        },
        typeService: [],
        platforms: [],
        domains: [],
        plans: [],
        sponsors: [],
        additionalResources: [],
        additionalUsers: [],
        paymentType: null,
        dueDate: null,
        discount: 0,
        amount: 0,
        totalAmount: 0,
        paymentTypeSetup: null,
        dueDateSetup: null,
        discountSetup: 0,
        amountSetup: 0,
        totalAmountSetup: 0,
        inputPriceSetup: 0,
        numberInstallments: null,
        linkDomain: null,
        representative:{
            name: null,
            cpf: null,
            email: null,
            primaryPhone: null
        },
        witness:{
            name: null,
            cpf: null,
            email: null
        },
        financialContact:{
            name: null,
            email: null,
            primaryPhone: null
        },
        valueInstallments: []
    },
    valueInstallment: {
        value: null,
        number: null
    },
    credential: {
        name: "MAIN",
        type: null,
        user: null,
        pass: null,
        accessURL: null,
        systemUser: null,
        authorizationToken: null,
        company: null,
        status: 'ACTIVE'
    },
    types:[
        {_id:"website",name: "str.wordpress"},
        {_id:"app",name: "str.siberian"},
        {_id:"email-marketing",name: "str.nitronews"},
        {_id:"webmail",name: "str.skymail"},
        {_id:"crm",name: "str.meet.crm"},
        {_id:"payment", name: "str.payment"},
        {_id:"design-pro", name: "str.trakto"},
    ],
    paymentTypes:[
        {_id:"BANK-SPLIT"},
        {_id:"CREDIT-CARD"}
    ],
    credentialFilters:{
        systemUserId: null,
        status: 'ACTIVE'
    },
    userFilters:{
        company: null,
        status: 'ACTIVE',
        name: ''
    },
    filtersSponsor:{
        name: '',
        company: null,
        status: 'ACTIVE'
    },
    money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' ',
          precision: 2,
          masked: false
    },
    newData: {
        status: "ACTIVE",
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        isAcceptGeneralTerms: true,
        type: "USER-COMPANY",
        companyUser:{
            userType: null,
            company: null,
            status: "ACTIVE",
            photoURL: "https://",
            userPlatforms:[],
            subtype: "SIMPLE"
        }
    },
    addInfo: {
        status: "ACTIVE",
        company: null,
        instagramURL: "https://",
        facebookURL: "https://",
        googleBusinessURL: "https://",
        telegram: "",
        whatsApp: "",
        logoURL: null,
        youtubeURL: "https://",
        twitterURL: "https://",
        facebookAds:{
            user:null,
            password:null
        },
       googleAds:{
            user:null,
            password:null
        },
        googleAnalytics:{
            user:null,
            password:null
        },
        RDStation:{
            user:null,
            password:null
        },
        streamingRadio:{
            user:null,
            password:null
        },
        podcast:{
            user:null,
            password:null
        },
        colorPaletteOne: null,
        colorPaletteTwo: null,
        colorPaletteThree: null,
        templateSite: null,
        obsTemplateSite: null,
        templateApp: null,
        obsTemplateApp: null,
        appName: null,
        appDescription: null,
        appleStoreUser: null,
        appleStorePassword: null,
        buyLogo: null,
        authWithoutLogo: null,
        domains:[],
        siteMigration:{
            hostURL: null,
            user: null,
            password: null
        },
        webmailMigration:[],
        webmail: [],
        emailMarketing: null
    },
    webmailMigration: {            
        currentHost: null,
        currentEmail: null,
        currentPassword: null
    },
    webmail: null,
    domainData:{
        url: null,
        registerLocal: null,
        user: null,
        password: null
    },
    sponsor: {
        status: "ACTIVE",
        company: null,
        fantasyName: null,
        socialName: null,
        primaryPhone: null,
        secundaryPhone: null,
        document: null,
        responsible: null,
        address: {
            state: -1,
            cep: null,
            city: null,
            address: null,
            neighborhood: null,
            number: null,
            name:'MAIN'
        },
    },
    
  }
},
methods: Controller.methods,
created() {
    Controller.init(this);

},
mounted(){
}
}

</script>
<style>
.multiselect__option--highlight {
    background: #1DA1F2;
    outline: none;
    color: #fff;
}
.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #1DA1F2;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #1DA1F2;
}
.box{
    height: 30px; 
    weight: 30px;
}
</style>
