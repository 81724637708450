<script>
import Endpoints from '../../../components/common/Endpoints';
import Request from '../../../components/common/Request';
import Common from '../../../components/common/Common';
export default {
    init: (payload) => {

        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

        payload.getServices();
        payload.findAllTemplate();
    },
    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;

            let data = JSON.parse(JSON.stringify(this.data));
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('jsonData', JSON.stringify(data));

            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), formData, `${Endpoints.templates.formData}${this.data._id ? this.data._id : ''}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.valueKey += 1; 
                    this.$fire({
                        text: this.data._id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                            this.findAllTemplate();
                    });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.service}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        isAdminMaster: function(){
            let user = this.$session.get('user');
            if (user){
                return user.companyUser && user.companyUser.subtype == "ADMIN_MASTER";
            }
            return false;
        },
        checkForm: function (e) {
            this.errors = [];
            if (!this.data.name) {
                this.errors.push(this.$t('response.template.register.name.required'));
            }
            if (!this.data.platform) {
                this.errors.push(this.$t('response.template.register.platform.required'));
            }

            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.save();
            }
        },
        cancelAdd: function (){
            this.data = {
                    name: null,
                    platform: null,
                    photoURL: "https://",
                    linkURL: null,
                    status: "ACTIVE"
                }
        },
        archive: function(response){
            if (response==1){

                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.data, `${Endpoints.templates.template}${this.data._id ? this.data._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.findAllTemplate();
                        });
             
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
        },
        confirmArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.template.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationTemplate').modal('show');
        },
        findAllTemplate: function(){
            const state = this;
            Request.do(state, 'GET', Request.getDefaultHeader(state), null, `${Endpoints.templates.template}`,
            (templateResponse)=>{//SUCCESS CALLBACK
                state.templates = templateResponse.results;
            },
            (error)=>{//ERROR CALLBACK
            }); 
        },
        selectItem: function(item){

            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));
            this.data.platform = this.data.platform._id;

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
