var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "overlay" },
        [
          _c(
            "base-popup",
            { staticClass: "popup", attrs: { closable: false } },
            [
              _c("p", { staticClass: "warning" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("string.msg.platforms.label.no.extension")) +
                    "\n    "
                )
              ]),
              !!_vm.extensionDownloadUrl
                ? _c(
                    "a",
                    {
                      staticClass: "extension-link",
                      attrs: {
                        href: _vm.extensionDownloadUrl,
                        target: "_blank"
                      },
                      on: { click: _vm.close }
                    },
                    [
                      _c("button", { staticClass: "download-btn" }, [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("string.msg.extension.download.label")
                              ) +
                              "\n        "
                          )
                        ]),
                        _c("i", { staticClass: "fa fa-download" })
                      ])
                    ]
                  )
                : _c("p", [
                    _vm._v(
                      "\n      Seu navegador não é compatível com nossa plataforma. \n      Por favor, para ter acesso completo aos nossos serviços instale o \n      "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.google.com/chrome/",
                          target: "_blank"
                        },
                        on: { click: _vm.close }
                      },
                      [_vm._v("\n        Chrome\n      ")]
                    ),
                    _vm._v("\n        ou o\n      "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.mozilla.org/en-US/firefox/new/",
                          target: "_blank"
                        },
                        on: { click: _vm.close }
                      },
                      [_vm._v("\n        Firefox\n      ")]
                    ),
                    _vm._v(".\n    ")
                  ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }