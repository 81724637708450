<template>
  <router-link 
    v-if='active'
    :to='serviceLink'
    :type='key'
    class="cursor-pointer"
  >
    <base-side-bar-item
      class='service-list-item'
      :icon='serviceIcon'
      :label='serviceLabel'
      labelClass='service-list-item-label'
    />
  </router-link>
  <base-side-bar-item
    v-else
    class='service-disabled service-list-item'
    :icon='serviceIcon'
    :label='serviceLabel'
    labelClass='service-list-item-label'
  >
    <span class='upgrade-warning'>
      {{ $t('string.msg.platform.menu.upgrade.label') }}
    </span>
  </base-side-bar-item>
</template>

<script>
import BaseSideBarItem from './BaseSideBarItem'

export default {
  components: {
    BaseSideBarItem
  },
  props: {
    serviceLink: {
      type: String
    },
    serviceIcon: {
      type: String,
      required: true  
    },
    serviceLabel: {
      type: String,
      required: true  
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
