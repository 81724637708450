<script>
export default {
    camelize: (text) => {
        if (text){
            const words = text.split(" ")
            const result = []
            words.slice(0).forEach((word) => result.push(`${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`))
            return result.join(' ');
        }
        return '';
    },
    toUpperCase: (text) => {
        if (text){
            return text.toString().toUpperCase();
        }
        return '';
    },
    formatDecimal: (text) =>{
        return parseFloat(Math.round(text * 100) / 100).toFixed(2).toString().split(",").join("").split(".").join(",")
    },
    formatDate: (text) => {
        return new Date(text).toLocaleDateString()
    },
    checkPassword: (str) =>{
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        return re.test(str);
    },
    isValidEmail: (email) =>{   
        var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        if (reg.test(email)){
            return true; 
        }
        else{
            return false;
        }
    } 
}
</script>
