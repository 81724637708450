var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.menu.platforms.website.label.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.platforms.configuration.label.title")
                      )
                    )
                  ]),
                  _c("form", [
                    _c("div", [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("string.msg.menu.company.have.domain.label")
                          )
                        )
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "m-1" }, [
                        _c(
                          "div",
                          { staticClass: "radio radio-info form-check-inline" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.option1,
                                  expression: "option1"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                id: "inlineRadio1",
                                name: "radioInline",
                                checked: ""
                              },
                              domProps: {
                                value: _vm.option1,
                                checked: _vm._q(_vm.option1, _vm.option1)
                              },
                              on: {
                                click: _vm.setOption1,
                                change: function($event) {
                                  _vm.option1 = _vm.option1
                                }
                              }
                            }),
                            _c("label", { attrs: { for: "inlineRadio1" } }, [
                              _vm._v(_vm._s(_vm.$t("string.msg.yes")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-info form-check-inline" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.option2,
                                  expression: "option2"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                id: "inlineRadio2",
                                name: "radioInline"
                              },
                              domProps: {
                                value: _vm.option2,
                                checked: _vm._q(_vm.option2, _vm.option2)
                              },
                              on: {
                                click: _vm.setOption2,
                                change: function($event) {
                                  _vm.option2 = _vm.option2
                                }
                              }
                            }),
                            _c("label", { attrs: { for: "inlineRadio2" } }, [
                              _vm._v(_vm._s(_vm.$t("string.msg.no")))
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm.option1 == true
                      ? _c("div", { staticClass: "form-row mt-2" }, [
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.menu.company.what.domains.label"
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.domain,
                                  expression: "domain"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("str.url.site")
                              },
                              domProps: { value: _vm.domain },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.domain = $event.target.value
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "form-group col-md-2" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "text-dark-gray btn btn-warning w-3 mt-4",
                                on: {
                                  click: function($event) {
                                    return _vm.addDomain(_vm.domain)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("string.msg.add.item.btn"))
                                )
                              ]
                            )
                          ]),
                          _vm.company.domains
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "col-form-label",
                                    attrs: { for: "inputAddress" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.list.domain.label.title"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-centered table-hover mb-0",
                                    attrs: { id: "datatable" }
                                  },
                                  [
                                    _c("thead", { staticClass: "thead-dark" }, [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { staticClass: "border-top-0" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.domain.label.name"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "border-top-0" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.domain.table.label.remove"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm._l(_vm.company.domains, function(item) {
                                      return _c(
                                        "tbody",
                                        {
                                          key: item,
                                          staticClass: "cursor-pointer"
                                        },
                                        [
                                          item
                                            ? _c("tr", [
                                                _c("td", [
                                                  item.length > 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(item) +
                                                            "\n                                    "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]),
                                                _c("td", [
                                                  _c("span", [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "text-dark-gray btn btn-warning w-3",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeDomain(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "string.msg.x"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ])
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm.option2 == true
                      ? _c("div", { staticClass: "form-row mt-2" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("div", { staticClass: "mt-3 mb-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "custom-control custom-checkbox"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.company.buyDomain,
                                        expression: "company.buyDomain"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "customCheck1"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.company.buyDomain
                                      )
                                        ? _vm._i(_vm.company.buyDomain, null) >
                                          -1
                                        : _vm.company.buyDomain
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.company.buyDomain,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.company,
                                                "buyDomain",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.company,
                                                "buyDomain",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.company,
                                            "buyDomain",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customCheck1" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.menu.company.need.buy.domain"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.company.domains &&
                    _vm.company.domains.length > 0 &&
                    _vm.option2 == false
                      ? _c("div", { staticClass: "form-row mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label",
                              attrs: { for: "inputAddress" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("str.link.where.buy.domain")) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.company.linkDomain,
                                  expression: "company.linkDomain"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("str.url.link.domain")
                              },
                              domProps: { value: _vm.company.linkDomain },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.company,
                                    "linkDomain",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger mt-3" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "text-center mb-2 mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-30",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ]),
                  _vm._m(0)
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      }),
      _c("ModalCongrats")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "40px" } },
      [
        _c("span", { staticClass: "step step-on" }),
        _c("span", { staticClass: "step step-off" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }