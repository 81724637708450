<template>
  <div class="navbar-custom">
    <ul class="list-unstyled topnav-menu float-right mb-0">
      <li class="dropdown notification-list">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle nav-link">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobil  e menu toggle-->
      </li>

      <li class="dropdown notification-list show">
        <a
          class="
            nav-link
            dropdown-toggle
            nav-user
            mr-0
            waves-effect waves-light
          "
          href="https://parresia.bitrix24.com.br/"
          target="_blank"
          role="button"
          aria-haspopup="false"
          aria-expanded="true"
        >
          <i class="fas fa-tasks noti-icon"></i>
        </a>
        <div class="nav-tooltip">Projetos</div>
      </li>

      <li class="dropdown notification-list show">
        <a
          class="
            nav-link
            dropdown-toggle
            nav-user
            mr-0
            waves-effect waves-light
          "
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="true"
        >
          <i class="far fa-bell noti-icon"></i>
        </a>
        <div class="nav-tooltip">Notificações</div>

        <div
          class="dropdown-menu dropdown-menu-right profile-dropdown"
          x-placement="bottom-end"
          style="
            position: absolute;
            will-change: transform;
            top: 0px;
            left: 0px;
            transform: translate3d(-38px, 70px, 0px);
          "
        >
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item cursor-pointer mt-2"
          >
            Nenhuma notificação
          </a>
        </div>
      </li>

      <li
        v-bind:class="{
          hidden: isAdminMaster() == false && isAdminParresia() == false,
        }"
        class="dropdown notification-list show"
      >
        <a
          class="
            nav-link
            dropdown-toggle
            nav-user
            mr-0
            waves-effect waves-light
          "
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="true"
        >
          <i class="fe-settings noti-icon"></i>
        </a>
        <div class="nav-tooltip">Configurações</div>

        <ul
          class="dropdown-menu dropdown-menu-right profile-dropdown"
          x-placement="bottom-end"
          style="
            width: 200px;
            position: absolute;
            will-change: transform;
            top: 0px;
            left: 0px;
            transform: translate3d(-38px, 70px, 0px);
          "
        >
          <router-link
            to="/configuration-platform"
            class="cursor-pointer"
            style="color: gray"
          >
            <li href="javascript:void(0);" class="dropdown-item cursor-pointer">
              <i class="fe-settings"></i>
              <label class="cursor-pointer">
                {{
                  $t("string.msg.menu.configutation.geral.label.title")
                }}</label
              >
            </li>
          </router-link>

          <router-link
            to="/companies"
            class="cursor-pointer"
            style="color: gray"
          >
            <li href="javascript:void(0);" class="dropdown-item cursor-pointer">
              <i class="far fa-user"></i>
              <label class="cursor-pointer">
                {{ $t("string.msg.menu.clients.config.label.title") }}</label
              >
            </li>
          </router-link>

          <router-link to="/reports" class="cursor-pointer" style="color: gray">
            <li href="javascript:void(0);" class="dropdown-item cursor-pointer">
              <i class="far fa-file-alt"></i>
              <label class="cursor-pointer">
                {{ $t("string.msg.menu.report.label.title") }}</label
              >
            </li>
          </router-link>
        </ul>
      </li>

      <li class="dropdown notification-list show">
        <a
          class="
            nav-link
            dropdown-toggle
            nav-user
            mr-0
            waves-effect waves-light
          "
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="true"
        >
          <img
            v-if="user.companyUser && user.companyUser.photoURL != 'https://'"
            v-bind:src="`${domain}${user.companyUser.photoURL}`"
            alt="user-image"
            class="rounded-circle"
            height="35"
            width="35"
          />
          <i
            v-else
            class="far fa-user noti-icon rounded-circle"
            color="white"
            alt="user-image"
            height="35"
            width="35"
          ></i>
        </a>
        <div class="nav-tooltip">Conta</div>

        <ul
          class="dropdown-menu dropdown-menu-right profile-dropdown"
          x-placement="bottom-end"
          style="
            position: absolute;
            will-change: transform;
            top: 0px;
            left: 0px;
            transform: translate3d(-38px, 70px, 0px);
          "
        >
          <!-- item-->
          <router-link to="/perfil-user" style="color: gray">
            <li
              href="javascript:void(0);"
              class="dropdown-item cursor-pointer mt-2"
            >
              <i class="dripicons-user cursor-pointer"></i>
              <label class="cursor-pointer">
                {{ $t("string.msg.menu.user.perfil.label.title") }}</label
              >
            </li>
          </router-link>

          <router-link to="/update-company-info" style="color: gray">
            <li
              href="javascript:void(0);"
              class="dropdown-item notify-item cursor-pointer"
            >
              <i class="far fa-building"></i>
              <label class="cursor-pointer">
                {{
                  $t("string.msg.menu.company.registration.label.title")
                }}</label
              >
            </li>
          </router-link>

          <router-link to="/change-password" style="color: gray">
            <li
              href="javascript:void(0);"
              class="dropdown-item notify-item cursor-pointer"
            >
              <i class="fas fa-key cursor-pointer"></i>
              <label class="cursor-pointer">
                {{ $t("string.msg.menu.change.password.label.title") }}</label
              >
            </li>
          </router-link>

          <router-link to="/terms" style="color: gray">
            <li
              href="javascript:void(0);"
              class="dropdown-item notify-item cursor-pointer"
            >
              <i class="fas fa-file-signature cursor-pointer"></i>
              <label class="cursor-pointer">
                {{ $t("string.msg.menu.terms.label.title") }}</label
              >
            </li>
          </router-link>

          <div class="dropdown-divider"></div>

          <!-- item-->
          <router-link to="/logout" style="color: gray">
            <li href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="dripicons-power cursor-pointer"></i>
              <span>{{ $t("string.msg.menu.dashboard.label.exit") }}</span>
            </li>
          </router-link>
        </ul>
      </li>
    </ul>

    <ul class="list-unstyled menu-left mb-0">
      <li class="float-left">
        <a
          class="logo cursor-pointer"
          :class="{ 'sidebar-closed': sidebarClosed }"
        >
          <span class="logo-lg">
            <router-link to="/dashboard" class="cursor-pointer">
              <img
                src="assets/images/parresia_logo.png"
                alt="parresia"
                height="55px"
              />
            </router-link>
          </span>
          <span class="logo-sm">
            <router-link to="/dashboard" class="cursor-pointer">
              <img
                src="assets/images/parresia_logo.png"
                alt="parresia"
                height="30px"
              />
            </router-link>
          </span>
        </a>
      </li>
      <li class="float-left">
        <a 
            class="button-menu-mobile navbar-toggle" 
            :class="{ open: !sidebarClosed }"
            @click="toggleSidebar"
        >
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Controller from "./CrtHeader";
import Endpoints from "../../common/Endpoints";
import { mapActions, mapState } from "vuex";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      errors: [],
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      domain: Endpoints.domain,
      user: {
        firstName: "",
      },
    };
  },
  computed: mapState({
    sidebarClosed: state => !state.ui.sidebarOpened
  }),
  methods: {
    ...Controller.methods,
    ...mapActions(["toggleSidebar"]),
  },
  created() {},
  mounted() {
    Controller.init(this);
    this.loadUserInfos();
  },
};
</script>

<style scoped>
</style>