<template>
    <div id="wrapper" :key="valuekey">

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <h4 class="page-title">{{$t('string.msg.list.templates.label')}}</h4>
                        <form class="mt-4">
                            <div v-if="data._id" class="form-group col-md-12">
                                <h6 class="text-right">   
                                    <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                    <span v-if="data.status == 'ACTIVE'" style="color: green">
                                        {{$t(data.status)}} 
                                    </span>
                                    <span v-if="data.status == 'ARCHIVED'" style="color: blue">
                                        {{$t(data.status)}} 
                                    </span>
                                </h6>
                            </div>
                            <div v-if="data._id && data.status != 'ARCHIVED' && isAdminMaster()" class="form-row">
                                <div class="form-group col-md-2">
                                    <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                            <code v-if="!data._id" class="highlighter-rouge">*</code>
                                        {{$t('string.msg.form.crud.user.label.name')}}
                                    </label>
                                    <input type="text" v-model="data.name" :placeholder="$t('string.msg.form.crud.user.placeholder.name')" class="form-control">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.templates.label')}}
                                    </label>
                                    <select id="userType" v-model="data.platform" class="form-control">
                                        <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                         <option :value="item._id" v-bind:key="item._id" v-for="item in services">{{camelize(item.whiteLabelSystem ? item.whiteLabelSystem : item.name)}}</option>
                                    </select>
                                </div>
                            </div>  
                            <div class="form-row" >
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        {{$t('string.msg.form.template.placeholder.link')}}
                                    </label>
                                    <input type="text" v-model="data.linkURL" :placeholder="$t('string.msg.form.template.placeholder.link')" class="form-control">
                                </div>
                                <div  class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.template.label.image')}}
                                    </label>
                                    <input type="file" id="photo" ref="file" v-on:change="handleFileUpload()" class="form-control">
                                </div> 
                            </div> 
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.type.label')}}
                                    </label>
                                    <select id="userType" v-model="data.type" class="form-control">
                                        <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                        <option value="SITE" selected>{{$t('string.msg.website')}}</option>
                                        <option value="APP" selected>{{$t('string.msg.app')}}</option>
                                    </select>
                                </div>
                                <div v-if="data.photoURL && data.photoURL != 'https://'" class="form-group col-md-6 text-center">
                                    <img v-if="data.photoURL" v-bind:src="`${domain}${data.photoURL}`" alt="image" height="150" width="150">
                                </div>
                            </div>
                            <div class="form-row text-center mt-4">
                                <div class="form-group col-md-6">
                                    <button @click="cancelAdd" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                </div>
                                <div class="form-group col-md-6">
                                    <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                </div>
                            </div>
                            

                            <div class="table-responsive mt-4" v-if="templates && templates.length > 0">
                                <p class="sub-header">
                                    {{$t('string.msg.list.template')}}
                                </p>
                                <table class="table table-centered table-hover mb-0"> 
                                    <thead class="thead-dark" >
                                        <tr>
                                            <th scope="col">{{$t('string.msg.table.template.name')}}</th>
                                            <th scope="col">{{$t('string.msg.table.template.platform')}}</th>
                                            <th scope="col">{{$t('string.msg.table.template.linkURL')}}</th>
                                            <th scope="col">{{$t('string.msg.table.template.image')}}</th>
                                            <th scope="col">{{$t('string.msg.table.template.status')}}</th>
                                        </tr>
                                    </thead>
                                        <tbody v-bind:key="item._id" v-for="item in templates" class="cursor-pointer">
                                            <tr v-if="item.status == 'ACTIVE'" @click="selectItem(item)">
                                                <td>
                                                    <span v-if="item.name">
                                                        {{camelize(item.name)}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.platform && item.platform.name">
                                                        {{camelize(item.platform.name)}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.linkURL">
                                                        {{item.linkURL}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.photoURL && item.photoURL != 'https://'">
                                                        <img  v-bind:src="`${domain}${item.photoURL}`" alt="image" height="60" width="60">
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.status">
                                                        {{ $t(item.status) }}
                                                    </span>
                                                </td>
                                            
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            <div v-if="errors.length" class="alert alert-danger">
                                <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                <ul>
                                    <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
</div>
</template>

<script>
import Controller from './CrtTemplate';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from './Confirmation';
import Endpoints from '../../../components/common/Endpoints';
export default {
    name: 'Template',
    props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
    components: {
    Spinner,
    Toast,
    Confirmation
    },

    data() {
        return {
            errors: [],
            titleToast: null,
            messageToast: null,
            messageIdToast: null,
            formCrud: null,
            items: null,
            services:[],
            file: null,
            templates: [],
            domain: Endpoints.domain,
            data: {
                name: null,
                platform: null,
                photoURL: "https://",
                linkURL: null,
                status: "ACTIVE",
                type: null
            },
        }
    },
    methods: Controller.methods,
    created() {
    Controller.init(this);
    },
    mounted(){
    }
    }
    </script>
