<template>
    <div id="wrapper" >
      <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <h4 class="page-title">{{$t('string.msg.terms.label.title')}}</h4>

                            </div>
                        </div>
                    </div>

                     <div class="row">
                       
                       <div class="col-lg-12">
                        <div class="card-box">
                            <h4 class="header-title mb-4">
                                <h4 class="page-title">
                                    {{$t('string.msg.terms.system.label.title')}}
                                </h4>
                            </h4>
                     
                                <b-tabs>
                                    <b-tab active >
                                        <template v-slot:title>
                                            <strong>{{$t('string.msg.term.geral.label.title')}}</strong>
                                        </template>
                                        <ModalTermOfUse />
                                    </b-tab>

                                    <b-tab>
                                        <template v-slot:title>
                                            <strong>{{$t('string.msg.term.privacy.policy.label.title')}}</strong>
                                        </template>
                                        <ModalPrivacyPolicy   />
                                    </b-tab>
                                </b-tabs>
                        </div>
                        <!-- end card-box-->
                      </div>
                      <!-- end col -->
                     </div>
          
                </div>
              
            </div>
        </div>
        
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
      
    </div>
</template>

<script>

import Controller from './CrtTerms';
import ModalPrivacyPolicy from './Modal/ModalPrivacyPolicy';
import ModalTermOfUse from './Modal/ModalTermOfUse';

export default {
    name: 'Terms',
    components: {
    ModalPrivacyPolicy,
    ModalTermOfUse,
    },
    data() {
        return {
            errors: [],
            titleToast: null,
            messageToast: null,
            messageIdToast: null,
        }
    },
    methods: Controller.methods,
    created() {
    },
    mounted() {
        Controller.init(this);
    }
    }
    </script>

    <style>
    </style>
