var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalListAddress",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalListAddress",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title text-uppercase" }, [
                _vm._v(
                  _vm._s(_vm.$t("string.company.register.modal.address.title"))
                )
              ]),
              _vm._m(0)
            ]),
            _c(
              "div",
              { staticClass: "list-group" },
              _vm._l(_vm.addresses, function(item, index) {
                return _c(
                  "a",
                  {
                    key: item.id,
                    staticClass:
                      "list-group-item list-group-item-action cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$emit("select-address", index)
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(item.formatted))])]
                )
              }),
              0
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("img", { attrs: { src: "assets/img/icon-close-black.png" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }