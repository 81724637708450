<script>
let domain
switch (process.env.NODE_ENV) {
  case 'development':
    domain = "http://localhost:3000"
    break
  case 'staging':
    domain = 'https://staging-api-gateway-parresia.herokuapp.com'
    break
  case 'production':
  default:
    domain = 'https://api-gateway-parresia.herokuapp.com'
    break
}

const d4signToken = process.env.D4SIGN_TOKEN 
                  ? process.env.D4SIGN_TOKEN
                  : 'live_4fdae9365c48c4b99989cde71c9ea9e60b9c300e18b7729125df96ce7b08c642'
const d4signCryptKey = process.env.D4SIGN_CRYPT_KEY
                    ? process.env.D4SIGN_CRYPT_KEY
                    : 'live_crypt_TCgYgShDgJang3a6JkTiFImbppFNIiVV'

export default {
  domain: domain,
  addresses: {
    address: `${domain}/api/address/v1/`,
    geolocation: `${domain}/api/address/geo/v1/`,
  },
  systemUsers: {
    reject: `${domain}/api/users/system/status/reject/v1/`,
    delete: `${domain}/api/users/delete/v1/`,
    systemUser: `${domain}/api/users/v1/`,
    systemUserFormdata: `${domain}/api/users/formdata/v1/`,
    login: `${domain}/api/users/system/login/v1/`,
    refreshToken: `${domain}/api/users/system/refresh-token/v1`,
    gencode: `${domain}/api/users/system/password/gencode/v1/`,
    checkCode: `${domain}/api/users/system/password/checkcode/v1/`,
    resetPass: `${domain}/api/users/system/password/change/online/v1/`,
    resetPassNotLog: `${domain}/api/users/system/password/change/v1/`,
    getMe: `${domain}/api/users/me/v1/`,
    ListUserByType: `${domain}/api/users/bytype/v1/`,
    filter: `${domain}/api/users/system/search/customeruser/v1/`,
    companyUser: `${domain}/api/users/system/search/companyuser/v1/`,
    updateTerms: `${domain}/api/users/system/update/v1/`,
    count: `${domain}/api/generic/count/byfilter/v1/`,
    approveDocument: `${domain}/api/users/system/status/aprove/v1/`,
    waitingForApproval: `${domain}/api/users/system/search/customeruser/waiting/approval/v1`,
    typeUser: `${domain}/api/users/types/v1/`,
  },
  templates: {
    formData: `${domain}/api/company/templates/formdata/v1/`,
    template: `${domain}/api/company/templates/v1/`,
    filter: `${domain}/api/company/templates/filter/v1/`,
  },
  sponsors: {
    filter: `${domain}/api/company/sponsors/filter/v1/`,
    sponsor:  `${domain}/api/company/sponsors/v1/`
  },
  services: {
    active: `${domain}/api/company/services/active/v1/`,
    service: `${domain}/api/company/services/v1/`,
    createWithImage: `${domain}/api/company/services/formdata/v1/`,
  },
  plans: {
    plan: `${domain}/api/company/plans/v1/`,
  },
  companies: {
    addInfo: `${domain}/api/company/addinfos/v1/`,
    addInfoFormData: `${domain}/api/company/addinfos/formdata/v1/`,
    getAddInfo: `${domain}/api/company/addinfos/bycompany/v1/`,
    company: `${domain}/api/company/v1/`,
    companyWithLogo: `${domain}/api/company/formdata/v1/`,
    listActivies: `${domain}/api/company/list/v1/`,
    listByType: `${domain}/api/company/list/bytype/v1/`,
    companyUser: `${domain}/api/users/system/search/companyuser/v1/`,
    filter: `${domain}/api/company/filter/v1/`,
    addResource: `${domain}/api/company/addresouces/formdata/v1/`,
    listResources: `${domain}/api/company/addresouces/v1/`,
    listContractTemplates: `${domain}/api/company/contracts/templates/v1`,
    generateContract: `${domain}/api/company/contracts/v1`
  },
  credencials: {
    filter: `${domain}/api/credencials/filter/v1/`,
    credencial: `${domain}/api/credencials/v1/`,
    delete: `${domain}/api/credencials/delete/v1/`,
    listFromUser: `${domain}/api/credencials/listfromuser/v1`,
    credencialList: `${domain}/api/credencials/listfromuser/v1?type={type}`,
    authSiberian: `${domain}/api/credencials/authsiberian/v1`,
    authPayment: `${domain}/api/credencials/authpayment/v1`,
    authTrakto: `${domain}/api/credencials/authtrakto/v1`,
  },
  cep: {
    find: `https://viacep.com.br/ws/`,
  },
  helpers: {
    companyType: `${domain}/api/helpers/data/company/types/v1/`,
  },
  location: {
    geolocation: `https://maps.googleapis.com/maps/api/geocode/json?latlng=`,
  },
  contracts: {
    templates: `https://secure.d4sign.com.br/api/v1/templates?tokenAPI=${d4signToken}&cryptKey=${d4signCryptKey}`,
    documentbytemplate: `https://secure.d4sign.com.br/api/v1/documents/ab942d25-88a2-456f-8111-e5fd219eff67/?tokenAPI=${d4signToken}&cryptKey=${d4signCryptKey}`
  },
  xframe: {
    firefox: process.env.VUE_APP_XFRAME_FIREFOX_URL,
    chrome: process.env.VUE_APP_XFRAME_CHROME_URL
  }
};
</script>
