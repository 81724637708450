<template>
  <div 
    class="left-side-menu"
    :class="{ closed }"
  >
    <div class="slimscroll-menu">
      <div id="sidebar-menu" style="height: 600px; position: relative; display: block;" >
        <ul 
          id="side-menu"
          class="metismenu" 
        >

          <li class="menu-title" >
            {{ $t("string.msg.platforms.label.title") }}
          </li>
          
          <service-side-bar-item
            v-for='credential in credentialItems'
            :key='credential.type'
            :service-icon='credential.i'
            :service-label='credential.label'
            :service-link='credential.route'
            :active='credential.active'
          />

          <br><br>

          <router-link 
            to="/logout" 
            class="cursor-pointer"
          >
            <li>
              <i class="dripicons-power cursor-pointer"></i>
              <span>{{ $t("string.msg.menu.dashboard.label.exit") }}</span>
            </li>
          </router-link>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Controller from "./CrtLeftSideBar";
import ServiceSideBarItem from '../../ServiceSideBarItem'

export default {
  name: "LeftSideBar",
  components: {
    ServiceSideBarItem
  },
  data() {
    return {
      errors: [],
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      credentialItems: [
        {
          type: 'website',
          route: '/platforms-site/website',
          i: 'fas fa-globe',
          label: 'string.msg.menu.company.platforms.wordpress.label.title',
          active: false
        },
        {
          type: 'app',
          route: '/platforms-site/app',
          i: 'fas fa-mobile-alt',
          label: 'string.msg.menu.company.platforms.siberian.label.title',
          active: false
        },
        {
          type: 'email-marketing',
          route: '/platforms-site/email-marketing',
          i: 'far fa-paper-plane',
          label: 'string.msg.menu.company.platforms.nitronews.label.title',
          active: false
        },
        {
          type: 'crm',
          route: '/platforms-site/crm',
          i: 'far fa-handshake',
          label: 'string.msg.menu.company.platforms.meet.label.title',
          active: false
        },
        {
          type: 'payment',
          route: '/platforms-site/payment',
          i: 'fas fa-money-check-alt',
          label: 'string.msg.menu.company.platforms.payment.label.title',
          active: false
        },
        {
          type: 'design-pro',
          route: '/platforms-site/design-pro',
          i: 'fas fa-paint-brush',
          label: 'string.msg.menu.company.platforms.trakto.label.title',
          active: false
        }
      ]
    };
  },
  computed: mapState({
    closed: state => !state.ui.sidebarOpened
  }),
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {
    $(".item-with-submenus > a").click(function() {
      $(this)
        .parent()
        .toggleClass("mm-active");
      $(this)
        .parent()
        .children("ul.nav-second-level.mm-collapse")
        .toggleClass("mm-show");
    });
  },
};
</script>

<style scoped></style>
