<template>
<div id="wrapper" :key="valuekey">
    <div class="container-fluid">
        <!-- Form row -->
        <div class="row">
            <div class="col-md-12">
                <div class="card-box">


                     <div class="col-md-11">
                        <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.plans.label.title')}}</h4></h4>
                    </div>

                    <form>

                        <div v-if="data._id" class="form-group col-md-12">
                                
                            <h6 class="text-right">   
                                <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                <span v-if="data.status == 'ACTIVE'" style="color: green">
                                    {{$t(data.status)}} 
                                </span>
                                <span v-if="data.status == 'ARCHIVED'" style="color: blue">
                                    {{$t(data.status)}} 
                                </span>
                            </h6>

                        </div>

                        <div v-if="data._id && data.status != 'ARCHIVED'  && isAdminMaster()" class="form-row">
                            <div class="form-group col-md-2">
                                <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.plans.label.name')}}
                                </label>
                                 <input v-model="data.name" type="text" class="form-control" :placeholder="$t('string.msg.plans.label.name')" />
                            </div>
                        </div>


                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.user.type.placeholder.platforms')}}
                                </label>
                                <div class="d-flex flex-wrap">
                                    <div class="container-switch" v-bind:key="item.id" v-for="item in services">
                                        <label class="switch">
                                            <input 
                                                v-model="data.platforms" 
                                                type="checkbox"
                                                :id="item._id" 
                                                :value="item._id"
                                                :true-value="item._id"
                                                :false-value="null"
                                                >
                                            <span class="slider round"></span>
                                        </label>
                                        <span class="label-swith">{{camelize(item.whiteLabelSystem ? item.whiteLabelSystem : item.name)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.plans.label.features')}}
                                </label>
                                <input v-model="data.features" type="text" class="form-control" :placeholder="$t('string.msg.plans.label.features')" />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.plans.label.user.types')}}
                                </label>
                                <select id="userType" v-model="userType" class="form-control">
                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                    <option :value="item" v-bind:key="item" v-for="item in userTypes">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('str-msg-quantity')}}
                                </label>
                               <input v-model="quantity" type="text" class="form-control" :placeholder="$t('str-msg-quantity')" />
                            </div>
                            <div class="form-group col-md-2">
                                <button @click="addUser(userType, quantity)" class="text-dark-gray btn btn-warning w-3 mt-4">{{$t('string.msg.add.item.btn')}}</button>
                            </div>
                        </div>

                        <div v-if="data.userTypes && data.userTypes.length > 0" class="table-responsive">
                            <table class="table table-centered table-hover mb-0" id="datatable">
                                <thead class="thead-dark">
                                    <tr>
                                        <th class="border-top-0">{{$t('string.msg.domain.label.name')}}</th>
                                        <th class="border-top-0">{{$t('string.msg.user.label.quantity')}}</th>
                                        <th class="border-top-0">{{$t('string.msg.domain.table.label.remove')}}</th>
                                    </tr>
                                </thead>
                                <tbody v-bind:key="item" v-for="(item, index) in data.userTypes" class="cursor-pointer">
                                    <tr v-if="item">
                                        <td>
                                            <span v-if="item.user && item.user.name">
                                                {{ item.user.name}}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="item.quantity">
                                                {{ item.quantity}}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                <button @click="removeTypeUser(index)" class="text-dark-gray btn btn-warning w-3">{{$t('string.msg.x')}}</button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.plans.label.additinal.resources')}}
                                </label>
                                <multiselect v-model="data.additionalResources" :options="additionalResources" placeholder="Selecione" 
                                    @input="onChangePlans()"  deselectLabel="Pressione para remover" 
                                    :close-on-select="true" selectLabel="Pressione para selecionar" 
                                    selectedLabel="Selecionado" :multiple="true" track-by="name"  
                                    :custom-label="customLabelAdditionalResources" :show-labels="false" >
                                </multiselect>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.plans.label.price.setup')}}
                                </label>
                                <input  v-money="money" id="setupPrice" v-model.number="data.setupPrice" type="text" class="form-control" :placeholder="$t('string.msg.plans.label.price.setup')" />
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.plans.label.price.menthly')}}
                                </label>
                                <input  v-money="money" id="monthlyPrice" v-model.number="data.monthlyPrice" type="text" class="form-control" :placeholder="$t('string.msg.plans.label.price.menthly')" />
                            </div>
                        </div>

                        <div v-if="errors.length" class="alert alert-danger">
                            <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                            <ul>
                                <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                            
                        <br/>
                        <div class="form-row text-center">
                            <div class="form-group col-md-6">
                                <button @click="clearForm" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                            </div>
                            <div class="form-group col-md-6">
                                <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                            </div>
                        </div>

                    </form>
                </div>
                <List v-on:load-item="selectItem"/>
            </div>
        </div>
    </div>
    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
</div>
</template>

<script>
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from './Confirmation';
import Controller from './CrtPlans';
import List from './ListPlans';
import Endpoints from '../../../components/common/Endpoints';

export default {
  name: 'Plans',
  components: {
    Spinner,
    Toast,
    List,
    Confirmation
  },
  data() {
      return {
        errors: [],
        services: [],
        userTypes: [],
        additionalResources: [],
        valuekey: 0,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        file: null,
        domain: Endpoints.domain,
        money: {
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            suffix: '',
            precision: 2,
            masked: false
        },
        data: {
            name: null,
            platforms: [],
            features: null,
            userTypes: [],
            additionalResources: [],
            setupPrice: 0,
            monthlyPrice: 0, 
            status: "ACTIVE"
        },
      }
  },
  methods: Controller.methods,
  created() {
  },
  mounted(){
    Controller.init(this);
  }
}
</script>
<style>
.multiselect__option--highlight {
    background: #1DA1F2;
    outline: none;
    color: #fff;
}
.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #1DA1F2;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #1DA1F2;
}
</style>