import { render, staticRenderFns } from "./Confirmation.vue?vue&type=template&id=a539bb76&"
import script from "./Confirmation.vue?vue&type=script&lang=js&"
export * from "./Confirmation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a539bb76')) {
      api.createRecord('a539bb76', component.options)
    } else {
      api.reload('a539bb76', component.options)
    }
    module.hot.accept("./Confirmation.vue?vue&type=template&id=a539bb76&", function () {
      api.rerender('a539bb76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/User/Types/Confirmation.vue"
export default component.exports