<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: () => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
    },
    methods:{
        filter: function(){
            let state = this;
            state.items = [];
            state.errors = [];

            if(state.userType == 'SEARCH-USER'){
                $('#spinner').modal('toggle');
                Request.do(state, 'POST', Request.getDefaultHeader(state), state.filters, `${Endpoints.systemUsers.companyUser}`,
                (response)=>{//SUCCESS CALLBACK
                    state.items = response.results;
                    $('#spinner').modal('toggle');  
                },
                (error)=>{//ERROR CALLBACK
                });
            }
            else
            if(state.userType == 'SEARCH-USER-COMPANY'){
                if(state.filters.name.length >= 3){
                    $('#spinner').modal('toggle');
                    Request.do(state, 'POST', Request.getDefaultHeader(state), state.filters, `${Endpoints.systemUsers.companyUser}`,
                    (response)=>{//SUCCESS CALLBACK
                        state.items = response.results;
                        $('#spinner').modal('toggle');  
                    },
                    (error)=>{//ERROR CALLBACK
                    });
                }
                else
                {  state.errors.push(state.$t('string.msg.name.min.four.caracter')); }
            }
            else
            if(state.userType == null){
                state.errors.push(state.$t('string.msg.filter.search.user.type'));
            }
        },
        selectItem: function(item){
            this.$emit('load-item', item);
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
