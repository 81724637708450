var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("h4", { staticClass: "page-title" }, [
                _vm._v(
                  _vm._s(_vm.$t("string.msg.list.user.label.colaborators"))
                )
              ]),
              _c("form", { staticClass: "mt-4" }, [
                _vm.newData._id
                  ? _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("h6", { staticClass: "text-right" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("string.msg.ratinng.label.status")) +
                              ":"
                          )
                        ]),
                        _vm.newData.status == "ACTIVE"
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.$t(_vm.newData.status)) +
                                  " \n                                        "
                              )
                            ])
                          : _vm._e(),
                        _vm.newData.status == "ARCHIVED"
                          ? _c("span", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.$t(_vm.newData.status)) +
                                  " \n                                        "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.newData._id &&
                _vm.newData.status != "ARCHIVED" &&
                _vm.isAdminMaster()
                  ? _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-dark-gray btn btn-warning w-100",
                            on: { click: _vm.confirmArchive }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.status.archive")))]
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        !_vm.newData._id
                          ? _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                                            " +
                            _vm._s(
                              _vm.$t("string.msg.form.crud.user.label.name")
                            ) +
                            "\n                                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newData.firstName,
                          expression: "newData.firstName"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.form.crud.user.placeholder.name"
                        )
                      },
                      domProps: { value: _vm.newData.firstName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newData,
                            "firstName",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        !_vm.newData._id
                          ? _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                                            " +
                            _vm._s(
                              _vm.$t("string.msg.form.crud.user.label.lastname")
                            ) +
                            "\n                                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newData.lastName,
                          expression: "newData.lastName"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.form.crud.user.placeholder.lastname"
                        )
                      },
                      domProps: { value: _vm.newData.lastName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newData, "lastName", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                                            " +
                            _vm._s(
                              _vm.$t("string.msg.form.crud.user.label.email")
                            ) +
                            "\n                                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newData.email,
                          expression: "newData.email"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.form.crud.user.placeholder.email"
                        )
                      },
                      domProps: { value: _vm.newData.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newData, "email", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        !_vm.newData._id
                          ? _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                                            " +
                            _vm._s(
                              _vm.$t("string.msg.form.crud.user.label.password")
                            ) +
                            "\n                                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newData.password,
                          expression: "newData.password"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        placeholder: _vm.$t(
                          "string.msg.form.crud.user.placeholder.password"
                        )
                      },
                      domProps: { value: _vm.newData.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newData, "password", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _c("code", { staticClass: "highlighter-rouge" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "\n                                            " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.table.list.user.label.access.level"
                              )
                            ) +
                            "\n                                        "
                        )
                      ]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newData.companyUser.subtype,
                            expression: "newData.companyUser.subtype"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "companyType" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.newData.companyUser,
                              "subtype",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("string.msg.select.selector.item"))
                            )
                          ]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "ADMIN_MASTER", selected: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("string.msg.user.type.admin.master")
                              )
                            )
                          ]
                        ),
                        _c("option", { attrs: { value: "ADMIN_PARRESIA" } }, [
                          _vm._v(_vm._s(_vm.$t("string.msg.user.type.admin")))
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-row text-center" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: { click: _vm.cancelAdd }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger w-50",
                        on: { click: _vm.checkForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                    )
                  ])
                ]),
                _vm.users && _vm.users.length > 0
                  ? _c("div", { staticClass: "table-responsive mt-4" }, [
                      _c("p", { staticClass: "sub-header" }, [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.list.company.user.label.colaborators"
                              )
                            ) +
                            "\n                                    "
                        )
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "table table-centered table-hover mb-0"
                        },
                        [
                          _c("thead", { staticClass: "thead-dark" }, [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.menu.crud.user.table.label.name"
                                    )
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.menu.crud.user.table.label.email"
                                    )
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.user.user.level.label")
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._l(_vm.users, function(item) {
                            return _c(
                              "tbody",
                              { key: item._id, staticClass: "cursor-pointer" },
                              [
                                item.status == "ACTIVE"
                                  ? _c(
                                      "tr",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.selectItem(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          item.firstName && item.lastName
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(
                                                      _vm.toUpperCase(
                                                        item.firstName
                                                      ) +
                                                        " " +
                                                        _vm.toUpperCase(
                                                          item.lastName
                                                        )
                                                    ) +
                                                    "\n                                                        "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.email
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(item.email) +
                                                    "\n                                                        "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.companyUser &&
                                          item.companyUser.subtype
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(
                                                      _vm.camelize(
                                                        _vm.$t(
                                                          item.companyUser
                                                            .subtype
                                                        )
                                                      )
                                                    ) +
                                                    "\n                                                        "
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm.errors.length
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(
                          _vm._s(_vm.$t("response.msg.error.empty.fields"))
                        )
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.errors, function(error) {
                          return _c("li", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }