var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalReset",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalReset",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content card card-radius" }, [
            _c("div", {
              staticClass: "backgroundColorRed card-radius-top topBorderCard"
            }),
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title text-uppercase" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("string.msg.user.change.email.label.password.title")
                  )
                )
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { attrs: { id: "form-esqueci-senha" } }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.change.password.label.password")
                      )
                    ),
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")])
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.password,
                        expression: "data.password"
                      }
                    ],
                    staticClass: "form-control form-control-lg has-border",
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t(
                        "string.msg.change.password.placeholder.password"
                      )
                    },
                    domProps: { value: _vm.data.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.data, "password", $event.target.value)
                      }
                    }
                  }),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.change.password.label.cfpassword")
                      )
                    ),
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")])
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.passwordConfirm,
                        expression: "data.passwordConfirm"
                      }
                    ],
                    staticClass: "form-control form-control-lg has-border",
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t(
                        "string.msg.change.password.placeholder.cfpassword"
                      )
                    },
                    domProps: { value: _vm.data.passwordConfirm },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.data,
                          "passwordConfirm",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("br"),
                  _vm.errors.length
                    ? _c("div", { staticClass: " alert-danger" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-group text-center mt-4" }, [
                  _c("div", { staticClass: "d-flex bd-highlight mb-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger w-20",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("reset-pass")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("string.btn.send")) + " ")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }