var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footer", class: { "sidebar-opened": _vm.sidebarOpened } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _c("span", { staticStyle: { color: "black" } }, [
              _vm._v(_vm._s(_vm.$t("string.msg.footer.text"))),
              _c(
                "a",
                {
                  staticClass: "ml-1",
                  staticStyle: { color: "black" },
                  attrs: { href: "https://www.parresia.com/", target: "_blank" }
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("string.msg.footer.text.company")))
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }