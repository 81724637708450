<script>
import Endpoints from '../../components/common/Endpoints';
import Common from '../../components/common/Common';
import Request from '../../components/common/Request';

export default {
    init: (payload) => {
    },
    methods:{
        filter: function(){
            let state = this;
            state.items = [];
            Request.do(state, 'POST', Request.getDefaultHeader(state), this.filters, `${Endpoints.companies.filter}`,
            (response)=>{//SUCCESS CALLBACK
                state.items = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        selectItem: function(item){
            this.errors = [];
            this.messageIdToast = null;
            $('#modalCompany').modal('toggle');
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
