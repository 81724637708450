<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: async (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        $('#spinner').modal('toggle');

        payload.getServices();

        let response = (await Request.do(payload, 'GET', Request.getDefaultHeader(payload), {}, `${Endpoints.systemUsers.getMe}`)).data;
        setTimeout(async function(){
            if (response && response.status==200){
                payload.data = response.result;
            }
            $('#spinner').modal('toggle');

        }, 200);
    },

    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;

            let data = JSON.parse(JSON.stringify(this.data));
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('jsonData', JSON.stringify(data));

             Request.do(this, 'PUT', Request.getDefaultHeader(this), formData, `${Endpoints.systemUsers.systemUserFormdata}${data._id}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.valueKey += 1; 
                    this.$fire({
                        text: this.$t('str.register.put.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                        // location.reload();
                    });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
           
            
        },
        forceRerender() {   
            this.componentKey += 1;  
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.service}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        checkForm: function (e) {
            this.errors = [];
            if (!this.data.firstName) {
                this.errors.push(this.$t('response.user.register.firstname.required'));
            }
            if (!this.data.lastName) {
                this.errors.push(this.$t('response.user.register.lastname.required'));
            }
            if (!this.data.email) {
                this.errors.push(this.$t('response.user.register.email.required'));
            }
            if (!this.data.companyUser.subtype || this.data.companyUser.subtype === null) {
                this.errors.push(this.$t('response.user.register.level.access.required'));
            }

            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.errors = [];
                this.save();
            }
        },
        showText(text) {
            this.text = text;
        },
        clearText() {
            setTimeout( () => this.text = '', 500);
        },
        configMessage :function(title, message, position, time){
            const config ={
               title: title,
                message: message,
                position: position,
                x: window.innerWidth /60,
                y: window.innerHeight /1.3,
                time: time,
            }
            return config
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
