<script>

export default {
    init: async () => {
    const el = document.body;
    el.removeAttribute("class");
    $(".modal").modal("hide");
  },
  methods: {
    showModal(){
      setTimeout(async function(){
        $('#modalCompany').modal('show');
      }, 100);
    },
    checkData: function(response){
    if (response != 0){
      
       this.company = response;
        alert(JSON.stringify(this.company))
      }
   }
  }
}
</script>
