<template>
    <div class="modal fade" id="modalCongrats" tabindex="-1" role="dialog" aria-labelledby="modalCongrats" aria-hidden="true" style="top:100px">
        <div class="modal-dialog" role="document">
            <div class="backgroundColorRed card-radius-top topBorderCard text-center">
                <img  class="mt-2" src="assets/images/parresia_logo.png" alt="parresia" height="35px">
            </div>
            <div class="modal-content">
                <br/>
                <div class="content text-center">
                    <h3>{{$t('string.msg.menu.company.start.build.your.site.label.title')}}</h3>
                </div>
                <div class="modal-body">
                    <p>{{$t('string.msg.menu.company.start.build.your.site.label.description')}}</p> 
                </div>
                <div class="modal-footer" style="align-self: center;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{$t('string.msg.ok')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Common from '../../../../components/common/Common';
    export default {
    methods:{
    },
    data() {
      return {
          camelize: Common.camelize
        }
    }
}
</script>

