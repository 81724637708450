var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "col-md-11" }, [
                  _c("h4", { staticClass: "header-title mb-4" }, [
                    _c("h4", { staticClass: "page-title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.platforms.label.description"))
                      )
                    ])
                  ])
                ]),
                _c("form", [
                  _vm.data._id
                    ? _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("h6", { staticClass: "text-right" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("string.msg.ratinng.label.status")
                              ) + ":"
                            )
                          ]),
                          _vm.data.status == "ACTIVE"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e(),
                          _vm.data.status == "ARCHIVED"
                            ? _c("span", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.data._id &&
                  _vm.data.status != "ARCHIVED" &&
                  _vm.isAdminMaster()
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "text-dark-gray btn btn-warning w-100",
                              on: { click: _vm.confirmArchive }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("string.msg.status.archive"))
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.platforms.placeholder.name")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.name,
                            expression: "data.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.platforms.placeholder.name"
                          )
                        },
                        domProps: { value: _vm.data.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.platforms.placeholder.documentation"
                                )
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.documentation,
                            expression: "data.documentation"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.platforms.placeholder.documentation"
                          )
                        },
                        domProps: { value: _vm.data.documentation },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "documentation",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.platforms.placeholder.integrationType"
                                )
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.integrationType,
                            expression: "data.integrationType"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.platforms.placeholder.integrationType"
                          )
                        },
                        domProps: { value: _vm.data.integrationType },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "integrationType",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.platforms.placeholder.whiteLabelSystem"
                                )
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.whiteLabelSystem,
                            expression: "data.whiteLabelSystem"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.platforms.placeholder.whiteLabelSystem"
                          )
                        },
                        domProps: { value: _vm.data.whiteLabelSystem },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "whiteLabelSystem",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("str.msg.whitelabel.api")) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c(
                        "strong",
                        { staticClass: "ml-1", staticStyle: { color: "red" } },
                        [_vm._v(_vm._s(_vm.$t("str.msg.no.edit.this.field")))]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.whiteLabelApi,
                            expression: "data.whiteLabelApi"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("str.msg.whitelabel.api")
                        },
                        domProps: { value: _vm.data.whiteLabelApi },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "whiteLabelApi",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "col-form-label",
                      attrs: { for: "inputAddress" }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("string.msg.platforms.table.label.image")
                          ) +
                          "\n                        "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c("input", {
                        ref: "file",
                        staticClass: "form-control",
                        attrs: { type: "file", id: "logo" },
                        on: {
                          change: function($event) {
                            return _vm.handleFileUpload()
                          }
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6 text-center" },
                      [
                        _vm.data.logoURL
                          ? _c("img", {
                              attrs: {
                                src: "" + _vm.domain + _vm.data.logoURL,
                                alt: "image",
                                height: "120",
                                width: "150"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("div", { staticClass: "form-row text-center" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary w-50",
                          on: { click: _vm.clearForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                      )
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-50",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("List", { on: { "load-item": _vm.selectItem } })
        ],
        1
      ),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }