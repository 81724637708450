<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ this.data.type }}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
            <div class="col-md-12">
              <div class="card-box">
                <p class="sub-header">{{ $t("string.msg.platforms.label.description") }}</p>

                <form>
                  <div class="form-row">
                    <div class="form-group col-md-3">
                      <label for="inputAddress" class="col-form-label">
                        <code class="highlighter-rouge">*</code>
                        {{ $t("string.msg.product.brand.label.id") }}
                      </label>
                      <input type="text" readonly v-model="data._id" class="form-control" />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputAddress" class="col-form-label">
                        <code class="highlighter-rouge">*</code>
                        {{ $t("string.msg.platforms.label.name") }}
                      </label>
                      <input type="text" v-model="data.name" class="form-control" />
                    </div>
                    <div v-if="data.type == 'website'" class="form-group col-md-6">
                      <label for="inputAddress" class="col-form-label">
                        <code class="highlighter-rouge">*</code>
                        {{ $t("string.msg.platforms.label.accessURL") }}
                      </label>
                      <input type="text" v-model="data.accessURL" class="form-control" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputAddress" class="col-form-label">
                        <code class="highlighter-rouge">*</code>
                        {{ $t("string.msg.platforms.label.user") }}
                      </label>
                      <input type="text" v-model="data.user" class="form-control" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputAddress" class="col-form-label">
                        <code class="highlighter-rouge">*</code>
                        {{ $t("string.msg.platforms.label.pass") }}
                      </label>
                      <input type="password" v-model="data.pass" class="form-control" />
                    </div>
                  </div>

                  <div v-if="errors.length" class="alert alert-danger">
                    <p class="lead">{{ $t("response.msg.error.empty.fields") }}</p>
                    <ul>
                      <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                  </div>

                  <div class="d-flex justify-content-between bd-highlight mb-3">
                    <button
                      @click="save"
                      class="btn btn-danger w-20"
                    >{{ $t("string.msg.form.btn.save") }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation
      :title="titleToast"
      :message="messageToast"
      :cancelText="cancelText"
      :yesText="yesText"
      v-on:confirmation-return="archive"
    />
  </div>
</template>

<script>
import Controller from "./CrtWebEmail";
import Spinner from "../../../components/layout/Spinner";
import Toast from "../../../components/layout/Toast";
import Confirmation from "../../../components/layout/Confirmation";

export default {
  name: "WebEmail",
  components: {
    Spinner,
    Toast,
    Confirmation
  },
  data() {
    return {
      errors: [],
      valuekey: 0,
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      data: {
        name: null,
        status: "ACTIVE",
        user: null,
        pass: null,
        accessURL: null
      }
    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {}
};
</script>

<style></style>
