var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.user.update.profile.label.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("form", [
                    _c("div", { staticClass: "form-row mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12 text-center" },
                        [
                          _vm.data.companyUser.photoURL &&
                          _vm.data.companyUser.photoURL != "https://"
                            ? _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src:
                                    "" +
                                    _vm.domain +
                                    _vm.data.companyUser.photoURL,
                                  alt: "user-image",
                                  height: "120",
                                  width: "120"
                                }
                              })
                            : _c("i", {
                                staticClass:
                                  "far fa-user noti-icon rounded-circle",
                                staticStyle: {
                                  color: "gray",
                                  "font-size": "45px"
                                },
                                attrs: { alt: "user-image" }
                              })
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            !_vm.data._id
                              ? _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t("string.msg.form.crud.user.label.name")
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.firstName,
                              expression: "data.firstName"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.form.crud.user.placeholder.name"
                            )
                          },
                          domProps: { value: _vm.data.firstName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "firstName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            !_vm.data._id
                              ? _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.form.crud.user.label.lastname"
                                  )
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.lastName,
                              expression: "data.lastName"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.form.crud.user.placeholder.lastname"
                            )
                          },
                          domProps: { value: _vm.data.lastName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "lastName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.form.crud.user.label.email"
                                  )
                                ) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.email,
                              expression: "data.email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: "",
                            type: "text",
                            placeholder: _vm.$t(
                              "string.msg.form.crud.user.placeholder.email"
                            )
                          },
                          domProps: { value: _vm.data.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "email", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(_vm.$t("str.company.user.rg")) +
                                "\n                                        "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.companyUser.documentRg,
                              expression: "data.companyUser.documentRg"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("str.company.user.rg")
                          },
                          domProps: { value: _vm.data.companyUser.documentRg },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data.companyUser,
                                "documentRg",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm.data.companyUser.subtype != "SIMPLE"
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(_vm.$t("str.company.user.cpf")) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.companyUser.documentCpf,
                                  expression: "data.companyUser.documentCpf"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["###.###.###-##"],
                                  expression: "['###.###.###-##']"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("str.company.user.cpf")
                              },
                              domProps: {
                                value: _vm.data.companyUser.documentCpf
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data.companyUser,
                                    "documentCpf",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "form-group col-md-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.user.label.perfil.photo"
                                      )
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("input", {
                              ref: "file",
                              staticClass: "form-control",
                              attrs: { type: "file", id: "photo" },
                              on: {
                                change: function($event) {
                                  return _vm.handleFileUpload()
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-row text-center mt-4" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger w-50",
                            on: { click: _vm.checkForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("FlashMessage")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }