<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

        payload.getServices();
    },
    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;
            let state = this;

            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.data, `${Endpoints.systemUsers.systemUser}${this.data._id ? this.data._id : ''}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                        text: this.$t('str.register.user.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                            location.reload();
                    });
                    
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
        },
        archive: function(response){
            if (response==1){
                $('#spinner').modal('toggle');
                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), null, `${Endpoints.systemUsers.systemUser}${this.data._id}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.user.delete.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                location.reload();
                        });
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }
                            $('#toast').modal('show');
                        }
                        console.log(JSON.stringify(error));
                });
            }
        },
        confirmArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.user.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationModal').modal('show');
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.service}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        selectItem: function(item){

            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));

            if(this.data.companyUser.userPlatforms && this.data.companyUser.userPlatforms.length > 0){
                let copyPlatforms = this.data.companyUser.userPlatforms;
                let platforms = [];
                for(let i=0; i< copyPlatforms.length; i++){
                    if(copyPlatforms[i] && copyPlatforms[i]._id)
                        platforms.push(copyPlatforms[i]._id)
                }
                this.data.companyUser.userPlatforms = platforms;
            }

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        clearForm: function(){
          this.company = "";
          this.data = {};
        },
         camelize: Common.camelize,
         toUpperCase: Common.toUpperCase,
    }
}
</script>
