<script>

export default {
    init: async () => {
    const el = document.body;
    el.removeAttribute("class");
    $(".modal").modal("hide");

  },
  methods: {
  },
}
</script>
