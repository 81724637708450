<script>
import Endpoints from "../../../components/common/Endpoints";
import Common from "../../../components/common/Common";
import Request from "../../../components/common/Request";

export default {
    init: async (payload) => {
    const el = document.body;
    el.removeAttribute("class");
    $(".modal").modal("hide");

    let response = (await Request.do(payload, 'GET', Request.getDefaultHeader(payload), {}, `${Endpoints.systemUsers.getMe}`)).data;
    payload.user = JSON.parse(JSON.stringify(response.result));
    if(response.result.companyUser.company){
     payload.company = JSON.parse(JSON.stringify(response.result.companyUser.company));
    }

    if(payload.company.appInAppleStore == true){
      payload.option1 = true;
    }else{
      payload.option2 = true;
    }

  },
  methods: {
  save: function() {
    
    let data = JSON.parse(JSON.stringify(this.company));

    //REMOVE _ID AND ADDRESS FOR UPDATE
    delete data._id;
    delete data.address;

    data.haveApp = true;

    Request.do(this, 'PUT', Request.getDefaultHeader(this), data, `${Endpoints.companies.company}${this.company._id}`,
        (companyResponse)=>{//SUCCESS CALLBACK
            // this.$fire({
            //     text: this.$t('str.register.put.sucess'),
            //     type: "success",
            //     timer: 2000
            // }).then(r => {
            // });
            $('#modalCongrats').modal('toggle');
        },
        (error)=>{//ERROR CALLBACK
            let res = error.response;
            if (res.status == 500){
                this.titleToast = this.$t('parresia.msg.toast.advice');
                
                if (res.data.messageId === 'parresia.already.exists'){
                    this.messageToast = this.$t('parresia.company.register.error.element.exists');
                }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                    this.messageToast = this.$t('parresia.msg.error.required.fields');
                }else{
                    this.messageToast = this.$t('parresia.msg.error.generic');
                    this.messageIdToast = res.data.messageId;
                }

                $('#toast').modal('show');
            }
    });     
  },
  setOption1(){
    if(this.option1 == false){
      this.option1 = true;
      this.option2 = false;
    }
  },
  setOption2(){
    if(this.option2 == false){
      this.option2 = true;
      this.option1 = false; 
    }
  },
  checkForm: function (e) {

    this.errors = [];

    if (!this.company.appName && this.company.appName.length == 0) {
        this.errors.push(this.$t('string.msg.menu.app.name.required'));
    }

    if(this.option1 == true){
      this.company.appInAppleStore = true;
    }

    if(this.option2 == true){
      this.company.appInAppleStore = false;
    }

    if (this.errors.length>0){
        e.preventDefault();
    }else{
        this.save();
      }
    },
    camelize: Common.camelize,
    toUpperCase: Common.toUpperCase
  }
};
</script>
