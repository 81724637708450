var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.menu.platforms.app.label.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.platforms.configuration.label.title")
                      )
                    )
                  ]),
                  _c("form", [
                    _c("div", { staticClass: "form-row mt-2" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("str.app.name.label")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.company.appName,
                              expression: "company.appName"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("str.app.name.label")
                          },
                          domProps: { value: _vm.company.appName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.company,
                                "appName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "string.msg.menu.company.wanna.buy.apple.store.label"
                          )
                        )
                      )
                    ]),
                    _c("div", { staticClass: "m-1" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-info form-check-inline" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.option1,
                                expression: "option1"
                              }
                            ],
                            attrs: {
                              type: "radio",
                              id: "inlineRadio1",
                              name: "radioInline"
                            },
                            domProps: {
                              value: _vm.option1,
                              checked: _vm._q(_vm.option1, _vm.option1)
                            },
                            on: {
                              click: _vm.setOption1,
                              change: function($event) {
                                _vm.option1 = _vm.option1
                              }
                            }
                          }),
                          _c("label", { attrs: { for: "inlineRadio1" } }, [
                            _vm._v(_vm._s(_vm.$t("string.msg.yes")))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-info form-check-inline" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.option2,
                                expression: "option2"
                              }
                            ],
                            attrs: {
                              type: "radio",
                              id: "inlineRadio2",
                              name: "radioInline"
                            },
                            domProps: {
                              value: _vm.company.option2,
                              checked: _vm._q(_vm.option2, _vm.company.option2)
                            },
                            on: {
                              click: _vm.setOption2,
                              change: function($event) {
                                _vm.option2 = _vm.company.option2
                              }
                            }
                          }),
                          _c("label", { attrs: { for: "inlineRadio2" } }, [
                            _vm._v(_vm._s(_vm.$t("string.msg.no")))
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "mt-4" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("str.msg.obs")))]),
                      _c("span", { staticStyle: { color: "gray" } }, [
                        _vm._v(
                          _vm._s(
                            " " +
                              _vm.$t("string.msg.menu.company.app.label.title")
                          )
                        )
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "mt-3 alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "text-center mb-2 mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-30",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      }),
      _c("ModalCongrats")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }