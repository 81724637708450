const state = () => ({
  sidebarOpened: false
})

const getters = {}

const mutations = {
  OPEN_SIDEBAR (state) {
    state.sidebarOpened = true
  },
  CLOSE_SIDEBAR (state) {
    state.sidebarOpened = false
  },
  TOGGLE_SIDEBAR (state) {
    state.sidebarOpened = !state.sidebarOpened
  }
}

const actions = {
  openSidebar ({ commit }) {
    commit('OPEN_SIDEBAR')
  },
  closeSidebar ({ commit }) {
    commit('CLOSE_SIDEBAR')
  },
  toggleSidebar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  }
}

export default {
  state, 
  getters,
  mutations,
  actions
}