var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "string.msg.user.change.email.label.password.title"
                        )
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      "\n                                  " +
                        _vm._s(
                          _vm.$t(
                            "string.msg.user.change.email.label.password.description"
                          )
                        ) +
                        "\n                              "
                    )
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                              " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.change.password.label.password"
                                )
                              ) +
                              "\n                                          "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.password,
                            expression: "data.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "password" },
                        domProps: { value: _vm.data.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "password", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                              " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.change.password.label.npassword"
                                )
                              ) +
                              "\n                                          "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPassword,
                            expression: "newPassword"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t(
                            "string.msg.user.change.password.placeholder.npassword"
                          )
                        },
                        domProps: { value: _vm.newPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newPassword = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                              " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.change.password.label.cnpassword"
                                )
                              ) +
                              "\n                                          "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cfNewPassword,
                            expression: "cfNewPassword"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t(
                            "string.msg.user.change.password.placeholder.cnpassword"
                          )
                        },
                        domProps: { value: _vm.cfNewPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.cfNewPassword = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _c("br"),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("div", { staticClass: "form-row text-center" }, [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-50",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }