import Vue from 'vue';
import Router from 'vue-router';
import User from './views/User/User.vue';
import CrudUser from './views/User/Crud/User.vue';
import Perfil from './views/User/Perfil/User.vue';
import ChangeEmail from './views/User/ChangeEmail/ChangeEmail.vue';
import ChangePassword from './views/User/ChangePassword/ChangePassword.vue';
import ListUser from './views/User/List/User.vue';
import Login from './views/Login/Login.vue';
import Logout from './views/Logout/Logout.vue';
import Dashboard from './views/Dashboard/Dashboard.vue';
import Companies from './views/Company/Crud/Company.vue';
import UpdateInfo from './views/Company/UpdateInfo/Company.vue';
import AddInfo from './views/Company/AddInfo/Company.vue';
import Platforms from './views/Configuration/Platforms/Platforms.vue';
import RegisterCompany from './views/Company/Register/Company.vue';
import Terms from './views/Terms/Terms.vue';
import App from './views/Configuration/App/App.vue';
import Collection from './views/Configuration/Collection/Collection.vue';
import Donation from './views/Configuration/Donation/Donation.vue';
import EmailMarketing from './views/Configuration/EmailMarketing/EmailMarketing.vue';
import WebSite from './views/Configuration/WebSite/WebSite.vue';
import WebEmail from './views/Configuration/WebEmail/WebEmail.vue';
import Credential from './views/Credential/Credential.vue';
import Configuration from './views/Configuration/Configuration.vue';
import Customer from './views/Customer/Customer.vue';
import Service from './views/Configuration/Services/Services.vue';
import AdditionalResource from './views/Configuration/AdditionalResources/AdditionalResources.vue';
import Plans from './views/Configuration/Plans/Plans.vue';
import Report from './views/Report/Report.vue';
import Template from './views/Configuration/Templates/Template.vue';
import Sponsor from './views/Configuration/Sponsors/Sponsor.vue';

import VueSession from 'vue-session';//https://github.com/victorsferreira/vue-session
Vue.use(VueSession)
Vue.use(Router)

const session = Vue.prototype.$session
const checkSession = () => {
  if (!session.exists('user')) {
    const user = localStorage.getItem('user')
    if (!user) {
      return '/login';//redirect to login page
    } 

    session.set('user', JSON.parse(user))
    return
  }
  return
}

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/users',
      name: 'users',
      component: User
    },
    {
      path: '/list-user',
      name: 'list-user',
      component: ListUser,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/dashboard',
      alias: '/',
      name: 'dashboard',
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/companies/:companyId',
      name: 'companies',
      props: true,
      component: Companies,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/companies',
      name: 'companies',
      component: Companies,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/update-company-info',
      name: 'update-company-info',
      component: UpdateInfo,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/configuration-platform',
      name: 'configuration-platform',
      component: Configuration,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/services',
      name: 'services',
      component: Service,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/additional-resources',
      name: 'additional-resources',
      component: AdditionalResource,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/customer',
      name: 'customer',
      component: Customer,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Report,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/plans',
      name: 'plans',
      component: Plans,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/add-company-info',
      name: 'add-company-info',
      component: AddInfo,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/credential',
      name: 'credential',
      component: Credential,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/platforms-site/:type',
      name: 'platforms-site',
      component: Platforms,
      props: true,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }

    },
    {
      path: '/register-company',
      name: 'register-company',
      component: RegisterCompany
    },
    {
      path: '/crud-user',
      name: 'crud-user',
      component: CrudUser,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/perfil-user',
      name: 'perfil-user',
      component: Perfil,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/change-email',
      name: 'change-email',
      component: ChangeEmail,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/mobile-app',
      name: 'mobile-app',
      component: App,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/collection',
      name: 'collection',
      component: Collection,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/donation',
      name: 'donation',
      component: Donation,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/email-marketing',
      name: 'email-marketing',
      component: EmailMarketing,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/web-site',
      name: 'web-site',
      component: WebSite,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/web-email',
      name: 'web-email',
      component: WebEmail,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/template',
      name: 'template',
      component: Template,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
    {
      path: '/sponsor',
      name: 'sponsor',
      component: Sponsor,
      beforeEnter: (to, from, next) => {
        next(checkSession());
      }
    },
  ]
});
