<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

        payload.getCompanies();
    },
    methods:{
        filter: function(){
            let state = this;
            state.items = [];
            state.errors = [];

            $('#spinner').modal('toggle');
            Request.do(state, 'POST', Request.getDefaultHeader(state), state.filters, `${Endpoints.systemUsers.companyUser}`,
            (response)=>{//SUCCESS CALLBACK
                state.items = response.results;
                $('#spinner').modal('toggle');  
            },
            (error)=>{//ERROR CALLBACK
            });
          
        },
        searchUserByCompany: function(item){
            if(item && item._id){
                this.filters.company = item._id;
                $('#spinner').modal('toggle');
                Request.do(this, 'POST', Request.getDefaultHeader(this), this.filters, `${Endpoints.systemUsers.companyUser}`,
                (response)=>{//SUCCESS CALLBACK
                    this.items = response.results;
                    $('#spinner').modal('toggle');  
                },
                (error)=>{//ERROR CALLBACK
                });
            }
            
        },
        getCompanies: function(){
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.companies.company}`,
            (response)=>{//SUCCESS CALLBACK
                this.companies = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        customLabelCompany(option) {
            return `${option.fantasyName}`
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
