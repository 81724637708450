<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.menu.configutation.geral.label.title')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
              <div class="col-md-12">
                    <div class="card-box">

                        <b-tabs>
                            
                            <b-tab active >
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.plataforms')}}</strong>
                                </template>
                                <Services/>
                            </b-tab>

                            <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.users')}}</strong>
                                </template>
                                <Collaborators/>
                            </b-tab>

                            <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.clients')}}</strong>
                                </template>
                                <TypeUsers/>
                            </b-tab>

                             <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.additional.resources')}}</strong>
                                </template>
                                <AdditionalResources/>
                                
                            </b-tab>

                             <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.plans')}}</strong>
                                </template>
                                <Plans/>
                              </b-tab>

                              <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.template')}}</strong>
                                </template>
                                <Templates/>
                              </b-tab>

                              <!-- <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.configuration.sponsor')}}</strong>
                                </template>
                                <Sponsors/>
                              </b-tab> -->
                        </b-tabs>

                  </div> <!-- end card-box -->
              </div> <!-- end col -->
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
import Controller from "./CrtConfiguration";
import Services from "../Configuration/Services/Services.vue";
import Collaborators from "../User/Crud/User.vue";
import TypeUsers from "../User/Types/Types.vue";
import AdditionalResources from "../Configuration/AdditionalResources/AdditionalResources.vue";
import Plans from "../Configuration/Plans/Plans.vue";
import Templates from "../Configuration/Templates/Template.vue";
import Sponsors from "../Configuration/Sponsors/Sponsor.vue";
export default {
  name: "Configuration",
  components: {
      Services,
      Collaborators,
      TypeUsers,
      AdditionalResources,
      Plans,
      Templates,
      Sponsors
  },
  data() {
    return {
      valuekey: 0,
    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {}
};
</script>
