<template>
<div id="wrapper" :key="valuekey">
    <div class="container-fluid">
        <!-- Form row -->
        <div class="row">
            <div class="col-md-12">
                <div class="card-box">

                     <div class="col-md-11">
                        <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.platforms.label.description')}}</h4></h4>
                    </div>

                    <form>

                        <div v-if="data._id" class="form-group col-md-12">
                                
                            <h6 class="text-right">   
                                <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                <span v-if="data.status == 'ACTIVE'" style="color: green">
                                    {{$t(data.status)}} 
                                </span>
                                <span v-if="data.status == 'ARCHIVED'" style="color: blue">
                                    {{$t(data.status)}} 
                                </span>
                            </h6>

                        </div>

                        <div class="form-row" v-if="data._id && data.status != 'ARCHIVED' && isAdminMaster()">
                            <div class="form-group col-md-2">
                                <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.platforms.placeholder.name')}}
                                </label>
                                <input type="text" v-model="data.name" class="form-control" :placeholder="$t('string.msg.platforms.placeholder.name')">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.platforms.placeholder.documentation')}}
                                </label>
                                <input type="text" v-model="data.documentation" class="form-control" :placeholder="$t('string.msg.platforms.placeholder.documentation')">
                            </div>
                
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.platforms.placeholder.integrationType')}}
                                </label>
                                <input type="text" v-model="data.integrationType" class="form-control" :placeholder="$t('string.msg.platforms.placeholder.integrationType')">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.platforms.placeholder.whiteLabelSystem')}}
                                </label>
                                <input type="text" v-model="data.whiteLabelSystem" class="form-control" :placeholder="$t('string.msg.platforms.placeholder.whiteLabelSystem')">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('str.msg.whitelabel.api')}}
                                </label><strong class="ml-1" style="color: red;">{{$t('str.msg.no.edit.this.field')}}</strong>
                                <input type="text" v-model="data.whiteLabelApi" class="form-control" :placeholder="$t('str.msg.whitelabel.api')">
                            </div>
                        </div>

                        <label for="inputAddress" class="col-form-label">
                            {{$t('string.msg.platforms.table.label.image')}}
                        </label>
                        <div class="form-row"> 
                            <div  class="form-group col-md-6">
                                <input type="file" id="logo" ref="file" v-on:change="handleFileUpload()" class="form-control">
                            </div> 
                            <div  class="form-group col-md-6 text-center">
                                <img v-if="data.logoURL" v-bind:src="`${domain}${data.logoURL}`" alt="image" height="120" width="150">
                            </div> 
                        </div>  


                        <div v-if="errors.length" class="alert alert-danger">
                            <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                            <ul>
                                <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                        
                        <br/>
                        <div class="form-row text-center">
                            <div class="form-group col-md-6">
                                <button @click="clearForm" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                            </div>
                            <div class="form-group col-md-6">
                                <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <List v-on:load-item="selectItem"/>
    </div>
    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
</div>
</template>

<script>
import Controller from './CrtServices';
import List from './ListServices';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from '../../../components/layout/Confirmation';
import Endpoints from '../../../components/common/Endpoints';
import Vue from 'vue';
Vue.prototype.$eventHub = new Vue();
export default {
  name: 'Services',
  components: {
    Spinner,
    Toast,
    List,
    Confirmation
  },
  data() {
      return {
        errors: [],
        services: [],
        valuekey: 0,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        file: null,
        domain: Endpoints.domain,
        data: {
            name: null,
            documentation: null,
            integrationType: null,
            whiteLabelSystem: null,
            whiteLabelApi: null,
            logoURL: null,
            status:"ACTIVE"
        }
      }
  },
  methods: Controller.methods,
  created() {
  },
  mounted(){
    Controller.init(this);
  }
}
</script>

<style>
</style>
