var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "page-title-box" }, [
                    _c("h4", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.list.user.label.title")))
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "card-box" }, [
                    _c("p", { staticClass: "sub-header" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.$t("string.msg.list.user.label.description")
                          ) +
                          "\n                                "
                      )
                    ]),
                    _c("form", [
                      _vm.data._id
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-3" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _c(
                                    "code",
                                    { staticClass: "highlighter-rouge" },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.product.brand.label.id"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data._id,
                                    expression: "data._id"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.data._id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data,
                                      "_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "form-group col-md-2" }, [
                              _c("br"),
                              _c("br"),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "text-dark-gray btn btn-warning w-100",
                                  on: { click: _vm.confirmArchive }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("string.msg.status.archive"))
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label",
                              attrs: { for: "inputAddress" }
                            },
                            [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(
                                    _vm.$t("string.msg.list.user.label.name")
                                  ) +
                                  "\n                                            "
                              )
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.firstName,
                                expression: "data.firstName"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.data.firstName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data,
                                  "firstName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label",
                              attrs: { for: "inputAddress" }
                            },
                            [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.list.user.label.lastname"
                                    )
                                  ) +
                                  "\n                                            "
                              )
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.lastName,
                                expression: "data.lastName"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.data.lastName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data,
                                  "lastName",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label",
                              attrs: { for: "inputAddress" }
                            },
                            [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(
                                    _vm.$t("string.msg.list.user.label.email")
                                  ) +
                                  "\n                                            "
                              )
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.email,
                                expression: "data.email"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.data.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.data, "email", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm.data.companyUser && _vm.data.companyUser.subtype
                          ? _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.list.user.label.type"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _vm.data.companyUser.subtype == "ADMIN"
                                ? _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: _vm.camelize(
                                        _vm.$t(
                                          "string.msg.user.type.company.admin"
                                        )
                                      ),
                                      type: "text",
                                      readonly: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm.data.companyUser.subtype == "ADMIN_MASTER"
                                ? _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: _vm.camelize(
                                        _vm.$t(
                                          "string.msg.user.type.company.admin.master"
                                        )
                                      ),
                                      type: "text",
                                      readonly: ""
                                    }
                                  })
                                : _vm.data.companyUser.subtype == "SIMPLE"
                                ? _c("input", {
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder: _vm.camelize(
                                        _vm.$t(
                                          "string.msg.user.type.company.simple"
                                        )
                                      ),
                                      type: "text",
                                      readonly: ""
                                    }
                                  })
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]),
                      _vm.data.companyUser.subtype == "ADMIN"
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("str.company.user.rg")) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.companyUser.documentRg,
                                    expression: "data.companyUser.documentRg"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("str.company.user.rg")
                                },
                                domProps: {
                                  value: _vm.data.companyUser.documentRg
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.companyUser,
                                      "documentRg",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _c(
                                    "code",
                                    { staticClass: "highlighter-rouge" },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("str.company.user.cpf")) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.companyUser.documentCpf,
                                    expression: "data.companyUser.documentCpf"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###.###.###-##"],
                                    expression: "['###.###.###-##']"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("str.company.user.cpf")
                                },
                                domProps: {
                                  value: _vm.data.companyUser.documentCpf
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.companyUser,
                                      "documentCpf",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm.data.companyUser
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "form-group col-md-12" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.list.user.label.company"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.data.companyUser.company.fantasyName,
                                    expression:
                                      "data.companyUser.company.fantasyName"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value:
                                    _vm.data.companyUser.company.fantasyName
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.companyUser.company,
                                      "fantasyName",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-12" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label",
                              attrs: { for: "inputAddress" }
                            },
                            [
                              _c("code", { staticClass: "highlighter-rouge" }, [
                                _vm._v("*")
                              ]),
                              _vm._v(
                                "\n                                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.user.platforms.with.access.label"
                                    )
                                  ) +
                                  "\n                                                "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.services, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "container-switch"
                                },
                                [
                                  _c("label", { staticClass: "switch" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.data.companyUser.userPlatforms,
                                          expression:
                                            "data.companyUser.userPlatforms"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: item._id,
                                        "true-value": item._id,
                                        "false-value": null
                                      },
                                      domProps: {
                                        value: item._id,
                                        checked: Array.isArray(
                                          _vm.data.companyUser.userPlatforms
                                        )
                                          ? _vm._i(
                                              _vm.data.companyUser
                                                .userPlatforms,
                                              item._id
                                            ) > -1
                                          : _vm._q(
                                              _vm.data.companyUser
                                                .userPlatforms,
                                              item._id
                                            )
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.data.companyUser
                                                .userPlatforms,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? item._id : null
                                          if (Array.isArray($$a)) {
                                            var $$v = item._id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.data.companyUser,
                                                  "userPlatforms",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.data.companyUser,
                                                  "userPlatforms",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.data.companyUser,
                                              "userPlatforms",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _c("span", { staticClass: "slider round" })
                                  ]),
                                  _c("span", { staticClass: "label-swith" }, [
                                    _vm._v(_vm._s(_vm.camelize(item.name)))
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "form-row text-center" }, [
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary w-50",
                              on: { click: _vm.clearForm }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("string.msg.form.btn.clear"))
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "form-group col-md-6" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger w-50",
                              on: { click: _vm.save }
                            },
                            [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("List", { on: { "load-item": _vm.selectItem } })
            ],
            1
          )
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }