var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-xl-12" }, [
        _c("div", { staticClass: "card-box" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-11" }, [
              _c("h4", { staticClass: "header-title mb-4" }, [
                _c("h4", { staticClass: "page-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("string.msg.user.type.list.label.title"))
                  )
                ])
              ])
            ]),
            _vm.items
              ? _c("div", { staticClass: "col-md-1" }, [
                  _c("h4", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("str.total")))
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "text-center",
                      staticStyle: { color: "grey" }
                    },
                    [_vm._v(_vm._s(_vm.items.length))]
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-centered table-hover mb-0",
                attrs: { id: "datatable" }
              },
              [
                _c("thead", { staticClass: "thead-dark" }, [
                  _c("tr", [
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.label.name")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.label.platforms")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.usertypes")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.plans.additional.resources"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.setup.price")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.monthy.price")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.status")))
                    ])
                  ])
                ]),
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "tbody",
                    {
                      key: item._id,
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.selectItem(item)
                        }
                      }
                    },
                    [
                      item.status == "ACTIVE"
                        ? _c("tr", [
                            _c("td", [
                              item.name
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.camelize(item.name)) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.platforms && item.platforms.length > 0
                                ? _c(
                                    "span",
                                    _vm._l(item.platforms, function(platform) {
                                      return _c("span", { key: platform }, [
                                        platform.name
                                          ? _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.camelize(
                                                    _vm.$t(platform.name)
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br")
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.userTypes && item.userTypes.length > 0
                                ? _c(
                                    "span",
                                    _vm._l(item.userTypes, function(users) {
                                      return _c("span", { key: users }, [
                                        users.user && users.user.name
                                          ? _c("span", [
                                              users.user.name
                                                ? _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.camelize(
                                                          users.user.name
                                                        ) +
                                                          " - " +
                                                          _vm.$t(
                                                            "str.msg.quantity"
                                                          ) +
                                                          users.quantity
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.additionalResources &&
                              item.additionalResources.length > 0
                                ? _c(
                                    "span",
                                    _vm._l(item.additionalResources, function(
                                      addResources
                                    ) {
                                      return _c("span", { key: addResources }, [
                                        addResources.name
                                          ? _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.camelize(
                                                    _vm.$t(addResources.name)
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br")
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.setupPrice
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          "R$ " +
                                            _vm.$t(item.setupPrice.toFixed(2))
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.monthlyPrice
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          "R$ " +
                                            _vm.$t(item.monthlyPrice.toFixed(2))
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.status
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t(item.status)) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }