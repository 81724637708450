var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-custom" }, [
    _c("ul", { staticClass: "list-unstyled topnav-menu float-right mb-0" }, [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c(
        "li",
        {
          staticClass: "dropdown notification-list show",
          class: {
            hidden:
              _vm.isAdminMaster() == false && _vm.isAdminParresia() == false
          }
        },
        [
          _vm._m(3),
          _c("div", { staticClass: "nav-tooltip" }, [_vm._v("Configurações")]),
          _c(
            "ul",
            {
              staticClass: "dropdown-menu dropdown-menu-right profile-dropdown",
              staticStyle: {
                width: "200px",
                position: "absolute",
                "will-change": "transform",
                top: "0px",
                left: "0px",
                transform: "translate3d(-38px, 70px, 0px)"
              },
              attrs: { "x-placement": "bottom-end" }
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "cursor-pointer",
                  staticStyle: { color: "gray" },
                  attrs: { to: "/configuration-platform" }
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "dropdown-item cursor-pointer",
                      attrs: { href: "javascript:void(0);" }
                    },
                    [
                      _c("i", { staticClass: "fe-settings" }),
                      _c("label", { staticClass: "cursor-pointer" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.menu.configutation.geral.label.title"
                              )
                            )
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "cursor-pointer",
                  staticStyle: { color: "gray" },
                  attrs: { to: "/companies" }
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "dropdown-item cursor-pointer",
                      attrs: { href: "javascript:void(0);" }
                    },
                    [
                      _c("i", { staticClass: "far fa-user" }),
                      _c("label", { staticClass: "cursor-pointer" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.menu.clients.config.label.title"
                              )
                            )
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "cursor-pointer",
                  staticStyle: { color: "gray" },
                  attrs: { to: "/reports" }
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "dropdown-item cursor-pointer",
                      attrs: { href: "javascript:void(0);" }
                    },
                    [
                      _c("i", { staticClass: "far fa-file-alt" }),
                      _c("label", { staticClass: "cursor-pointer" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("string.msg.menu.report.label.title"))
                        )
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c("li", { staticClass: "dropdown notification-list show" }, [
        _c(
          "a",
          {
            staticClass:
              "\n          nav-link\n          dropdown-toggle\n          nav-user\n          mr-0\n          waves-effect waves-light\n        ",
            attrs: {
              "data-toggle": "dropdown",
              href: "#",
              role: "button",
              "aria-haspopup": "false",
              "aria-expanded": "true"
            }
          },
          [
            _vm.user.companyUser && _vm.user.companyUser.photoURL != "https://"
              ? _c("img", {
                  staticClass: "rounded-circle",
                  attrs: {
                    src: "" + _vm.domain + _vm.user.companyUser.photoURL,
                    alt: "user-image",
                    height: "35",
                    width: "35"
                  }
                })
              : _c("i", {
                  staticClass: "far fa-user noti-icon rounded-circle",
                  attrs: {
                    color: "white",
                    alt: "user-image",
                    height: "35",
                    width: "35"
                  }
                })
          ]
        ),
        _c("div", { staticClass: "nav-tooltip" }, [_vm._v("Conta")]),
        _c(
          "ul",
          {
            staticClass: "dropdown-menu dropdown-menu-right profile-dropdown",
            staticStyle: {
              position: "absolute",
              "will-change": "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(-38px, 70px, 0px)"
            },
            attrs: { "x-placement": "bottom-end" }
          },
          [
            _c(
              "router-link",
              { staticStyle: { color: "gray" }, attrs: { to: "/perfil-user" } },
              [
                _c(
                  "li",
                  {
                    staticClass: "dropdown-item cursor-pointer mt-2",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [
                    _c("i", { staticClass: "dripicons-user cursor-pointer" }),
                    _c("label", { staticClass: "cursor-pointer" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("string.msg.menu.user.perfil.label.title")
                          )
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "router-link",
              {
                staticStyle: { color: "gray" },
                attrs: { to: "/update-company-info" }
              },
              [
                _c(
                  "li",
                  {
                    staticClass: "dropdown-item notify-item cursor-pointer",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [
                    _c("i", { staticClass: "far fa-building" }),
                    _c("label", { staticClass: "cursor-pointer" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.registration.label.title"
                            )
                          )
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "router-link",
              {
                staticStyle: { color: "gray" },
                attrs: { to: "/change-password" }
              },
              [
                _c(
                  "li",
                  {
                    staticClass: "dropdown-item notify-item cursor-pointer",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-key cursor-pointer" }),
                    _c("label", { staticClass: "cursor-pointer" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.change.password.label.title"
                            )
                          )
                      )
                    ])
                  ]
                )
              ]
            ),
            _c(
              "router-link",
              { staticStyle: { color: "gray" }, attrs: { to: "/terms" } },
              [
                _c(
                  "li",
                  {
                    staticClass: "dropdown-item notify-item cursor-pointer",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-file-signature cursor-pointer"
                    }),
                    _c("label", { staticClass: "cursor-pointer" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("string.msg.menu.terms.label.title"))
                      )
                    ])
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "dropdown-divider" }),
            _c(
              "router-link",
              { staticStyle: { color: "gray" }, attrs: { to: "/logout" } },
              [
                _c(
                  "li",
                  {
                    staticClass: "dropdown-item notify-item",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [
                    _c("i", { staticClass: "dripicons-power cursor-pointer" }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.menu.dashboard.label.exit"))
                      )
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      ])
    ]),
    _c("ul", { staticClass: "list-unstyled menu-left mb-0" }, [
      _c("li", { staticClass: "float-left" }, [
        _c(
          "a",
          {
            staticClass: "logo cursor-pointer",
            class: { "sidebar-closed": _vm.sidebarClosed }
          },
          [
            _c(
              "span",
              { staticClass: "logo-lg" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { to: "/dashboard" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "assets/images/parresia_logo.png",
                        alt: "parresia",
                        height: "55px"
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "span",
              { staticClass: "logo-sm" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { to: "/dashboard" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "assets/images/parresia_logo.png",
                        alt: "parresia",
                        height: "30px"
                      }
                    })
                  ]
                )
              ],
              1
            )
          ]
        )
      ]),
      _c("li", { staticClass: "float-left" }, [
        _c(
          "a",
          {
            staticClass: "button-menu-mobile navbar-toggle",
            class: { open: !_vm.sidebarClosed },
            on: { click: _vm.toggleSidebar }
          },
          [_vm._m(4)]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown notification-list" }, [
      _c("a", { staticClass: "navbar-toggle nav-link" }, [
        _c("div", { staticClass: "lines" }, [
          _c("span"),
          _c("span"),
          _c("span")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown notification-list show" }, [
      _c(
        "a",
        {
          staticClass:
            "\n          nav-link\n          dropdown-toggle\n          nav-user\n          mr-0\n          waves-effect waves-light\n        ",
          attrs: {
            href: "https://parresia.bitrix24.com.br/",
            target: "_blank",
            role: "button",
            "aria-haspopup": "false",
            "aria-expanded": "true"
          }
        },
        [_c("i", { staticClass: "fas fa-tasks noti-icon" })]
      ),
      _c("div", { staticClass: "nav-tooltip" }, [_vm._v("Projetos")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown notification-list show" }, [
      _c(
        "a",
        {
          staticClass:
            "\n          nav-link\n          dropdown-toggle\n          nav-user\n          mr-0\n          waves-effect waves-light\n        ",
          attrs: {
            "data-toggle": "dropdown",
            href: "#",
            role: "button",
            "aria-haspopup": "false",
            "aria-expanded": "true"
          }
        },
        [_c("i", { staticClass: "far fa-bell noti-icon" })]
      ),
      _c("div", { staticClass: "nav-tooltip" }, [_vm._v("Notificações")]),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-menu-right profile-dropdown",
          staticStyle: {
            position: "absolute",
            "will-change": "transform",
            top: "0px",
            left: "0px",
            transform: "translate3d(-38px, 70px, 0px)"
          },
          attrs: { "x-placement": "bottom-end" }
        },
        [
          _c(
            "a",
            {
              staticClass: "dropdown-item cursor-pointer mt-2",
              attrs: { href: "javascript:void(0);" }
            },
            [_vm._v("\n          Nenhuma notificação\n        ")]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "\n          nav-link\n          dropdown-toggle\n          nav-user\n          mr-0\n          waves-effect waves-light\n        ",
        attrs: {
          "data-toggle": "dropdown",
          href: "#",
          role: "button",
          "aria-haspopup": "false",
          "aria-expanded": "true"
        }
      },
      [_c("i", { staticClass: "fe-settings noti-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lines" }, [
      _c("span"),
      _c("span"),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }