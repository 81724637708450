var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-body" },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-holder" }, [
          _c("div", { staticClass: "form-content" }, [
            _c("div", { staticClass: "form-items" }, [
              _vm._m(1),
              _vm._m(2),
              _c("form", [
                _c("div", { staticClass: "form-group mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.email,
                        expression: "data.email"
                      }
                    ],
                    staticClass: "input-text form-control",
                    attrs: {
                      type: "email",
                      required: "",
                      placeholder: _vm.$t("string.msg.login.placeholder.email")
                    },
                    domProps: { value: _vm.data.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.data, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-group mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.password,
                        expression: "data.password"
                      }
                    ],
                    staticClass: "input-text form-control",
                    attrs: {
                      type: "password",
                      required: "",
                      placeholder: _vm.$t(
                        "string.msg.login.placeholder.password"
                      )
                    },
                    domProps: { value: _vm.data.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.data, "password", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-group col-md-12 text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-login",
                      staticStyle: {
                        "background-color": "#ffffff",
                        color: "gray"
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("string.msg.login.btn.login")))]
                  )
                ]),
                _c("br"),
                _c("div", { staticClass: "text-center mb-2" }, [
                  _c("a", { staticClass: "text-muted underline" }, [
                    _c("small", { staticStyle: { color: "#ffffff" } }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.login.text.signup")))
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "cursor-pointer",
                        staticStyle: { "margin-left": "10px" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "text-muted underline",
                            on: {
                              click: function($event) {
                                return _vm.register()
                              }
                            }
                          },
                          [
                            _c("small", [
                              _c("b", { staticStyle: { color: "#ffffff" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.login.label.signup")
                                  )
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _c("br"),
                _c("div", { staticClass: "text-center mb-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.checkForgotPassword()
                        }
                      }
                    },
                    [
                      _c("a", { staticClass: "text-muted underline" }, [
                        _c("small", { staticStyle: { color: "#ffffff" } }, [
                          _vm._v(_vm._s(_vm.$t("string.login.forgot.password")))
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("ModalForgotPassword", {
        attrs: { data: _vm.data, errors: _vm.errors },
        on: { "check-code": _vm.checkCode }
      }),
      _c("ModalNewPassword", {
        attrs: { data: _vm.data, errors: _vm.errors },
        on: { "reset-pass": _vm.resetPass }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-holder" }, [
      _c("div", { staticClass: "bg" }),
      _c("div", { staticClass: "info-holder" }, [
        _c("img", { attrs: { src: "images/graphic2.svg", alt: "" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("img", {
        attrs: {
          src: "assets/images/parresia_logo.png",
          alt: "parresia",
          height: "80px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page-links", staticStyle: { "margin-top": "50px" } },
      [
        _c("a", { staticClass: "active", attrs: { href: "#" } }, [
          _vm._v("Login")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }