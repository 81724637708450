<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card-box">
             
                <div class="form-row">
                    <div class="col-md-11">
                        <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.additional.resources.list.label.title')}}</h4></h4>
                    </div>
                    <div class="col-md-1" v-if="items">
                        <h4 class="text-center">{{$t('str.total')}}</h4>
                        <h4 class="text-center" style="color:grey">{{items.length}}</h4>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-centered table-hover mb-0" id="datatable">
                        <thead class="thead-dark">
                            <tr>
                                <th class="border-top-0" style="width:15%">{{$t('string.msg.additional.platforms.label.name')}}</th>
                                <th class="border-top-0" style="width:10%">{{$t('string.msg.additional.platforms.label.price')}}</th>
                                <th class="border-top-0" style="width:15%">{{$t('string.msg.additional.platforms.label.platform')}}</th>
                                <th class="border-top-0" style="width:40%">{{$t('string.msg.additional.platforms.label.features')}}</th>
                                <th class="border-top-0" style="width:10%">{{$t('string.msg.additional.platforms.label.logo')}}</th>
                                <th class="border-top-0" style="width:10%">{{$t('string.msg.platforms.table.label.status')}}</th>
                            </tr>
                        </thead>
                        <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer" @click="selectItem(item)">
                            <tr>
                                <td>
                                    <span>
                                        {{camelize(item.name)}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.price">
                                         {{'R$ ' + $t(item.price.toFixed(2))}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.platform && item.platform.name">
                                        {{$t(item.platform.name)}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.features">
                                        {{$t(item.features)}}
                                    </span>
                                </td>
                                
                                <td>
                                    <span v-if="item.imageURL && item.imageURL != 'https://'">
                                         <img  v-bind:src="`${domain}${item.imageURL}`" alt="image" height="60" width="60">
                                    </span>
                                    <span v-else>
                                           <label >{{$t('str.no.images')}}</label><br/> 
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.status">
                                        {{$t(item.status)}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <Spinner />
      <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    </div>
</template>

<script>
import Controller from './CrtListAdditionalResources';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';

export default {
  name: 'ListAdditionalResources',
  props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        items: [],
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
