var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalCompany",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalCompany",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "confirmationModalLabel" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("string.msg.modal.search.company.title"))
                  )
                ]
              ),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t("string.msg.companies.search.label.name")
                            ) +
                            "\n                            "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.name,
                          expression: "filters.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-4" }, [
                    _c("div", { staticClass: "justify-content-between" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success w-100",
                          on: { click: _vm.filter }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.search")))]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "450px",
                          "overflow-y": "scroll"
                        },
                        attrs: { id: "table" }
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-centered table-hover mb-0",
                            attrs: { id: "datatable" }
                          },
                          [
                            _c("thead", { staticClass: "thead-dark" }, [
                              _c("tr", [
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.label.fantasyName"
                                      )
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("string.msg.company.label.phone")
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.table.label.type"
                                      )
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.table.label.platforms"
                                      )
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.table.label.status"
                                      )
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.table.label.logo"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._l(_vm.items, function(item) {
                              return _c(
                                "tbody",
                                {
                                  key: item._id,
                                  staticClass: "cursor-pointer",
                                  attrs: { "data-dismiss": "modal" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "confirmation-return",
                                        item
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("tr", [
                                    _c("td", [
                                      item.fantasyName
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.camelize(item.fantasyName)
                                                ) +
                                                "\n                                            "
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    _c("td", [
                                      item.primaryPhone
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(item.primaryPhone) +
                                                "\n                                            "
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    item.typeService
                                      ? _c(
                                          "td",
                                          _vm._l(item.typeService, function(
                                            services
                                          ) {
                                            return _c(
                                              "span",
                                              { key: services },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.camelize(
                                                        _vm.$t(services)
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _c("br")
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    item.platforms
                                      ? _c(
                                          "td",
                                          _vm._l(item.platforms, function(
                                            platform
                                          ) {
                                            return _c(
                                              "span",
                                              { key: platform },
                                              [
                                                platform.service &&
                                                platform.service.name
                                                  ? _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.camelize(
                                                            _vm.$t(
                                                              platform.service
                                                                .name
                                                            )
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c("br")
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    item.status
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(_vm.$t(item.status)) +
                                              "\n                                        "
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("td", [
                                      item.logoURL && item.logoURL != "http://"
                                        ? _c("span", [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "" +
                                                  _vm.domain +
                                                  item.logoURL,
                                                alt: "image",
                                                height: "60",
                                                width: "60"
                                              }
                                            })
                                          ])
                                        : _c("span", [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("str.no.images"))
                                              )
                                            ]),
                                            _c("br")
                                          ])
                                    ])
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirmation-return", 0)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("string.msg.term.btn.exit")))]
              )
            ])
          ])
        ]
      ),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }