<script>
import Endpoints from '../../components/common/Endpoints';
import Common from '../../components/common/Common';
import Request from '../../components/common/Request';

export default {
    init: (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

        payload.searchUsers();
    },
    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;
            let state = this;
 
            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.data, `${Endpoints.credencials.credencial}${this.data._id ? this.data._id : ''}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                        text: this.$t('str.register.credential.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                            location.reload();
                    });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
        },
        searchUsers: function(){
            let state = this;
            state.users = [];

            Request.do(state, 'POST', Request.getDefaultHeader(state),state.filters, `${Endpoints.systemUsers.companyUser}`,
            (response)=>{//SUCCESS CALLBACK
                state.users = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        onChange(item){
            this.userId = item._id;
            if(item.companyUser.company){
                this.company = JSON.parse(JSON.stringify(item.companyUser.company));
            }
        },
        checkForm: function (e) {
            this.errors = [];

            if (!this.userId) {
                this.errors.push(this.$t('response.credential.register.system.user.required'));
            }
            if (!this.data.user) {
                this.errors.push(this.$t('response.credential.register.user.required'));
            }
            // if (!this.data.pass) {
            //     this.errors.push(this.$t('response.credential.register.pass.required'));
            // }
             if (!this.data.type) {
                this.errors.push(this.$t('response.credential.register.type.required'));
            }

            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.data.company = this.data.systemUser.companyUser.company_id;
                this.data.systemUser = this.userId;
                this.save();
            }
        },
        removeCredential: function(response){
            if (response==1){
                $('#spinner').modal('toggle');
                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), null, `${Endpoints.credencials.credencial}${this.data._id}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                         this.$fire({
                            text: this.$t('str.delete.credential.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                location.reload();
                        });
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }
                            $('#toast').modal('show');
                        }
                        console.log(JSON.stringify(error));
                });
            }
        },
        confirmRemove: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("str.wanna.archive.credential.label");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationModal').modal('show');
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.service}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        showModalCredential(){
        
            $('#modalCredential').modal('show');
        },
        selectItem: function(item){

            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));

            this.userId = this.data.systemUser._id;

            if(this.data.systemUser && this.data.systemUser.companyUser && this.data.systemUser.companyUser.company){
                this.company = this.data.systemUser.companyUser.company;
            }

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        clearForm: function(){
          this.company = null;
          this.data = JSON.parse(JSON.stringify({
                name: "MAIN",
                type: null,
                user: null,
                pass: null,
                accessURL: null,
                systemUser: null,
                authorizationToken: null,
                company: null,
                status: 'ACTIVE'
            }))
        },
         camelize: Common.camelize,
         toUpperCase: Common.toUpperCase,
    }
}
</script>
