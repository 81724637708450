<script>
import Endpoints from '../../components/common/Endpoints';
import Common from '../../components/common/Common';
import Request from '../../components/common/Request';

import { mapActions } from 'vuex';

export default {
    init: async(payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

      
    },
    methods:{
    }
 
}
</script>
