var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.company.registration.label.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("form", [
                    _c("div", { staticClass: "card-box" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "nav nav-tabs nav-bordered nav-justified"
                        },
                        [
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link active",
                                attrs: {
                                  href: "#company",
                                  "data-toggle": "tab",
                                  "aria-expanded": "true"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.register.label"
                                      )
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#plans",
                                  "data-toggle": "tab",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(_vm.$t("string.msg.plans.label")) +
                                    "\n                                        "
                                )
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#sponsors",
                                  "data-toggle": "tab",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.sponsors.label")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#platforms",
                                  "data-toggle": "tab",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.technical.data")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            )
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#additionalUsers",
                                  "data-toggle": "tab",
                                  "aria-expanded": "false"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.additional.ser")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "tab-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane active",
                            attrs: { id: "company" }
                          },
                          [
                            _c("h4", { staticClass: "page-title" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.data.company.label")
                                  )
                                )
                              ])
                            ]),
                            _vm.data._id
                              ? _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("h6", { staticClass: "text-right" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.ratinng.label.status"
                                            )
                                          ) + ":"
                                        )
                                      ]),
                                      _vm.data.status == "ACTIVE"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "green" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.$t(_vm.data.status)
                                                  ) +
                                                  " \n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.data.status == "ARCHIVED"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "blue" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.$t(_vm.data.status)
                                                  ) +
                                                  " \n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.data._id &&
                            _vm.data.status != "ARCHIVED" &&
                            _vm.isAdminMaster()
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-2" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "text-dark-gray btn btn-warning w-100",
                                          on: { click: _vm.confirmArchive }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.status.archive"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.fantasyName"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.fantasyName,
                                        expression: "data.fantasyName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.fantasyName"
                                      )
                                    },
                                    domProps: { value: _vm.data.fantasyName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "fantasyName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.socialName"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.socialName,
                                        expression: "data.socialName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.socialName"
                                      )
                                    },
                                    domProps: { value: _vm.data.socialName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "socialName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.document"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "###.###.###-##",
                                          "##.###.###/####-##"
                                        ],
                                        expression:
                                          "['###.###.###-##', '##.###.###/####-##']"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.document,
                                        expression: "data.document"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "tel",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.document"
                                      )
                                    },
                                    domProps: { value: _vm.data.document },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "document",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      !_vm.data._id
                                        ? _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.primaryphone.whatsapp"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.primaryPhone,
                                        expression: "data.primaryPhone"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "(##) ####-####",
                                          "(##) #####-####"
                                        ],
                                        expression:
                                          "['(##) ####-####', '(##) #####-####']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.primaryPhone"
                                      )
                                    },
                                    domProps: { value: _vm.data.primaryPhone },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "primaryPhone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.table.label.type"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.typeService[0],
                                          expression: "data.typeService[0]"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "typeService" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.data.typeService,
                                            0,
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: [] }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.select.selector.item"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.companyTypes, function(item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item,
                                            domProps: { value: item._id }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.camelize(_vm.$t(item.name))
                                              )
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.company.address")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-2" },
                                [
                                  _c("label", [
                                    _c(
                                      "code",
                                      { staticClass: "highlighter-rouge" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                                    " +
                                        _vm._s(
                                          _vm.$t("string.msg.company.label.cep")
                                        ) +
                                        "\n                                                "
                                    )
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "#####-###",
                                        expression: "'#####-###'"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.address.cep,
                                        expression: "data.address.cep"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "tel",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.cep"
                                      )
                                    },
                                    domProps: { value: _vm.data.address.cep },
                                    on: {
                                      blur: _vm.loadInfosByCEP,
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.address,
                                          "cep",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm.data.address.cep &&
                            _vm.data.address.cep.length == 9
                              ? _c("div", { staticClass: "form-row " }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c("label", [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.state"
                                              )
                                            ) +
                                            "    \n                                                "
                                        )
                                      ]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.address.state,
                                              expression: "data.address.state"
                                            }
                                          ],
                                          staticClass: "form-control select2",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.data.address,
                                                "state",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: { selected: "" },
                                              domProps: { value: -1 }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.element.select.selectitem"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "AC" } },
                                            [_vm._v("AC")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "AL" } },
                                            [_vm._v("AL")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "AP" } },
                                            [_vm._v("AP")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "AM" } },
                                            [_vm._v("AM")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "BA" } },
                                            [_vm._v("BA")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "CE" } },
                                            [_vm._v("CE")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "DF" } },
                                            [_vm._v("DF")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "ES" } },
                                            [_vm._v("ES")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "GO" } },
                                            [_vm._v("GO")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "MA" } },
                                            [_vm._v("MA")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "MT" } },
                                            [_vm._v("MT")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "MS" } },
                                            [_vm._v("MS")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "MG" } },
                                            [_vm._v("MG")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "PA" } },
                                            [_vm._v("PA")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "PB" } },
                                            [_vm._v("PB")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "PR" } },
                                            [_vm._v("PR")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "PE" } },
                                            [_vm._v("PE")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "PI" } },
                                            [_vm._v("PI")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "RJ" } },
                                            [_vm._v("RJ")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "RN" } },
                                            [_vm._v("RN")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "RS" } },
                                            [_vm._v("RS")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "RO" } },
                                            [_vm._v("RO")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "RR" } },
                                            [_vm._v("RR")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "SC" } },
                                            [_vm._v("SC")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "SP" } },
                                            [_vm._v("SP")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "SE" } },
                                            [_vm._v("SE")]
                                          ),
                                          _c(
                                            "option",
                                            { attrs: { value: "TO" } },
                                            [_vm._v("TO")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-sm-4" }, [
                                    _c("label", [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.city"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.address.city,
                                          expression: "data.address.city"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "string.msg.company.label.city"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.data.address.city
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data.address,
                                            "city",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-sm-4" },
                                    [
                                      _c("label", [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.street"
                                              )
                                            ) +
                                            "\n                                                "
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.data.address.address,
                                            expression: "data.address.address"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.label.street"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.data.address.address
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.data.address,
                                              "address",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.data.address.cep &&
                            _vm.data.address.cep.length == 9
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c("label", [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.number"
                                              )
                                            ) +
                                            "\n                                                "
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.data.address.number,
                                            expression: "data.address.number"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.label.number"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.data.address.number
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.data.address,
                                              "number",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c("label", [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.neighborhood"
                                              )
                                            ) +
                                            "\n                                                "
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.data.address.neighborhood,
                                            expression:
                                              "data.address.neighborhood"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.label.neighborhood"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.data.address.neighborhood
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.data.address,
                                              "neighborhood",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.complement"
                                              )
                                            ) +
                                            "\n                                                "
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.data.address.complement,
                                            expression:
                                              "data.address.complement"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.label.complement"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.data.address.complement
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.data.address,
                                              "complement",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "label",
                              {
                                staticClass: "mt-2 col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.representative.user"
                                      )
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.user.label.name")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.representative.name,
                                        expression: "data.representative.name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.name"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.representative.name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.representative,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.user.label.email"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.representative.email,
                                        expression: "data.representative.email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.email"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.representative.email
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.representative,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.company.user.cpf")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.representative.cpf,
                                        expression: "data.representative.cpf"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["###.###.###-##"],
                                        expression: "['###.###.###-##']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.company.user.cpf"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.representative.cpf
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.representative,
                                          "cpf",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.primaryphone.whatsapp"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.data.representative.primaryPhone,
                                        expression:
                                          "data.representative.primaryPhone"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "(##) ####-####",
                                          "(##) #####-####"
                                        ],
                                        expression:
                                          "['(##) ####-####', '(##) #####-####']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.primaryPhone"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.data.representative.primaryPhone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.representative,
                                          "primaryPhone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "mt-2 col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.company.witness.user")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.user.label.name")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.witness.name,
                                        expression: "data.witness.name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.name"
                                      )
                                    },
                                    domProps: { value: _vm.data.witness.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.witness,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.user.label.email"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.witness.email,
                                        expression: "data.witness.email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.email"
                                      )
                                    },
                                    domProps: { value: _vm.data.witness.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.witness,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.company.user.cpf")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.witness.cpf,
                                        expression: "data.witness.cpf"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["###.###.###-##"],
                                        expression: "['###.###.###-##']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.company.user.cpf"
                                      )
                                    },
                                    domProps: { value: _vm.data.witness.cpf },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.witness,
                                          "cpf",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "mt-2 col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.financial.user"
                                      )
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.user.label.name")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.financialContact.name,
                                        expression: "data.financialContact.name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.name"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.financialContact.name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.financialContact,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.user.label.email"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.financialContact.email,
                                        expression:
                                          "data.financialContact.email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.user.label.email"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.financialContact.email
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.financialContact,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.primaryphone.whatsapp"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.data.financialContact
                                            .primaryPhone,
                                        expression:
                                          "data.financialContact.primaryPhone"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "(##) ####-####",
                                          "(##) #####-####"
                                        ],
                                        expression:
                                          "['(##) ####-####', '(##) #####-####']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.placeholder.primaryPhone"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.data.financialContact.primaryPhone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data.financialContact,
                                          "primaryPhone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-row text-center mt-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary w-50",
                                        on: { click: _vm.clearForm }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.form.btn.clear")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger w-50",
                                        on: { click: _vm.checkForm }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.form.btn.save")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "tab-pane", attrs: { id: "plans" } },
                          [
                            _c("h4", { staticClass: "page-title" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.data.company.plans.label"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.plans.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.plans[0],
                                          expression: "data.plans[0]"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "plans" },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.data.plans,
                                              0,
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            return _vm.onChangePlans()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: [] }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.select.selector.item"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.plans, function(item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item,
                                            domProps: { value: item }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.camelize(item.name))
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _vm.data.plans && _vm.data.plans.length > 0
                              ? _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-centered table-hover mb-0",
                                      attrs: { id: "datatable" }
                                    },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.plans.label.name"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.plans.setup.price"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.plans.monthy.price"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _vm._l(_vm.data.plans, function(item) {
                                        return _c(
                                          "tbody",
                                          {
                                            key: item._id,
                                            staticClass: "cursor-pointer"
                                          },
                                          [
                                            _c("tr", [
                                              _c("td", [
                                                item.name
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                                                " +
                                                          _vm._s(
                                                            _vm.camelize(
                                                              item.name
                                                            )
                                                          ) +
                                                          "\n                                                            "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]),
                                              _c("td", [
                                                item.setupPrice
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                                                " +
                                                          _vm._s(
                                                            "R$ " +
                                                              item.setupPrice.toFixed(
                                                                2
                                                              )
                                                          ) +
                                                          "\n                                                            "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]),
                                              _c("td", [
                                                item.monthlyPrice
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                                                " +
                                                          _vm._s(
                                                            "R$ " +
                                                              item.monthlyPrice.toFixed(
                                                                2
                                                              )
                                                          ) +
                                                          "\n                                                            "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ])
                                            ])
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.additional.resources.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.AdditionalResources,
                                      placeholder: "Selecione",
                                      deselectLabel: "Pressione para remover",
                                      "close-on-select": true,
                                      selectLabel: "Pressione para selecionar",
                                      selectedLabel: "Selecionado",
                                      multiple: true,
                                      "track-by": "name",
                                      "custom-label":
                                        _vm.customLabelAdditionalResources,
                                      "show-labels": false
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.onChangePlans()
                                      }
                                    },
                                    model: {
                                      value: _vm.data.additionalResources,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "additionalResources",
                                          $$v
                                        )
                                      },
                                      expression: "data.additionalResources"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm.data.additionalResources &&
                            _vm.data.additionalResources.length > 0
                              ? _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-centered table-hover mb-0",
                                      attrs: { id: "datatable" }
                                    },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.additionalResources.label.name"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.additionalResources.label.platform"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.additionalResources.label.features"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "border-top-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.additionalResources.label.price"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _vm._l(
                                        _vm.data.additionalResources,
                                        function(item) {
                                          return _c(
                                            "tbody",
                                            {
                                              key: item._id,
                                              staticClass: "cursor-pointer"
                                            },
                                            [
                                              _c("tr", [
                                                _c("td", [
                                                  item.name
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              _vm.camelize(
                                                                item.name
                                                              )
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]),
                                                _c("td", [
                                                  item.platform
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              _vm.camelize(
                                                                item.platform
                                                                  .name
                                                              )
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]),
                                                _c("td", [
                                                  item.features
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              item.features
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]),
                                                _c("td", [
                                                  item.price
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                                " +
                                                            _vm._s(
                                                              "R$ " +
                                                                item.price.toFixed(
                                                                  2
                                                                )
                                                            ) +
                                                            "\n                                                            "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ])
                                              ])
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.additional.users.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.userTypes,
                                      placeholder: "Selecione",
                                      deselectLabel: "Pressione para remover",
                                      "close-on-select": true,
                                      selectLabel: "Pressione para selecionar",
                                      selectedLabel: "Selecionado",
                                      multiple: true,
                                      "track-by": "name",
                                      "custom-label": _vm.customLabelUserTypes,
                                      "show-labels": false
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.onChangePlans()
                                      }
                                    },
                                    model: {
                                      value: _vm.data.additionalUsers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "additionalUsers",
                                          $$v
                                        )
                                      },
                                      expression: "data.additionalUsers"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm.data.additionalUsers &&
                            _vm.data.additionalUsers.length > 0
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-centered table-hover mb-0",
                                          attrs: { id: "datatable" }
                                        },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "border-top-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.additional.label.name"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "border-top-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.additional.users.price"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _vm._l(
                                            _vm.data.additionalUsers,
                                            function(item) {
                                              return _c(
                                                "tbody",
                                                {
                                                  key: item._id,
                                                  staticClass: "cursor-pointer"
                                                },
                                                [
                                                  _c("tr", [
                                                    _c("td", [
                                                      item.name
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                                                    " +
                                                                _vm._s(
                                                                  _vm.camelize(
                                                                    item.name
                                                                  )
                                                                ) +
                                                                "\n                                                                "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]),
                                                    item.price
                                                      ? _c("td", [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                "R$ " +
                                                                  item.price.toFixed(
                                                                    2
                                                                  )
                                                              ) +
                                                              "\n                                                            "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.company.billing.data"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _vm.otherPeriod == null || _vm.otherPeriod == 0
                              ? _c("div", { staticClass: "form-row  mt-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-6" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "col-form-label",
                                          attrs: { for: "inputAddress" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.contract.period"
                                                )
                                              ) +
                                              "\n                                                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.contractPeriod,
                                              expression: "data.contractPeriod"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { id: "periodContract" },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.data,
                                                "contractPeriod",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              attrs: { selected: "" },
                                              domProps: { value: null }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "string.msg.select.selector.item"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: 12 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("str.msg.month.12")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: 24 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("str.msg.month.24")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: 36 } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("str.msg.month.36")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.data.contractPeriod != 12 &&
                            _vm.data.contractPeriod != 24 &&
                            _vm.data.contractPeriod != 36
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-6" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "col-form-label",
                                          attrs: { for: "inputAddress" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.contract.period.other"
                                                )
                                              ) +
                                              "\n                                                "
                                          )
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.otherPeriod,
                                            expression: "otherPeriod"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##"],
                                            expression: "['##']"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.contract.period"
                                          )
                                        },
                                        domProps: { value: _vm.otherPeriod },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.otherPeriod =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("str.msg.menthly")) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.company.amount")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "amount",
                                      disabled: "",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.amount"
                                      )
                                    },
                                    domProps: { value: _vm.data.amount }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.discount"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.discount,
                                        expression: "data.discount"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "discount",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.discount"
                                      )
                                    },
                                    domProps: { value: _vm.data.discount },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "discount",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6 mt-4" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "text-dark-gray btn btn-warning w-20",
                                      on: {
                                        click: function($event) {
                                          return _vm.discountAmount(
                                            _vm.data.discount
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("string.msg.discount.btn")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.totalAmount"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "totalAmount",
                                      disabled: "",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.totalAmount"
                                      )
                                    },
                                    domProps: { value: _vm.data.totalAmount }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.company.dueDate")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.dueDate,
                                          expression: "data.dueDate"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.data,
                                            "dueDate",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.element.select.selectitem"
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._l(31, function(item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: item,
                                            domProps: { value: index + 1 }
                                          },
                                          [_vm._v(" " + _vm._s(index + 1))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _c(
                                    "code",
                                    { staticClass: "highlighter-rouge" },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t("string.msg.company.paymentType")
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.paymentType,
                                      expression: "data.paymentType"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "paymentType" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.data,
                                        "paymentType",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: null }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.select.selector.item"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.paymentTypes, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item,
                                        domProps: { value: item._id }
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item._id)))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("str.msg.setup")) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.company.amount")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "amountSetup",
                                      disabled: "",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.amount"
                                      )
                                    },
                                    domProps: { value: _vm.data.amountSetup }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.discount"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.discountSetup,
                                        expression: "data.discountSetup"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "discountSetup",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.discount"
                                      )
                                    },
                                    domProps: { value: _vm.data.discountSetup },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "discountSetup",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6 mt-4" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "text-dark-gray btn btn-warning w-20",
                                      on: {
                                        click: function($event) {
                                          return _vm.discountAmountSetup(
                                            _vm.data.discountSetup
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("string.msg.discount.btn")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.totalAmount"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "totalAmountSetup",
                                      disabled: "",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.totalAmount"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.totalAmountSetup
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("string.msg.company.dueDate")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.dueDateSetup,
                                          expression: "data.dueDateSetup"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.data,
                                            "dueDateSetup",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.element.select.selectitem"
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._l(31, function(item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: item,
                                            domProps: { value: index + 1 }
                                          },
                                          [_vm._v(" " + _vm._s(index + 1))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _c(
                                    "code",
                                    { staticClass: "highlighter-rouge" },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t("string.msg.company.paymentType")
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.paymentTypeSetup,
                                      expression: "data.paymentTypeSetup"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "paymentTypeSetup" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.data,
                                        "paymentTypeSetup",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: null }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.select.selector.item"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.paymentTypes, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item,
                                        domProps: { value: item._id }
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item._id)))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.input.value"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "money",
                                        rawName: "v-money",
                                        value: _vm.money,
                                        expression: "money"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "totalAmountSetup",
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.totalAmount"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.data.inputPriceSetup
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-group col-md-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _c(
                                    "code",
                                    { staticClass: "highlighter-rouge" },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.company.installments"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.numberInstallments,
                                      expression: "data.numberInstallments"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "numberInstallments" },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.data,
                                          "numberInstallments",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        return _vm.installmentsOfSetup(
                                          _vm.data.numberInstallments
                                        )
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: { value: null }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.select.selector.item"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("option", { domProps: { value: 1 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.1")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 2 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.2")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 3 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.3")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 4 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.4")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 5 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.5")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 6 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.6")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 7 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.7")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 8 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.8")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 9 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.9")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 10 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.10")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 11 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.11")
                                      )
                                    )
                                  ]),
                                  _c("option", { domProps: { value: 12 } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.installments.12")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _vm.data.numberInstallments &&
                            _vm.data.numberInstallments > 1
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-centered table-hover mb-0",
                                          attrs: { id: "datatable" }
                                        },
                                        [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "border-top-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.installments.table"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "border-top-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.additional.users.price"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _vm._l(
                                            _vm.data.valueInstallments,
                                            function(item, index) {
                                              return _c(
                                                "tbody",
                                                {
                                                  key: item,
                                                  staticClass: "cursor-pointer"
                                                },
                                                [
                                                  _c("tr", [
                                                    _c("td", [
                                                      item.number
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                                                    " +
                                                                _vm._s(
                                                                  item.number +
                                                                    "º"
                                                                ) +
                                                                "\n                                                                "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]),
                                                    _c("td", [
                                                      item.value
                                                        ? _c("span", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "money",
                                                                  rawName:
                                                                    "v-money",
                                                                  value:
                                                                    _vm.money,
                                                                  expression:
                                                                    "money"
                                                                }
                                                              ],
                                                              attrs: {
                                                                id: index,
                                                                disabled: ""
                                                              },
                                                              domProps: {
                                                                value:
                                                                  item.value
                                                              }
                                                            })
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  ])
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "form-row text-center mt-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger w-30",
                                        on: { click: _vm.checkForm }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.form.btn.save")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "sponsors" }
                          },
                          [
                            _c("h4", { staticClass: "page-title" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.list.sponsors.label")
                                  )
                                )
                              ])
                            ]),
                            _c("form", { staticClass: "mt-2" }, [
                              _vm.sponsor._id
                                ? _c(
                                    "div",
                                    { staticClass: "form-group col-md-12" },
                                    [
                                      _c("h6", { staticClass: "text-right" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.ratinng.label.status"
                                              )
                                            ) + ":"
                                          )
                                        ]),
                                        _vm.sponsor.status == "ACTIVE"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: { color: "green" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t(_vm.sponsor.status)
                                                    ) +
                                                    " \n                                                    "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.sponsor.status == "ARCHIVED"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: { color: "blue" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t(_vm.sponsor.status)
                                                    ) +
                                                    " \n                                                    "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.sponsor._id &&
                              _vm.sponsor.status != "ARCHIVED" &&
                              _vm.isAdminMaster()
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "text-dark-gray btn btn-warning w-100",
                                            on: {
                                              click: _vm.confirmArchiveSponsor
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.status.archive"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.fantasyName"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.fantasyName,
                                          expression: "sponsor.fantasyName"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "string.msg.company.placeholder.fantasyName"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.fantasyName
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "fantasyName",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.socialName"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.socialName,
                                          expression: "sponsor.socialName"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "string.msg.company.placeholder.socialName"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.socialName
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "socialName",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.document"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: [
                                            "###.###.###-##",
                                            "##.###.###/####-##"
                                          ],
                                          expression:
                                            "['###.###.###-##', '##.###.###/####-##']"
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.document,
                                          expression: "sponsor.document"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "tel",
                                        placeholder: _vm.$t(
                                          "string.msg.company.placeholder.document"
                                        )
                                      },
                                      domProps: { value: _vm.sponsor.document },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "document",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        !_vm.sponsor._id
                                          ? _c(
                                              "code",
                                              {
                                                staticClass: "highlighter-rouge"
                                              },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t("str.msg.primaryPhone")
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.primaryPhone,
                                          expression: "sponsor.primaryPhone"
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: [
                                            "(##) ####-####",
                                            "(##) #####-####"
                                          ],
                                          expression:
                                            "['(##) ####-####', '(##) #####-####']"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "str.msg.primaryPhone"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.primaryPhone
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "primaryPhone",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.responsible"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.responsible,
                                          expression: "sponsor.responsible"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "string.msg.company.label.responsible"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.responsible
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "responsible",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-form-label",
                                        attrs: { for: "inputAddress" }
                                      },
                                      [
                                        !_vm.sponsor._id
                                          ? _c(
                                              "code",
                                              {
                                                staticClass: "highlighter-rouge"
                                              },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.primaryphone.whatsapp"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.secundaryPhone,
                                          expression: "sponsor.secundaryPhone"
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: [
                                            "(##) ####-####",
                                            "(##) #####-####"
                                          ],
                                          expression:
                                            "['(##) ####-####', '(##) #####-####']"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "string.msg.company.label.primaryphone.whatsapp"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.secundaryPhone
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor,
                                            "secundaryPhone",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(_vm.$t("string.msg.address")) +
                                      "\n                                            "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-2" },
                                  [
                                    _c("label", [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.cep"
                                            )
                                          ) +
                                          "\n                                                    "
                                      )
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "#####-###",
                                          expression: "'#####-###'"
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sponsor.address.cep,
                                          expression: "sponsor.address.cep"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "tel",
                                        placeholder: _vm.$t(
                                          "string.msg.company.label.cep"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.sponsor.address.cep
                                      },
                                      on: {
                                        blur: _vm.loadInfosByCEPSponsor,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.sponsor.address,
                                            "cep",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm.sponsor.address.cep &&
                              _vm.sponsor.address.cep.length == 9
                                ? _c("div", { staticClass: "form-row " }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-4" },
                                      [
                                        _c("label", [
                                          _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.label.state"
                                                )
                                              ) +
                                              "    \n                                                    "
                                          )
                                        ]),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.sponsor.address.state,
                                                expression:
                                                  "sponsor.address.state"
                                              }
                                            ],
                                            staticClass: "form-control select2",
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.sponsor.address,
                                                  "state",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: { selected: "" },
                                                domProps: { value: -1 }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "string.msg.element.select.selectitem"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "AC" } },
                                              [_vm._v("AC")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "AL" } },
                                              [_vm._v("AL")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "AP" } },
                                              [_vm._v("AP")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "AM" } },
                                              [_vm._v("AM")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "BA" } },
                                              [_vm._v("BA")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "CE" } },
                                              [_vm._v("CE")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "DF" } },
                                              [_vm._v("DF")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "ES" } },
                                              [_vm._v("ES")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "GO" } },
                                              [_vm._v("GO")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "MA" } },
                                              [_vm._v("MA")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "MT" } },
                                              [_vm._v("MT")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "MS" } },
                                              [_vm._v("MS")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "MG" } },
                                              [_vm._v("MG")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "PA" } },
                                              [_vm._v("PA")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "PB" } },
                                              [_vm._v("PB")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "PR" } },
                                              [_vm._v("PR")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "PE" } },
                                              [_vm._v("PE")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "PI" } },
                                              [_vm._v("PI")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "RJ" } },
                                              [_vm._v("RJ")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "RN" } },
                                              [_vm._v("RN")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "RS" } },
                                              [_vm._v("RS")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "RO" } },
                                              [_vm._v("RO")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "RR" } },
                                              [_vm._v("RR")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "SC" } },
                                              [_vm._v("SC")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "SP" } },
                                              [_vm._v("SP")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "SE" } },
                                              [_vm._v("SE")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "TO" } },
                                              [_vm._v("TO")]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-sm-4" }, [
                                      _c("label", [
                                        _c(
                                          "code",
                                          { staticClass: "highlighter-rouge" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.company.label.city"
                                              )
                                            ) +
                                            "\n                                                    "
                                        )
                                      ]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.sponsor.address.city,
                                            expression: "sponsor.address.city"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "string.msg.company.label.city"
                                          )
                                        },
                                        domProps: {
                                          value: _vm.sponsor.address.city
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.sponsor.address,
                                              "city",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-sm-4" },
                                      [
                                        _c("label", [
                                          _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.label.street"
                                                )
                                              ) +
                                              "\n                                                    "
                                          )
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.sponsor.address.address,
                                              expression:
                                                "sponsor.address.address"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "string.msg.company.label.street"
                                            )
                                          },
                                          domProps: {
                                            value: _vm.sponsor.address.address
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.sponsor.address,
                                                "address",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.sponsor.address.cep &&
                              _vm.sponsor.address.cep.length == 9
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-4" },
                                      [
                                        _c("label", [
                                          _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.label.number"
                                                )
                                              ) +
                                              "\n                                                    "
                                          )
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sponsor.address.number,
                                              expression:
                                                "sponsor.address.number"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "string.msg.company.label.number"
                                            )
                                          },
                                          domProps: {
                                            value: _vm.sponsor.address.number
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.sponsor.address,
                                                "number",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-4" },
                                      [
                                        _c("label", [
                                          _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.label.neighborhood"
                                                )
                                              ) +
                                              "\n                                                    "
                                          )
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.sponsor.address
                                                  .neighborhood,
                                              expression:
                                                "sponsor.address.neighborhood"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "string.msg.company.label.neighborhood"
                                            )
                                          },
                                          domProps: {
                                            value:
                                              _vm.sponsor.address.neighborhood
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.sponsor.address,
                                                "neighborhood",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-4" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            "\n                                                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.company.label.complement"
                                                )
                                              ) +
                                              "\n                                                    "
                                          )
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.sponsor.address.complement,
                                              expression:
                                                "sponsor.address.complement"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "string.msg.company.label.complement"
                                            )
                                          },
                                          domProps: {
                                            value:
                                              _vm.sponsor.address.complement
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.sponsor.address,
                                                "complement",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "form-row text-center mt-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-6" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-secondary w-50",
                                          on: { click: _vm.clearSponsor }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.form.btn.clear"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-6" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger w-50",
                                          on: { click: _vm.checkFormSponsor }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("string.msg.form.btn.save")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.sponsors && _vm.sponsors.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "table-responsive mt-4" },
                                    [
                                      _c("p", { staticClass: "sub-header" }, [
                                        _vm._v(
                                          "\n                                                    " +
                                            _vm._s(
                                              _vm.$t("string.msg.list.sponsors")
                                            ) +
                                            "\n                                                "
                                        )
                                      ]),
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-centered table-hover mb-0"
                                        },
                                        [
                                          _c(
                                            "thead",
                                            { staticClass: "thead-dark" },
                                            [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.sponsor.name"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.sponsor.primaryPhone"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.sponsor.responsible"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.sponsor.secundaryPhone"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.sponsor.status"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._l(_vm.sponsors, function(item) {
                                            return _c(
                                              "tbody",
                                              {
                                                key: item._id,
                                                staticClass: "cursor-pointer"
                                              },
                                              [
                                                item.status == "ACTIVE"
                                                  ? _c(
                                                      "tr",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectItemSponsor(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("td", [
                                                          item.fantasyName
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                                                                        " +
                                                                    _vm._s(
                                                                      _vm.camelize(
                                                                        item.fantasyName
                                                                      )
                                                                    ) +
                                                                    "\n                                                                    "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]),
                                                        _c("td", [
                                                          item.primaryPhone
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                                                                        " +
                                                                    _vm._s(
                                                                      item.primaryPhone
                                                                    ) +
                                                                    "\n                                                                    "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]),
                                                        _c("td", [
                                                          item.responsible
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                                                                        " +
                                                                    _vm._s(
                                                                      _vm.camelize(
                                                                        item.responsible
                                                                      )
                                                                    ) +
                                                                    "\n                                                                    "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]),
                                                        _c("td", [
                                                          item.secundaryPhone
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                                                                        " +
                                                                    _vm._s(
                                                                      item.secundaryPhone
                                                                    ) +
                                                                    "\n                                                                    "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]),
                                                        _c("td", [
                                                          item.status
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "\n                                                                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        item.status
                                                                      )
                                                                    ) +
                                                                    "\n                                                                    "
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "platforms" }
                          },
                          [
                            _c("h4", { staticClass: "page-title" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.list.techinical.data.label"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "form-row mt-2" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "string.msg.company.visual.identity.data"
                                        )
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.brand/coat"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    ref: "file",
                                    staticClass: "form-control",
                                    attrs: { type: "file", id: "logo" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleFileUpload()
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group col-md-6 text-center"
                                },
                                [
                                  _vm.addInfo.logoURL
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            "" +
                                            _vm.domain +
                                            _vm.addInfo.logoURL,
                                          alt: "image",
                                          height: "120",
                                          width: "150"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _c("form", { attrs: { action: "#" } }, [
                                _c("div", { staticClass: "form-group mb-0" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                        " +
                                          _vm._s(_vm.$t("str.primary.color")) +
                                          "\n                                                     "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.colorPaletteOne,
                                        expression: "addInfo.colorPaletteOne"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["XXXXXX"],
                                        expression: "['XXXXXX']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.colorPaletteOne
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.addInfo,
                                            "colorPaletteOne",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.changePaletteOne(
                                            _vm.addInfo.colorPaletteOne
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("div", { ref: "box1", staticClass: "box" })
                                ]),
                                _c("div", { staticClass: "form-group mb-0" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                        " +
                                          _vm._s(
                                            _vm.$t("str.secondary.color")
                                          ) +
                                          "\n                                                     "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.colorPaletteTwo,
                                        expression: "addInfo.colorPaletteTwo"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["XXXXXX"],
                                        expression: "['XXXXXX']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.colorPaletteTwo
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.addInfo,
                                            "colorPaletteTwo",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.changePaletteTwo(
                                            _vm.addInfo.colorPaletteTwo
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("div", { ref: "box2", staticClass: "box" })
                                ]),
                                _c("div", { staticClass: "form-group mb-4" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                        " +
                                          _vm._s(
                                            _vm.$t("str.terciaria.color")
                                          ) +
                                          "\n                                                     "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.colorPaletteThree,
                                        expression: "addInfo.colorPaletteThree"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["XXXXXX"],
                                        expression: "['XXXXXX']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.colorPaletteThree
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.addInfo,
                                            "colorPaletteThree",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.changePaletteThree(
                                            _vm.addInfo.colorPaletteThree
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("div", { ref: "box3", staticClass: "box" })
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "form-row mt-2" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { for: "inputAddress" }
                                },
                                [
                                  _vm._v(
                                    "\n                                                " +
                                      _vm._s(
                                        _vm.$t("str.msg.company.social.media")
                                      ) +
                                      "\n                                            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.instagram"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.instagramURL,
                                        expression: "addInfo.instagramURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.instagram.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.instagramURL
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "instagramURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.facebook"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.facebookURL,
                                        expression: "addInfo.facebookURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.facebook.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.facebookURL
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "facebookURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.twitter"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.twitterURL,
                                        expression: "addInfo.twitterURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.twitter.placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.addInfo.twitterURL },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "twitterURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.youtube"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.youtubeURL,
                                        expression: "addInfo.youtubeURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.youtube.placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.addInfo.youtubeURL },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "youtubeURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.googlebusiness"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.googleBusinessURL,
                                        expression: "addInfo.googleBusinessURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.googlebusiness.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.googleBusinessURL
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "googleBusinessURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.whatsapp"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.whatsApp,
                                        expression: "addInfo.whatsApp"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "(##) ####-####",
                                          "(##) #####-####"
                                        ],
                                        expression:
                                          "['(##) ####-####', '(##) #####-####']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.whatsapp.placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.addInfo.whatsApp },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "whatsApp",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.label.telegram"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.telegram,
                                        expression: "addInfo.telegram"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "(##) ####-####",
                                          "(##) #####-####"
                                        ],
                                        expression:
                                          "['(##) ####-####', '(##) #####-####']"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.telegram.placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.addInfo.telegram },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "telegram",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.company.external.integration.data"
                                      )
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.facebookAds"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.facebookAds.user,
                                        expression: "addInfo.facebookAds.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.facebookAds.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.facebookAds,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.facebookAds.password,
                                        expression:
                                          "addInfo.facebookAds.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.facebookAds.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.facebookAds,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.googleAds"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.googleAds.user,
                                        expression: "addInfo.googleAds.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.googleAds.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.googleAds,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.googleAds.password,
                                        expression: "addInfo.googleAds.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.googleAds.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.googleAds,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.googleAnalytics"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.googleAnalytics.user,
                                        expression:
                                          "addInfo.googleAnalytics.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.googleAnalytics.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.googleAnalytics,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addInfo.googleAnalytics.password,
                                        expression:
                                          "addInfo.googleAnalytics.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.addInfo.googleAnalytics.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.googleAnalytics,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.RDStation"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.RDStation.user,
                                        expression: "addInfo.RDStation.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.RDStation.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.RDStation,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.RDStation.password,
                                        expression: "addInfo.RDStation.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.RDStation.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.RDStation,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.streamingRadio"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.streamingRadio.user,
                                        expression:
                                          "addInfo.streamingRadio.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.streamingRadio.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.streamingRadio,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addInfo.streamingRadio.password,
                                        expression:
                                          "addInfo.streamingRadio.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.streamingRadio.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.streamingRadio,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.podcast"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.podcast.user,
                                        expression: "addInfo.podcast.user"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.podcast.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.podcast,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.podcast.password,
                                        expression: "addInfo.podcast.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.password.placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.podcast.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.podcast,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.company.site.data")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row mt-0" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.template.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.templatesSite,
                                      placeholder: "Selecione",
                                      deselectLabel: "Pressione para remover",
                                      "close-on-select": true,
                                      selectLabel: "Pressione para selecionar",
                                      selectedLabel: "Selecionado",
                                      "track-by": "_id",
                                      "custom-label": _vm.customLabelTemplates,
                                      "show-labels": false
                                    },
                                    model: {
                                      value: _vm.addInfo.templateSite,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addInfo,
                                          "templateSite",
                                          $$v
                                        )
                                      },
                                      expression: "addInfo.templateSite"
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "str.msg.config.adjustment.template.site"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.obsTemplateSite,
                                        expression: "addInfo.obsTemplateSite"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { height: "120px" },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.obsTemplateSite
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "obsTemplateSite",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "form-row mt-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.url.domain.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.domainData.url,
                                        expression: "domainData.url"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.url.domain.label"
                                      )
                                    },
                                    domProps: { value: _vm.domainData.url },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.domainData,
                                          "url",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "str.msg.register.local.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.domainData.registerLocal,
                                        expression: "domainData.registerLocal"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.register.local.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.domainData.registerLocal
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.domainData,
                                          "registerLocal",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("str.msg.user.label")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.domainData.user,
                                        expression: "domainData.user"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.msg.user.label")
                                    },
                                    domProps: { value: _vm.domainData.user },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.domainData,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.password.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.domainData.password,
                                        expression: "domainData.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.password.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.domainData.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.domainData,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "form-group col-md-12 text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-dark-gray btn btn-warning w-30 mt-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.addDomain(_vm.domainData)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("string.msg.add.item.btn"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.addInfo.domains
                              ? _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.list.domain.label.title"
                                            )
                                          ) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-centered table-hover mb-0",
                                      attrs: { id: "datatable" }
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "str.msg.url.domain.label"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "str.msg.register.local.label"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("str.msg.user.label")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "str.msg.password.label"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "string.msg.domain.table.label.remove"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._l(_vm.addInfo.domains, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "tbody",
                                          {
                                            key: item,
                                            staticClass: "cursor-pointer"
                                          },
                                          [
                                            item
                                              ? _c("tr", [
                                                  _c("td", [
                                                    item.url
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(item.url) +
                                                              "\n                                                            "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.registerLocal
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                item.registerLocal
                                                              ) +
                                                              "\n                                                            "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.user
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                item.user
                                                              ) +
                                                              "\n                                                            "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.password
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                " +
                                                              _vm._s(
                                                                item.password
                                                              ) +
                                                              "\n                                                            "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "text-dark-gray btn btn-warning w-3",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeDomain(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "string.msg.x"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ])
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(_vm.$t("str.msg.site.migration")) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.current.host.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addInfo.siteMigration.hostURL,
                                        expression:
                                          "addInfo.siteMigration.hostURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.current.host.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.siteMigration.hostURL
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.siteMigration,
                                          "hostURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("str.msg.user.label")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.siteMigration.user,
                                        expression: "addInfo.siteMigration.user"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.msg.user.label")
                                    },
                                    domProps: {
                                      value: _vm.addInfo.siteMigration.user
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.siteMigration,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.password.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addInfo.siteMigration.password,
                                        expression:
                                          "addInfo.siteMigration.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.password.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.siteMigration.password
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo.siteMigration,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.company.app.data")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.template.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.templatesApp,
                                      placeholder: "Selecione",
                                      deselectLabel: "Pressione para remover",
                                      "close-on-select": true,
                                      selectLabel: "Pressione para selecionar",
                                      selectedLabel: "Selecionado",
                                      "track-by": "_id",
                                      "custom-label": _vm.customLabelTemplates,
                                      "show-labels": false
                                    },
                                    model: {
                                      value: _vm.addInfo.templateApp,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addInfo,
                                          "templateApp",
                                          $$v
                                        )
                                      },
                                      expression: "addInfo.templateApp"
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "str.msg.config.adjustment.template.site"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.obsTemplateApp,
                                        expression: "addInfo.obsTemplateApp"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { height: "120px" },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.obsTemplateApp
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "obsTemplateApp",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("str.app.name.label")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.appName,
                                        expression: "addInfo.appName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.app.name.label")
                                    },
                                    domProps: { value: _vm.addInfo.appName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "appName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "str.msg.config.app.description.app"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.appDescription,
                                        expression: "addInfo.appDescription"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { height: "120px" },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addInfo.appDescription
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "appDescription",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-2",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("str.msg.apple.store.access")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("str.msg.user.label")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.appleStoreUser,
                                        expression: "addInfo.appleStoreUser"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.msg.user.label")
                                    },
                                    domProps: {
                                      value: _vm.addInfo.appleStoreUser
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "appleStoreUser",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.password.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.appleStorePassword,
                                        expression: "addInfo.appleStorePassword"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.password.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.addInfo.appleStorePassword
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "appleStorePassword",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("str.msg.email.marketing.label")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                   " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.crud.email.marketing"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addInfo.emailMarketing,
                                        expression: "addInfo.emailMarketing"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.email.access")
                                    },
                                    domProps: {
                                      value: _vm.addInfo.emailMarketing
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addInfo,
                                          "emailMarketing",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("string.msg.company.webmail.data")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("string.msg.email")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.webmail,
                                        expression: "webmail"
                                      }
                                    ],
                                    staticClass: "form-control mb-1",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.company.label.user.placeholder"
                                      )
                                    },
                                    domProps: { value: _vm.webmail },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.webmail = $event.target.value
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "form-group col-md-12 text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-dark-gray btn btn-warning w-30 mt-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.addEmail(_vm.webmail)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("string.msg.add.item.btn"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.addInfo.webmail
                              ? _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(
                                            _vm.$t("str.msg.account.emails")
                                          ) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-centered table-hover mb-0",
                                      attrs: { id: "datatable" }
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("string.msg.email")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "string.msg.domain.table.label.remove"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._l(_vm.addInfo.webmail, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "tbody",
                                          {
                                            key: item,
                                            staticClass: "cursor-pointer"
                                          },
                                          [
                                            item
                                              ? _c("tr", [
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                                                                " +
                                                          _vm._s(item) +
                                                          "\n                                                            "
                                                      )
                                                    ])
                                                  ]),
                                                  _c("td", [
                                                    _c("span", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "text-dark-gray btn btn-warning w-3",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeEmail(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "string.msg.x"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ])
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label mt-4",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.$t("str.msg.webmail.migration")
                                    ) +
                                    "\n                                        "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.current.host.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.webmailMigration.currentHost,
                                        expression:
                                          "webmailMigration.currentHost"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.current.host.label"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.webmailMigration.currentHost
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.webmailMigration,
                                          "currentHost",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(_vm.$t("str.msg.user.label")) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.webmailMigration.currentEmail,
                                        expression:
                                          "webmailMigration.currentEmail"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t("str.msg.user.label")
                                    },
                                    domProps: {
                                      value: _vm.webmailMigration.currentEmail
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.webmailMigration,
                                          "currentEmail",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t("str.msg.password.label")
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.webmailMigration.currentPassword,
                                        expression:
                                          "webmailMigration.currentPassword"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "str.msg.password.label"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.webmailMigration.currentPassword
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.webmailMigration,
                                          "currentPassword",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "form-group col-md-12 text-center"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-dark-gray btn btn-warning w-30 mt-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.addWebmailMigration(
                                          _vm.webmailMigration
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("string.msg.add.item.btn"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.addInfo.webmailMigration
                              ? _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(
                                            _vm.$t("str.msg.account.to.migrate")
                                          ) +
                                          "\n                                            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-centered table-hover mb-0",
                                      attrs: { id: "datatable" }
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "thead-dark" },
                                        [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "str.msg.current.host.label"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("str.msg.user.label")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "str.msg.password.label"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "border-top-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "string.msg.domain.table.label.remove"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.addInfo.webmailMigration,
                                        function(item, index) {
                                          return _c(
                                            "tbody",
                                            {
                                              key: item,
                                              staticClass: "cursor-pointer"
                                            },
                                            [
                                              item
                                                ? _c("tr", [
                                                    _c("td", [
                                                      item.currentHost
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  item.currentHost
                                                                ) +
                                                                "\n                                                            "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]),
                                                    _c("td", [
                                                      item.currentEmail
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  item.currentEmail
                                                                ) +
                                                                "\n                                                            "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]),
                                                    _c("td", [
                                                      item.currentPassword
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                                                " +
                                                                _vm._s(
                                                                  item.currentPassword
                                                                ) +
                                                                "\n                                                            "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]),
                                                    _c("td", [
                                                      _c("span", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "text-dark-gray btn btn-warning w-3",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.removeWebmailMigration(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "string.msg.x"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ])
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "form-row text-center mt-4 mb-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger w-30",
                                        on: { click: _vm.saveAddInfo }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.form.btn.save")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "additionalUsers" }
                          },
                          [
                            _c("h4", { staticClass: "page-title" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.user.data.platforms.label"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm.newData._id
                              ? _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("h6", { staticClass: "text-right" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.ratinng.label.status"
                                            )
                                          ) + ":"
                                        )
                                      ]),
                                      _vm.newData.status == "ACTIVE"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "green" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.$t(_vm.newData.status)
                                                  ) +
                                                  " \n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.newData.status == "ARCHIVED"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "blue" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.$t(_vm.newData.status)
                                                  ) +
                                                  " \n                                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.newData._id && _vm.newData.status != "ARCHIVED"
                              ? _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-2" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "text-dark-gray btn btn-warning w-100",
                                          on: { click: _vm.confirmUserArchive }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.status.archive"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.list.user.label.type"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.newData.companyUser.userType,
                                          expression:
                                            "newData.companyUser.userType"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "userType" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.newData.companyUser,
                                            "userType",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.select.selector.item"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.userTypes, function(item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item._id,
                                            domProps: { value: item._id }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.camelize(item.name))
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      !_vm.newData._id
                                        ? _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.form.crud.user.label.name"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newData.firstName,
                                        expression: "newData.firstName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.form.crud.user.placeholder.name"
                                      )
                                    },
                                    domProps: { value: _vm.newData.firstName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newData,
                                          "firstName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      !_vm.newData._id
                                        ? _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.form.crud.user.label.lastname"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newData.lastName,
                                        expression: "newData.lastName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.form.crud.user.placeholder.lastname"
                                      )
                                    },
                                    domProps: { value: _vm.newData.lastName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newData,
                                          "lastName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.form.crud.user.label.email"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newData.email,
                                        expression: "newData.email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "string.msg.form.crud.user.placeholder.email"
                                      )
                                    },
                                    domProps: { value: _vm.newData.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newData,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      !_vm.newData._id
                                        ? _c(
                                            "code",
                                            {
                                              staticClass: "highlighter-rouge"
                                            },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.form.crud.user.label.password"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newData.password,
                                        expression: "newData.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      placeholder: _vm.$t(
                                        "string.msg.form.crud.user.placeholder.password"
                                      )
                                    },
                                    domProps: { value: _vm.newData.password },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newData,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-row text-center mt-4" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary w-30",
                                        on: { click: _vm.clearUserForm }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.form.btn.clear")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "text-dark-gray btn btn-warning w-30",
                                        on: { click: _vm.checkUserForm }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("string.msg.save"))
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c("h4", { staticClass: "sub-header mt-4" }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(
                                    _vm.$t(
                                      "string.msg.list.user.register.credential.label"
                                    )
                                  ) +
                                  "\n                                        "
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.company.table.label.platform"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.credential.type,
                                          expression: "credential.type"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "credentialType" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.credential,
                                            "type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "" },
                                          domProps: { value: null }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "string.msg.select.selector.item"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.services, function(item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item,
                                            domProps: {
                                              value: item.whiteLabelSystem
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.camelize(
                                                  item.whiteLabelSystem
                                                    ? item.whiteLabelSystem
                                                    : item.name
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.platforms.label.accessURL"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.credential.accessURL,
                                        expression: "credential.accessURL"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.credential.accessURL
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.credential,
                                          "accessURL",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.menu.users.credential.label"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.credential.user,
                                        expression: "credential.user"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.credential.user },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.credential,
                                          "user",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-form-label",
                                      attrs: { for: "inputAddress" }
                                    },
                                    [
                                      _c(
                                        "code",
                                        { staticClass: "highlighter-rouge" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.platforms.label.pass"
                                            )
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.credential.pass,
                                        expression: "credential.pass"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.credential.pass },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.credential,
                                          "pass",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-row text-center mt-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-md-12 text-center"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "text-dark-gray btn btn-warning w-30",
                                        on: { click: _vm.checkFormCredential }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                " +
                                            _vm._s(
                                              _vm.credential._id
                                                ? "string.msg.add.credential.platform.btn.update"
                                                : _vm.$t(
                                                    "string.msg.add.credential.platform.btn.add"
                                                  )
                                            ) +
                                            "\n                                                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm.newData._id &&
                            _vm.credentials &&
                            _vm.credentials.length > 0
                              ? _c("div", { staticClass: "mt-4" }, [
                                  _c("h4", { staticClass: "sub-header" }, [
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.list.platforms.user.label"
                                          )
                                        ) +
                                        "\n                                            "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive mt-2" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-centered table-hover mb-0",
                                          attrs: { id: "datatable" }
                                        },
                                        [
                                          _c(
                                            "thead",
                                            { staticClass: "thead-dark" },
                                            [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "border-top-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.list.credential.label.type"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "border-top-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.list.credential.label.url"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "border-top-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.list.user.label.user"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "border-top-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.list.user.label.password"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "border-top-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "string.msg.table.list.user.label.status"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm.isAdminMaster()
                                                  ? _c("th", {
                                                      staticClass:
                                                        "border-top-0"
                                                    })
                                                  : _vm._e()
                                              ])
                                            ]
                                          ),
                                          _vm._l(_vm.credentials, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "tbody",
                                              {
                                                key: item._id,
                                                staticClass: "cursor-pointer",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectCredentialItem(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("tr", [
                                                  _c("td", [
                                                    item.type
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                    " +
                                                              _vm._s(
                                                                _vm.camelize(
                                                                  _vm.$t(
                                                                    item.type
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.accessURL
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                    " +
                                                              _vm._s(
                                                                item.accessURL
                                                              ) +
                                                              "\n                                                                "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.user
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                    " +
                                                              _vm._s(
                                                                item.user
                                                              ) +
                                                              "\n                                                                "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.pass
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                    " +
                                                              _vm._s(
                                                                item.pass
                                                              ) +
                                                              "\n                                                                "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("td", [
                                                    item.status
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                                    " +
                                                              _vm._s(
                                                                _vm.camelize(
                                                                  _vm.$t(
                                                                    item.status
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                                                                "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _vm.isAdminMaster()
                                                    ? _c("td", [
                                                        _c("span", [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-secondary w-30",
                                                              on: {
                                                                click:
                                                                  _vm.confirmRemoveCredential
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "string.msg.table.list.user.platforms.label.delete"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e()
                                                ])
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.users && _vm.users.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "table-responsive mt-4" },
                                  [
                                    _c("h4", { staticClass: "sub-header" }, [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "string.msg.list.company.user.label.users"
                                            )
                                          ) +
                                          "\n                                            "
                                      )
                                    ]),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-centered table-hover mb-0"
                                      },
                                      [
                                        _c(
                                          "thead",
                                          { staticClass: "thead-dark" },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.menu.crud.user.table.label.name"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.menu.crud.user.table.label.email"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "string.msg.user.user.type.label"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._l(_vm.users, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "tbody",
                                            {
                                              key: item._id,
                                              staticClass: "cursor-pointer"
                                            },
                                            [
                                              item.status == "ACTIVE"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectUserItem(
                                                            index,
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("td", [
                                                        item.firstName
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    _vm.toUpperCase(
                                                                      item.firstName
                                                                    ) + " "
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ])
                                                          : _vm._e(),
                                                        item.lastName
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    _vm.toUpperCase(
                                                                      item.lastName
                                                                    )
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]),
                                                      _c("td", [
                                                        item.email
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    item.email
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]),
                                                      _c("td", [
                                                        item.companyUser &&
                                                        item.companyUser
                                                          .userType &&
                                                        item.companyUser
                                                          .userType.name
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                                    " +
                                                                  _vm._s(
                                                                    _vm.camelize(
                                                                      item
                                                                        .companyUser
                                                                        .userType
                                                                        .name
                                                                    )
                                                                  ) +
                                                                  "\n                                                                "
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      }),
      _c("ConfirmationCredential", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.removeCredential }
      }),
      _c("ConfirmationUser", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archiveUser }
      }),
      _c("ConfirmationSponsor", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archiveSponsor }
      }),
      _c("ModalAddress")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }