<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card-box">
             
                <div class="form-row">
                    <div class="col-md-11">
                            <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.user.type.list.label.title')}}</h4></h4>
                    </div>
                    <div class="col-md-1" v-if="items">
                        <h4 class="text-center">{{$t('str.total')}}</h4>
                        <h4 class="text-center" style="color:grey">{{items.length}}</h4>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-centered table-hover mb-0" id="datatable">
                        <thead class="thead-dark">
                            <tr>
                                <th class="border-top-0">{{$t('string.msg.label.name')}}</th>
                                <th class="border-top-0">{{$t('string.msg.platforms.label.platforms')}}</th>
                                <th class="border-top-0">{{$t('string.msg.userType.price')}}</th>
                                <th class="border-top-0">{{$t('string.msg.platforms.table.label.status')}}</th>
                            </tr>
                        </thead>
                        <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer" @click="selectItem(item)">
                            <tr>
                                <td>
                                    <span>
                                        {{camelize(item.name)}}
                                    </span>
                                </td>
                                 <td>
                                    <span v-if="item.platforms && item.platforms.length > 0">
                                        <span  v-bind:key="platform" v-for="platform in item.platforms">   
                                            <label v-if="platform.name" >{{camelize($t(platform.name))}}</label><br/> 
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.price">
                                        {{'R$ ' + $t(item.price.toFixed(2))}}
                                    </span>
                                    <span v-else>
                                        {{'R$ 00,00'}}
                                    </span>
                                </td>
                                
                                <td v-if="item.status" >
                                    <span v-if="item.status">
                                        {{$t(item.status)}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <Spinner />
      <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    </div>
</template>

<script>
import Controller from './CrtListTypes';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';

export default {
  name: 'ListServices',
  props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        items: [],
        companyTypes: [],
        filters: {
            typeService: '',
            name: '',
            status:'ACTIVE',
        },
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
