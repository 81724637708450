var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "col-md-11" }, [
                  _c("h4", { staticClass: "header-title mb-4" }, [
                    _c("h4", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.user.type.label.title")))
                    ])
                  ])
                ]),
                _c("form", [
                  _vm.data._id
                    ? _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("h6", { staticClass: "text-right" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("string.msg.ratinng.label.status")
                              ) + ":"
                            )
                          ]),
                          _vm.data.status == "ACTIVE"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e(),
                          _vm.data.status == "ARCHIVED"
                            ? _c("span", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.data._id &&
                  _vm.data.status != "ARCHIVED" &&
                  _vm.isAdminMaster()
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "text-dark-gray btn btn-warning w-100",
                              on: { click: _vm.confirmArchive }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("string.msg.status.archive"))
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.user.type.placeholder.name")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.name,
                            expression: "data.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.user.type.placeholder.name"
                          )
                        },
                        domProps: { value: _vm.data.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "name", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.type.placeholder.platforms"
                                )
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-wrap" },
                        _vm._l(_vm.services, function(item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "container-switch" },
                            [
                              _c("label", { staticClass: "switch" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.platforms,
                                      expression: "data.platforms"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: item._id,
                                    "true-value": item._id,
                                    "false-value": null
                                  },
                                  domProps: {
                                    value: item._id,
                                    checked: Array.isArray(_vm.data.platforms)
                                      ? _vm._i(_vm.data.platforms, item._id) >
                                        -1
                                      : _vm._q(_vm.data.platforms, item._id)
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.data.platforms,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? item._id : null
                                      if (Array.isArray($$a)) {
                                        var $$v = item._id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.data,
                                              "platforms",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.data,
                                              "platforms",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.data, "platforms", $$c)
                                      }
                                    }
                                  }
                                }),
                                _c("span", { staticClass: "slider round" })
                              ]),
                              _c("span", { staticClass: "label-swith" }, [
                                _vm._v(_vm._s(_vm.camelize(item.name)))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("string.msg.userType.price")) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.price,
                            expression: "data.price"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "salary",
                          type: "text",
                          placeholder: _vm.$t("string.msg.userType.price")
                        },
                        domProps: { value: _vm.data.price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "price", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("div", { staticClass: "form-row text-center" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary w-50",
                          on: { click: _vm.clearForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                      )
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-50",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ])
                ])
              ]),
              _c("List", { on: { "load-item": _vm.selectItem } })
            ],
            1
          )
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }