<script>
import Endpoints from "../../common/Endpoints"
import Request from "../../common/Request"
export default {
  init: async (payload) => {
    let response = (
      await Request.do(
        payload,
        "GET",
        Request.getDefaultHeader(payload),
        {},
        `${Endpoints.credencials.listFromUser}`
      )
    ).data

    payload.userServices = response.result.map((c) => c.type)
    payload.credentialItems = payload.credentialItems.map((i) => ({
      ...i,
      active: payload.userServices.indexOf(i.type) > -1,
    }))
  },
  methods: {
    hasType: function (type) {
      if (this.user) {
        return this.user.serviceTypes.findIndex((x) => x == type) > -1
      } else {
        return false
      }
    },
    isAdminMaster: function () {
      if (this.user) {
        return (
          this.user.companyUser &&
          this.user.companyUser.subtype == "ADMIN_MASTER"
        )
      }
      return false
    },
    isAdminParresia: function () {
      if (this.user) {
        return (
          this.user.companyUser &&
          this.user.companyUser.subtype == "ADMIN_PARRESIA"
        )
      }
      return false
    },
    isAdmin: function () {
      if (this.user) {
        return (
          this.user.companyUser && this.user.companyUser.subtype == "ADMIN"
        )
      }
      return false
    },
    isSimple: function () {
      if (this.user) {
        return (
          this.user.companyUser && this.user.companyUser.subtype == "SIMPLE"
        )
      }
      return false
    },
    to: function (path) {
      this.$router.push({ path: path })
    }
  },
}
</script>
