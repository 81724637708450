<template>
    <div id="wrapper" :key="valuekey">
          <div class="content-page">
              <div class="content">
                  <div class="container-fluid">
                      <!-- Form row -->
                      <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <h4 class="page-title">{{$t('string.msg.user.update.profile.label.title')}}</h4>

                            </div>
                        </div>
                       </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="card-box">
                                  <form>
                                    <div class="form-row mb-2" >
                                        <div class="form-group col-md-12 text-center">
                                            <img v-if="data.companyUser.photoURL && data.companyUser.photoURL!='https://'" v-bind:src="`${domain}${data.companyUser.photoURL}`" 
                                                alt="user-image" class="rounded-circle"  height="120" width="120">
                                            <i v-else class="far fa-user noti-icon rounded-circle" alt="user-image" style="color:gray; font-size:45px"></i>
                                        </div>
                                    </div>
                                    <div class="form-row" >
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                    <code v-if="!data._id" class="highlighter-rouge">*</code>
                                                {{$t('string.msg.form.crud.user.label.name')}}
                                            </label>
                                            <input type="text" v-model="data.firstName" :placeholder="$t('string.msg.form.crud.user.placeholder.name')" class="form-control">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                    <code v-if="!data._id" class="highlighter-rouge">*</code>
                                                {{$t('string.msg.form.crud.user.label.lastname')}}
                                            </label>
                                            <input type="text" v-model="data.lastName" :placeholder="$t('string.msg.form.crud.user.placeholder.lastname')" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-row" >
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.form.crud.user.label.email')}}
                                            </label>
                                             <input disabled type="text" v-model="data.email" :placeholder="$t('string.msg.form.crud.user.placeholder.email')" class="form-control">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('str.company.user.rg')}}
                                            </label>
                                             <input  type="text" v-model="data.companyUser.documentRg"  :placeholder="$t('str.company.user.rg')" class="form-control">
                                        </div>
                                    </div> 

                                    <div v-if="data.companyUser.subtype != 'SIMPLE'" class="form-row" >
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('str.company.user.cpf')}}
                                            </label>
                                            <input  type="text" v-model="data.companyUser.documentCpf" v-mask="['###.###.###-##']" :placeholder="$t('str.company.user.cpf')" class="form-control">
                                        </div>
                                        <div  class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.user.label.perfil.photo')}}
                                            </label>
                                            <input type="file" id="photo" ref="file" v-on:change="handleFileUpload()" class="form-control">
                                        </div> 
                                    </div> 

                                    <div v-if="errors.length" class="alert alert-danger">
                                        <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                        <ul>
                                            <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                        </ul>
                                    </div>
                                    <div class="form-row text-center mt-4">
                                        <div class="form-group col-md-12">
                                            <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                        </div>
                                    </div>

                                  </form>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <FlashMessage/>
      </div>
</template>

<script>
import Controller from './CrtUser';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Endpoints from '../../../components/common/Endpoints';
export default {
  name: 'PerfilUser',
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        valuekey: 0,
        domain: Endpoints.domain,
        services: [],
        titleToast: null,
        messageToast: null,
        file: null,
        messageIdToast: null,
        data: {
            status: "ACTIVE",
            firstName: null,
            lastName: null,
            email: null,
            isAcceptGeneralTerms: true,
            type: "USER-COMPANY",
            companyUser:{
                company: null,
                status: "ACTIVE",
                photoURL: "https://",
                userPlatforms:[],
                subtype: null,
                rg: null,
                cpf: null,
            }
        },
      }
  },
  methods: Controller.methods,
  created() {
  },
  mounted(){
    Controller.init(this);
  }
}
</script>

<style>
</style>
