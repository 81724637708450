import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import VueTheMask from 'vue-the-mask';//https://vuejs-tips.github.io/vue-the-mask/
import money from 'v-money';
import VueSimpleAlert from "vue-simple-alert";
import BootstrapVue from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import store from './store'

Vue.component('multiselect', Multiselect)
Vue.use(BootstrapVue);
Vue.use(VueSimpleAlert);
Vue.use(VueTheMask);

Vue.use(money, {precision: 2});
Vue.config.productionTip = false;
Vue.config.silent = true;

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
