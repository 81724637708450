var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "content-page",
        class: { "sidebar-opened": _vm.sidebarOpened }
      },
      [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("string.msg.menu.dashboard.label.title")
                        ) +
                        "\n              "
                    )
                  ])
                ])
              ])
            ]),
            _c("iframe", {
              staticStyle: { height: "100vh", width: "100%" },
              attrs: {
                id: "NewsParresiaFeedIFrame",
                src: "https://news.parresia.com/",
                frameborder: "0",
                sandbox:
                  "allow-forms allow-modals allow-same-origin allow-popups allow-presentation allow-scripts"
              }
            })
          ])
        ]),
        _c("Spinner"),
        _c("Toast", {
          attrs: {
            title: _vm.titleToast,
            message: _vm.messageToast,
            messageId: _vm.messageIdToast
          }
        }),
        _vm.showExtensionWarning ? _c("extension-warning-popup") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }