var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      staticStyle: { top: "100px" },
      attrs: {
        id: "modalCongrats",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalCongrats",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-content" }, [
          _c("br"),
          _c("div", { staticClass: "content text-center" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "string.msg.menu.company.start.build.your.site.label.title"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "string.msg.menu.company.start.build.your.site.label.description"
                  )
                )
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "modal-footer",
              staticStyle: { "align-self": "center" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v(_vm._s(_vm.$t("string.msg.ok")))]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "backgroundColorRed card-radius-top topBorderCard text-center"
      },
      [
        _c("img", {
          staticClass: "mt-2",
          attrs: {
            src: "assets/images/parresia_logo.png",
            alt: "parresia",
            height: "35px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }