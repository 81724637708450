<template>
<div id="wrapper" :key="valuekey">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">{{$t('str.msg.credentials.label')}}</h4>

                </div>
            </div>
        </div>
        <!-- Form row -->
        <div class="row" >
            <div class="col-md-12">
                <div class="card-box">
                    <p class="sub-header">
                        {{$t('str.msg.credentials.config.description')}}
                    </p>
                    <form>

                        <div v-if="data._id" class="form-row">
                            <div class="form-group col-md-3">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code> {{$t('string.msg.product.brand.label.id')}}
                                </label>
                                <input type="text" readonly v-model="data._id" class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <br/>
                                <br/>
                                <button v-on:click="confirmRemove
                                " class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                            </div>
                        </div>  

                        <di
                        v class="form-row">

                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.credential.table.label.users')}}
                                </label>
                                <select id="companyType" v-model="data.systemUser" class="form-control" @change="onChange(data.systemUser)">
                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                    <option :value="item" v-bind:key="item" v-for="item in users">{{camelize(item.firstName) + ' ' + camelize(item.lastName) + ' - ' + camelize(item.companyUser.company.fantasyName)}}</option>
                                </select>
                                
                            </div>

                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.company.table.label.platform')}}
                                </label>
                                <select id="companyType" v-model="data.type" class="form-control">
                                    <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                    <option :value="item._id" v-bind:key="item" v-for="item in types">{{camelize($t(item.name))}}</option>
                                </select>
                            </div>

                        </di>                            

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.menu.users.credential.label')}}
                                </label>
                                <input type="text" v-model="data.user" class="form-control">
                                    <label v-if="data.systemUser" for="inputAddress" class="col-form-label" @click="showModalCredential()" style="color:#1D9FF0">
                                    <i class="fas fa-key"></i>
                                    {{$t('string.msg.credential.access.label.show')}}
                                </label>

                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    <code class="highlighter-rouge">*</code>
                                    {{$t('string.msg.platforms.label.pass')}}
                                </label>
                                <input type="text" v-model="data.pass" class="form-control">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.platforms.label.accessURL')}}
                                </label>
                                <input type="text" v-model="data.accessURL" class="form-control">
                            </div>
                        </div>


                        <div v-if="errors.length" class="alert alert-danger">
                            <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                            <ul>
                                <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                        
                        <br/>
                        <div class="form-row text-center">
                            <div class="form-group col-md-6">
                                <button @click="clearForm" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                            </div>
                            <div class="form-group col-md-6">
                                <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                            </div>
                        </div>

                    </form>

                    </div>
            </div>
        </div>
        <List v-on:load-item="selectItem"/>
    </div>

    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
    <ModalCredential :company="company"/>
</div>
</template>

<script>
import Controller from './CrtCredential';
import List from './ListCredential';
import Spinner from '../../components/layout/Spinner';
import Toast from '../../components/layout/Toast';
import Confirmation from '../../components/layout/Confirmation';
import ModalCredential from './Modal/ModalCredential';
export default {
  name: 'Credential',
  components: {
    Spinner,
    Toast,
    List,
    ModalCredential,
    Confirmation
  },
  data() {
      return {
        errors: [],
        addresses: [],
        valuekey: 0,
        newPassword: null,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        company:null,
        services: [],
        users:[],
        userId: null,
        data: {
            name: "MAIN",
            type: null,
            user: null,
            pass: null,
            accessURL: null,
            systemUser: null,
            authorizationToken: null,
            company: null,
            status: 'ACTIVE'
        },
        types:[
            {_id:"website",name: "str.wordpress"},
            {_id:"app",name: "str.siberian"},
            {_id:"email-marketing",name: "str.nitronews"},
            {_id:"webmail",name: "str.skymail"},
            {_id:"crm",name: "str.meet.crm"},
            {_id:"payment", name: "str.payment"},
            {_id:"design-pro", name: "str.trakto"},
        ],
        filters: {
            status:"ACTIVE",
        }
        
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
