<script>
import Endpoints from '../../../components/common/Endpoints';
import Request from '../../../components/common/Request';
import Common from '../../../components/common/Common';

const instanceateAddress = (addressObj, geo) => {
    addressObj.name = 'MAIN';
    if (geo.geometry.lat){
        addressObj.lat = geo.geometry.lat;
    }else return null;
    if (geo.geometry.lng){
        addressObj.lng = geo.geometry.lng;
    }else return null;

    return addressObj;
}


export default {
    init: (payload) => {

        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');

        payload.findAllSponsors();
    },
    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;

            if(this.data.address && this.data.address._id){
              delete this.data.address._id;
            }

            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.data, `${Endpoints.sponsors.sponsor}${this.data._id ? this.data._id : ''}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.valueKey += 1; 
                    this.$fire({
                        text: this.data._id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                            this.findAllSponsors();
                    });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
        },
        isAdminMaster: function(){
            let user = this.$session.get('user');
            if (user){
                return user.companyUser && user.companyUser.subtype == "ADMIN_MASTER";
            }
            return false;
        },
        checkForm: function (e) {
            this.errors = [];
            if (!this.data.fantasyName) {
                this.errors.push(this.$t('response.template.register.fantasyname.required'));
            }
            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.save();
            }
        },
        cancelAdd: function (){
            this.errors = [];
            this.data = {
              status: "ACTIVE",
              fantasyName: null,
              socialName: null,
              primaryPhone: null,
              secundaryPhone: null,
              document: null,
              responsible: null,
              address: {
                state: -1,
                cep: null,
                city: null,
                address: null,
                neighborhood: null,
                number: null
              }
            }
        },
        archive: function(response){
            if (response==1){

                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.data, `${Endpoints.sponsors.sponsor}${this.data._id ? this.data._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.cancelAdd();
                                this.findAllSponsors();
                        });
             
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
        },  
        loadGeolocation: function (callbackSuccess, callbackError) {
            let state = this;
            Request.do(this, 'POST', Request.getDefaultHeader(this), this.data.address, `${Endpoints.addresses.geolocation}`,
                    (geoResponse)=>{//SUCCESS CALLBACK
                        if (geoResponse.results.length==0){
                            return callbackError(this.$t('string.company.register.address.invalid'));
                        }else if (geoResponse.results.length==1){
                            let geo = geoResponse.results[0];
                            let addressObj = instanceateAddress(state.data.address, geoResponse.results[0]);
                            if (addressObj){
                        
                                return callbackSuccess(state.data);
                            }else {
                                return callbackError(state.$t('string.company.register.address.invalid'));
                            }
                        }else if (geoResponse.results.length>1){
                            state.addresses = geoResponse.results;
                            $('#modalListAddress').modal('toggle');
                        } 
                    },
                    (error)=>{//ERROR CALLBACK
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else if (res.data.messageId === 'response.already.exists'){
                                this.messageToast = this.$t('response.user.already.exists');
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                        
                    });        
        },
        selectAddress: function(index){
            let addressObj = instanceateAddress(this.data.address, this.addresses[index]);
            
            if (addressObj){
                addressObj.ibge = this.data.address.ibge;
                addressObj.gia = this.data.address.gia;
                addressObj.complement = this.data.complement;
                this.data.address = addressObj;
                $('#modalListAddress').modal('toggle');
                this.save(this.data);
            }else {
                this.errors = [];
                this.errors.push(this.$t('string.company.register.address.invalid'));
                 $('#modalListAddress').modal('toggle');
            }
        },
        loadInfosByCEP: function(){
            let state = this;
            if (this.data.address.cep && this.data.address.cep.length == 9){
                $('#spinner').modal('toggle');
                Request.do(this, 'GET', {}, {}, `${Endpoints.cep.find}${this.data.address.cep}/json`,
                    (response)=>{//SUCCESS CALLBACK
                        if (response){
                            state.data.address.address = response.logradouro;
                            state.data.address.neighborhood = response.bairro;
                            state.data.address.city = response.localidade;
                            state.data.address.state = response.uf;
                            state.data.address.ibge = response.ibge;
                            state.data.address.gia = response.gia;
                        }
                        $('#spinner').modal('toggle');
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        console.log(JSON.stringify(error));
                });
            }
        }, 
        confirmArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.sponsor.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationSponsor').modal('show');
        },
        findAllSponsors: function(){
            const state = this;
            Request.do(state, 'POST', Request.getDefaultHeader(state), this.filters, `${Endpoints.sponsors.filter}`,
            (sponsorResponse)=>{//SUCCESS CALLBACK
                state.sponsors = sponsorResponse.results;
            },
            (error)=>{//ERROR CALLBACK
            }); 
        },
        selectItem: function(item){

            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
