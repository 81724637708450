var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "page-title-box" }, [
                _c("h4", { staticClass: "page-title" }, [
                  _vm._v(_vm._s(_vm.$t("str.msg.credentials.label")))
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card-box" }, [
                _c("p", { staticClass: "sub-header" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("str.msg.credentials.config.description")) +
                      "\n                    "
                  )
                ]),
                _c(
                  "form",
                  [
                    _vm.data._id
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.product.brand.label.id"
                                      )
                                    ) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data._id,
                                  expression: "data._id"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.data._id },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(_vm.data, "_id", $event.target.value)
                                }
                              }
                            })
                          ]),
                          _c("div", { staticClass: "form-group col-md-2" }, [
                            _c("br"),
                            _c("br"),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "text-dark-gray btn btn-warning w-100",
                                on: { click: _vm.confirmRemove }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("string.msg.status.archive"))
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("di", { staticClass: "form-row", attrs: { v: "" } }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.credential.table.label.users"
                                  )
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.systemUser,
                                expression: "data.systemUser"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "companyType" },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "systemUser",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function($event) {
                                  return _vm.onChange(_vm.data.systemUser)
                                }
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: null }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.select.selector.item")
                                  )
                                )
                              ]
                            ),
                            _vm._l(_vm.users, function(item) {
                              return _c(
                                "option",
                                { key: item, domProps: { value: item } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.camelize(item.firstName) +
                                        " " +
                                        _vm.camelize(item.lastName) +
                                        " - " +
                                        _vm.camelize(
                                          item.companyUser.company.fantasyName
                                        )
                                    )
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.company.table.label.platform"
                                  )
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.type,
                                expression: "data.type"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "companyType" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { selected: "" },
                                domProps: { value: null }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.select.selector.item")
                                  )
                                )
                              ]
                            ),
                            _vm._l(_vm.types, function(item) {
                              return _c(
                                "option",
                                { key: item, domProps: { value: item._id } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.camelize(_vm.$t(item.name)))
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.menu.users.credential.label"
                                  )
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.user,
                              expression: "data.user"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.data.user },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "user", $event.target.value)
                            }
                          }
                        }),
                        _vm.data.systemUser
                          ? _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                staticStyle: { color: "#1D9FF0" },
                                attrs: { for: "inputAddress" },
                                on: {
                                  click: function($event) {
                                    return _vm.showModalCredential()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-key" }),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.credential.access.label.show"
                                      )
                                    ) +
                                    "\n                                "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t("string.msg.platforms.label.pass")
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.pass,
                              expression: "data.pass"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.data.pass },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "pass", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t("string.msg.platforms.label.accessURL")
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.accessURL,
                              expression: "data.accessURL"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.data.accessURL },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "accessURL",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c("br"),
                    _c("div", { staticClass: "form-row text-center" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary w-50",
                            on: { click: _vm.clearForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                        )
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger w-50",
                            on: { click: _vm.checkForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                        )
                      ])
                    ])
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("List", { on: { "load-item": _vm.selectItem } })
        ],
        1
      ),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      }),
      _c("ModalCredential", { attrs: { company: _vm.company } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }