<template>
    <div class="modal fade" id="modalCredential" tabindex="-1" role="dialog" aria-labelledby="modalCredential" aria-hidden="true" style="top:100px">
        <div class="modal-dialog" role="document">
            <div class="backgroundColorRed card-radius-top topBorderCard text-center">
                <img src="assets/images/parresia_logo.png" alt="parresia" height="60px">
            </div>
            <div class="modal-content">
                <br/>
                <div class="content text-center">
                    <h4>{{$t('string.msg.credential.company.access.title')}}</h4>
                </div>
                <div  v-if="company && company.emailAccess && company.passwordAccess" class="modal-body">
                    <h6>{{company.emailAccess}}</h6>
                    <h6>{{company.passwordAccess}}</h6>
                </div>
                 <div  v-else class="modal-body text-center">
                    <h6 style="color:gray">{{$t('string.msg.credential.no.informed.by.company')}}</h6> 
                </div>
                <div class="modal-footer" style="align-self: center;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"  v-on:click="$emit('confirmation-return', 1)">{{$t('string.msg.ok')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Common from '../../../components/common/Common';
    export default {
    props: ['company'],
    methods:{
    },
    data() {
      return {
          camelize: Common.camelize
        }
    }
}
</script>

