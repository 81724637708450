<script>
import Endpoints from "../../../components/common/Endpoints";
import Common from "../../../components/common/Common";
import Request from "../../../components/common/Request";

export default {
    init: async (payload) => {
    const el = document.body;
    el.removeAttribute("class");
    $(".modal").modal("hide");

    let response = (await Request.do(payload, 'GET', Request.getDefaultHeader(payload), {}, `${Endpoints.systemUsers.getMe}`)).data;
    payload.user = JSON.parse(JSON.stringify(response.result));
    if(response.result.companyUser.company){
     payload.company = JSON.parse(JSON.stringify(response.result.companyUser.company));
    }

  },
  methods: {
  save: function() {
    
    let data = JSON.parse(JSON.stringify(this.company));

    //REMOVE _ID FOR UPDATE
    delete data._id;
    delete data.address;

    Request.do(this, 'PUT', Request.getDefaultHeader(this), data, `${Endpoints.companies.company}${this.company._id}`,
        (companyResponse)=>{//SUCCESS CALLBACK
        },
        (error)=>{//ERROR CALLBACK
            let res = error.response;
            if (res.status == 500){
                this.titleToast = this.$t('parresia.msg.toast.advice');
                
                if (res.data.messageId === 'parresia.already.exists'){
                    this.messageToast = this.$t('parresia.company.register.error.element.exists');
                }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                    this.messageToast = this.$t('parresia.msg.error.required.fields');
                }else{
                    this.messageToast = this.$t('parresia.msg.error.generic');
                    this.messageIdToast = res.data.messageId;
                }

                $('#toast').modal('show');
            }
    });     
  },
  checkForm: function (e) {

    this.errors = [];

    if (!this.company.buyDomain && this.company.domains.length == 0) {
        this.errors.push(this.$t('response.platforms.configuration.domain.website.required'));
    }

    if (this.errors.length>0){
        e.preventDefault();
    }else{
        this.save();
      }
    },
    camelize: Common.camelize,
    toUpperCase: Common.toUpperCase
  }
};
</script>
