var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-xl-12" }, [
        _c("div", { staticClass: "card-box" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-11" }, [
              _c("h4", { staticClass: "header-title mb-4" }, [
                _c("h4", { staticClass: "page-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("string.msg.list.user.label.description"))
                  )
                ])
              ])
            ]),
            _vm.items
              ? _c("div", { staticClass: "col-md-1" }, [
                  _c("h4", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("str.total")))
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "text-center",
                      staticStyle: { color: "grey" }
                    },
                    [_vm._v(_vm._s(_vm.items.length))]
                  )
                ])
              : _vm._e()
          ]),
          _c("p", { staticClass: "sub-header" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t("string.msg.list.user.filter.label.description")
                ) +
                "\n            "
            )
          ]),
          _c("form", [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label",
                    attrs: { for: "inputAddress" }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("string.msg.filter.placeholder")) +
                        "\n                        "
                    )
                  ]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userType,
                        expression: "userType"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.userType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { selected: "" }, domProps: { value: null } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("string.msg.select.user.type.placeholder")
                          )
                        )
                      ]
                    ),
                    _c("option", { attrs: { value: "SEARCH-USER" } }, [
                      _vm._v(_vm._s(_vm.$t("SEARCH-USER")))
                    ]),
                    _c(
                      "option",
                      { attrs: { value: "SEARCH-USER-COMPANY", selected: "" } },
                      [_vm._v(_vm._s(_vm.$t("SEARCH-USER-COMPANY")))]
                    )
                  ]
                )
              ]),
              _vm.userType != null && _vm.userType == "SEARCH-USER"
                ? _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("string.msg.list.user.label.search")
                            ) +
                            "\n                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.name,
                          expression: "filters.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                : _vm.userType == "SEARCH-USER-COMPANY"
                ? _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.companies.search.label.name.company"
                              )
                            ) +
                            "\n                        "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.name,
                          expression: "filters.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                : _vm._e()
            ]),
            _c("br"),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between bd-highlight mb-3"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success w-20",
                    on: { click: _vm.filter }
                  },
                  [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.search")))]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-centered table-hover mb-0",
                attrs: { id: "datatable" }
              },
              [
                _c("thead", { staticClass: "thead-dark" }, [
                  _c("tr", [
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.name"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.email"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.level"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.table.list.user.label.company")
                        )
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.table.list.user.label.status")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._l(_vm.items, function(item) {
                  return _c("tbody", {
                    key: item._id,
                    staticClass: "cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.selectItem(item)
                      }
                    }
                  })
                })
              ],
              2
            )
          ]),
          _vm.errors.length
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c("p", { staticClass: "lead" }, [
                  _vm._v(_vm._s(_vm.$t("response.msg.error.empty.fields")))
                ]),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error) {
                    return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }