<template>
    <div class="account-pages w-100 mt-5 mb-5">
            <div class="content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-8 col-xl-8">
                            <div class="backgroundColorRed card-radius-top topBorderCard text-center" style="padding:5px">
                                <router-link to="/" class="cursor-pointer" style="margin-top:10px;margin-left:10px; float: left">
                                    <i class="mdi mdi-keyboard-backspace btn-back" style="color:white"></i>{{``}}<span class="btn-back" style="color:white">{{$t('string.msg.form.btn.goback')}}</span>
                                </router-link>
                                <a class="text-center">
                                    <span><img class="mt-1" src="assets/images/parresia_logo.png" alt="parresia" height="35px"></span>
                                </a>
                            </div>
                               
                            <div class="card-box">
                                
                                <h4 class="header-title">{{$t('string.msg.company.label.title')}}</h4>
                                <p class="sub-header">
                                    {{$t('string.msg.company.label.decription')}}
                                </p>
                                
                                <form>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.placeholder.organization')}}
                                            </label>
                                            <input type="text" v-model="data.fantasyName" class="form-control" :placeholder="$t('string.msg.company.placeholder.organization.placeholder')">
                                        </div>
                                          <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code v-if="!data._id" class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                            </label>
                                            <input v-model="data.primaryPhone" type="text" class="form-control" :placeholder='$t("string.msg.company.placeholder.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                        </div>
                          
                                    </div>

                                    <div class="form-row">
                                  
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.table.label.type')}}
                                            </label>
                                            <select id="companyType" v-model="data.typeService[0]" class="form-control">
                                                <option :value="[]" selected>{{$t('string.msg.select.selector.item')}}</option>
                                                <option :value="item._id" v-bind:key="item" v-for="item in companyTypes">{{camelize($t(item.name))}}</option>
                                            </select>
                                        </div>
                                    </div>
                       
                                    <div v-if="errors.length" class="alert alert-danger">
                                        <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                        <ul>
                                            <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                        </ul>
                                    </div>

                                    <!-- Circles which indicates the steps of the form: -->
                                    <div style="text-align:center;margin-top:40px;">
                                        <span class="step step-on"></span>
                                        <span class="step step-off"></span>
                                    </div>
                                    
                                    <br/>
                                    <div class="form-row">
                                        <div class="form-group col-md-12 text-center">
                                        <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.next')}}</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        
    </div>
</template>

<script>
import Controller from './CrtCompany';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';

export default {
  name: 'Company',
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        valuekey: 0,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        companyTypes: [],
        file: null,
        data: {
            status: "ACTIVE",
            fantasyName: null,
            socialName: null,
            email: null,
            primaryPhone: null,
            logoURL: null,
            document: null,
            address: null,
            typeService: [],
            platforms: []
        }
      }
  },
  methods: Controller.methods,
  created() {
  },
  mounted(){
    Controller.init(this);

  }
}
</script>

<style>
</style>
