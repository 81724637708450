var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalEsqueciSenha",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalEsqueciSenha",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "card card-radius" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title text-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("string.login.forgot.password")))
                ]),
                _vm._m(1)
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("string.msg.change.password.message")) +
                      "\n                "
                  )
                ]),
                _c("form", { attrs: { id: "form-esqueci-senha" } }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.code,
                          expression: "data.code"
                        }
                      ],
                      staticClass: "form-control form-control-lg has-border",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.change.password.placeholder.code"
                        )
                      },
                      domProps: { value: _vm.data.code },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "code", $event.target.value)
                        }
                      }
                    }),
                    _c("br"),
                    _vm.errors.length
                      ? _c("div", { staticClass: " alert-danger" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "d-flex bd-highlight mb-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger w-20",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("check-code")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("string.btn.send")) + " ")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "backgroundColorRed card-radius-top topBorderCard text-center"
      },
      [
        _c("img", {
          attrs: {
            src: "assets/images/parresia_logo.png",
            alt: "parresia",
            height: "35px"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }