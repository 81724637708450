<template>
    <div id="wrapper" :key="valuekey">

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <h4 class="page-title">{{$t('string.msg.list.sponsors.label')}}</h4>
                        <form class="mt-4">
                            <div v-if="sponsor._id" class="form-group col-md-12">
                                <h6 class="text-right">   
                                    <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                    <span v-if="sponsor.status == 'ACTIVE'" style="color: green">
                                        {{$t(sponsor.status)}} 
                                    </span>
                                    <span v-if="sponsor.status == 'ARCHIVED'" style="color: blue">
                                        {{$t(sponsor.status)}} 
                                    </span>
                                </h6>
                            </div>
                            <div v-if="sponsor._id && sponsor.status != 'ARCHIVED' && isAdminMaster()" class="form-row">
                                <div class="form-group col-md-2">
                                    <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.fantasyName')}}
                                    </label>
                                    <input type="text" v-model="sponsor.fantasyName" class="form-control" :placeholder="$t('string.msg.company.placeholder.fantasyName')">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        {{$t('string.msg.company.label.socialName')}}
                                    </label>
                                    <input type="text" v-model="sponsor.socialName" class="form-control" :placeholder="$t('string.msg.company.placeholder.socialName')">
                                </div>
                    
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        {{$t('string.msg.company.label.document')}}
                                    </label>
                                    <input type="tel" v-mask="['###.###.###-##', '##.###.###/####-##']"   v-model="sponsor.document" class="form-control" :placeholder="$t('string.msg.company.placeholder.document')">
                                </div>
                                  <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code v-if="!sponsor._id" class="highlighter-rouge">*</code>
                                        {{$t('str.msg.primaryPhone')}}
                                    </label>
                                    <input v-model="sponsor.primaryPhone" type="text" class="form-control" :placeholder='$t("str.msg.primaryPhone")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                  </div>
                            </div>  

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.responsible')}}
                                    </label>
                                    <input type="text" v-model="sponsor.responsible" class="form-control" :placeholder="$t('string.msg.company.label.responsible')">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputAddress" class="col-form-label">
                                        <code v-if="!sponsor._id" class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.primaryphone.whatsapp')}}
                                    </label>
                                    <input v-model="sponsor.secundaryPhone" type="text" class="form-control" :placeholder='$t("string.msg.company.label.primaryphone.whatsapp")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                  </div>
                            </div>  

                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.address')}}
                            </label>
                            <div class="form-row">
                                <div class="form-group col-md-2">
                                    <label>
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.cep')}}
                                    </label>
                                    <input @blur="loadInfosByCEP" v-mask="'#####-###'" v-model="sponsor.address.cep" type="tel" class="form-control" :placeholder="$t('string.msg.company.label.cep')" />
                                </div>
                            </div>
                            <div v-if="sponsor.address.cep && sponsor.address.cep.length == 9" class="form-row ">
                                <div class="form-group col-md-4">
                                    <label>
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.state')}}    
                                    </label>
                                    <select v-model="sponsor.address.state" class="form-control select2">
                                        <option :value="-1" selected>{{$t('string.msg.element.select.selectitem')}}</option>
                                        <option value="AC">AC</option>
                                        <option value="AL">AL</option>
                                        <option value="AP">AP</option>
                                        <option value="AM">AM</option>
                                        <option value="BA">BA</option>
                                        <option value="CE">CE</option>
                                        <option value="DF">DF</option>
                                        <option value="ES">ES</option>
                                        <option value="GO">GO</option>
                                        <option value="MA">MA</option>
                                        <option value="MT">MT</option>
                                        <option value="MS">MS</option>
                                        <option value="MG">MG</option>
                                        <option value="PA">PA</option> 
                                        <option value="PB">PB</option>
                                        <option value="PR">PR</option>
                                        <option value="PE">PE</option>
                                        <option value="PI">PI</option>
                                        <option value="RJ">RJ</option>
                                        <option value="RN">RN</option>
                                        <option value="RS">RS</option>
                                        <option value="RO">RO</option>
                                        <option value="RR">RR</option>
                                        <option value="SC">SC</option>
                                        <option value="SP">SP</option>
                                        <option value="SE">SE</option>
                                        <option value="TO">TO</option>
                                    </select>
                                </div>
                                <div class="col-sm-4">
                                    <label>
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.city')}}
                                    </label>
                                    <input v-model="sponsor.address.city" type="text" class="form-control" :placeholder="$t('string.msg.company.label.city')" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label>
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.street')}}
                                    </label>
                                    <input v-model="sponsor.address.address" type="text" class="form-control" :placeholder="$t('string.msg.company.label.street')" />
                                </div>
                            </div>
                            <div v-if="sponsor.address.cep && sponsor.address.cep.length == 9" class="form-row">

                                <div class="form-group col-md-4">
                                    <label>
                                    <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.number')}}
                                    </label>
                                    <input v-model="sponsor.address.number" type="text" class="form-control" :placeholder="$t('string.msg.company.label.number')" />
                                </div>
                                <div class="form-group col-md-4">
                                    <label>
                                        <code class="highlighter-rouge">*</code>
                                        {{$t('string.msg.company.label.neighborhood')}}
                                    </label>
                                <input v-model="sponsor.address.neighborhood" type="text" class="form-control" :placeholder="$t('string.msg.company.label.neighborhood')" />
                                </div>
                                <div class="form-group col-md-4">
                                        <label>
                                        {{$t('string.msg.company.label.complement')}}
                                    </label>
                                    <input v-model="sponsor.address.complement" type="text" class="form-control" :placeholder="$t('string.msg.company.label.complement')" />
                                </div>
                            </div>


                            <div class="form-row text-center mt-2">
                                <div class="form-group col-md-6">
                                    <button @click="cancelAdd" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                </div>
                                <div class="form-group col-md-6">
                                    <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                </div>
                            </div>

                            <div v-if="errors.length" class="alert alert-danger">
                              <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                              <ul>
                                  <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                              </ul>
                            </div>
                            

                            <div class="table-responsive mt-4" v-if="sponsors && sponsors.length > 0">
                                <p class="sub-header">
                                    {{$t('string.msg.list.sponsors')}}
                                </p>
                                <table class="table table-centered table-hover mb-0"> 
                                    <thead class="thead-dark" >
                                        <tr>
                                            <th scope="col">{{$t('string.msg.table.sponsor.name')}}</th>
                                            <th scope="col">{{$t('string.msg.table.sponsor.primaryPhone')}}</th>
                                            <th scope="col">{{$t('string.msg.table.sponsor.responsible')}}</th>
                                            <th scope="col">{{$t('string.msg.table.sponsor.secundaryPhone')}}</th>
                                            <th scope="col">{{$t('string.msg.table.sponsor.status')}}</th>
                                        </tr>
                                    </thead>
                                        <tbody v-bind:key="item._id" v-for="item in sponsors" class="cursor-pointer">
                                            <tr v-if="item.status == 'ACTIVE'" @click="selectItem(item)">
                                                <td>
                                                    <span v-if="item.fantasyName">
                                                        {{camelize(item.fantasyName)}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.primaryPhone">
                                                        {{item.primaryPhone}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.responsible">
                                                        {{camelize(item.responsible)}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.secundaryPhone">
                                                        {{item.secundaryPhone}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="item.status">
                                                        {{ $t(item.status) }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                      
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
</div>
</template>

<script>
import Controller from './CrtSponsor';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from './Confirmation';
export default {
    name: 'Sponsor',
    props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
    components: {
    Spinner,
    Toast,
    Confirmation
    },

    data() {
        return {
            errors: [],
            titleToast: null,
            messageToast: null,
            messageIdToast: null,
            formCrud: null,
            items: null,
            file: null,
            sponsors: [],
            addresses:[],
            data: {
              status: "ACTIVE",
              fantasyName: null,
              socialName: null,
              primaryPhone: null,
              secundaryPhone: null,
              document: null,
              responsible: null,
              address: {
                state: -1,
                cep: null,
                city: null,
                address: null,
                neighborhood: null,
                number: null,
                name:'MAIN'
              },
            },
            filters: {
              name: '',
              status:'ACTIVE',
          },
        }
    },
    methods: Controller.methods,
    created() {
    Controller.init(this);
    },
    mounted(){
    }
    }
    </script>
