<script>
import Endpoints from "../../../components/common/Endpoints";
import Common from "../../../components/common/Common";
import Request from "../../../components/common/Request";

export default {
  init: payload => {
    const el = document.body;
    el.removeAttribute("class");
    el.classList.add('platforms-page');
    $(".modal").modal("hide");

    payload.data.type = payload.$route.params.type;
    payload.data.status = "ACTIVE";
    payload.data.pass = null;
    payload.data.user = null;
    payload.data.accessURL = null;
    payload.data.urlToLoad = null;
    payload.data._id = null;
    payload.data.showIframe = true
  },
  methods: {
    save: async function() {
      $("#spinner").modal("toggle");
      this.errors = [];
      this.messageIdToast = null;

      try {
        let response = await Request.do(
          this,
          this.data._id ? "PUT" : "POST",
          Request.getDefaultHeader(this),
          this.data,
          `${Endpoints.credencials.credencial}${
            this.data._id ? this.data._id : ""
          }`
        );
        this.data.valuekey += 1;
        this.loadData();
        $("#spinner").modal("toggle");
      } catch (error) {
        //ERROR CALLBACK
        let res = error.response;
        if (res.status == 400) {
          this.titleToast = this.$t("parresia.msg.toast.advice");

          if (res.data.messageId === "parresia.already.exists") {
            this.messageToast = this.$t(
              "parresia.company.register.error.element.exists"
            );
          } else if (
            res.data.messageId === "parresia.msg.error.required.fields"
          ) {
            this.messageToast = this.$t("parresia.msg.error.required.fields");
          } else {
            this.messageToast = this.$t("parresia.msg.error.generic");
            this.messageIdToast = res.data.messageId;
          }

          $("#toast").modal("show");
        }
        console.log(JSON.stringify(res.data));
      }
    },
    loadData: async function() {
      let url = `${Endpoints.credencials.credencialList}`;
      url = url.replace("{type}", this.data.type);
      this.showSpinner();

      try {
        let newData = {};
        let response = await Request.do(
          this,
          "GET",
          Request.getDefaultHeader(this),
          null,
          url
        );

        if (response.data){
          newData = response.data.result;
          if (this.hasExtension) {
            if (this.data.type == "app") {
              try {
                let res = await Request.do(this, "GET", Request.getDefaultHeader(this), {}, `${Endpoints.credencials.authSiberian}`);
                newData.accessURL = res.data.result.redirect_url.replace('http://', 'https://');
                newData.urlToLoad = newData.accessURL;
              } catch (e) {
                console.error(e)
              }
              this.hideSpinner()
              this.data = newData
            } else if (this.data.type == "payment") {
              let res = await Request.do(this, "GET", Request.getDefaultHeader(this), {}, `${Endpoints.credencials.authPayment}`);
              newData.urlToLoad = res.data.result.url
              newData.accessURL = newData.urlToLoad
              this.hideSpinner()
              this.data = newData
            } else if (this.data.type == "email-marketing") {
              const params = new URLSearchParams();
              params.append("txtemail", newData.user);
              params.append("txtsenha", newData.pass);
              this.loadWithAjax(newData, params, newData.accessURL + "/login-externo", newData.accessURL + "/home");
            } else if (this.data.type == "webmail") {
              const params = new URLSearchParams();
              params.append("_username", newData.user);
              params.append("_pass", newData.pass);
              params.append("_task", 'login');
              params.append("_action", 'login');
              newData.accessURL = "https://webmail.skymail.com.br/"
              this.loadWithAjax(newData, params, newData.accessURL + "?_task=login", newData.accessURL);
            } else if (this.data.type == "crm") {
              const params = new URLSearchParams();
              params.append("login", newData.user);
              params.append("senha", newData.pass);
              this.loadWithAjax(newData, params, newData.accessURL + "/auth/login", newData.accessURL + "/dashboard");
            } else if (this.data.type == "website") {
              const { accessURL } = newData
              const url           = accessURL.replace('\\', '/').endsWith('/') // remove / ou \ do final do texto se existir
                                  ? accessURL.slice(0, -1)
                                  : accessURL

              const authUrl       = url + '/wp-json/integracao-clube-parresia/v1/auth'
              const loginUrl      = url + '/integracao-clube-parresia/login'
              
              fetch(authUrl, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                  user: newData.user,
                  password: newData.pass
                })
              })
              .then(response => {
                if (!response.ok) return

                return response.json()
              })
              .then(({ token }) => {
                newData.urlToLoad = loginUrl + '?token=' + token
                this.data = newData    
              })
              .catch(err => console.error(err))
              .finally(() => this.hideSpinner())
            } else if (this.data.type == "design-pro"){
              const responseTrakto = await Request.do(this, "GET", Request.getDefaultHeader(this), null, `${Endpoints.credencials.authTrakto}`);
              newData.accessURL = responseTrakto.data.result.url.replace('http://', 'https://');
              newData.urlToLoad = newData.accessURL
              this.hideSpinner()
              this.data = newData
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.hideSpinner()
      }
    },
    loadWithAjax: async function(data, params, urlLogin, urlHome) {
      fetch(urlLogin, {
        method: "POST",
        credentials: "include",
        mode: "no-cors",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: params.toString(),
      })
      .then((res) => {
        data.accessURL = urlHome;
        data.urlToLoad = data.accessURL;
        this.data = data;
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => this.hideSpinner())
    },
    openFormat: function(id) {
      this.data.traktoEditor.openFromFormat(id, 
      data => { console.log('openFromFormat finished'); }, 
      {
          backgroundImage: '',
          width: 1,
          height: 1,
          exportResolution: ['thumbnail', 'low', 'medium', 'high']
      });
    },
    openTemplate: function(id) {
      this.data.traktoEditor.openFromTemplate(id, data => { console.log('openFromTemplate finished'); }, 
      {
          backgroundImage: '',
          width: 1,
          height: 1,
          exportResolution: ['thumbnail', 'low', 'medium', 'high']
      });
    },
    openDocument: function(id) {
      this.data.traktoEditor.openDocument(id, data => { console.log('openDocument finished');},
      {
          exportResolution: ['thumbnail', 'low', 'medium', 'high']
      });
    },
    showSpinner: function () {
      $('#spinner').modal('show')
    },
    hideSpinner: function () {
      $('#spinner').modal('hide')
    },
    checkBrowser: function () {
      const agent = navigator.userAgent.match(/(chrome|firefox)/i)
      if (agent) {
        this.isBrowserSupported = true
        this.extensionDownloadLink = Endpoints.xframe[agent[0].toLowerCase()]
      }
    },
    camelize: Common.camelize,
    toUpperCase: Common.toUpperCase
  }
};

// // PARA O website FUNCIONAR, OBRIGATÓRIO COLOCAR O SCRIPT ABAIXO NO ARQUIVO DE LOGIN.
// ?>
//   <script>
//     function setLoginData (evt) {
//       document.getElementById("user_login").value = evt.data.user;
//       document.getElementById("user_pass").value = evt.data.pass;
//       document.getElementById('loginform').submit();
//     }
//     if (window.addEventListener) {
//       window.addEventListener("message", setLoginData, false);
//     }
//     else {
//       window.attachEvent("onmessage", setLoginData);
//     }
// </script>
// <?php
// </script>
