<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card-box">
                <div class="form-row">
                    <div class="col-md-11">
                        <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.list.credential.label.description')}}</h4></h4>
                    </div>
                    <div class="col-md-1" v-if="items">
                        <h4 class="text-center">{{$t('str.total')}}</h4>
                        <h4 class="text-center" style="color:grey">{{items.length}}</h4>
                    </div>
                </div>               
                <p class="sub-header">
                    {{$t('string.msg.list.credential.filter.label.description')}}
                </p>
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.filter.placeholder')}}
                            </label>
                            <select v-model="userType" class="form-control">
                                <option :value="null" selected>{{$t('string.msg.select.user.type.placeholder')}}</option>
                                <option value="ACTIVE">{{$t('ACTIVE')}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6" v-if="userType != null && userType == 'SEARCH-USER'">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.list.user.label.search')}}
                            </label>
                            <input type="text" v-model="filters.name" class="form-control">
                        </div>
                        <div class="form-group col-md-6" v-else-if="userType == 'SEARCH-USER-COMPANY'">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.companies.search.label.name.company')}}
                            </label>
                            <input type="text" v-model="filters.name" class="form-control">
                        </div>
                    </div>

                    <br/>
                    <div class="d-flex justify-content-between bd-highlight mb-3">
                        <button @click="filter" class="btn btn-success w-20">{{$t('string.msg.form.btn.search')}}</button>
                    </div>
                </form>

                  <div class="table-responsive">
                    <table class="table table-centered table-hover mb-0" id="datatable">
                        <thead class="thead-dark">
                            <tr>
                                <th class="border-top-0">{{$t('string.msg.table.list.credential.label.type')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.systemUser')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.user')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.password')}}</th>
                                <th class="border-top-0">{{$t('string.msg.table.list.user.label.status')}}</th>
                            </tr>
                        </thead>
                        <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer" @click="selectItem(item)">
                              <tr>
                                <td>
                                    <span v-if="item.type">
                                        {{camelize($t(item.type))}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.systemUser && item.systemUser.firstName">
                                        {{camelize(item.systemUser.firstName) + ' '}}
                                    </span>
                                    <span v-if="item.systemUser && item.systemUser.lastName">
                                        {{camelize(item.systemUser.lastName)}}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="item.user">
                                        {{item.user}}
                                    </span>
                                </td>
                                 <td>
                                    <span v-if="item.pass">
                                        {{item.pass}}
                                    </span>
                                </td>
                                <td>
                                  <span v-if="item.status">
                                        {{camelize($t(item.status))}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="errors.length" class="alert alert-danger">
                    <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                    <ul>
                        <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Controller from './CrtListCredential';
import Spinner from '../../components/layout/Spinner';
import Toast from '../../components/layout/Toast';
import Vue from 'vue'
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

export default {
  name: 'CredentialUser',
  props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
  components: {
    Spinner,
    Toast,
    
  },
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        items: [],
        services: [],
        userType: null,
        PageNumbers:1,
        pageOfItems: [],
        filters: {
            status:"ACTIVE",
        }
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
