var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-pages w-100 mt-5 mb-5" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-md-8 col-lg-8 col-xl-8" }, [
              _c(
                "div",
                {
                  staticClass:
                    "backgroundColorRed card-radius-top topBorderCard text-center",
                  staticStyle: { padding: "5px" }
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "cursor-pointer",
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-left": "10px",
                        float: "left"
                      },
                      attrs: { to: "register-company" }
                    },
                    [
                      _c("i", {
                        staticClass: "mdi mdi-keyboard-backspace btn-back",
                        staticStyle: { color: "white" }
                      }),
                      _vm._v(_vm._s("")),
                      _c(
                        "span",
                        {
                          staticClass: "btn-back",
                          staticStyle: { color: "white" }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.goback")))]
                      )
                    ]
                  ),
                  _vm._m(0)
                ],
                1
              ),
              _c("div", { staticClass: "card-box" }, [
                _c("h4", { staticClass: "header-title" }, [
                  _vm._v(_vm._s(_vm.$t("string.msg.user.label.title")))
                ]),
                _c("p", { staticClass: "sub-header" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.$t("string.msg.user.label.decription")) +
                      "\n                            "
                  )
                ]),
                _c("form", [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                            " +
                              _vm._s(_vm.$t("string.msg.user.label.name")) +
                              "\n                                        "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.firstName,
                            expression: "data.firstName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.user.placeholder.name"
                          )
                        },
                        domProps: { value: _vm.data.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "firstName", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                            " +
                              _vm._s(_vm.$t("string.msg.user.label.lastname")) +
                              "\n                                        "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.lastName,
                            expression: "data.lastName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.user.placeholder.lastname"
                          )
                        },
                        domProps: { value: _vm.data.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "lastName", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                            " +
                              _vm._s(_vm.$t("string.msg.user.label.email")) +
                              "\n                                        "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.email,
                            expression: "data.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.user.placeholder.email"
                          )
                        },
                        domProps: { value: _vm.data.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "email", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          !_vm.data._id
                            ? _c("code", { staticClass: "highlighter-rouge" }, [
                                _vm._v("*")
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n                                            " +
                              _vm._s(_vm.$t("string.msg.user.label.password")) +
                              "\n                                        "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.password,
                            expression: "data.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t(
                            "string.msg.user.placeholder.password"
                          )
                        },
                        domProps: { value: _vm.data.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "password", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("br"),
                  _c("br"),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm._m(1),
                  _c("br"),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 text-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger w-50",
                            on: { click: _vm.checkForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.next")))]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("ModalAddress", {
        attrs: { addresses: _vm.addresses },
        on: { "select-address": _vm.selectAddress }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "text-center" }, [
      _c("span", [
        _c("img", {
          staticClass: "mt-1",
          attrs: {
            src: "assets/images/parresia_logo.png",
            alt: "parresia",
            height: "35px"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "40px" } },
      [
        _c("span", { staticClass: "step step-on" }),
        _c("span", { staticClass: "step step-on" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }