<template>
 <div class="form-body">
   
        <div class="row">
            <div class="img-holder">
                <div class="bg"></div>
                <div class="info-holder">
                    <img src="images/graphic2.svg" alt="">
                </div>
            </div>
            <div class="form-holder">
                <div class="form-content">
                    <div class="form-items">
                        <div class="text-center">
                            <img src="assets/images/parresia_logo.png" alt="parresia" height="80px">
                        </div>
                        <div class="page-links" style="margin-top:50px">
                            <a href="#" class="active">Login</a>
                        </div>
                                    
                        <form>

                            <div class="form-group mb-3">
                                <input class="input-text form-control" v-model="data.email" type="email" required="" :placeholder="$t('string.msg.login.placeholder.email')">
                            </div>
                            <div class="form-group mb-3">
                                <input class="input-text form-control" v-model="data.password" type="password" required=""  :placeholder="$t('string.msg.login.placeholder.password')">
                            </div>

                    
                            <div class="form-group col-md-12 text-center">
                                <button @click="submit" class="btn btn-login" style="background-color: #ffffff;color:gray">{{$t('string.msg.login.btn.login')}}</button>
                            </div>

                            <br>
                            <div class="text-center mb-2">
                                <a class="text-muted underline"><small style="color: #ffffff;">{{$t('string.msg.login.text.signup')}}</small>
                                    <a class="cursor-pointer" style="margin-left:10px">
                                        <a  @click="register()"  class="text-muted underline"><small><b style="color: #ffffff">{{$t('string.msg.login.label.signup')}}</b></small></a>
                                    </a>
                                </a>
                            </div>
                            
                            <br>
                            <div class="text-center mb-2">
                                <a @click="checkForgotPassword()" class="cursor-pointer">
                                    <a  class="text-muted underline" > <small style="color: #ffffff">{{$t('string.login.forgot.password')}}</small></a>
                                </a>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast"/>
    <ModalForgotPassword v-on:check-code="checkCode" :data="data" :errors="errors"/>
    <ModalNewPassword v-on:reset-pass="resetPass" :data="data" :errors="errors"/>
    </div>
</template>

<script>
import Controller from './CrtLogin';
import Spinner from '../../components/layout/Spinner';
import Toast from '../../components/layout/Toast';
import ModalForgotPassword from './Modal/ModalForgotPassword';
import ModalNewPassword from './Modal/ModalNewPassword';

export default {
    name: 'Home',
    components: {
    Spinner,
    Toast,
    ModalForgotPassword,
    ModalNewPassword,
  },
  
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        data: {
          email: "",
          password: "",
          passwordConfirm: null,
          code: null,
          systemUser: null
        },
        user: null,
      }
  },
  methods: Controller.methods,
  sockets: {
        connect: function () {
            console.log('socket connected')
        },
        customEmit: function (data) {
            console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
        }
  },
  created() {
    Controller.init(this);
  }
}
</script>

<style>
</style>
