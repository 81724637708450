<script>
import Endpoints from "../../../components/common/Endpoints";
import Common from "../../../components/common/Common";
import Request from "../../../components/common/Request";

export default {
  init: payload => {
    const el = document.body;
    el.removeAttribute("class");
    $(".modal").modal("hide");
  },
  methods: {
    save: function() {
     
    },
    clearForm: function() {
      this.data = {};
      this.errors = [];
    },
    camelize: Common.camelize,
    toUpperCase: Common.toUpperCase
  }
};
</script>
