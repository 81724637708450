<template>
    <div class="modal " id="spinner" tabindex="-1" role="dialog" aria-labelledby="spinner" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered d-flex justify-content-center" role="document">
            <div class="sk-circle">
                <br>
                <img src="assets/images/parresia_logo.png" alt="parresia" height="20" style="margin-left:12px">
                <div class="sk-circle1 sk-child"></div>
                <div class="sk-circle2 sk-child"></div>
                <div class="sk-circle3 sk-child"></div>
                <div class="sk-circle4 sk-child"></div>
                <div class="sk-circle5 sk-child"></div>
                <div class="sk-circle6 sk-child"></div>
                <div class="sk-circle7 sk-child"></div>
                <div class="sk-circle8 sk-child"></div>
                <div class="sk-circle9 sk-child"></div>
                <div class="sk-circle10 sk-child"></div>
                <div class="sk-circle11 sk-child"></div>
                <div class="sk-circle12 sk-child"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
    }
</script>

