var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal ",
      attrs: {
        id: "toast",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "toast",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal-dialog-centered d-flex justify-content-center",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "toast-header" }, [
              _c("strong", { staticClass: "mr-auto" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c(
                "button",
                {
                  staticClass: "ml-2 mb-1 close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "toast",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "toast-body" }, [
              _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
              _vm.messageId
                ? _c("small", [_vm._v("ID: " + _vm._s(_vm.messageId))])
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }