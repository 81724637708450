<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.menu.platforms.app.label.title')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
            <div class="col-md-12">
              <div class="card-box">
                <p class="sub-header">{{ $t("string.msg.platforms.configuration.label.title") }}</p>

                <form>

                  <div  class="form-row mt-2">
                     <label for="inputAddress" class="col-form-label">
                          {{$t('str.app.name.label')}}
                      </label>
                    <div class="form-group col-md-12">
                        <input type="text" v-model="company.appName" class="form-control" :placeholder="$t('str.app.name.label')"/>
                    </div>
                  </div>


                  <label>{{$t('string.msg.menu.company.wanna.buy.apple.store.label')}}</label>
                  <div class="m-1">
                    <div class="radio radio-info form-check-inline">
                      <input type="radio" id="inlineRadio1" @click="setOption1" v-model="option1" :value="option1" name="radioInline">
                      <label for="inlineRadio1">{{$t('string.msg.yes')}}</label>
                    </div>
                    <div class="radio radio-info form-check-inline">
                      <input type="radio" id="inlineRadio2" @click="setOption2" v-model="option2" :value="company.option2" name="radioInline">
                      <label for="inlineRadio2">{{$t('string.msg.no')}}</label>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label>{{$t('str.msg.obs')}}</label>
                    <span style="color:gray">{{' ' + $t('string.msg.menu.company.app.label.title')}}</span>  
                  </div>

                  
                  <div v-if="errors.length" class="mt-3 alert alert-danger">
                    <p class="lead">{{ $t("response.msg.error.empty.fields") }}</p>
                    <ul>
                      <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                  </div>

                  <div class="text-center mb-2 mt-4">
                    <button @click="checkForm" class="btn btn-danger w-30">{{ $t("string.msg.form.btn.save") }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>

    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation
      :title="titleToast"
      :message="messageToast"
      :cancelText="cancelText"
      :yesText="yesText"
      v-on:confirmation-return="archive"
    />
    <ModalCongrats/>
  </div>
</template>

<script>
import Controller from "./CrtApp";
import Spinner from "../../../components/layout/Spinner";
import Toast from "../../../components/layout/Toast";
import Confirmation from "../../../components/layout/Confirmation";
import ModalCongrats from './Modal/ModalCongrats'
export default {
  name: "WebSite",
  components: {
    Spinner,
    Toast,
    Confirmation,
    ModalCongrats
  },
  data() {
    return {
      errors: [],
      valuekey: 0,
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      option1: false,
      option2: false,
      user: null,
      file: null,
      company: {
            status: null,
            fantasyName: null,
            socialName: null,
            email: null,
            primaryPhone: null,
            logoURL: null,
            document: null,
            buyLogo: null,
            authWithoutLogo: null,
            buyDomain: null,
            domains: [],
            linkDomain: null,
            haveApp: null,
            appName: null,
            appInAppleStore: null,
            typeService: [],
            platforms: []
        },

    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {

  
  }
};
</script>

<style></style>
