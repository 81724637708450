var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal ",
        attrs: {
          id: "spinner",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "spinner",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "modal-dialog modal-dialog-centered d-flex justify-content-center",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "sk-circle" }, [
              _c("br"),
              _c("img", {
                staticStyle: { "margin-left": "12px" },
                attrs: {
                  src: "assets/images/parresia_logo.png",
                  alt: "parresia",
                  height: "20"
                }
              }),
              _c("div", { staticClass: "sk-circle1 sk-child" }),
              _c("div", { staticClass: "sk-circle2 sk-child" }),
              _c("div", { staticClass: "sk-circle3 sk-child" }),
              _c("div", { staticClass: "sk-circle4 sk-child" }),
              _c("div", { staticClass: "sk-circle5 sk-child" }),
              _c("div", { staticClass: "sk-circle6 sk-child" }),
              _c("div", { staticClass: "sk-circle7 sk-child" }),
              _c("div", { staticClass: "sk-circle8 sk-child" }),
              _c("div", { staticClass: "sk-circle9 sk-child" }),
              _c("div", { staticClass: "sk-circle10 sk-child" }),
              _c("div", { staticClass: "sk-circle11 sk-child" }),
              _c("div", { staticClass: "sk-circle12 sk-child" })
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }