<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

const instanceateAddress = (addressObj, geo) => {
    addressObj.name = 'MAIN';
    if (geo.geometry.lat){
        addressObj.lat = geo.geometry.lat;
    }else return null;
    if (geo.geometry.lng){
        addressObj.lng = geo.geometry.lng;
    }else return null;

    return addressObj;
}

export default {
    init: async (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        
        payload.getCompanyTypes();
        payload.getServices();
        payload.getPlans();
        payload.getTemplatesSite();
        payload.getTemplatesApp();
        payload.getUserTypes();
        payload.getAdditionalResources();
        payload.getContractTemplates()

        if(payload.companyId){
            Request.do(payload, 'POST', Request.getDefaultHeader(payload), {name: "",status:"ACTIVE",companyId:payload.companyId}, `${Endpoints.companies.filter}`,
            (response)=>{//SUCCESS CALLBACK
                payload.selectItem(response.results[0]);
            },
            (error)=>{//ERROR CALLBACK
            });

        }
    },
    methods:{
        getCompany(){
            if(this.companyId){
                Request.do(this, 'POST', Request.getDefaultHeader(this), { name: "", status:"ACTIVE", companyId:this.companyId }, `${Endpoints.companies.filter}`,
                (response)=>{//SUCCESS CALLBACK
                    this.selectItem(response.results[0]);
                },
                (error)=>{//ERROR CALLBACK
                });

            }
        },
        save: function(){
            this.errors = [];
            this.messageIdToast = null;
            let state = this;

            let platforms = [];
            let data = JSON.parse(JSON.stringify(this.data));
            
            
            if (data.amount){
                data.amount = Number(data.amount.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.amount = 0;
            }

            if (data.totalAmount){
                data.totalAmount = Number(data.totalAmount.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.totalAmount = 0;
            }

            if (data.discount){
                data.discount = Number(data.discount.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.discount = 0;
            }

            if (data.amountSetup){
                data.amountSetup = Number(data.amountSetup.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.amountSetup = 0;
            }

            if (data.totalAmountSetup){
                data.totalAmountSetup = Number(data.totalAmountSetup.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.totalAmountSetup = 0;
            }

            if (data.discountSetup){
                data.discountSetup = Number(data.discountSetup.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.discountSetup = 0;
            }

            if (data.inputPriceSetup){
                data.inputPriceSetup = Number(data.inputPriceSetup.toString().split(".").join("").split(",").join(".").split("R$ ").join(""));
            }else{
                data.inputPriceSetup = 0;
            }

            if(data.platforms && data.platforms.length > 0){
                let copyPlatforms = data.platforms;
                let form = {
                    service: null,
                    status:"ACTIVE"
                }   
                for(let i=0; i< copyPlatforms.length; i++){
                    if(copyPlatforms[i]){
                        form.service = copyPlatforms[i];
                        form.status = "ACTIVE"
                        platforms.push(JSON.parse(JSON.stringify(form)))
                    }
                }
                data.platforms = platforms;
            }

            if(data.plans && data.plans.length > 0){
                let copyPlans = data.plans;
                let plans = [];
                for(let i=0; i< copyPlans.length; i++){
                    if(copyPlans[i] && copyPlans[i]._id)
                        plans.push(copyPlans[i]._id)
                }
                data.plans = plans;
            }

            if(data.additionalResources && data.additionalResources.length > 0){
                let copyAdditionalResources = data.additionalResources;
                let additionalResources = [];
                for(let i=0; i< copyAdditionalResources.length; i++){
                    if(copyAdditionalResources[i] && copyAdditionalResources[i]._id)
                        additionalResources.push(copyAdditionalResources[i]._id)
                }
                data.additionalResources = additionalResources;
            }

            if(data.additionalUsers && data.additionalUsers.length > 0){
                let copyAdditionalUsers = data.additionalUsers;
                let additionalUsers = [];
                for(let i=0; i< copyAdditionalUsers.length; i++){
                    if(copyAdditionalUsers[i] && copyAdditionalUsers[i]._id)
                        additionalUsers.push(copyAdditionalUsers[i]._id)
                }
                data.additionalUsers = additionalUsers;
            }   

            if(this.otherPeriod != null && this.otherPeriod != 0){
                data.contractPeriod = this.otherPeriod;
            }


            let formData = new FormData();
            formData.append('file', null);
            formData.append('jsonData', JSON.stringify(data));
            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), formData, `${Endpoints.companies.companyWithLogo}${this.data._id ? this.data._id : ''}`,
                (companyResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                            text: this.data._id ? this.$t('str.register.put.sucess') : this.$t('string.user.register.success'),
                            type: "success",
                            timer: 2000
                            }).then(r => {    
                                this.$eventHub.$emit('refreshList');
                                state.data._id = companyResponse.result._id;
                                this.errors = [];
                        });
                },
                (error)=>{//ERROR CALLBACK
                    let res = error.response;
                    if (res.status == 500){
                        this.titleToast = this.$t('parresia.msg.toast.advice');
                        
                        if (res.data.messageId === 'parresia.already.exists'){
                            this.messageToast = this.$t('parresia.company.register.error.element.exists');
                        }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                            this.messageToast = this.$t('parresia.msg.error.required.fields');
                        }else{
                            this.messageToast = this.$t('parresia.msg.error.generic');
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });     
        },
        isAdminMaster: function(){
            let user = this.$session.get('user');
            if (user){
                return user.companyUser && user.companyUser.subtype == "ADMIN_MASTER";
            }
            return false;
        },
        getUsers: function(){
            this.users = [];
            
            if(this.data._id){
                this.userFilters.company = this.data._id;
            }

            Request.do(this, 'POST', Request.getDefaultHeader(this), this.userFilters, `${Endpoints.systemUsers.companyUser}`,
            (response)=>{//SUCCESS CALLBACK
                this.users = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        setBuyLogo(){
            if(this.data.buyLogo === null){
                this.data.buyLogo = true;
            }else{
                this.data.buyLogo = true;
            }
        },
        getCompanyTypes: function(){
            //################### LOAD COMPANY TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.helpers.companyType}`,
            (response)=>{//SUCCESS CALLBACK
                this.companyTypes = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getPlans: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.plans.plan}`,
            (response)=>{//SUCCESS CALLBACK
                this.plans = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getAdditionalInfo: function(id){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'POST', Request.getDefaultHeader(this), {company: id}, `${Endpoints.companies.getAddInfo}`,
            (response)=>{//SUCCESS CALLBACK
                this.addInfo = response.result;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getTemplatesSite: function(){
            //################### LOAD TEMPLATES
            Request.do(this, 'POST', Request.getDefaultHeader(this), {type: "SITE"}, `${Endpoints.templates.filter}`,
            (response)=>{//SUCCESS CALLBACK
                this.templatesSite = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getTemplatesApp: function(){
            //################### LOAD TEMPLATES
            Request.do(this, 'POST', Request.getDefaultHeader(this), {type: 'APP'}, `${Endpoints.templates.filter}`,
            (response)=>{//SUCCESS CALLBACK
                this.templatesApp = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getUserTypes: function(){
            //################### LOAD USER TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.systemUsers.typeUser}`,
            (response)=>{//SUCCESS CALLBACK
                this.userTypes = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getAdditionalResources: function(){
            //################### LOAD RESOURCES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.companies.listResources}`,
            (response)=>{//SUCCESS CALLBACK
                this.additionalResources = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.active}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        customLabelTemplates(option) {
            return `${option.name}`
        },
        customLabelUserTypes(option) {
            return `${option.name}`
        },
        customLabelAdditionalResources(option) {
            return `${option.name}`
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        onChangePlans: function(){

            let valuePlans = 0;
            let setupPrice = 0;
            if(this.data.plans && this.data.plans.length > 0){
                let copyPlans = this.data.plans;
                for(let i=0; i< copyPlans.length; i++){
                    valuePlans = valuePlans + copyPlans[i].monthlyPrice;
                    setupPrice = setupPrice + copyPlans[i].setupPrice;
                }
            }

            let valueAddResources = 0;
             if(this.data.additionalResources && this.data.additionalResources.length > 0){
                let copyAdditionalResources = this.data.additionalResources;
                for(let i=0; i< copyAdditionalResources.length; i++){
                    valueAddResources = valueAddResources + copyAdditionalResources[i].price;
                }
            }

            let valueAddUsers = 0;
            if(this.data.additionalUsers && this.data.additionalUsers.length > 0){
                let copyAdditionalUsers = this.data.additionalUsers;
                for(let i=0; i< copyAdditionalUsers.length; i++){
                    valueAddUsers = valueAddUsers + copyAdditionalUsers[i].price;
                }
            }

            this.data.amountSetup = Number(setupPrice).toFixed(2);
            this.data.amount = Number(valuePlans + valueAddResources + valueAddUsers).toFixed(2);
            this.data.totalAmount = Number(this.data.amount).toFixed(2);
            this.data.totalAmountSetup = Number(setupPrice).toFixed(2);
        },
        discountAmount: function(value){
            let copyValue = value.toString().split(".").join("").split(",").join(".").split("R$ ").join("");
            this.data.totalAmount = Number( Number(this.data.amount) - Number(copyValue) ).toFixed(2);
        },
        discountAmountSetup: function(value){
            let copyValue = Number(value.toString().split(".").join("").split(",").join(".").split("R$ ").join("")).toFixed(2);
            this.data.totalAmountSetup = Number( Number(this.data.amountSetup) - Number(copyValue) ).toFixed(2);

            this.installmentsOfSetup(Number(this.data.numberInstallments));
        },
        clearFormCredentials: function(){
            this.errors = [];
            this.credential= {
                name: "MAIN",
                type: null,
                user: null,
                pass: null,
                accessURL: null,
                systemUser: null,
                authorizationToken: null,
                company: null,
                status: 'ACTIVE'
            }
            this.credential.company = this.data._id;
        },
        clearForm: function(){
            this.errors = [];
            this.users = [];
            this.data =  {
                status: "ACTIVE",
                fantasyName: null,
                socialName: null,
                email: null,
                primaryPhone: null,
                logoURL: null,
                document: null,
                buyLogo: null,
                authWithoutLogo: null,
                buyDomain: false,
                haveApp: null,
                appName: null,
                appInAppleStore: false,
                passwordAccess: null,
                emailAccess: null,
                contractPeriod: null,
                address: {
                    state: -1,
                    cep: null,
                    city: null,
                    address: null,
                    neighborhood: null,
                    number: null
                },
                typeService: [],
                platforms: [],
                domains: [],
                plans: [],
                sponsors: [],
                additionalResources: [],
                additionalUsers: [],
                paymentType: null,
                dueDate: null,
                discount: 0,
                amount: 0,
                totalAmount: 0,
                paymentTypeSetup: null,
                dueDateSetup: null,
                discountSetup: 0,
                amountSetup: 0,
                totalAmountSetup: 0,
                inputPriceSetup: 0,
                numberInstallments: null,
                linkDomain: null,
                representative:{
                    name: null,
                    cpf: null,
                    email: null,
                    primaryPhone: null
                },
                witness:{
                    name: null,
                    cpf: null,
                    email: null
                },
                financialContact:{
                    name: null,
                    email: null,
                    primaryPhone: null
                },
                valueInstallments: []
            }

            this.addInfo = {
                status: "ACTIVE",
                company: null,
                instagramURL: "https://",
                facebookURL: "https://",
                googleBusinessURL: "https://",
                telegram: "",
                whatsApp: "",
                youtubeURL: "https://",
                twitterURL: "https://",
                facebookAds:{
                    user:null,
                    password:null
                },
                googleAds:{
                    user:null,
                    password:null
                },
                googleAnalytics:{
                    user:null,
                    password:null
                },
                RDStation:{
                    user:null,
                    password:null
                },
                streamingRadio:{
                    user:null,
                    password:null
                },
                podcast:{
                    user:null,
                    password:null
                },
                colorPaletteOne: null,
                colorPaletteTwo: null,
                colorPaletteThree: null,
                templateSite: null,
                obsTemplateSite: null,
                templateApp: null,
                obsTemplateApp: null,
                appName: null,
                appDescription: null,
                appleStoreUser: null,
                appleStorePassword: null,
                buyLogo: null,
                authWithoutLogo: null,
                domains:[],
                siteMigration:{
                    hostURL: null,
                    user: null,
                    password: null
                },
                webmailMigration:[],
                webmail: [],
                emailMarketing: null
            }

            this.sponsor= {
                status: "ACTIVE",
                fantasyName: null,
                socialName: null,
                primaryPhone: null,
                secundaryPhone: null,
                document: null,
                responsible: null,
                address: {
                    state: -1,
                    cep: null,
                    city: null,
                    address: null,
                    neighborhood: null,
                    number: null,
                    name:'MAIN'
                },
            },

            this.webmailMigration= {            
                currentHost: null,
                currentEmail: null,
                currentPassword: null
            };

            this.webmail= null,

            this.domainData = {
                url: null,
                registerLocal: null,
                user: null,
                password: null
            };

            this.valueInstallment = {
                value: null,
                number: null
            };

            this.credential = {
                name: "MAIN",
                type: null,
                user: null,
                pass: null,
                accessURL: null,
                systemUser: null,
                authorizationToken: null,
                company: null,
                status: 'ACTIVE'
            };

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        
        },
        inputPlatform(type, status){
                let copyCredentials = this.credentials;
                let index = copyCredentials.findIndex(x => x.type == String(type));
                if(status == false){
                    copyCredentials[index].status = 'ACTIVE';
                }else{
                     copyCredentials[index].status = 'DISABLED';
                }
              
                this.credentials = copyCredentials;
                this.setPlatformsForUser();
            
        },
        switchVisibility() {
            if(this.passwordFieldType == 'password'){
                this.passwordFieldType = 'text';
            }else
            if(this.passwordFieldType == 'text'){
                this.passwordFieldType = 'password'
            }
        },
        saveSponsor: function(){

            if(this.data._id){
                this.errors = [];
                this.messageIdToast = null;

                if(this.sponsor.address && this.sponsor.address._id){
                    delete this.sponsor.address._id;
                }

                if(this.sponsor.address && this.sponsor.address.cep == null){
                    this.sponsor.address = null;
                }

                this.sponsor.company = this.data._id;

                Request.do(this, this.sponsor._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.sponsor, `${Endpoints.sponsors.sponsor}${this.sponsor._id ? this.sponsor._id : ''}`,
                    (userResponse)=>{//SUCCESS CALLBACK
                        this.valueKey += 1; 
                        this.$fire({
                            text: this.sponsor._id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.clearSponsor();
                                this.findAllSponsors(this.data._id);
                        });
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else if (res.data.messageId === 'response.already.exists'){
                                this.messageToast = this.$t('response.company.already.exists');
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
            
        },
        findAllSponsors: function(id){
            if(id){
                const state = this;
                this.filtersSponsor.company = id;
                Request.do(state, 'POST', Request.getDefaultHeader(state), this.filtersSponsor, `${Endpoints.sponsors.filter}`,
                (sponsorResponse)=>{//SUCCESS CALLBACK
                    state.sponsors = sponsorResponse.results;
                },
                (error)=>{//ERROR CALLBACK
                }); 
            }
        },
        confirmArchiveSponsor: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.sponsor.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationSponsor').modal('show');
        },
        archiveSponsor: function(response){
            if (response==1){

                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.sponsor, `${Endpoints.sponsors.sponsor}${this.sponsor._id ? this.sponsor._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.clearSponsor();
                                this.findAllSponsors(this.data._id);
                        });
             
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
        },  
        checkForm: function (e) {

            this.errors = [];
            if (!this.data.fantasyName) {
                this.errors.push(this.$t('response.company.register.fantasy.name.required'));
            }
            if (!this.data.document) {
                this.errors.push(this.$t('response.user.register.document.required'));
            }
            if (this.data.typeService.length == 0) {
                this.errors.push(this.$t('response.company.register.serviceType.required'));
            }       

            
            if (this.errors.length>0){
                e.preventDefault();
            }else{
                if(this.data.address && this.data.address.cep && this.data.address.cep.length == 9){
                this.loadGeolocation(
                    (data)=>{
                        this.errors = [];
                        this.save(data);
                    },
                    (error)=>{
                        this.errors.push(error);
                    });
                }
                else{
                    this.data.address = null
                    this.errors = [];
                    this.save();
                }
            }
        },
        archive: function(response){
            if (response==1){

                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.data, `${Endpoints.companies.company}${this.data._id ? this.data._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                location.reload();
                        });
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }
                            $('#toast').modal('show');
                        }
                });
            }
        },
        selectItemSponsor: function(item){

            this.errors = [];

            if(!item.address){
                item.address =  {
                    state: -1,
                    cep: null,
                    city: null,
                    address: null,
                    neighborhood: null,
                    number: null,
                    name:'MAIN'
                }
            }

            this.sponsor = JSON.parse(JSON.stringify(item));

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        checkFormSponsor: function(){
            this.erroerrorsrsSponsor = [];

            if (!this.data._id) {
                this.errors.push(this.$t('response.template.register.company.required'));
            }

            if (!this.sponsor.fantasyName) {
                this.errors.push(this.$t('response.template.register.fantasyname.required'));
            }
            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.saveSponsor();
            }
        },
        clearSponsor: function(){
            this.errors = [];
            this.sponsor= {
                status: "ACTIVE",
                company: null,
                fantasyName: null,
                socialName: null,
                primaryPhone: null,
                secundaryPhone: null,
                document: null,
                responsible: null,
                address: {
                    state: -1,
                    cep: null,
                    city: null,
                    address: null,
                    neighborhood: null,
                    number: null,
                    name:'MAIN'
                },
            }
        },
        loadGeolocationSponsor: function (callbackSuccess, callbackError) {
            let state = this;
            Request.do(this, 'POST', Request.getDefaultHeader(this), this.sponsor.address, `${Endpoints.addresses.geolocation}`,
                    (geoResponse)=>{//SUCCESS CALLBACK
                        if (geoResponse.results.length==0){
                            return callbackError(this.$t('string.company.register.address.invalid'));
                        }else if (geoResponse.results.length==1){
                            let geo = geoResponse.results[0];
                            let addressObj = instanceateAddress(state.sponsor.address, geoResponse.results[0]);
                            if (addressObj){
                        
                                return callbackSuccess(state.sponsor);
                            }else {
                                return callbackError(state.$t('string.company.register.address.invalid'));
                            }
                        }else if (geoResponse.results.length>1){
                            state.addresses = geoResponse.results;
                            $('#modalListAddress').modal('toggle');
                        } 
                    },
                    (error)=>{//ERROR CALLBACK
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else if (res.data.messageId === 'response.already.exists'){
                                this.messageToast = this.$t('response.user.already.exists');
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                        
                    });        
        },
        selectAddressSponsor: function(index){
            let addressObj = instanceateAddress(this.sponsor.address, this.addresses[index]);
            
            if (addressObj){
                addressObj.ibge = this.sponsor.address.ibge;
                addressObj.gia = this.sponsor.address.gia;
                addressObj.complement = this.sponsor.complement;
                this.sponsor.address = addressObj;
                $('#modalListAddress').modal('toggle');
                this.save(this.sponsor);
            }else {
                this.errors = [];
                this.errors.push(this.$t('string.company.register.address.invalid'));
                 $('#modalListAddress').modal('toggle');
            }
        },
        loadInfosByCEPSponsor: function(){
            let state = this;
            if (this.sponsor.address.cep && this.sponsor.address.cep.length == 9){
                $('#spinner').modal('toggle');
                Request.do(this, 'GET', {}, {}, `${Endpoints.cep.find}${this.sponsor.address.cep}/json`,
                    (response)=>{//SUCCESS CALLBACK
                        if (response){
                            state.sponsor.address.address = response.logradouro;
                            state.sponsor.address.neighborhood = response.bairro;
                            state.sponsor.address.city = response.localidade;
                            state.sponsor.address.state = response.uf;
                            state.sponsor.address.ibge = response.ibge;
                            state.sponsor.address.gia = response.gia;
                        }
                        $('#spinner').modal('toggle');
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        console.log(JSON.stringify(error));
                });
            }
        }, 
        loadGeolocation: function (callbackSuccess, callbackError) {
            let state = this;
            Request.do(this, 'POST', Request.getDefaultHeader(this), this.data.address, `${Endpoints.addresses.geolocation}`,
                    (geoResponse)=>{//SUCCESS CALLBACK
                        if (geoResponse.results.length==0){
                            return callbackError(this.$t('string.company.register.address.invalid'));
                        }else if (geoResponse.results.length==1){
                            let geo = geoResponse.results[0];
                            let addressObj = instanceateAddress(state.data.address, geoResponse.results[0]);
                            if (addressObj){
                        
                                return callbackSuccess(state.data);
                            }else {
                                return callbackError(state.$t('string.company.register.address.invalid'));
                            }
                        }else if (geoResponse.results.length>1){
                            state.addresses = geoResponse.results;
                            $('#modalListAddress').modal('toggle');
                        } 
                    },
                    (error)=>{//ERROR CALLBACK
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else if (res.data.messageId === 'response.already.exists'){
                                this.messageToast = this.$t('response.user.already.exists');
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                        
                    });        
        },
        selectAddress: function(index){
            let addressObj = instanceateAddress(this.data.address, this.addresses[index]);
            
            if (addressObj){
                addressObj.ibge = this.data.address.ibge;
                addressObj.gia = this.data.address.gia;
                addressObj.complement = this.data.complement;
                this.data.address = addressObj;
                $('#modalListAddress').modal('toggle');
                this.save(this.data);
            }else {
                this.errors = [];
                this.errors.push(this.$t('string.company.register.address.invalid'));
                 $('#modalListAddress').modal('toggle');
            }
        },
        loadInfosByCEP: function(){
            let state = this;
            if (this.data.address.cep && this.data.address.cep.length == 9){
                $('#spinner').modal('toggle');
                Request.do(this, 'GET', {}, {}, `${Endpoints.cep.find}${this.data.address.cep}/json`,
                    (response)=>{//SUCCESS CALLBACK
                        if (response){
                            state.data.address.address = response.logradouro;
                            state.data.address.neighborhood = response.bairro;
                            state.data.address.city = response.localidade;
                            state.data.address.state = response.uf;
                            state.data.address.ibge = response.ibge;
                            state.data.address.gia = response.gia;
                        }
                        $('#spinner').modal('toggle');
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        console.log(JSON.stringify(error));
                });
            }
        }, 
        selectCredentialItem: function(item, index){
            this.indexCredential = index;
            this.credential = JSON.parse(JSON.stringify(item));
            this.credential.systemUser = item.systemUser._id;
        },
        removeCredential: function(response){
            if (response==1){
                this.errors = [];
                this.messageIdToast = null;
                let state = this;
            
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), null, `${Endpoints.credencials.credencial}${this.credential._id}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                         this.$fire({
                            text: this.$t('str.delete.credential.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.credentials.splice(this.indexCredential, 1);
                                this.clearFormCredentials();
                        });
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }
                            $('#toast').modal('show');
                        }
                        console.log(JSON.stringify(error));
                });
            }
        },
        confirmRemoveCredential: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("str.wanna.archive.credential.label");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#archiveCredentialModal').modal('show');
        },
        confirmArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.company.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationModal').modal('show');
        },
        editCredential: async function(){
            if(this.credentials && this.credentials.length > 0){
            
                for(let i =0; i< this.credentials.length; i++){

                    let credential = JSON.parse(JSON.stringify(this.credentials[i]));

                    if(credential.type == 'app'){
                        this.credential.accessURL = this.filtersServicesURL.app;
                    }else
                    if(credential.type == 'crm'){
                        this.credential.accessURL = this.filtersServicesURL.crm;
                    }else
                    if(credential.type == 'email-marketing'){
                        this.credential.accessURL = this.filtersServicesURL.emailMarketing;
                    }else
                    if(credential.type == 'payment'){
                        this.credential.accessURL = this.filtersServicesURL.payment;
                    }else
                    if(credential.type == 'website'){
                        this.credential.accessURL = this.filtersServicesURL.website;
                    }else
                    if(credential.type == 'design-pro'){
                        this.credential.accessURL =  this.filtersServicesURL.designPro;
                    }else
                    if(credential.type == 'webmail'){
                        this.credential.accessURL = this.filtersServicesURL.webmail;
                    }

                    await Request.do(this,  'PUT', Request.getDefaultHeader(this), credential, `${Endpoints.credencials.credencial}${credential._id}`,
                    (userResponse)=>{//SUCCESS CALLBACK
                    },
                    (error)=>{//ERROR CALLBACK
                        $('#spinner').modal('toggle');
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else if (res.data.messageId === 'response.already.exists'){
                                this.messageToast = this.$t('response.company.already.exists');
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                    });
                }
            
            }
            
        },
        saveCredential: async function(){

            this.messageIdToast = null;
            let state = this;
            
            const id = this.credential._id
            this.credential = {
                company: this.data._id,
                type: this.credential.type,
                systemUser: this.newData._id,
                accessURL: this.credential.accessURL,
                authorizationToken: null,
                status: this.credential.status,
                pass: this.credential.pass,
                user: this.credential.user
            }
            if (id) this.credential._id = id
            
            if(this.credential.type == 'app' && this.credential.accessURL === ''){
                this.credential.accessURL = 'https://app.parresia.com/admin/backoffice';
            }else
            if(this.credential.type == 'crm'){
                this.credential.accessURL = 'https://parresia.crm.net.br';
            }else
            if(this.credential.type == 'email-marketing'){
                this.credential.accessURL = 'https://emkt.parresia.com';
            }else
            if(this.credential.type == 'payment'){
                this.credential.accessURL = 'https://painel-arrecadacao.parresia.com/admin/';
            }

            const method = id ? 'PUT' : 'POST'
            const endpoint = `${Endpoints.credencials.credencial}${id ? id : ''}`
            await Request.do(this, method, Request.getDefaultHeader(this), this.credential, endpoint,
                (userResponse)=>{//SUCCESS CALLBACK
                    if (id) this.getCredentials() /// reload credentials if editing
                    this.$fire({
                        text: id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                        type: "success",
                        timer: 2000
                    })
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    console.debug(error, res)
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
            
            
        },
        clearFiltersServices(){
            this.filtersServices = {
                designPro: false,
                website: false,
                app: false,
                emailMarketing: false,
                crm: false,
                payment: false,
                webmail: false
            };
            this.filtersServicesURL = {
                designPro: '',
                website: null,
                app: 'https://app.parresia.com/admin/backoffice',
                emailMarketing: 'https://emkt.parresia.com',
                crm: 'https://parresia.crm.net.br',
                payment: 'https://painel-arrecadacao.parresia.com/admin/',
                webmail: null
            };
        },
        changeApp(status){
            this.filtersServices.app = !this.filtersServices.app;
            this.inputPlatform('app', status);
        },
        getCredentials: async function(){
            this.credentials = [];
            this.clearFiltersServices();

            await Request.do(this, 'POST', Request.getDefaultHeader(this), this.credentialFilters, `${Endpoints.credencials.filter}`,
            (response)=>{//SUCCESS CALLBACK
                this.credentials = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });

            await this.setPlatformsForUser();
        },
        checkFormCredential(e){
            this.errors = [];

            if (!this.credential.systemUser) {
                this.errors.push(this.$t('response.credential.register.system.user.required'));
            }

            if (!this.credential.type) {
                this.errors.push(this.$t('response.credential.register.type.required'));
            }

            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.saveCredential();
            }
        },
        selectItem: function(item){

            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));
            this.credentials = [];
            if(this.data.platforms && this.data.platforms.length > 0){
                let copyPlatforms = this.data.platforms;
                let platforms = [];
                for(let i=0; i< copyPlatforms.length; i++){
                    if(copyPlatforms[i].service && copyPlatforms[i].service._id)
                        platforms.push(copyPlatforms[i].service._id)
                }
                this.data.platforms = platforms;
            }

            if(this.data.address && this.data.address.cep && this.data.address.cep.length == 9 ){
                this.data.address.cep = this.data.address.cep;
                this.data.address.state = this.data.address.state.toUpperCase();
                this.data.address.address =  Common.camelize(this.data.address.address);
                this.data.address.city =  Common.camelize(this.data.address.city);
                this.data.address.neighborhood =  Common.camelize(this.data.address.neighborhood);
                delete this.data.address._id;
                if(this.data.address.complement){
                    this.data.address.complement =  Common.camelize(this.data.address.complement);
                }

            }else{
                this.data.address = JSON.parse(JSON.stringify({
                    state: -1,
                    cep: null,
                    city: null,
                    address: null,
                    neighborhood: null,
                    number: null
                }));
            }

            if(!this.data.representative){
                this.data.representative = {
                    name: null,
                    cpf: null,
                    email: null,
                    primaryPhone: null
                }
            }

            this.data.fantasyName = Common.camelize(this.data.fantasyName);
            if(this.data.socialName){
                this.data.socialName = Common.camelize(this.data.socialName);
            }
  
            if(!this.data.witness){
                 this.data.witness = {
                    name: null,
                    cpf: null,
                    email: null
                }
            }

            if(!this.data.financialContact){
                 this.data.financialContact = {
                    name: null,
                    email: null,
                    primaryPhone: null
                }
            }



            let valuePlans = 0;
            let setupPrice = 0;
            if(this.data.plans && this.data.plans.length > 0){
                let copyPlans = this.data.plans;
                for(let i=0; i< copyPlans.length; i++){
                    valuePlans = valuePlans + copyPlans[i].monthlyPrice;
                    setupPrice = setupPrice + copyPlans[i].setupPrice;
                }
            }

            let valueAddResources = 0;
             if(this.data.additionalResources && this.data.additionalResources.length > 0){
                let copyAdditionalResources = this.data.additionalResources;
                for(let i=0; i< copyAdditionalResources.length; i++){
                    valueAddResources = valueAddResources + copyAdditionalResources[i].price;
                }
            }

            let valueAddUsers = 0;
            if(this.data.additionalUsers && this.data.additionalUsers.length > 0){
                let copyAdditionalUsers = this.data.additionalUsers;
                for(let i=0; i< copyAdditionalUsers.length; i++){
                    valueAddUsers = valueAddUsers + copyAdditionalUsers[i].price;
                }
            }

            if(this.data.contractPeriod != null && this.data.contractPeriod != 12 && this.data.contractPeriod != 24 && this.data.contractPeriod != 36){
                this.otherPeriod = this.data.contractPeriod;
            }

            this.data.amount = 0;
            this.data.amountSetup = Number(setupPrice).toFixed(2);
            this.data.amount = Number( Number(valuePlans) + Number(valueAddResources) + Number(valueAddUsers)).toFixed(2);

            let amount = this.data.amount;
            let discount = this.data.discount;
            this.data.totalAmount = Number( Number(this.data.amount) - Number(this.data.discount) );

            this.addInfo.company = this.data._id;
            this.userFilters.company = this.data._id;
            this.newData.companyUser.company = this.data._id;

            this.getUsers();
            this.findAllSponsors(this.data._id);
            this.getAddInfo(this.data._id);   
            this.installmentsOfSetup(Number(this.data.numberInstallments));
            this.getAdditionalInfo(this.data._id);

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    
        },
        archiveUser: function(response){
            if (response==1){
                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.newData, `${Endpoints.systemUsers.systemUser}${this.newData._id ? this.newData._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.getUsers();
                        });
             
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
        },
        confirmUserArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.user.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#archiveUserModal').modal('show');
        },
        setPlatformsForSave(){
            // credentialsUser
            
        },
        async setPlatformsForUser(){
            let credentials = this.credentials;


            for(let i=0; i< credentials.length; i++){

                if(credentials[i].type == 'design-pro' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.designPro = true;
                    this.filtersServicesURL.designPro = credentials[i].accessURL;
                }else
                if(credentials[i].type == 'website' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.website = true;
                    this.filtersServicesURL.website = credentials[i].accessURL;
                }else
                if(credentials[i].type == 'app' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.app = true;
                    this.filtersServicesURL.app = credentials[i].accessURL;
                }else
                if(credentials[i].type == 'email-marketing' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.emailMarketing = true;
                    this.filtersServicesURL.emailMarketing = credentials[i].accessURL;
                }
                else
                if(credentials[i].type == 'crm' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.crm = true;
                    this.filtersServicesURL.crm = credentials[i].accessURL;
                }
                else
                if(credentials[i].type == 'payment' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.payment = true;
                    this.filtersServicesURL.payment = credentials[i].accessURL;
                }
                else
                if(credentials[i].type == 'webmail' && credentials[i].status == 'ACTIVE'){
                    this.filtersServices.webmail = true;
                    this.filtersServicesURL.webmail = credentials[i].accessURL;
                }
            }
        },
        selectUserItem: function(index, item){
            this.errors = [];
            this.indexUser = index;
            this.newData = JSON.parse(JSON.stringify(item));

            if(this.newData.companyUser.userType){
                this.newData.companyUser.userType = item.companyUser.userType._id;
            }             
            this.newData.password = null;
            this.credentialFilters.systemUserId = item._id;
            
            this.getCredentials();

            let data = JSON.parse(JSON.stringify(item));
            this.credential.systemUser = data._id;
            this.errorsUser = [];
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    
        },
        clearUserForm: function (){
            this.errors = [];
            this.newData = {
                status: "ACTIVE",
                firstName: null,
                lastName: null,
                email: null,
                password: null,
                isAcceptGeneralTerms: true,
                type: "USER-COMPANY",
                companyUser:{
                    userType: null,
                    company: null,
                    status: "ACTIVE",
                    photoURL: "https://",
                    userPlatforms:[],
                    subtype: "SIMPLE"
                }
            }
            this.credentials = [];
            this.clearFiltersServices();

        },
        saveUser: async function(){
            this.errors = [];
            this.messageIdToast = null;
            let state = this;

            this.newData.companyUser.company = this.data._id
            await Request.do(this, this.newData._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.newData, `${Endpoints.systemUsers.systemUser}${this.newData._id ? this.newData._id : ''}`,
                (userResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                       text: this.newData._id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(async r => {
                            if(!this.newData._id){
                                let id = userResponse.result._id;
                                /*
                                await this.saveCredential(id, 'design-pro', this.newData.password)
                                await this.saveCredential(id, 'webmail', this.newData.password)
                                await this.saveCredential(id, 'email-marketing', this.newData.password)
                                await this.saveCredential(id, 'app', this.newData.password)
                                await this.saveCredential(id, 'website', this.newData.password)
                                await this.saveCredential(id, 'payment', this.newData.password)
                                await this.saveCredential(id, 'crm', this.newData.password)
                                */
                                this.newData._id = id;
                                this.newData.password = null;
                            }else{
                                await this.editCredential();
                            }
                            await this.getUsers();

                    });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });
        },
        checkUserForm: function (e) {
            this.errors = [];
            if (!this.newData.firstName) {
                this.errors.push(this.$t('response.user.register.firstname.required'));
            }
            if (!this.newData.lastName) {
                this.errors.push(this.$t('response.user.register.lastname.required'));
            }
            if (!this.newData.email) {
                this.errors.push(this.$t('response.user.register.email.required'));
            }
            if (!this.newData.companyUser.userType) {
                this.errors.push(this.$t('response.user.register.company.type.required'));
            }
            if (!this.newData._id && !this.newData.password) {
                this.errors.push(this.$t('response.user.register.password.required'));
            }
            
            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.saveUser();
            }
        },
        saveAddInfo: async function(){
            this.errors = [];
            this.messageIdToast = null;
            let state = this;

            let data = JSON.parse(JSON.stringify(this.addInfo));
            data.company =  this.data._id;


            if(data.templateSite && data.templateSite._id){
                data.templateSite = data.templateSite._id;
           }
            if(data.templateApp && data.templateApp._id){
                data.templateApp = data.templateApp._id;
            }

            if(!data.siteMigration){
                data.siteMigration = {
                    hostURL: null,
                    user: null,
                    password: null,
                }
            }

            if(!data.facebookAds){
                data.facebookAds = {
                    user: null,
                    password: null,
                }
            }

            if(!data.googleAds){
                data.googleAds = {
                    user: null,
                    password: null,
                }
            }

            if(!data.googleAnalytics){
                data.googleAnalytics = {
                    user: null,
                    password: null,
                }
            }

            if(!data.RDStation){
                data.RDStation = {
                    user: null,
                    password: null,
                }
            }

            if(!data.streamingRadio){
                data.streamingRadio = {
                    user: null,
                    password: null,
                }
            }

            if(!data.podcast){
                data.podcast = {
                    user: null,
                    password: null,
                }
            }


            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('jsonData', JSON.stringify(data));

            await Request.do(this, this.addInfo._id  ? 'PUT' : 'POST', Request.getDefaultHeader(this), formData, `${Endpoints.companies.addInfoFormData}${this.addInfo._id ? this.addInfo._id : ''}`,
                (companyResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                        text: this.$t('str.register.put.sucess'),
                        type: "success",
                        timer: 2000
                    }).then(r => {
                        this.getCompany();
                    });
                },
                (error)=>{//ERROR CALLBACK
                    let res = error.response;
                    if (res.status == 500){
                        this.titleToast = this.$t('parresia.msg.toast.advice');
                        
                        if (res.data.messageId === 'parresia.already.exists'){
                            this.messageToast = this.$t('parresia.company.register.error.element.exists');
                        }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                            this.messageToast = this.$t('parresia.msg.error.required.fields');
                        }else{
                            this.messageToast = this.$t('parresia.msg.error.generic');
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });     
        },
        getAddInfo: function(company){
            //################### LOAD COMPANY TYPES
            let state = this;

            Request.do(state, 'POST', Request.getDefaultHeader(state), {company: company}, `${Endpoints.companies.getAddInfo}`,
            (response)=>{//SUCCESS CALLBACK
                if(response.status == 200){
                    state.addInfo = response.result;

                    state.changePaletteOne(state.addInfo.colorPaletteOne)
                    state.changePaletteTwo(state.addInfo.colorPaletteTwo)
                    state.changePaletteThree(state.addInfo.colorPaletteThree)

                }
            },
            (error)=>{//ERROR CALLBACK
                let res = error.response;
                if (res.status == 404){
                    this.titleToast = this.$t('parresia.msg.toast.advice');
                    
                    if (res.data.messageId === 'parresia.already.exists'){
                        this.messageToast = this.$t('parresia.company.register.error.element.exists');
                    }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                        this.messageToast = this.$t('parresia.msg.error.required.fields');
                    }else{
                        this.messageToast = this.$t('parresia.msg.error.generic');
                        this.messageIdToast = res.data.messageId;
                    }

                    $('#toast').modal('show');
                }
            });     
        },
        installmentsOfSetup: function(number){
            if(number && number > 0){

                this.data.valueInstallments = [];

                let totalAmountSetup = this.data.totalAmountSetup;
                let inputPriceSetup = this.data.inputPriceSetup;

                let valueInstallment= {
                    value: null,
                    number: null
                }

                let monthValue = Number((totalAmountSetup - inputPriceSetup) / number); 
            
                for(let i=0; i< number; i++){
                    valueInstallment.value = parseFloat(Math.round(monthValue * 100) / 10000).toFixed(2);
                    valueInstallment.number = i+1;
                    this.data.valueInstallments.push(JSON.parse(JSON.stringify(valueInstallment)));
                    valueInstallment.value = null;
                    valueInstallment.number = null;
                }

            }
        },
        removeDomain(index){
            this.addInfo.domains.splice(index, 1);
        },
        addDomain(item){
            if(item && item.url){
                this.addInfo.domains.push(item);
                this.domainData = {
                    url: null,
                    registerLocal: null,
                    user: null,
                    password: null
                };
            }
        },
        removeEmail(index){
            this.addInfo.webmail.splice(index, 1);
        },
        addEmail(item){
            if(item && item.length > 0){
                this.addInfo.webmail.push(item);
                this.webmail = null;
            }
        },
        removeWebmailMigration(index){
            this.addInfo.webmailMigration.splice(index, 1);
        },
        addWebmailMigration(item){
            if(item && item.currentEmail.length > 0){
                this.addInfo.webmailMigration.push(item);
                this.webmailMigration = {
                    currentHost: null,
                    currentEmail: null,
                    currentPassword: null
                };
            }
        },
        setToBuyDomain(){
            this.data.buyDomain = true;
        },
        changePaletteOne: function(color) {
            let $ref = this.$refs['box1']
            $ref.style.backgroundColor = '#' + color;
        },
        changePaletteTwo: function(color) {
            let $ref = this.$refs['box2']
            $ref.style.backgroundColor = '#' + color;
        },
        changePaletteThree: function(color) {
            let $ref = this.$refs['box3']
            $ref.style.backgroundColor = '#' + color;
        },
        getContractTemplates: function () {
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, Endpoints.companies.listContractTemplates,
                response => {
                    this.contractTemplates = response.result
                },
                error => console.error(error)
            )
        },
        generateContract: function () {
            const data = this.getContractData()

            if (data) {
                const body = {
                    name_document: `Contrato de adesão ao Clube Parresia - ${this.data.socialName}`,
                    templates: {
                        [this.contract.templateId]: data
                    }
                }
                return Request.do(this, 'POST', Request.getDefaultHeader(this), body, Endpoints.companies.generateContract,
                    response => {
                        this.generateContractResult = { 
                            success: true,
                            msg: this.$t('string.msg.company.contract.success')
                         }
                    },
                    error =>{
                        this.generateContractResult = {
                            success: false,
                            msg: this.$t('string.msg.company.contract.requestlimit')
                        }
                        console.error(error)
                    } 
                )
            }

            this.generateContractResult = {
                success: false,
                msg: this.$t('string.msg.company.contract.incompletedata')
            }
            return
        },
        getContractData: function () {
            const d = this.data
            const endereco = `${d.address.address}, nº${d.address.number}, ${d.address.neighborhood} - ${d.address.city} - ${d.address.state} CEP ${d.address.cep}`
            const produtos = this.data.plans[0].platforms.map(p => p.whiteLabelSystem).join(', ')
            
            return {
                cliente: d.socialName,
                endereco,
                cnpj: d.document,
                inscricaoestadual: 'isento',
                responsavel: d.representative.name,
                endereco2: endereco,
                cpf: d.representative.cpf,
                produtos,
                valor: d.totalAmount,
                plano: 'Plano '+d.plans[0].name,
                adicionais: d.additionalResources.map(r => r.name),
                mensalidade: d.amount,
                cidade: this.contract.city,
                dia: this.contract.day,
                mes: this.contract.month,
                ano: this.contract.year,
                nomecontratante: d.socialName,
                representante: d.representative.name,
                cpfrepresentante: d.representative.cpf,
                nometestemunha: d.witness.name,
                cpftestemunha: d.witness.cpf,
                campotestemunha2: null,
                nometestemunha2: null,
                cpftestemunha2: null
            }
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
    

}
</script>