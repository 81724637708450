<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.menu.platforms.website.label.title')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->

          <div class="row">

            <div class="col-md-12">
              <div class="card-box">
                <p class="sub-header">{{ $t("string.msg.platforms.configuration.label.title") }}</p>

                <form>

                  <div>
                    <label>{{$t('string.msg.menu.company.have.domain.label')}}</label>
                    <br/>
                    <div class="m-1">
                      <div class="radio radio-info form-check-inline">
                        <input type="radio" id="inlineRadio1" @click="setOption1" v-model="option1" :value="option1" name="radioInline" checked="">
                        <label for="inlineRadio1">{{$t('string.msg.yes')}}</label>
                      </div>
                      <div class="radio radio-info form-check-inline">
                        <input type="radio" id="inlineRadio2" @click="setOption2" v-model="option2" :value="option2" name="radioInline">
                        <label for="inlineRadio2">{{$t('string.msg.no')}}</label>
                      </div>
                    </div>
                  </div>

                  
                  <div v-if="option1 == true" class="form-row mt-2">

                    <div class="form-group col-md-6">
                      <label for="inputAddress" class="col-form-label">
                          {{$t('string.msg.menu.company.what.domains.label')}}
                      </label>
                      <input type="text" v-model="domain" class="form-control" :placeholder="$t('str.url.site')"/>
                    </div>
                    <div class="form-group col-md-2">
                      <button @click="addDomain(domain)" class="text-dark-gray btn btn-warning w-3 mt-4">{{$t('string.msg.add.item.btn')}}</button>
                    </div>

                    <div v-if="company.domains" class="table-responsive">
                      <label for="inputAddress" class="col-form-label">
                          {{$t('string.msg.list.domain.label.title')}}
                      </label>
                      <table class="table table-centered table-hover mb-0" id="datatable">
                          <thead class="thead-dark">
                              <tr>
                                  <th class="border-top-0">{{$t('string.msg.domain.label.name')}}</th>
                                  <th class="border-top-0">{{$t('string.msg.domain.table.label.remove')}}</th>
                              </tr>
                          </thead>
                          <tbody v-bind:key="item" v-for="item in company.domains" class="cursor-pointer">
                              <tr v-if="item">
                                  <td>
                                      <span v-if="item.length > 0">
                                          {{item}}
                                      </span>
                                  </td>
                                  <td>
                                      <span>
                                           <button @click="removeDomain(item)" class="text-dark-gray btn btn-warning w-3">{{$t('string.msg.x')}}</button>
                                      </span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>


                  <div v-if="option2 == true" class="form-row mt-2">
                    <div class="form-group col-md-12">
                       <div class="mt-3 mb-3">
                          <div class="custom-control custom-checkbox">
                              <input  v-model="company.buyDomain" type="checkbox" class="custom-control-input" id="customCheck1">
                              <label class="custom-control-label" for="customCheck1">{{$t('string.msg.menu.company.need.buy.domain')}}</label>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="company.domains && company.domains.length > 0 && option2 == false" class="form-row mt-2">
                     <label for="inputAddress" class="col-form-label">
                          {{$t('str.link.where.buy.domain')}}
                      </label>
                    <div class="form-group col-md-12">
                        <input type="text" v-model="company.linkDomain" class="form-control" :placeholder="$t('str.url.link.domain')"/>
                    </div>
                  </div>


                  <div v-if="errors.length" class="alert alert-danger mt-3">
                    <p class="lead">{{ $t("response.msg.error.empty.fields") }}</p>
                    <ul>
                      <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                  </div>

                  <div class="text-center mb-2 mt-4">
                    <button @click="checkForm" class="btn btn-danger w-30">{{ $t("string.msg.form.btn.save") }}</button>
                  </div>
                </form>
                                      <!-- Circles which indicates the steps of the form: -->
                                    <div style="text-align:center;margin-top:40px; text-center">
                                        <span class="step step-on"></span>
                                        <span class="step step-off"></span>
                                    </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>

    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation
      :title="titleToast"
      :message="messageToast"
      :cancelText="cancelText"
      :yesText="yesText"
      v-on:confirmation-return="archive"
    />
    <ModalCongrats/>
  </div>
</template>

<script>
import Controller from "./CrtWebSite";
import Spinner from "../../../components/layout/Spinner";
import Toast from "../../../components/layout/Toast";
import Confirmation from "../../../components/layout/Confirmation";
import ModalCongrats from './Modal/ModalCongrats'
export default {
  name: "WebSite",
  components: {
    Spinner,
    Toast,
    Confirmation,
    ModalCongrats
  },
  data() {
    return {
      errors: [],
      valuekey: 0,
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      option1: false,
      option2: false,
      user: null,
      domain: null,
      file: null,
      company: {
            status: null,
            fantasyName: null,
            socialName: null,
            email: null,
            primaryPhone: null,
            logoURL: null,
            document: null,
            buyLogo: null,
            authWithoutLogo: null,
            buyDomain: null,
            domains: [],
            linkDomain: null,
            haveApp: null,
            appName: null,
            appInAppleStore: null,
            typeService: [],
            platforms: []
        },

    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {}
};
</script>

<style></style>
