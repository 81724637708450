var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-xl-12" }, [
        _c("div", { staticClass: "card-box" }, [
          _c("form", [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-form-label",
                    attrs: { for: "inputAddress" }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("string.msg.list.user.label.search")) +
                        "\n                        "
                    )
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.name,
                      expression: "filters.name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-form-label",
                      attrs: { for: "inputAddress" }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t("string.msg.list.companies.label.search")
                          ) +
                          "\n                        "
                      )
                    ]
                  ),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.companies,
                      "close-on-select": true,
                      "track-by": "_id",
                      "custom-label": _vm.customLabelCompany,
                      searchable: true,
                      placeholder: "Selecione"
                    },
                    on: {
                      input: function($event) {
                        return _vm.searchUserByCompany(_vm.company)
                      }
                    },
                    model: {
                      value: _vm.company,
                      callback: function($$v) {
                        _vm.company = $$v
                      },
                      expression: "company"
                    }
                  })
                ],
                1
              )
            ]),
            _c("br"),
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between bd-highlight mb-3"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success w-20",
                    on: { click: _vm.filter }
                  },
                  [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.search")))]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-centered table-hover mb-0",
                attrs: { id: "datatable" }
              },
              [
                _c("thead", { staticClass: "thead-dark" }, [
                  _c("tr", [
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.name"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.email"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.table.list.user.label.level"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.table.list.user.label.company")
                        )
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.table.list.user.label.status")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "tbody",
                    { key: item._id, staticClass: "cursor-pointer" },
                    [
                      item.status == "ACTIVE"
                        ? _c("tr", [
                            _c("td", [
                              item.firstName && item.lastName
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.camelize(item.firstName) +
                                            " " +
                                            _vm.camelize(item.lastName)
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.email
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item.email) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.companyUser.subtype == "ADMIN"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.user.type.company.admin"
                                          )
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : item.companyUser.subtype == "SIMPLE"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.user.type.company.simple"
                                          )
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : item.companyUser.subtype == "ADMIN_MASTER"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.user.type.company.admin.master"
                                          )
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.companyUser &&
                              item.companyUser.company &&
                              item.companyUser.company.fantasyName
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.camelize(
                                            item.companyUser.company.fantasyName
                                          )
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s("X") +
                                          "\n                                "
                                      )
                                    ]
                                  )
                            ]),
                            _c("td", [
                              item.status
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.camelize(_vm.$t(item.status))
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm.errors.length
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c("p", { staticClass: "lead" }, [
                  _vm._v(_vm._s(_vm.$t("response.msg.error.empty.fields")))
                ]),
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error) {
                    return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }