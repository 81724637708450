<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';


export default {
    init: (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        payload.getServices();
    },
    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;

            let data = JSON.parse(JSON.stringify(this.data));
            if (data.price){
                data.price = data.price.toString().split(".").join("").split(",").join(".").split("R$ ").join("");
            }

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('jsonData', JSON.stringify(data));

            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), formData, `${Endpoints.companies.addResource}${this.data._id ? this.data._id : ''}`,
                (companyResponse)=>{//SUCCESS CALLBACK
                    this.errors = [];
                    this.$fire({
                        text: this.data._id ? this.$t('str.register.put.sucess') : this.$t('str.register.saved.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(() => {
                    });
                },
                (error)=>{//ERROR CALLBACK
                    let res = error.response;
                    if (res.status == 500){
                        this.titleToast = this.$t('parresia.msg.toast.advice');
                        
                        if (res.data.messageId === 'parresia.already.exists'){
                            this.messageToast = this.$t('parresia.company.register.error.element.exists');
                        }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                            this.messageToast = this.$t('parresia.msg.error.required.fields');
                        }else{
                            this.messageToast = this.$t('parresia.msg.error.generic');
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });     
        },
        isAdminMaster: function(){
            let user = this.$session.get('user');
            if (user){
                return user.companyUser && user.companyUser.subtype == "ADMIN_MASTER";
            }
            return false;
        },
        getServices: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.services.service}`,
            (response)=>{//SUCCESS CALLBACK
                this.services = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
        clearForm: function(){
            this.errors = [];
            this.data =  {
                name: null,
                platform: null,
                price: null,
                features: null,
                imageURL: 'https://',
                status:"ACTIVE"
            }
        },
        checkForm: function (e) {

            this.errors = [];
            if (!this.data.name) {
                this.errors.push(this.$t('response.additional.resources.register.name.required'));
            }
            
            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.save();
            }
        },
        archive: function(response){
            if (response==1){

                this.errors = [];
                this.messageIdToast = null;
                let state = this;
                Request.do(this, 'DELETE', Request.getDefaultHeader(this), this.data, `${Endpoints.companies.listResources}${this.data._id ? this.data._id : ''}`,
                    (companyResponse)=>{//SUCCESS CALLBACK
                        this.$fire({
                            text: this.$t('str.archive.put.sucess'),
                            type: "success",
                            timer: 2000
                            }).then(r => {
                                this.clearForm();
                        });
             
                    },
                    (error)=>{//ERROR CALLBACK
                
                        let res = error.response;
                        if (res && res.status){
                            this.titleToast = this.$t("string.msg.warning");
                            if (res.data.messageId === 'response.msg.error.empty.fields'){
                                let fields = "";
                                res.data.errors.forEach(function (value) {
                                    fields += state.$t(value.id)+";<br/>";
                                });
                                this.titleToast = this.$t(res.data.messageId);
                                this.messageToast = this.$t(fields);
                            }else{
                                this.messageToast = this.$t(res.data.messageId);
                                this.messageIdToast = res.data.messageId;
                            }

                            $('#toast').modal('show');
                        }
                });
            }
        },
        confirmArchive: function(item){
            this.titleToast = this.$t("string.msg.confirm");
            this.messageToast = this.$t("response.archive.additional.resources.confirmation");
            this.cancelText = this.$t("string.msg.cancel");
            this.yesText = this.$t("string.msg.yes");
            $('#confirmationAdditionalResources').modal('show');
        },
        selectItem: function(item){
            this.errors = [];
            this.data = JSON.parse(JSON.stringify(item));

            if(this.data.platform && this.data.platform._id){
                this.data.platform = this.data.platform._id;
            }
            
            if(this.data.price){
                this.data.price = this.data.price.toFixed(2);
            }

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }

}
</script>