<template>
  <div id="app">
    <Header/> 
    <LeftSideBar />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer';
import LeftSideBar from './components/layout/LeftSideBar/LeftSideBar';
import { mapActions } from 'vuex';

export default {
  name: "app",
  components: {
    Header,
    LeftSideBar,
    Footer
  },
  methods: mapActions('device', [ 'extensionInstallVerified' ]),
  created() {
    window.addEventListener('message', ({ source, data }) => {
      if (source != window) return

      if (data.type && data.type == 'XFRAME_INSTALLED') {
        this.extensionInstallVerified()
      }
    })
  }
}
</script>

<style>
</style>
