var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  this.type == "website"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.wordpress.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "app"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.siberian.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "email-marketing"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.nitronews.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "webmail"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.skymail.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "crm"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.meet.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "payment"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.payment.label.title"
                            )
                          )
                        )
                      ])
                    : this.type == "design-pro"
                    ? _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "string.msg.menu.company.platforms.trakto.label.title"
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "collapse",
                class: { show: !_vm.hasExtension },
                attrs: { id: "extensionWarning" }
              },
              [
                _c("div", { staticClass: "card card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "text-center col-md-12" }, [
                      _c("div", { staticClass: "card-box" }, [
                        _c("h2", { staticClass: "sub-header" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.platforms.label.no.extension"
                                )
                              ) +
                              "\n                  "
                          )
                        ]),
                        _vm.isBrowserSupported
                          ? _c("p", [
                              _c(
                                "a",
                                { attrs: { href: _vm.extensionDownloadLink } },
                                [_vm._v("Clique aqui para instalar.")]
                              )
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n                    Seu navegador não é compatível com nossa plataforma. \n                    Por favor, para ter acesso completo aos nossos serviços instale o \n                    "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.google.com/chrome/"
                                  }
                                },
                                [_vm._v("Chrome")]
                              ),
                              _vm._v(
                                "\n                     ou o\n                    "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://www.mozilla.org/en-US/firefox/new/"
                                  }
                                },
                                [_vm._v("Firefox")]
                              ),
                              _vm._v(".\n                  ")
                            ])
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm.data._id && _vm.hasExtension
              ? _c("embed", {
                  staticStyle: { height: "100vh", width: "100%" },
                  attrs: {
                    id: "iframePlatform",
                    src: this.data.urlToLoad,
                    frameborder: "0",
                    sandbox:
                      "allow-forms allow-modals allow-same-origin allow-popups allow-presentation allow-scripts"
                  }
                })
              : _vm._e()
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }