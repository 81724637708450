var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-xl-12" }, [
        _c("div", { staticClass: "card-box" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-11" }, [
              _c("h4", { staticClass: "header-title mb-4" }, [
                _c("h4", { staticClass: "page-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("string.msg.platforms.list.label.title"))
                  )
                ])
              ])
            ]),
            _vm.items
              ? _c("div", { staticClass: "col-md-1" }, [
                  _c("h4", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("str.total")))
                  ]),
                  _c(
                    "h4",
                    {
                      staticClass: "text-center",
                      staticStyle: { color: "grey" }
                    },
                    [_vm._v(_vm._s(_vm.items.length))]
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-centered table-hover mb-0",
                attrs: { id: "datatable" }
              },
              [
                _c("thead", { staticClass: "thead-dark" }, [
                  _c("tr", [
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.platforms.label.name")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("string.msg.platforms.label.whitelabel"))
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.platforms.label.whitelabelapi")
                        )
                      )
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.platforms.label.logo")))
                    ]),
                    _c("th", { staticClass: "border-top-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("string.msg.platforms.table.label.status")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "tbody",
                    {
                      key: item._id,
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.selectItem(item)
                        }
                      }
                    },
                    [
                      item.status == "ACTIVE"
                        ? _c("tr", [
                            _c("td", [
                              item.name
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.camelize(item.name)) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.whiteLabelSystem
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.camelize(item.whiteLabelSystem)
                                        ) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.whiteLabelSystem
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item.whiteLabelApi) +
                                        "\n                                "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              item.logoURL && item.logoURL != "http://"
                                ? _c("span", [
                                    _c("img", {
                                      attrs: {
                                        src: "" + _vm.domain + item.logoURL,
                                        alt: "image",
                                        height: "60",
                                        width: "60"
                                      }
                                    })
                                  ])
                                : _c("span", [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("str.no.images")))
                                    ]),
                                    _c("br")
                                  ])
                            ]),
                            item.status
                              ? _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t(item.status)) +
                                      "\n                            "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }