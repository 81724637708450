<template>
    <footer 
        class="footer"
        :class="{ 'sidebar-opened': sidebarOpened }"
    >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center">
                    <span style="color: black">{{$t('string.msg.footer.text')}}<a href="https://www.parresia.com/" target="_blank" class="ml-1" style="color: black"><strong>{{$t('string.msg.footer.text.company')}}</strong></a></span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Footer",
  computed: mapState({
    sidebarOpened: state => state.ui.sidebarOpened
  })
}
</script>

<style scoped>
</style>