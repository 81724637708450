<template>
  <div id="wrapper">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 v-if="this.type == 'website'" class="page-title">{{$t('string.msg.menu.company.platforms.wordpress.label.title')}}</h4>
                <h4 v-else-if="this.type == 'app'" class="page-title">{{$t('string.msg.menu.company.platforms.siberian.label.title')}}</h4>
                <h4 v-else-if="this.type == 'email-marketing'" class="page-title">{{$t('string.msg.menu.company.platforms.nitronews.label.title')}}</h4>
                <h4 v-else-if="this.type == 'webmail'" class="page-title">{{$t('string.msg.menu.company.platforms.skymail.label.title')}}</h4>
                <h4 v-else-if="this.type == 'crm'" class="page-title">{{$t('string.msg.menu.company.platforms.meet.label.title')}}</h4>
                <h4 v-else-if="this.type == 'payment'" class="page-title">{{$t('string.msg.menu.company.platforms.payment.label.title')}}</h4>
                <h4 v-else-if="this.type == 'design-pro'" class="page-title">{{$t('string.msg.menu.company.platforms.trakto.label.title')}}</h4>
              </div>
            </div>
          </div>

          <div v-bind:class="{ show: !hasExtension }" class="collapse" id="extensionWarning">
            <div class="card card-body">
              <div class="row">
                <div class="text-center col-md-12">
                  <div class="card-box">
                    <h2 class="sub-header">
                      {{ $t("string.msg.platforms.label.no.extension") }}
                    </h2>
                    <p v-if="isBrowserSupported">
                      <a :href="extensionDownloadLink">Clique aqui para instalar.</a>
                    </p>
                    <p v-else>
                      Seu navegador não é compatível com nossa plataforma. 
                      Por favor, para ter acesso completo aos nossos serviços instale o 
                      <a href="https://www.google.com/chrome/">Chrome</a>
                       ou o
                      <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <embed
            v-if="data._id && hasExtension"
            id="iframePlatform"
            :src="this.data.urlToLoad"
            style="height: 100vh; width:100%;"
            frameborder="0"
            sandbox="allow-forms allow-modals allow-same-origin allow-popups allow-presentation allow-scripts"
          />
        </div>
      </div>
    </div>

    <Spinner />
    <Toast
      :title="titleToast"
      :message="messageToast"
      :messageId="messageIdToast"
    />
    <Confirmation
      :title="titleToast"
      :message="messageToast"
      :cancelText="cancelText"
      :yesText="yesText"
      v-on:confirmation-return="archive"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Controller from "./CrtPlatforms.vue";
import Spinner from "../../../components/layout/Spinner";
import Toast from "../../../components/layout/Toast";
import Confirmation from "../../../components/layout/Confirmation";

export default {
  name: "Platforms",
  components: {
    Spinner,
    Toast,
    Confirmation,
  },
  computed: mapState({
    hasExtension: state => state.device.hasExtension
  }),
  data() {
    return {
      errors: [],
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      data: {
        name: null,
        status: "ACTIVE",
        isHidden: true,
        user: null,
        pass: null,
        accessURL: null,
        urlToLoad: null,
        documents: [],
        templates: [],
        formats: [],    
      },
      isBrowserSupported: false,
      extensionDownloadLink: '',
      loading: false
    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {
    if (this.hasExtension) this.loadData()
    else this.checkBrowser()
  },
  watch: {
    $route(to, from) {
      Controller.init(this);
      if (this.hasExtension) this.loadData()
    },
    hasExtension(to) {
      if (to) this.loadData()
    }
  },
  props: {
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
