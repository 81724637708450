<template>
  <div class='popup' v-if="!closed">
    <button 
      v-if="closable"
      class='close-btn' 
      type="button"
      aria-label="Close" 
      v-on:click="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <slot></slot>
  </div>
</template>

<script>

export default {
  methods: {
    close () {
      this.closed = true
    }
  },
  data () {
    return {
      closed: false
    }
  },
  props: {
    closable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
  div.popup {
    z-index: 100;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px rgba(0,0,0,0.1);
  }

  button.close-btn {
    position: absolute;
    top: 12px;
    right: 5px;
    border: none;
    background: none;
    font-size: 32px;
    line-height: 0%;
  }
</style>