<template>
    <div id="wrapper" :key="valuekey">
          <div class="content-page">
              <div class="content">
                  <div class="container-fluid">
                      <!-- Form row -->
                      <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <h4 class="page-title">{{$t('string.msg.additional.informations.label.title')}}</h4>

                            </div>
                        </div>
                       </div>

                      <div class="row">
                          <div class="col-md-12">
                              <div class="card-box">
                                  <form>
                                   <div class="form-group col-md-12">
                                        
                                        <h6 class="text-right">   
                                            <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                            <span v-if="data.status == 'ACTIVE'" style="color: green">
                                                {{$t(data.status)}} 
                                            </span>
                                            <span v-if="data.status == 'ARCHIVED'" style="color: blue">
                                                {{$t(data.status)}} 
                                            </span>
                                        </h6>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.label.instagram')}}
                                            </label>
                                            <input type="text" v-model="data.instagramURL" class="form-control" :placeholder="$t('string.msg.company.label.instagram.placeholder')">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                <code class="highlighter-rouge">*</code>
                                                {{$t('string.msg.company.label.facebook')}}
                                            </label>
                                            <input type="text" v-model="data.facebookURL" class="form-control" :placeholder="$t('string.msg.company.label.facebook.placeholder')">
                                        </div>
                                    </div>

                                     <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.label.googlebusiness')}}
                                            </label>
                                            <input type="text" v-model="data.googleBusinessURL" class="form-control" :placeholder="$t('string.msg.company.label.googlebusiness.placeholder')">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.label.youtube')}}
                                            </label>
                                            <input type="text" v-model="data.youtubeURL" class="form-control" :placeholder="$t('string.msg.company.label.youtube.placeholder')">
                                        </div>
                                    </div>

                                     <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.label.whatsapp')}}
                                            </label>
                                            <input v-model="data.whatsApp" type="text" class="form-control" :placeholder='$t("string.msg.company.label.whatsapp.placeholder")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                        </div>
                                         <div class="form-group col-md-6">
                                            <label for="inputAddress" class="col-form-label">
                                                {{$t('string.msg.company.label.telegram')}}
                                            </label>
                                            <input v-model="data.telegram" type="text" class="form-control" :placeholder='$t("string.msg.company.label.telegram.placeholder")' v-mask="['(##) ####-####', '(##) #####-####']"/>
                                        </div>
                                    </div>


                                    <div v-if="errors.length" class="alert alert-danger">
                                        <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                        <ul>
                                            <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                        </ul>
                                    </div>
                                    <div class="mb-2"></div>
                                    <div class="form-row text-center">
                                        <div class="form-group col-md-12">
                                            <button @click="save" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                        </div>
                                    </div>

                                  </form>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <FlashMessage/>
        <ModalAddress/>
      </div>
</template>

<script>
import Controller from './CrtCompany';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
export default {
  name: 'CompanyUpdate',
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        valuekey: 0,
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        data: {
            status: "ACTIVE",
            company: null,
            instagramURL: "https://",
            facebookURL: "https://",
            googleBusinessURL: "https://",
            telegram: "",
            whatsApp: "",
            youtubeURL: "https://"
        },
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted(){
  }
}
</script>

<style>
</style>
