var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("string.msg.configuration.clients")))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  { staticClass: "card-box" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mt-4 mb-4 btn btn-danger w-20",
                        on: { click: _vm.showModal }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.upload.company")))]
                    ),
                    _c(
                      "b-tabs",
                      [
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.company.register.label"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [_c("Companies", { attrs: { company: "company" } })],
                          1
                        ),
                        _vm.company && _vm.company_id
                          ? _c(
                              "b-tab",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("string.msg.plans.label")
                                              )
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3309609025
                                )
                              },
                              [
                                _vm.company && _vm.company_id
                                  ? _c("div")
                                  : _vm._e()
                              ]
                            )
                          : _c("b-tab", {
                              attrs: { disabled: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.plans.label")
                                          )
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            }),
                        _vm.company && _vm.company_id
                          ? _c(
                              "b-tab",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.platforms.label"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3891367501
                                )
                              },
                              [
                                _vm.company && _vm.company_id
                                  ? _c(
                                      "div",
                                      [
                                        _c("Platforms", {
                                          attrs: { company: _vm.company }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _c("b-tab", {
                              attrs: { disabled: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.platforms.label")
                                          )
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            }),
                        _vm.company && _vm.company_id
                          ? _c(
                              "b-tab",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function() {
                                        return [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "string.msg.additional.ser"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3168004858
                                )
                              },
                              [
                                _vm.company && _vm.company_id
                                  ? _c("div")
                                  : _vm._e()
                              ]
                            )
                          : _c("b-tab", {
                              attrs: { disabled: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("string.msg.additional.ser")
                                          )
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _c("ModalCompany", { on: { "confirmation-return": _vm.checkData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }