var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "col-md-11" }, [
                  _c("h4", { staticClass: "header-title mb-4" }, [
                    _c("h4", { staticClass: "page-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "string.msg.additional.resources.label.description"
                          )
                        )
                      )
                    ])
                  ])
                ]),
                _vm.data._id
                  ? _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("h6", { staticClass: "text-right" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("string.msg.ratinng.label.status")) +
                              ":"
                          )
                        ]),
                        _vm.data.status == "ACTIVE"
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                "
                              )
                            ])
                          : _vm._e(),
                        _vm.data.status == "ARCHIVED"
                          ? _c("span", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.data._id &&
                _vm.data.status != "ARCHIVED" &&
                _vm.isAdminMaster()
                  ? _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-dark-gray btn btn-warning w-100",
                            on: { click: _vm.confirmArchive }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.status.archive")))]
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _c("code", { staticClass: "highlighter-rouge" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.$t("string.msg.additional.resources.name")
                            ) +
                            "\n                                "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.name,
                          expression: "data.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.additional.resources.name"
                        )
                      },
                      domProps: { value: _vm.data.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _c("code", { staticClass: "highlighter-rouge" }, [
                        _vm._v("*")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "string.msg.additional.platforms.label.platform"
                            )
                          ) +
                          "\n                                  "
                      )
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.platform,
                            expression: "data.platform"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("string.msg.element.select.selectitem")
                              )
                            )
                          ]
                        ),
                        _vm._l(_vm.services, function(item) {
                          return _c(
                            "option",
                            { key: item._id, domProps: { value: item._id } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.camelize(
                                    item.whiteLabelSystem
                                      ? item.whiteLabelSystem
                                      : item.name
                                  )
                                )
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("string.msg.userType.price")) +
                            "\n                                "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "money",
                          rawName: "v-money",
                          value: _vm.money,
                          expression: "money"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.price,
                          expression: "data.price"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "salary",
                        type: "text",
                        placeholder: _vm.$t("string.msg.userType.price")
                      },
                      domProps: { value: _vm.data.price },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "price", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.additional.resources.placeholder.features"
                              )
                            ) +
                            "\n                                "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.features,
                          expression: "data.features"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.additional.resources.placeholder.features"
                        )
                      },
                      domProps: { value: _vm.data.features },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "features", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c(
                  "label",
                  {
                    staticClass: "col-form-label",
                    attrs: { for: "inputAddress" }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.$t("string.msg.platforms.table.label.image")
                        ) +
                        "\n                        "
                    )
                  ]
                ),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("input", {
                      ref: "file",
                      staticClass: "form-control",
                      attrs: { type: "file", id: "logo" },
                      on: {
                        change: function($event) {
                          return _vm.handleFileUpload()
                        }
                      }
                    })
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group col-md-6 text-center" },
                    [
                      _vm.data.imageURL
                        ? _c("img", {
                            attrs: {
                              src: "" + _vm.domain + _vm.data.imageURL,
                              alt: "image",
                              height: "120",
                              width: "150"
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _vm.errors.length
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(
                          _vm._s(_vm.$t("response.msg.error.empty.fields"))
                        )
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.errors, function(error) {
                          return _c("li", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c("br"),
                _c("div", { staticClass: "form-row text-center" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: { click: _vm.clearForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger w-50",
                        on: { click: _vm.checkForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("List", { on: { "load-item": _vm.selectItem } })
        ],
        1
      ),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }