var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.user.change.email.label.email.title")
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      "\n                                  " +
                        _vm._s(
                          _vm.$t(
                            "string.msg.user.change.email.label.email.description"
                          )
                        ) +
                        "\n                              "
                    )
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                              " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.change.email.label.email"
                                )
                              ) +
                              "\n                                          "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.email,
                            expression: "data.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "“true”" },
                        domProps: { value: _vm.data.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "email", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                              " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.change.email.label.nemail"
                                )
                              ) +
                              "\n                                          "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newEmail,
                            expression: "newEmail"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.user.change.email.placeholder.nemail"
                          )
                        },
                        domProps: { value: _vm.newEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newEmail = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-right justify-content-between bd-highlight mb-3"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-20",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ]
                  ),
                  _c("br"),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.save }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }