var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.valuekey, attrs: { id: "wrapper" } }, [
    _c("div", { staticClass: "content-page" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "page-title-box" }, [
                _c("h4", { staticClass: "page-title" }, [
                  _vm._v(_vm._s(_vm.$t("string.msg.menu.report.label.title")))
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "card-box" },
                [
                  _c(
                    "b-tabs",
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: { active: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("string.msg.report.company")
                                      )
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [_c("Companies")],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("string.msg.report.user"))
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [_c("Users")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }