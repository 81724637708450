<template>
    <div class="modal fade" id="archiveUserModal" tabindex="-1" role="dialog" aria-labelledby="archiveUserModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="archiveUserModal">{{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p v-html="message"></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="$emit('confirmation-return', 0)">{{cancelText}}</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('confirmation-return', 1)">{{yesText}}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["title", "message", "cancelText", "yesText"],
        methods:{
        }
    }
</script>