// estado inicial
const state = () => ({
  hasExtension: false
})

const getters = { /* sem getters por enquanto */ }

const mutations = {
  SET_HAS_EXTENSION(state, hasExtension) {
    state.hasExtension = !!hasExtension
  }
}

const actions = {
  extensionInstallVerified({ commit }) {
    commit('SET_HAS_EXTENSION', true)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}