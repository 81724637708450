<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.menu.report.label.title')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
              <div class="col-md-12">
                    <div class="card-box">

                        <b-tabs>
                            
                            <b-tab active >
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.report.company')}}</strong>
                                </template>
                                <Companies/>
                            </b-tab>

                            <b-tab>
                                <template v-slot:title>
                                    <strong>{{$t('string.msg.report.user')}}</strong>
                                </template>
                                <Users/>
                            </b-tab>
                        </b-tabs>

                  </div> <!-- end card-box -->
              </div> <!-- end col -->
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>

import Controller from "./CrtReport";
import Users from "./User/ListUser.vue";
import Companies from "./Company/ListCompanies.vue";
export default {
  name: "Report",
  components: {
      Users,
      Companies
    
  },
  data() {
    return {
      valuekey: 0,
    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {}
};
</script>
