var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "router-link",
        {
          staticClass: "cursor-pointer",
          attrs: { to: _vm.serviceLink, type: _vm.key }
        },
        [
          _c("base-side-bar-item", {
            staticClass: "service-list-item",
            attrs: {
              icon: _vm.serviceIcon,
              label: _vm.serviceLabel,
              labelClass: "service-list-item-label"
            }
          })
        ],
        1
      )
    : _c(
        "base-side-bar-item",
        {
          staticClass: "service-disabled service-list-item",
          attrs: {
            icon: _vm.serviceIcon,
            label: _vm.serviceLabel,
            labelClass: "service-list-item-label"
          }
        },
        [
          _c("span", { staticClass: "upgrade-warning" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("string.msg.platform.menu.upgrade.label")) +
                "\n  "
            )
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }