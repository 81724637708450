import ParresiaAPIService, { ERROR_TYPES } from '../../api/ParresiaAPIService'

const AuthStatus = Object.freeze({
  ONGOING: 'ONGOING',
  ERROR: 'ERROR',
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  NO_CONNECTION: 'NO_CONECTION',
  LOGGED_OUT: 'LOGGED_OUT'
})

// estado inicial
const state = () => ({
  user: {},
  jwt: localStorage.getItem('parresiaJWT'), // temporário
  status: null,
  error: null
})

const getters = { /* sem getters por enquanto */ }

const mutations = {
  SET_SESSION(state, { user, token }) {
    state.user = user
    state.token = token
  },
  SET_STATUS(state, status, error = null) {
    state.status = status
    state.error = error
  }
}

const api = new ParresiaAPIService()
const actions = {
  login({ commit }, email, password) {
    commit('SET_STATUS', AuthStatus.ONGOING)
    api
    .authenticate(email, password)
    .then((token, user) => {
      commit('SET_SESSION', { user, token })
      commit('SET_STATUS', AuthStatus.AUTHENTICATED)
    })
    .catch((error) => {
      switch (error.type) {
        case ERROR_TYPES.UNAUTHORIZED:
          commit('SET_STATUS', AuthStatus.UNAUTHORIZED)
          break
        case ERROR_TYPES.NO_CONNECTION: 
          commit('SET_STATUS', AuthStatus.NO_CONNECTION, error)
          break 
        case ERROR_TYPES.BAD_REQUEST:
        case ERROR_TYPES.REQUEST_ERROR:
        case ERROR_TYPES.UNEXPECTED:
        default:
          commit('SET_STATUS', AuthStatus.ERROR, error)
      }
    })
  },
  logout({ commit }) {
    commit('SET_USER', {})
    commit('SET_STATUS', AuthStatus.LOGGED_OUT)
  },
  loadUserData({ commit, dispatch, state }, jwt) {
    api
    .getJWTUserData(jwt)
    .then(user => commit('SET_USER', user))
    .catch(({ type }) => {
      if (type === ERROR_TYPES.UNAUTHORIZED) {
        dispatch('logout')
      }
      console.error(error.reason)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}