<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: (payload) => {

        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        setTimeout(async function(){
         $('#spinner').modal('toggle');

        let response = (await Request.do(payload, 'GET', Request.getDefaultHeader(payload), {}, `${Endpoints.systemUsers.getMe}`)).data;
        if (response && response.status==200){
            if(response.result.companyUser && response.result.companyUser.company && response.result.companyUser.company._id){
                let company = response.result.companyUser.company._id;
                payload.data.company = company;
                payload.getAddInfo(company);   
            }
        } 
        $('#spinner').modal('toggle');
        }, 200);

    },

    methods:{
        save: function(){
            this.errors = [];
            this.messageIdToast = null;
            
            Request.do(this, this.data._id ? 'PUT' : 'POST', Request.getDefaultHeader(this), this.data, `${Endpoints.companies.addInfo}${this.data._id ? this.data._id : ''}`,
                (companyResponse)=>{//SUCCESS CALLBACK
                    this.$fire({
                        text: this.$t('str.register.put.sucess'),
                        type: "success",
                        timer: 2000
                        }).then(r => {
                        });
                },
                (error)=>{//ERROR CALLBACK
                    let res = error.response;
                    if (res.status == 500){
                        this.titleToast = this.$t('parresia.msg.toast.advice');
                        
                        if (res.data.messageId === 'parresia.already.exists'){
                            this.messageToast = this.$t('parresia.company.register.error.element.exists');
                        }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                            this.messageToast = this.$t('parresia.msg.error.required.fields');
                        }else{
                            this.messageToast = this.$t('parresia.msg.error.generic');
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });     
        },
        getAddInfo: function(company){
            //################### LOAD COMPANY TYPES
            let state = this;

            Request.do(state, 'POST', Request.getDefaultHeader(state), {company: company}, `${Endpoints.companies.getAddInfo}`,
            (response)=>{//SUCCESS CALLBACK
                if(response.status == 200){
                    state.data = response.result;
                }
                },
                (error)=>{//ERROR CALLBACK
                    let res = error.response;
                    if (res.status == 404){
                        this.titleToast = this.$t('parresia.msg.toast.advice');
                        
                        if (res.data.messageId === 'parresia.already.exists'){
                            this.messageToast = this.$t('parresia.company.register.error.element.exists');
                        }else if (res.data.messageId === 'parresia.msg.error.required.fields'){
                            this.messageToast = this.$t('parresia.msg.error.required.fields');
                        }else{
                            this.messageToast = this.$t('parresia.msg.error.generic');
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
            });     
        },
        showText(text) {
            this.text = text
        },
        clearText() {
            setTimeout( () => this.text = '', 500);
        },
        configMessage :function(title, message, position, time){
            const config ={
               title: title,
                message: message,
                position: position,
                x: window.innerWidth /60,
                y: window.innerHeight /1.3,
                time: time,
            }
            return config
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
