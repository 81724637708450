<template>
  <div id="wrapper">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.configuration.clients')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
              <div class="col-md-12">
                    <div class="card-box">
                      
                      <button @click="showModal" class="mt-4 mb-4 btn btn-danger w-20">{{$t('string.msg.upload.company')}}</button>
                      <b-tabs>  

                          <b-tab>
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.company.register.label')}}</strong>
                              </template>
                              <Companies company="company"/>
                          </b-tab>

                          <b-tab v-if="company && company_id">
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.plans.label')}}</strong>
                              </template>
                               <div v-if="company && company_id">
                                <!-- <Plans :company="company"/> -->
                              </div>
                          </b-tab>
                          <b-tab v-else disabled>
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.plans.label')}}</strong>
                              </template>
                          </b-tab>

                          <b-tab v-if="company && company_id">
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.platforms.label')}}</strong>
                              </template>
                              <div v-if="company && company_id">
                                <Platforms :company="company"/>
                              </div>
                          </b-tab>
                          <b-tab v-else disabled>
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.platforms.label')}}</strong>
                              </template>
                          </b-tab>

                           <b-tab v-if="company && company_id">
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.additional.ser')}}</strong>
                              </template>
                              <div v-if="company && company_id">
                                <!-- <AdditionalUsers :company="company"/> -->
                              </div>
                          </b-tab>
                          <b-tab v-else disabled>
                              <template v-slot:title>
                                  <strong>{{$t('string.msg.additional.ser')}}</strong>
                              </template>
                          </b-tab>

                      </b-tabs>

                  </div> <!-- end card-box -->
              </div> <!-- end col -->
          </div>
        </div>  
      </div>
    </div>
    <ModalCompany v-on:confirmation-return="checkData" />
  </div>
</template>

<script>
import Controller from "./CrtCustomer";
import Companies from "../Company/Crud/Company.vue";
// import Plans from "../Company/Plans/Plans.vue";
import Platforms from "../Credential/Credential.vue";
// import AdditionalUsers from "../Company/AdditionalUsers/AdditionalUsers.vue";
import ModalCompany from './ModalAddCompany';
export default {
  name: "Customer",
  props: ["company"],
  watch:{
      'company'(){
        this.company = this.company;
      }
  },
  components: {
    
    Companies,
    // Plans,
    Platforms,
    ModalCompany
    // AdditionalUsers
  },
  data() {
    return {
      valuekey: 0,
    };
  },
  methods: Controller.methods,
  created() {
  },
  mounted() {
    Controller.init(this);
  }
  
};
</script>
