var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "col-md-11" }, [
                  _c("h4", { staticClass: "header-title mb-4" }, [
                    _c("h4", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("string.msg.plans.label.title")))
                    ])
                  ])
                ]),
                _c("form", [
                  _vm.data._id
                    ? _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("h6", { staticClass: "text-right" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("string.msg.ratinng.label.status")
                              ) + ":"
                            )
                          ]),
                          _vm.data.status == "ACTIVE"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e(),
                          _vm.data.status == "ARCHIVED"
                            ? _c("span", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t(_vm.data.status)) +
                                    " \n                                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.data._id &&
                  _vm.data.status != "ARCHIVED" &&
                  _vm.isAdminMaster()
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "form-group col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "text-dark-gray btn btn-warning w-100",
                              on: { click: _vm.confirmArchive }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("string.msg.status.archive"))
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("string.msg.plans.label.name")) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.name,
                            expression: "data.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("string.msg.plans.label.name")
                        },
                        domProps: { value: _vm.data.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "name", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "string.msg.user.type.placeholder.platforms"
                                )
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-wrap" },
                        _vm._l(_vm.services, function(item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "container-switch" },
                            [
                              _c("label", { staticClass: "switch" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.platforms,
                                      expression: "data.platforms"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: item._id,
                                    "true-value": item._id,
                                    "false-value": null
                                  },
                                  domProps: {
                                    value: item._id,
                                    checked: Array.isArray(_vm.data.platforms)
                                      ? _vm._i(_vm.data.platforms, item._id) >
                                        -1
                                      : _vm._q(_vm.data.platforms, item._id)
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.data.platforms,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? item._id : null
                                      if (Array.isArray($$a)) {
                                        var $$v = item._id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.data,
                                              "platforms",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.data,
                                              "platforms",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.data, "platforms", $$c)
                                      }
                                    }
                                  }
                                }),
                                _c("span", { staticClass: "slider round" })
                              ]),
                              _c("span", { staticClass: "label-swith" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.camelize(
                                      item.whiteLabelSystem
                                        ? item.whiteLabelSystem
                                        : item.name
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.plans.label.features")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.features,
                            expression: "data.features"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("string.msg.plans.label.features")
                        },
                        domProps: { value: _vm.data.features },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.data, "features", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.plans.label.user.types")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userType,
                              expression: "userType"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { id: "userType" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.userType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: null }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("string.msg.select.selector.item")
                                )
                              )
                            ]
                          ),
                          _vm._l(_vm.userTypes, function(item) {
                            return _c(
                              "option",
                              { key: item, domProps: { value: item } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "form-group col-md-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _c("code", { staticClass: "highlighter-rouge" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$t("str-msg-quantity")) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantity,
                            expression: "quantity"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("str-msg-quantity")
                        },
                        domProps: { value: _vm.quantity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.quantity = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "text-dark-gray btn btn-warning w-3 mt-4",
                          on: {
                            click: function($event) {
                              return _vm.addUser(_vm.userType, _vm.quantity)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.add.item.btn")))]
                      )
                    ])
                  ]),
                  _vm.data.userTypes && _vm.data.userTypes.length > 0
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-centered table-hover mb-0",
                            attrs: { id: "datatable" }
                          },
                          [
                            _c("thead", { staticClass: "thead-dark" }, [
                              _c("tr", [
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("string.msg.domain.label.name")
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("string.msg.user.label.quantity")
                                    )
                                  )
                                ]),
                                _c("th", { staticClass: "border-top-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.domain.table.label.remove"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._l(_vm.data.userTypes, function(item, index) {
                              return _c(
                                "tbody",
                                { key: item, staticClass: "cursor-pointer" },
                                [
                                  item
                                    ? _c("tr", [
                                        _c("td", [
                                          item.user && item.user.name
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(item.user.name) +
                                                    "\n                                            "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.quantity
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(item.quantity) +
                                                    "\n                                            "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "text-dark-gray btn btn-warning w-3",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeTypeUser(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("string.msg.x"))
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.$t(
                                    "string.msg.plans.label.additinal.resources"
                                  )
                                ) +
                                "\n                                "
                            )
                          ]
                        ),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.additionalResources,
                            placeholder: "Selecione",
                            deselectLabel: "Pressione para remover",
                            "close-on-select": true,
                            selectLabel: "Pressione para selecionar",
                            selectedLabel: "Selecionado",
                            multiple: true,
                            "track-by": "name",
                            "custom-label": _vm.customLabelAdditionalResources,
                            "show-labels": false
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangePlans()
                            }
                          },
                          model: {
                            value: _vm.data.additionalResources,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "additionalResources", $$v)
                            },
                            expression: "data.additionalResources"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.plans.label.price.setup")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          },
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.setupPrice,
                            expression: "data.setupPrice",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "setupPrice",
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.plans.label.price.setup"
                          )
                        },
                        domProps: { value: _vm.data.setupPrice },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "setupPrice",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label",
                          attrs: { for: "inputAddress" }
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t("string.msg.plans.label.price.menthly")
                              ) +
                              "\n                                "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "money",
                            rawName: "v-money",
                            value: _vm.money,
                            expression: "money"
                          },
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.monthlyPrice,
                            expression: "data.monthlyPrice",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "monthlyPrice",
                          type: "text",
                          placeholder: _vm.$t(
                            "string.msg.plans.label.price.menthly"
                          )
                        },
                        domProps: { value: _vm.data.monthlyPrice },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "monthlyPrice",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _vm.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            _vm._s(_vm.$t("response.msg.error.empty.fields"))
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", { key: error }, [
                              _vm._v(_vm._s(error))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("div", { staticClass: "form-row text-center" }, [
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary w-50",
                          on: { click: _vm.clearForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                      )
                    ]),
                    _c("div", { staticClass: "form-group col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger w-50",
                          on: { click: _vm.checkForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                      )
                    ])
                  ])
                ])
              ]),
              _c("List", { on: { "load-item": _vm.selectItem } })
            ],
            1
          )
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }