<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card-box">
             

                  <div class="form-row">
                    <div class="col-md-11">
                            <h4 class="header-title mb-4"><h4 class="page-title">{{$t('string.msg.company.list.label.title')}}</h4></h4>
                    </div>
                    <div class="col-md-1" v-if="items">
                        <h4 class="text-center">{{$t('str.total')}}</h4>
                        <h4 class="text-center" style="color:grey">{{items.length}}</h4>
                    </div>
                </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.company.table.label.type')}}
                            </label>
                            <select id="companyType" v-model="filters.typeService" class="form-control">
                                <option :value="''" selected>{{$t('string.msg.select.selector.item')}}</option>
                                <option :value="item._id" v-bind:key="item" v-for="item in companyTypes">{{camelize($t(item.name))}}</option>
                            </select>
                        </div>  

                        <div class="form-group col-md-4">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.companies.search.label.name')}}
                            </label>
                            <input type="text" v-model="filters.name" class="form-control">
                        </div>

                        <div class="form-group col-md-4">
                            <label for="inputAddress" class="col-form-label">
                                {{$t('string.msg.athletes.table.label.status')}}
                            </label>
                            <select id="types" v-model="filters.status" class="form-control">
                                     <option value="ACTIVE" selected>{{$t('string.msg.element.select.active')}}</option>
                                     <option value="ARCHIVED">{{$t('string.msg.element.select.archived')}}</option>
                            </select>
                        </div>  
                    </div>

                    <br/>
                    <div class="d-flex justify-content-between bd-highlight mb-3">
                        <button @click="filter" class="btn btn-success w-20">{{$t('string.msg.form.btn.search')}}</button>
                    </div>

                <div class="table-responsive">
                    <table class="table table-centered table-hover mb-0" id="datatable">
                        <thead class="thead-dark">
                            <tr>
                                <th class="border-top-0">{{$t('string.msg.company.label.fantasyName')}}</th>
                                 <th class="border-top-0">{{$t('string.msg.company.label.phone')}}</th>
                                <th class="border-top-0">{{$t('string.msg.company.table.label.type')}}</th>
                                <th class="border-top-0">{{$t('string.msg.company.table.label.plan')}}</th>
                                <th class="border-top-0">{{$t('string.msg.company.table.label.status')}}</th>
                                <th class="border-top-0">{{$t('string.msg.company.table.label.logo')}}</th>
                            </tr>
                        </thead>
                        <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer" @click="selectItem(item)">
                            <tr  >
                                <td>
                                    <span>
                                        {{camelize(item.fantasyName)}}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{camelize(item.primaryPhone)}}
                                    </span>
                                </td>
                                <td v-if="item.typeService">
                                    <span  v-bind:key="services" v-for="services in item.typeService">   
                                        <label >{{camelize($t(services))}}</label><br/> 
                                    </span>
                                </td>
                                <td>
                                    <span   v-if="item.plans && item.plans.length > 0">   
                                        <label>{{camelize(item.plans[0].name)}}</label><br/> 
                                    </span>
                                </td>
                                <td v-if="item.status" >
                                    {{$t(item.status)}}
                                </td>
                                <td>
                                    <span v-if="item.logoURL && item.logoURL != 'http://' ">
                                         <img  v-bind:src="`${domain}${item.logoURL}`" alt="image" height="60" width="60">
                                    </span>
                                    <span v-else>
                                           <label >{{$t('str.no.images')}}</label><br/> 
                                    </span>
                                </td>
                                

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
          <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    </div>
</template>

<script>
import Controller from './CrtListCompanies';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Vue from 'vue';
Vue.prototype.$eventHub = new Vue();
export default {
  name: 'ListCompanies',
  props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
  components: {
    Spinner,
    Toast,
  },
  data() {
      return {
        errors: [],
        titleToast: null,
        messageToast: null,
        messageIdToast: null,
        items: [],
        companyTypes: [],
        filters: {
            typeService: '',
            name: '',
            status:'ACTIVE',
        },
      }
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);

    var vm = this;
    this.$eventHub.$on('refreshList', function(){
        vm.filter();
    })
    
  },
  mounted(){
  }
}
</script>

<style>
</style>
