var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("string.msg.terms.label.title")))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  { staticClass: "card-box" },
                  [
                    _c("h4", { staticClass: "header-title mb-4" }, [
                      _c("h4", { staticClass: "page-title" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t("string.msg.terms.system.label.title")
                            ) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _c(
                      "b-tabs",
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: { active: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.term.geral.label.title"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [_c("ModalTermOfUse")],
                          1
                        ),
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "string.msg.term.privacy.policy.label.title"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [_c("ModalPrivacyPolicy")],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }