<template>
    <div id="wrapper" :key="valuekey">

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <h4 class="page-title">{{$t('string.msg.list.user.label.colaborators')}}</h4>
                        <form class="mt-4">

                                <div v-if="newData._id" class="form-group col-md-12">
                                    <h6 class="text-right">   
                                        <span>{{$t('string.msg.ratinng.label.status')}}:</span>
                                        <span v-if="newData.status == 'ACTIVE'" style="color: green">
                                            {{$t(newData.status)}} 
                                        </span>
                                        <span v-if="newData.status == 'ARCHIVED'" style="color: blue">
                                            {{$t(newData.status)}} 
                                        </span>
                                    </h6>
                                </div>
                                <div v-if="newData._id && newData.status != 'ARCHIVED' && isAdminMaster()" class="form-row">
                                    <div class="form-group col-md-2">
                                        <button v-on:click="confirmArchive" class="text-dark-gray btn btn-warning w-100">{{$t('string.msg.status.archive')}}</button>
                                    </div>
                                </div>

                                <div class="form-row" >
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress" class="col-form-label">
                                                <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                            {{$t('string.msg.form.crud.user.label.name')}}
                                        </label>
                                        <input type="text" v-model="newData.firstName" :placeholder="$t('string.msg.form.crud.user.placeholder.name')" class="form-control">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress" class="col-form-label">
                                                <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                            {{$t('string.msg.form.crud.user.label.lastname')}}
                                        </label>
                                        <input type="text" v-model="newData.lastName" :placeholder="$t('string.msg.form.crud.user.placeholder.lastname')" class="form-control">
                                    </div>
                                </div>
                                <div class="form-row" >
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress" class="col-form-label">
                                            {{$t('string.msg.form.crud.user.label.email')}}
                                        </label>
                                        <input type="text" v-model="newData.email" :placeholder="$t('string.msg.form.crud.user.placeholder.email')" class="form-control">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress" class="col-form-label">
                                                <code v-if="!newData._id" class="highlighter-rouge">*</code>
                                            {{$t('string.msg.form.crud.user.label.password')}}
                                        </label>
                                        <input type="password" v-model="newData.password" :placeholder="$t('string.msg.form.crud.user.placeholder.password')" class="form-control">
                                    </div>
                                </div> 
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress" class="col-form-label">
                                            <code class="highlighter-rouge">*</code>
                                            {{$t('string.msg.table.list.user.label.access.level')}}
                                        </label>
                                        <select id="companyType" v-model="newData.companyUser.subtype" class="form-control">
                                            <option :value="null" selected>{{$t('string.msg.select.selector.item')}}</option>
                                            <option value="ADMIN_MASTER" selected>{{$t('string.msg.user.type.admin.master')}}</option>
                                            <option value="ADMIN_PARRESIA">{{$t('string.msg.user.type.admin')}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row text-center">
                                    <div class="form-group col-md-6">
                                        <button @click="cancelAdd" class="btn btn-secondary w-50">{{$t('string.msg.form.btn.clear')}}</button>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button @click="checkForm" class="btn btn-danger w-50">{{$t('string.msg.form.btn.save')}}</button>
                                    </div>
                                </div>
                             

                                <div class="table-responsive mt-4" v-if="users && users.length > 0">
                                    <p class="sub-header">
                                        {{$t('string.msg.list.company.user.label.colaborators')}}
                                    </p>
                                    <table class="table table-centered table-hover mb-0"> 
                                        <thead class="thead-dark" >
                                            <tr>
                                                <th scope="col">{{$t('string.msg.menu.crud.user.table.label.name')}}</th>
                                                <th scope="col">{{$t('string.msg.menu.crud.user.table.label.email')}}</th>
                                                <th scope="col">{{$t('string.msg.user.user.level.label')}}</th>
                                            </tr>
                                        </thead>
                                            <tbody v-bind:key="item._id" v-for="item in users" class="cursor-pointer">
                                                <tr v-if="item.status == 'ACTIVE'" @click="selectItem(item)">
                                                    <td>
                                                        <span v-if="item.firstName && item.lastName">
                                                            {{toUpperCase(item.firstName) + ' ' + toUpperCase(item.lastName) }}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.email">
                                                            {{item.email}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.companyUser && item.companyUser.subtype">
                                                            {{camelize($t(item.companyUser.subtype))}}
                                                        </span>
                                                    </td>
                                                
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                <div v-if="errors.length" class="alert alert-danger">
                                    <p class="lead">{{$t('response.msg.error.empty.fields')}}</p>
                                    <ul>
                                        <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                                    </ul>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Spinner />
        <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
        <Confirmation :title="titleToast" :message="messageToast" :cancelText="cancelText" :yesText="yesText" v-on:confirmation-return="archive"/>
</div>
</template>

<script>
import Controller from './CrtUser';
import Spinner from '../../../components/layout/Spinner';
import Toast from '../../../components/layout/Toast';
import Confirmation from './Confirmation';
export default {
    name: 'User',
    props: ["valuekey"],
    watch:{
        'valuekey'(){
        }
    },
    components: {
    Spinner,
    Toast,
    Confirmation
    },

    data() {
        return {
            errors: [],
            titleToast: null,
            messageToast: null,
            messageIdToast: null,
            formCrud: null,
            items: null,
            users:[],
            services:[],
            newData: {
                status: "ACTIVE",
                firstName: null,
                lastName: null,
                email: null,
                password: null,
                isAcceptGeneralTerms: true,
                type: "USER-COMPANY",
                companyUser:{
                    company: null,
                    status: "ACTIVE",
                    photoURL: "https://",
                    userPlatforms:[],
                    subtype: null
                }
            },
            data: {
                status: "ACTIVE",
                firstName: null,
                lastName: null,
                email: null,
                password: null,
                isAcceptGeneralTerms: true,
                type: "USER-COMPANY",
                companyUser:{
                    company: null,
                    status: "ACTIVE",
                    photoURL: "https://",
                    userPlatforms:[],
                    subtype: null
                }
            },
            filters: {
                company: null
            },
        }
    },
    methods: Controller.methods,
    created() {
    Controller.init(this);
    },
    mounted(){
    }
    }
    </script>


    <style>
    </style>
