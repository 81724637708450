var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "string.msg.menu.platforms.email.marketing.label.title"
                        )
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("string.msg.platforms.configuration.label.title")
                      )
                    )
                  ]),
                  _c("form", [
                    _c("div", { staticClass: "form-row mt-2" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "string.msg.menu.company.email.marketing.label.title"
                              ) + "😃"
                            )
                          )
                        ])
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }