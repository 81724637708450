var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("h4", { staticClass: "page-title" }, [
                _vm._v(_vm._s(_vm.$t("string.msg.list.templates.label")))
              ]),
              _c("form", { staticClass: "mt-4" }, [
                _vm.data._id
                  ? _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("h6", { staticClass: "text-right" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("string.msg.ratinng.label.status")) +
                              ":"
                          )
                        ]),
                        _vm.data.status == "ACTIVE"
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                    "
                              )
                            ])
                          : _vm._e(),
                        _vm.data.status == "ARCHIVED"
                          ? _c("span", { staticStyle: { color: "blue" } }, [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.$t(_vm.data.status)) +
                                  " \n                                    "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.data._id &&
                _vm.data.status != "ARCHIVED" &&
                _vm.isAdminMaster()
                  ? _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-dark-gray btn btn-warning w-100",
                            on: { click: _vm.confirmArchive }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.status.archive")))]
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        !_vm.data._id
                          ? _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t("string.msg.form.crud.user.label.name")
                            ) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.name,
                          expression: "data.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.form.crud.user.placeholder.name"
                        )
                      },
                      domProps: { value: _vm.data.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _c("code", { staticClass: "highlighter-rouge" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.$t("string.msg.templates.label")) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.platform,
                            expression: "data.platform"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "userType" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("string.msg.select.selector.item"))
                            )
                          ]
                        ),
                        _vm._l(_vm.services, function(item) {
                          return _c(
                            "option",
                            { key: item._id, domProps: { value: item._id } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.camelize(
                                    item.whiteLabelSystem
                                      ? item.whiteLabelSystem
                                      : item.name
                                  )
                                )
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t(
                                "string.msg.form.template.placeholder.link"
                              )
                            ) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.linkURL,
                          expression: "data.linkURL"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "string.msg.form.template.placeholder.link"
                        )
                      },
                      domProps: { value: _vm.data.linkURL },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "linkURL", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _c("code", { staticClass: "highlighter-rouge" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.$t("string.msg.template.label.image")) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _c("input", {
                      ref: "file",
                      staticClass: "form-control",
                      attrs: { type: "file", id: "photo" },
                      on: {
                        change: function($event) {
                          return _vm.handleFileUpload()
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-form-label",
                        attrs: { for: "inputAddress" }
                      },
                      [
                        _c("code", { staticClass: "highlighter-rouge" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.$t("string.msg.type.label")) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.type,
                            expression: "data.type"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "userType" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("string.msg.select.selector.item"))
                            )
                          ]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "SITE", selected: "" } },
                          [_vm._v(_vm._s(_vm.$t("string.msg.website")))]
                        ),
                        _c(
                          "option",
                          { attrs: { value: "APP", selected: "" } },
                          [_vm._v(_vm._s(_vm.$t("string.msg.app")))]
                        )
                      ]
                    )
                  ]),
                  _vm.data.photoURL && _vm.data.photoURL != "https://"
                    ? _c(
                        "div",
                        { staticClass: "form-group col-md-6 text-center" },
                        [
                          _vm.data.photoURL
                            ? _c("img", {
                                attrs: {
                                  src: "" + _vm.domain + _vm.data.photoURL,
                                  alt: "image",
                                  height: "150",
                                  width: "150"
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-row text-center mt-4" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-50",
                        on: { click: _vm.cancelAdd }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.clear")))]
                    )
                  ]),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger w-50",
                        on: { click: _vm.checkForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                    )
                  ])
                ]),
                _vm.templates && _vm.templates.length > 0
                  ? _c("div", { staticClass: "table-responsive mt-4" }, [
                      _c("p", { staticClass: "sub-header" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.$t("string.msg.list.template")) +
                            "\n                                "
                        )
                      ]),
                      _c(
                        "table",
                        {
                          staticClass: "table table-centered table-hover mb-0"
                        },
                        [
                          _c("thead", { staticClass: "thead-dark" }, [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.table.template.name")
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.table.template.platform")
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.table.template.linkURL")
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.table.template.image")
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("string.msg.table.template.status")
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._l(_vm.templates, function(item) {
                            return _c(
                              "tbody",
                              { key: item._id, staticClass: "cursor-pointer" },
                              [
                                item.status == "ACTIVE"
                                  ? _c(
                                      "tr",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.selectItem(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          item.name
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.camelize(item.name)
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.platform && item.platform.name
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.camelize(
                                                        item.platform.name
                                                      )
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.linkURL
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(item.linkURL) +
                                                    "\n                                                    "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.photoURL &&
                                          item.photoURL != "https://"
                                            ? _c("span", [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "" +
                                                      _vm.domain +
                                                      item.photoURL,
                                                    alt: "image",
                                                    height: "60",
                                                    width: "60"
                                                  }
                                                })
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.status
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(
                                                      _vm.$t(item.status)
                                                    ) +
                                                    "\n                                                    "
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm.errors.length
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(
                          _vm._s(_vm.$t("response.msg.error.empty.fields"))
                        )
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.errors, function(error) {
                          return _c("li", { key: error }, [
                            _vm._v(_vm._s(error))
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }