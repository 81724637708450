var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.valuekey, attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "content-page" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "page-title-box" }, [
                  _c("h4", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(this.data.type))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card-box" }, [
                  _c("p", { staticClass: "sub-header" }, [
                    _vm._v(
                      _vm._s(_vm.$t("string.msg.platforms.label.description"))
                    )
                  ]),
                  _c("form", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm.$t("string.msg.product.brand.label.id")
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data._id,
                              expression: "data._id"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.data._id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "_id", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm.$t("string.msg.platforms.label.name")
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.name,
                              expression: "data.name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.data.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "name", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm.data.type == "website"
                        ? _c("div", { staticClass: "form-group col-md-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-form-label",
                                attrs: { for: "inputAddress" }
                              },
                              [
                                _c(
                                  "code",
                                  { staticClass: "highlighter-rouge" },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.$t(
                                        "string.msg.platforms.label.accessURL"
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.accessURL,
                                  expression: "data.accessURL"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.data.accessURL },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data,
                                    "accessURL",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm.$t("string.msg.platforms.label.user")
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.user,
                              expression: "data.user"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.data.user },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "user", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-form-label",
                            attrs: { for: "inputAddress" }
                          },
                          [
                            _c("code", { staticClass: "highlighter-rouge" }, [
                              _vm._v("*")
                            ]),
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm.$t("string.msg.platforms.label.pass")
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.pass,
                              expression: "data.pass"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "password" },
                          domProps: { value: _vm.data.pass },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "pass", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              _vm._s(_vm.$t("response.msg.error.empty.fields"))
                            )
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between bd-highlight mb-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger w-20",
                            on: { click: _vm.save }
                          },
                          [_vm._v(_vm._s(_vm.$t("string.msg.form.btn.save")))]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("Spinner"),
      _c("Toast", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          messageId: _vm.messageIdToast
        }
      }),
      _c("Confirmation", {
        attrs: {
          title: _vm.titleToast,
          message: _vm.messageToast,
          cancelText: _vm.cancelText,
          yesText: _vm.yesText
        },
        on: { "confirmation-return": _vm.archive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }