<template>
  <div id="wrapper" :key="valuekey">
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">{{ $t('string.msg.menu.platforms.collection.label.title')}}</h4>
              </div>
            </div>
          </div>
          <!-- Form row -->
          <div class="row">
            <div class="col-md-12">
              <div class="card-box">
                <p class="sub-header">{{ $t("string.msg.platforms.configuration.label.title") }}</p>

                <form>

                  <div v-if="errors.length" class="alert alert-danger">
                    <p class="lead">{{ $t("response.msg.error.empty.fields") }}</p>
                    <ul>
                      <li v-bind:key="error" v-for="error in errors">{{ error }}</li>
                    </ul>
                  </div>

                  <!-- <div class="text-center mb-2 mt-4">
                    <button @click="checkForm" class="btn btn-danger w-30">{{ $t("string.msg.form.btn.save") }}</button>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>

    <Spinner />
    <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    <Confirmation
      :title="titleToast"
      :message="messageToast"
      :cancelText="cancelText"
      :yesText="yesText"
      v-on:confirmation-return="archive"
    />
  </div>
</template>

<script>
import Controller from "./CrtCollection";
import Spinner from "../../../components/layout/Spinner";
import Toast from "../../../components/layout/Toast";
import Confirmation from "../../../components/layout/Confirmation";
export default {
  name: "WebSite",
  components: {
    Spinner,
    Toast,
    Confirmation,
  },
  data() {
    return {
      errors: [],
      valuekey: 0,
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      user: null,
      domain: null,
      file: null,
      company: {
            status: null,
            fantasyName: null,
            socialName: null,
            email: null,
            primaryPhone: null,
            logoURL: null,
            document: null,
            buyLogo: null,
            authWithoutLogo: null,
            buyDomain: null,
            domains: [],
            linkDomain: null,
            haveApp: null,
            appName: null,
            appInAppleStore: null,
            typeService: [],
            platforms: []
        },

    };
  },
  methods: Controller.methods,
  created() {
    Controller.init(this);
  },
  mounted() {}
};
</script>

<style></style>
