var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("div", [
      _c("i", { class: _vm.icon }),
      _c("span", { class: _vm.labelClass }, [
        _vm._v(_vm._s(_vm.$t(this.label)))
      ])
    ]),
    _c("div", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }