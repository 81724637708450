<script>
import Common from '../../common/Common';
import Request from '../../common/Request'
import Endpoints from '../../common/Endpoints'

export default {
    init: (payload) => {
        Common.loadFunctionsElements();
    },
    methods: {
        loadUserInfos: function () {
            if (this.$session){
                let user = this.$session.get('user');
                this.user = user ? user : {};
                this.user.firstName = Common.camelize(this.user.firstName);

                this.loadWebmailData()
            }
        },
        async loadWebmailData () {
            try {
                const url = Endpoints.credencials.credencialList.replace('{type}', 'webmail')
                const res = await Request.do(this, 'GET', Request.getDefaultHeader(this), null, url)

                if (!res.data) return
                
                this.webmailData = {
                    user: res.data.result.user,
                    pass: res.data.result.pass
                }
            } catch (error) {
                console.error(error)
            }
        },
        webmailLogin () {
            if (!this.webmailData.user) return

            function buildElement (tag, attrs) {
                const element = document.createElement(tag)
                for (let attr in attrs) {
                    element[attr] = attrs[attr]
                }
                return element
            }

            const form = buildElement(
                'form', {
                    method: 'post',
                    action: "https://webmail.skymail.com.br/index.php?_task=login",
                    target: '_blank'
                }
            )

            const action = buildElement(
                'input', {
                    type: 'hidden',
                    name: '_action',
                    value: 'login'
                }
            )

            const task = buildElement(
                'input', {
                    type: 'hidden',
                    name: '_task',
                    value: 'login'
                }
            )


            const user = buildElement(
                'input', {
                    type: 'text',
                    name: '_user',
                    value: this.webmailData.user
                }
            )

            const pass = buildElement(
                'input', {
                    type: 'text',
                    name: '_pass',
                    value: this.webmailData.pass
                }
            )

            form
            .appendChild(action)
            .appendChild(task)
            .appendChild(user)
            .appendChild(pass)

            document.body.appendChild(form)

            form.submit()

            document.body.removeChild(form)
        },
        loggout: function(){
            this.$router.push({ path: '/Logout' });
            window.location.href = "?#/Logout";
        },
        hasType: function(type){
            if (this.user){
                return this.user.serviceTypes.findIndex(x => x == type) > -1;
            }else{
                return false;
            }
        },
        isAdminMaster: function(){
            if (this.user){
                return this.user.companyUser && this.user.companyUser.subtype == "ADMIN_MASTER";
            }
            return false;
        },
        isAdminParresia: function(){
            if (this.user){
                return this.user.companyUser && this.user.companyUser.subtype == "ADMIN_PARRESIA";
            }
            return false;
        },
        isAdmin: function(){
            if (this.user){
                return this.user.companyUser && this.user.companyUser.subtype == "ADMIN";
            }
            return false;
        },
        isSimple: function(){
            if (this.user){
                return this.user.companyUser && this.user.companyUser.subtype == "SIMPLE";
            }
            return false;
        },
        to: function(path){
            this.$router.push({ path: path });
        }
    }
}
</script>
