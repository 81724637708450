<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: async (payload) => {
        const el = document.body;
        el.removeAttribute("class");
        el.classList.add('authentication-page');
        el.classList.add('authentication-bg');
        el.classList.add('authentication-bg-pattern');

        payload.getCompanyTypes();
    },
    methods:{
        save: function(){
            
            $('#spinner').modal('toggle');
            this.errors = [];
            this.messageIdToast = null;
            let state = this;

            let data = JSON.parse(JSON.stringify(this.data));
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('jsonData', JSON.stringify(data));

            Request.do(this, 'POST', Request.getDefaultHeader(this), formData, `${Endpoints.companies.companyWithLogo}`,
                (companyResponse)=>{//SUCCESS CALLBACK

                    this.data._id = companyResponse.result._id;
                    this.$session.destroy();
                    this.$session.start();
                    this.$session.set('company', companyResponse.result);
                    this.$router.push({ path: '/users' });
                },
                (error)=>{//ERROR CALLBACK
                    $('#spinner').modal('toggle');
                    let res = error.response;
                    if (res && res.status){
                        this.titleToast = this.$t("string.msg.warning");
                        if (res.data.messageId === 'response.msg.error.empty.fields'){
                            let fields = "";
                            res.data.errors.forEach(function (value) {
                                fields += state.$t(value.id)+";<br/>";
                            });
                            this.titleToast = this.$t(res.data.messageId);
                            this.messageToast = this.$t(fields);
                        }else if (res.data.messageId === 'response.already.exists'){
                            this.messageToast = this.$t('response.company.already.exists');
                        }else{
                            this.messageToast = this.$t(res.data.messageId);
                            this.messageIdToast = res.data.messageId;
                        }

                        $('#toast').modal('show');
                    }
                    console.log(JSON.stringify(error));
            });
        },
        getCompanyTypes: function(){
            //################### LOAD COMPANY TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.helpers.companyType}`,
            (response)=>{//SUCCESS CALLBACK
                this.companyTypes = response.results;
            },
            (error)=>{//ERROR CALLBACK
                console.log(error);
            });
        },
        checkForm: function (e) {
            this.errors = [];
            if (!this.data.fantasyName) {
                this.errors.push(this.$t('response.company.register.fantasy.name.required'));
            }
            if (!this.data.primaryPhone) {
                this.errors.push(this.$t('response.company.register.phone.required'));
            }
            if (this.data.typeService.length == 0) {
                this.errors.push(this.$t('response.user.register.typeService.required'));
            }    
            

            if (this.errors.length>0){
                e.preventDefault();
            }else{
                this.errors = [];
                this.save();
            }
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,

    }
}
</script>
