<template>
  <div 
    v-if="show"
    class="overlay"
  >
    <base-popup 
      :closable="false" 
      class="popup" 
    >
      <p class='warning'>
        {{ $t("string.msg.platforms.label.no.extension") }}
      </p>
      <a 
        v-if="!!extensionDownloadUrl"
        :href="extensionDownloadUrl"
        target="_blank"
        class="extension-link"
        @click="close"
      >
        <button class='download-btn'>
          <div>
            {{ $t("string.msg.extension.download.label") }}
          </div>
          <i class='fa fa-download'/>
        </button>
      </a>
      <p v-else>
        Seu navegador não é compatível com nossa plataforma. 
        Por favor, para ter acesso completo aos nossos serviços instale o 
        <a 
          href="https://www.google.com/chrome/"
          target="_blank"
          @click="close"
        >
          Chrome
        </a>
          ou o
        <a 
          href="https://www.mozilla.org/en-US/firefox/new/"
          target="_blank"
          @click="close"
        >
          Firefox
        </a>.
      </p>
    </base-popup>
  </div>
</template>
<script>
import BasePopup from './BasePopup'

function getExtensionDownloadUrl () {
  const extensionURLs = {
    chrome:
      "https://chrome.google.com/webstore/detail/parresia-x-frame/cllikmhomgkbhibdonbbfolhheafnhgd?hl=pt-BR",
    firefox:
      "https://gitlab.com/YgorSilva/xframe-firefox/uploads/ca1e7f3d4e3fb226e090e2ee502ed8f1/parresia_x_frame-1.0.0-fx.xpi",
  }
  const agent = navigator.userAgent.match(/(chrome|firefox)/i)
  if (!agent) return
  
  return extensionURLs[agent[0].toLowerCase()]
}

export default {
  components: {
    BasePopup
  },
  data () {
    const extensionDownloadUrl = getExtensionDownloadUrl()
    return {
      extensionDownloadUrl,
      show: true
    }
  },
  methods: {
    close () {
      this.show = false
    }
  }
}
</script>

<style scoped>
  .overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 100;
  }

  .popup {
    position: relative;
    bottom: 0; 
    left: 0;
    max-width: 420px;
    padding: 40px 30px 20px;
  }

  @media (max-width: 768px) {
    .popup {
      max-width: 90vw;
    }
  }

  p.warning {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 40px;
  }

  button.download-btn {
    font-size: 1rem;
    color: white;
    line-height: initial;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 10px;
    border-radius: 3px;
    background-color: #188ae2;
    width: 100%;
    border: none;
    display: flex;
    flex-flow: nowrap;
  }

  button > div {
    width: 100%;
  }

  button > i {
    position: relative;
  }
</style>