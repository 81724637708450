<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: async(payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        payload.domain = Endpoints.domain;//nao remover, usado para apresentar a foto
        
        payload.getCompanyTypes();
        payload.filter();

      
    },
    methods:{
        filter: function(){
            let state = this;
            state.items = [];
            Request.do(state, 'POST', Request.getDefaultHeader(state), this.filters, `${Endpoints.companies.filter}`,
            (response)=>{//SUCCESS CALLBACK
                state.items = response.results;
            },
            (error)=>{//ERROR CALLBACK
                console.log(error);
            });
        },
        getCompanyTypes: function(){
            //################### LOAD COMPANY TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.helpers.companyType}`,
            (response)=>{//SUCCESS CALLBACK
                this.companyTypes = response.results;
            },
            (error)=>{//ERROR CALLBACK
                console.log(error);
            });
        },
        selectItem: function(item){
            this.$emit('load-item', item);
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
