import axios from 'axios'

const domain =  process.env.NODE_ENV == 'development' 
  ? 'http://localhost:3000'
  : 'https://api-gateway-parresia.herokuapp.com';

const endpoints = {
  addresses: {
    address: `${domain}/api/address/v1/`,
    geolocation: `${domain}/api/address/geo/v1/`,
  },
  systemUsers: {
    reject: `${domain}/api/users/system/status/reject/v1/`,
    delete: `${domain}/api/users/delete/v1/`,
    systemUser: `${domain}/api/users/v1/`,
    systemUserFormdata: `${domain}/api/users/formdata/v1/`,
    login: `${domain}/api/users/system/login/v1/`,
    gencode: `${domain}/api/users/system/password/gencode/v1/`,
    checkCode: `${domain}/api/users/system/password/checkcode/v1/`,
    resetPass: `${domain}/api/users/system/password/change/online/v1/`,
    resetPassNotLog: `${domain}/api/users/system/password/change/v1/`,
    getMe: `${domain}/api/users/me/v1/`,
    ListUserByType: `${domain}/api/users/bytype/v1/`,
    filter: `${domain}/api/users/system/search/customeruser/v1/`,
    companyUser: `${domain}/api/users/system/search/companyuser/v1/`,
    updateTerms: `${domain}/api/users/system/update/v1/`,
    count: `${domain}/api/generic/count/byfilter/v1/`,
    approveDocument: `${domain}/api/users/system/status/aprove/v1/`,
    waitingForApproval: `${domain}/api/users/system/search/customeruser/waiting/approval/v1`,
    typeUser: `${domain}/api/users/types/v1/`,
  },
  templates: {
    formData: `${domain}/api/company/templates/formdata/v1/`,
    template: `${domain}/api/company/templates/v1/`,
    filter: `${domain}/api/company/templates/filter/v1/`,
  },
  sponsors: {
    filter: `${domain}/api/company/sponsors/filter/v1/`,
    sponsor:  `${domain}/api/company/sponsors/v1/`
  },
  services: {
    active: `${domain}/api/company/services/active/v1/`,
    service: `${domain}/api/company/services/v1/`,
    createWithImage: `${domain}/api/company/services/formdata/v1/`,
  },
  plans: {
    plan: `${domain}/api/company/plans/v1/`,
  },
  companies: {
    addInfo: `${domain}/api/company/addinfos/v1/`,
    addInfoFormData: `${domain}/api/company/addinfos/formdata/v1/`,
    getAddInfo: `${domain}/api/company/addinfos/bycompany/v1/`,
    company: `${domain}/api/company/v1/`,
    companyWithLogo: `${domain}/api/company/formdata/v1/`,
    listActivies: `${domain}/api/company/list/v1/`,
    listByType: `${domain}/api/company/list/bytype/v1/`,
    companyUser: `${domain}/api/users/system/search/companyuser/v1/`,
    filter: `${domain}/api/company/filter/v1/`,
    addResource: `${domain}/api/company/addresouces/formdata/v1/`,
    listResources: `${domain}/api/company/addresouces/v1/`,
    listContractTemplates: `${domain}/api/company/contracts/templates/v1`,
    generateContract: `${domain}/api/company/contracts/v1`
  },
  credencials: {
    filter: `${domain}/api/credencials/filter/v1/`,
    credencial: `${domain}/api/credencials/v1/`,
    credencialList: `${domain}/api/credencials/listfromuser/v1?type={type}`,
    authSiberian: `${domain}/api/credencials/authsiberian/v1`,
    authPayment: `${domain}/api/credencials/authpayment/v1`,
    authTrakto: `${domain}/api/credencials/authtrakto/v1`,
    authSite: `${domain}/api/credencials/authsite/v1`,
  },
  cep: {
    find: `https://viacep.com.br/ws/`,
  },
  helpers: {
    companyType: `${domain}/api/helpers/data/company/types/v1/`,
  },
  location: {
    geolocation: `https://maps.googleapis.com/maps/api/geocode/json?latlng=`,
  }
}

const ERROR_TYPES = Object.freeze({
  UNAUTHORIZED:  'UNAUTHORIZED',
  BAD_REQUEST:   'BAD_REQUEST',
  SERVER_ERROR:  'SERVER_ERROR',
  REQUEST_ERROR: 'REQUEST_ERROR',
  NO_CONNECTION: 'NO_CONNECTION',
})

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
}

export default class ParresiaAPIService {

  static ERROR_TYPES = ERROR_TYPES
  
  constructor() {
    // instância do axios com as configurações default para as requests
    this.axios = axios.create({
      headers: DEFAULT_HEADERS
    })

    // erro ao enviar requests faltando token de autenticação
    this.axios.interceptors.request.use(
      config => {
        if (
          config.headers.hasOwnProperty('Authorization')
          && !config.headers.Authorization
        ) { 
          throw new Error('No "Authorization" headers set')
        }
        return config
      },
      error => error
    )
    
    // configura middleware para tratar acess token expirado
    this.axios.interceptors.response.use(
      response => response,
      reason => {
        if (!reason.response) return

        const { status, message } = reason.response
        if (status == 401 && message == 'access token has expired.') {
          this.refreshToken(localStorage.accessToken)
          .then(jwt => localStorage.accessToken = jwt)
        }
      }
    )

    // configura middleware para expor os erros
    this.axios.interceptors.response.use(
      response => response,
      reason => {
        const error = { type: ERROR_TYPES.UNEXPECTED, reason }

        if (reason.response) {
          const { status } = reason.response
          if (status == 401) {
            error.type = ERROR_TYPES.UNAUTHORIZED
          }
        } else if (reason.request) {
          error.type = ERROR_TYPES.REQUEST_ERROR
        } else {
          error.type = ERROR_TYPES.NO_CONNECTION
        }

        return error
      }
    )
  }

  authenticate(email, password) {
    return this.axios
    .post(endpoints.systemUsers.login, { email, password })
    .then(({ data: { token }}) => token)
  }

  getUserCredentials(jwt) {
    const config = this.getAuthHeaderConfig(jwt)
    return this.axios
    .get(endpoints.credencials.listFromUser, config)
    .then(response => {
      return response.result.map(c => ({
        id: c._id,
        type: c.type,
        user: c.user,
        pass: c.pass,
        accessURL: c.accessURL,
      }))
    })
  }

  getAuthHeaderConfig(jwt) {
    return {
       headers: { 'Authorization': jwt }, 
    }
  }

  refreshToken(jwt) {

  }
}

export { ERROR_TYPES }