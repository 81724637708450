<template>
  <li>
    <div>
      <i :class='icon'/>
      <span :class='labelClass'>{{ $t(this.label) }}</span>
    </div>
    <div>
      <slot></slot>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      icon: String,
      label: String,
      labelClass: String,
    }
  }
</script>