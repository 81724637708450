var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      staticStyle: { top: "100px" },
      attrs: {
        id: "modalCredential",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modalCredential",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-content" }, [
          _c("br"),
          _c("div", { staticClass: "content text-center" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("string.msg.credential.company.access.title"))
              )
            ])
          ]),
          _vm.company && _vm.company.emailAccess && _vm.company.passwordAccess
            ? _c("div", { staticClass: "modal-body" }, [
                _c("h6", [_vm._v(_vm._s(_vm.company.emailAccess))]),
                _c("h6", [_vm._v(_vm._s(_vm.company.passwordAccess))])
              ])
            : _c("div", { staticClass: "modal-body text-center" }, [
                _c("h6", { staticStyle: { color: "gray" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("string.msg.credential.no.informed.by.company")
                    )
                  )
                ])
              ]),
          _c(
            "div",
            {
              staticClass: "modal-footer",
              staticStyle: { "align-self": "center" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirmation-return", 1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("string.msg.ok")))]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "backgroundColorRed card-radius-top topBorderCard text-center"
      },
      [
        _c("img", {
          attrs: {
            src: "assets/images/parresia_logo.png",
            alt: "parresia",
            height: "60px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }