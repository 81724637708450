<script>
import Endpoints from '../../components/common/Endpoints';
import Request from '../../components/common/Request';

export default {
    init: (payload) => {
        
    },
    methods:{
    }
}
</script>
