var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "left-side-menu", class: { closed: _vm.closed } },
    [
      _c("div", { staticClass: "slimscroll-menu" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "600px",
              position: "relative",
              display: "block"
            },
            attrs: { id: "sidebar-menu" }
          },
          [
            _c(
              "ul",
              { staticClass: "metismenu", attrs: { id: "side-menu" } },
              [
                _c("li", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("string.msg.platforms.label.title")) +
                      "\n        "
                  )
                ]),
                _vm._l(_vm.credentialItems, function(credential) {
                  return _c("service-side-bar-item", {
                    key: credential.type,
                    attrs: {
                      "service-icon": credential.i,
                      "service-label": credential.label,
                      "service-link": credential.route,
                      active: credential.active
                    }
                  })
                }),
                _c("br"),
                _c("br"),
                _c(
                  "router-link",
                  { staticClass: "cursor-pointer", attrs: { to: "/logout" } },
                  [
                    _c("li", [
                      _c("i", {
                        staticClass: "dripicons-power cursor-pointer"
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("string.msg.menu.dashboard.label.exit"))
                        )
                      ])
                    ])
                  ]
                )
              ],
              2
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }