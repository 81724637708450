<template>
    <div class="modal fade" id="modalCompany" tabindex="-1" role="dialog" aria-labelledby="modalCompany" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">

                  <h5 class="modal-title" id="confirmationModalLabel">{{$t('string.msg.modal.search.company.title')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <form>
                      <div class="form-row">

                          <div class="form-group col-md-6">
                                <label for="inputAddress" class="col-form-label">
                                    {{$t('string.msg.companies.search.label.name')}}
                                </label>
                                <input type="text" v-model="filters.name" class="form-control">
                            </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <div class="justify-content-between">
                              <button @click="filter" class="btn btn-success w-100">{{$t('string.msg.form.btn.search')}}</button>
                            </div>
                          </div>
                        </div>
                      
                        <div class="form-row">

                            <div class="table-responsive">
                               <div id="table" style="height:450px;overflow-y:scroll">
                                <table class="table table-centered table-hover mb-0" id="datatable">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th class="border-top-0">{{$t('string.msg.company.label.fantasyName')}}</th>
                                            <th class="border-top-0">{{$t('string.msg.company.label.phone')}}</th>
                                            <th class="border-top-0">{{$t('string.msg.company.table.label.type')}}</th>
                                            <th class="border-top-0">{{$t('string.msg.company.table.label.platforms')}}</th>
                                            <th class="border-top-0">{{$t('string.msg.company.table.label.status')}}</th>
                                            <th class="border-top-0">{{$t('string.msg.company.table.label.logo')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:key="item._id" v-for="item in items" class="cursor-pointer"  data-dismiss="modal" v-on:click="$emit('confirmation-return', item )">
                                        <tr>
                                            <td>
                                                <span v-if="item.fantasyName">
                                                    {{camelize(item.fantasyName)}}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="item.primaryPhone">
                                                    {{item.primaryPhone}}
                                                </span>
                                            </td>
                                            <td v-if="item.typeService">
                                                <span  v-bind:key="services" v-for="services in item.typeService">   
                                                    <label >{{camelize($t(services))}}</label><br/> 
                                                </span>
                                            </td>
                                            <td v-if="item.platforms">
                                                <span  v-bind:key="platform" v-for="platform in item.platforms">   
                                                    <label v-if="platform.service && platform.service.name" >{{camelize($t(platform.service.name))}}</label><br/> 
                                                </span>
                                            </td>
                                            <td v-if="item.status" >
                                                {{$t(item.status)}}
                                            </td>
                                            <td>
                                                <span v-if="item.logoURL && item.logoURL != 'http://' ">
                                                    <img  v-bind:src="`${domain}${item.logoURL}`" alt="image" height="60" width="60">
                                                </span>
                                                <span v-else>
                                                    <label >{{$t('str.no.images')}}</label><br/> 
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                          </div>
                        </div>
                    </div>
                </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="$emit('confirmation-return', 0 )">{{$t('string.msg.term.btn.exit')}}</button>
                </div>
            </div>
        </div>
         <Spinner />
         <Toast :title="titleToast" :message="messageToast" :messageId="messageIdToast" />
    </div>
</template>

<script>
import Toast from '../../components/layout/Toast';
import Spinner from '../../components/layout/Spinner';
import Controller from './CrtModalAddCompany';
import JwPagination from 'jw-vue-pagination';
import Vue from 'vue'
Vue.component('jw-pagination', JwPagination);

    export default {
        components: {
        },
        data() {
            return {
                errors: [],
                titleToast: null,
                messageToast: null,
                messageIdToast: null,
                items: [],
                itemSelected: {},
                filters: {
                    name: '',
                    status: 'ACTIVE'
                },
            }
        },
        methods: Controller.methods,
        created() {
        },
        mounted(){
            Controller.init(this);
        }
     
}
</script>

