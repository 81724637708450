<template>
  <div id="wrapper">
    <div
        class="content-page"
        :class="{ 'sidebar-opened': sidebarOpened }"
    >
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">
                  {{ $t("string.msg.menu.dashboard.label.title") }}
                </h4>
              </div>
            </div>
          </div>

          <iframe
            id="NewsParresiaFeedIFrame"
            src="https://news.parresia.com/"
            style="height: 100vh; width: 100%"
            frameborder="0"
            sandbox="allow-forms allow-modals allow-same-origin allow-popups allow-presentation allow-scripts"
          ></iframe>
        </div>
      </div>
      <Spinner />
      <Toast
        :title="titleToast"
        :message="messageToast"
        :messageId="messageIdToast"
      />
      <extension-warning-popup v-if="showExtensionWarning" />
    </div>
  </div>
</template>

<script>
import Controller from "./CrtDashboard";
import Spinner from "../../components/layout/Spinner";
import Toast from "../../components/layout/Toast";
import ExtensionWarningPopup from '../../components/ExtensionWarningPopup.vue';
import { mapState } from 'vuex';

export default {
  name: "Dashboard",
  components: {
    Spinner,
    Toast,
    ExtensionWarningPopup
  },
  data() {
    return {
      errors: [],
      titleToast: null,
      messageToast: null,
      messageIdToast: null,
      data: {},
      user: {},
      showExtensionsWarning: false
    };
  },
  computed: mapState({
    sidebarOpened: state => state.ui.sidebarOpened,
    noExtension: state => !state.device.hasExtension
  }),
  methods: Controller.methods,
  created() {},
  mounted() {
    Controller.init(this);

    // Hack to wait Vue properly get Vuex data and
    // decide whether to show extension warning popup
    // (needed to solve a bug where the popup appears for a few
    // milliseconds on the screen before Vue gets Vuex data)
    if (this.noExtension) { 
      this.showExtensionsWarning = true
    }
  }
};
</script>

    <style>
</style>
