<script>
import Endpoints from '../../../components/common/Endpoints';
import Common from '../../../components/common/Common';
import Request from '../../../components/common/Request';

export default {
    init: async(payload) => {
        const el = document.body;
        el.removeAttribute("class");
        $('.modal').modal('hide');
        payload.domain = Endpoints.domain;//nao remover, usado para apresentar a foto
        payload.filter();
    },
    methods:{
        filter: function(){
            //################### LOAD SERVICE TYPES
            Request.do(this, 'GET', Request.getDefaultHeader(this), null, `${Endpoints.companies.listResources}`,
            (response)=>{//SUCCESS CALLBACK
                this.items = response.results;
            },
            (error)=>{//ERROR CALLBACK
            });
        },
        selectItem: function(item){
            this.$emit('load-item', item);
        },
        camelize: Common.camelize,
        toUpperCase: Common.toUpperCase,
    }
}
</script>
