var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "confirmationSponsor",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "confirmationSponsor",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "confirmationSponsor" }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._m(0)
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } })
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function($event) {
                    return _vm.$emit("confirmation-return", 0)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.cancelText))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function($event) {
                    return _vm.$emit("confirmation-return", 1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.yesText))]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }